import React from 'react';

import { Dialog, DialogTitle, DialogContent, Card, Avatar, Typography, Chip, CircularProgress, Alert, DialogActions, Button } from '@mui/material';

import './TaskAnswerDialog.css';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import getUsername from '../../../utils/apiCaller/accountManager/UsernameGetter';
import getProfilePicture from '../../../utils/apiCaller/accountManager/ProfilePictureGetter';
import getTaskAnswer from '../../../utils/apiCaller/taskManager/TaskAnswerGetter';
import getTaskAnswerByAnswerCode from '../../../utils/apiCaller/taskManager/TaskAnswerByAnswerCodeGetter';
import formatMaterial from '../../../utils/MaterialFormatter';

export default class TaskAnswerDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      waiting: false,
      error: null,
      username: null,
      profilePicture: null,
      answer: null,
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'TaskAnswerDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
         nextProps.open !== this.props.open
      || nextState !== this.state
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open && this.props.open) {
      this.setState({
        waiting: true,
        error: null
      });

      if (this.props.answer) {
        getTaskAnswerByAnswerCode(this.props.classroom, this.props.task, this.props.answer, (status, answer) => {
          this.setState({
            waiting: false
          });
  
          if (status !== 'success') {
            switch (status) {
              case 'not-signed-in':
              case 'account-deleted':
                this.props.onCriticalError(status);
                break;
  
              default:
                this.setState({
                  error: status
                });
            }
            return;
          }
  
          this.setState({
            username: this.props.username,
            profilePicture: this.props.profilePicture,
            answer: answer
          });
        });
      } else {
        getUsername((status, username) => {
          if (status !== 'success') {
            this.props.onCriticalError(status);
            return;
          }
  
          getProfilePicture((status, profilePicture) => {
            if (status !== 'success') {
              this.props.onCriticalError(status);
              return;
            }
  
            getTaskAnswer(this.props.classroom, this.props.task, (status, answer) => {
              this.setState({
                waiting: false
              });
      
              if (status !== 'success') {
                switch (status) {
                  case 'not-signed-in':
                  case 'account-deleted':
                    this.props.onCriticalError(status);
                    break;
      
                  default:
                    this.setState({
                      error: status
                    });
                }
                return;
              }
      
              this.setState({
                username: username,
                profilePicture: profilePicture,
                answer: answer
              });
            });
          });
        });
      }
    }
  }

  render() {
    return (
      <Dialog
        open={
          this.props.open
        }
        onClose={
          () => this.handleClose()
        }
        fullWidth
      >
        <DialogTitle
          className='dialog-title'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </DialogTitle>
        <DialogContent>
          {this.state.answer && (
            <Card
              className='task-answer-dialog-answer'
            >
              <div
                className='answer-title-bar'
              >
                <div
                  className='author'
                >
                  <Avatar
                    className='avatar'
                    src={
                      this.state.profilePicture
                        ? `/profilePictures/${encodeURIComponent(encodeURIComponent(this.state.profilePicture))}`
                        : null
                    }
                  />
                  <Typography
                    variant='body1'
                  >
                    {this.state.username}
                  </Typography>
                </div>
              </div>
              <Typography
                variant='body1'
              >
                {formatMaterial(this.state.answer.content)}
              </Typography>
              <div
                className='attachment-list'
              >
                {this.state.answer.attachments.map((attachment) => (
                  !attachment.filename.endsWith('.mp4') && (
                    <a
                      className='attachment-link'
                      href={
                        `/attachments/taskAnswers/${encodeURIComponent(encodeURIComponent(attachment.attachmentCode))}`
                      }
                      download={
                        attachment.filename
                      }
                    >
                      <Chip
                        className='attachment'
                        label={
                          attachment.filename
                        }
                      />
                    </a>
                  )
                ))}
                <div>
                  {this.state.answer.attachments.map((attachment) => (
                    attachment.filename.endsWith('.mp4') && (
                      <div
                        className='video-container'
                        id={
                          `answer-video-container-${attachment.attachmentCode}`
                        }
                      >
                        <video
                          controls
                          className='video-player'
                          onPlaying={
                            () => this.handleVideoChange(attachment.attachmentCode, true)
                          }
                          onPause={
                            () => this.handleVideoChange(attachment.attachmentCode, false)
                          }
                          onEnded={
                            () => this.handleVideoChange(attachment.attachmentCode, false)
                          }
                        >
                          <source
                            src={
                              `/attachments/taskAnswers/${encodeURIComponent(encodeURIComponent(attachment.attachmentCode))}`
                            }
                          />
                        </video>
                        <Typography
                          variant='body1'
                          className='video-filename'
                        >
                          {attachment.filename}
                        </Typography>
                      </div>
                    )
                  ))}
                </div>
              </div>
            </Card>
          )}
          {(this.state.waiting || this.state.error) && (
            <div
              className='task-answer-dialog-status-container'
            >
              {this.state.waiting && (
                <CircularProgress
                  color='primary'
                />
              )}
              {this.state.error === 'no-connection' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.noConnectionError}
                </Alert>
              )}
              {this.state.error === 'unknown-error' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.unknownError}
                </Alert>
              )}
              {this.state.error === 'invalid-task-code' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.taskCodeError}
                </Alert>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color={
              this.props.accountType === 'teacher'
                ? 'secondary'
                : 'primary'
            }
            disabled={
              this.state.waiting
            }
            onClick={
              () => this.handleClose()
            }
          >
            {this.state.languageFile && this.state.languageFile.ok}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleVideoChange(attachmentCode, playing) {
    const videoContainer = document.getElementById(`answer-video-container-${attachmentCode}`);
    if (playing)
      videoContainer.classList.add('playing');
    else
      videoContainer.classList.remove('playing');
  }

  handleClose() {
    this.setState({
      waiting: false,
      error: null,
      answer: null
    });

    this.props.onClose();
  }
}