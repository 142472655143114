import React from 'react';

import { Typography } from '@mui/material';

import './Store.css';

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';

export default class Store extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'Store', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  render() {
    return (
      <main
        className='store'
      >
        <div
          className='store-empty'
        >
          <Typography
            variant='h4'
          >
            {this.state.languageFile && this.state.languageFile.storeEmpty}
          </Typography>
          <Typography
            variant='h5'
          >
            {this.state.languageFile && this.state.languageFile.storeEmptyDescription}
          </Typography>
        </div>
      </main>
    );
  }
}