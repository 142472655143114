import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { Typography, Card, Button, Box, IconButton } from "@mui/material";

import "./AccountTypeMenu.css";

import increaseTrafficCounter from '../../utils/apiCaller/TrafficCounterIncreaser';
import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import isSignedIn from "../../utils/apiCaller/accountManager/SignInStatusChecker";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

class AccountTypeMenuComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null
    };
  }

    componentDidMount() {
      increaseTrafficCounter((status) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }
      });

      getLanguageFile(this.props.language, 'AccountTypeMenu', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });

        isSignedIn((status, signedIn) => {
            if (status !== "success") {
                this.props.onCriticalError(status);
                return;
            }

            if (signedIn) {
                let navigate = this.props.navigate;
                navigate("/cockpit");
            }
        });
    }

    render() {
        return (
            <main 
              className="account-type-menu"
            >
              <Box 
                className="title-container"
              > 
                { process.env.REACT_APP_PLATFORM==='app' &&
                  <Link 
                    to='/' 
                    className="back-icon"
                  >
                    <IconButton>
                      <ArrowBackIcon 
                        fontSize="large" 
                      />
                    </IconButton>
                  </Link>
                }
                <Typography 
                  className="title" 
                  variant="h4"
                >
                  {this.state.languageFile && this.state.languageFile.prompt}
                </Typography>
                { process.env.REACT_APP_PLATFORM==='app' && 
                  <Box 
                    className="right-alignment"
                  >
                    <IconButton>
                      <ArrowBackIcon 
                        fontSize="large" 
                      />
                    </IconButton>
                  </Box>}
              </Box>
              <Box 
                className="vertical-center"
              >
                <div 
                  className="account-types"
                >
                    <Card 
                      className="account-type" 
                      sx={{ 
                        backgroundColor: "primary.dark" 
                      }}
                    >
                        <div 
                          className="text"
                        >
                            <Typography 
                              variant="h5"
                            >
                              {this.state.languageFile && this.state.languageFile.studentAccount}
                            </Typography>
                            <Typography 
                              variant="body1"
                            >
                              {this.state.languageFile && this.state.languageFile.studentAccountDescription}
                            </Typography>
                        </div>
                        <Link 
                          className="button-link" 
                          to="/signup/student"
                        >
                            <Button 
                              className="account-type-button" 
                              variant="outlined"
                            >
                              {this.state.languageFile && this.state.languageFile.create}
                            </Button>
                        </Link>
                    </Card>
                    <Card 
                      className="account-type" 
                      sx={{ 
                        backgroundColor: "secondary.dark"
                      }}
                    >
                        <div 
                          className="text"
                        >
                            <Typography
                              variant="h5"
                            >
                              {this.state.languageFile && this.state.languageFile.teacherAccount}
                            </Typography>
                            <Typography
                              variant="body1"
                            >
                              {this.state.languageFile && this.state.languageFile.teacherAccountDescription}
                            </Typography>
                        </div>
                        <Link 
                          className="button-link" 
                          to="/signup/teacher"
                        >
                            <Button 
                              className="account-type-button" 
                              variant="outlined"
                            >
                              {this.state.languageFile && this.state.languageFile.create}
                            </Button>
                        </Link>
                    </Card>
                </div>
              </Box>              
            </main>
        );
    }
}

export default function AccountTypeMenu(props) {
    return <AccountTypeMenuComponent {...props} navigate={useNavigate()}/>
};