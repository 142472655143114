import { callApi } from '../ApiCaller';

export default function createGradeStudentAccount(gradeCode, email, username, password, onFinish) {
  callApi('createGradeStudentAccount', {
    gradeCode: gradeCode,
    studentEmail: email,
    username: username,
    password: password
  }, (callStatus, httpStatus, studentCode) => {
    if (callStatus !== 'success') {
      onFinish(callStatus);
      return;
    }

    switch (httpStatus) {
      case 200:
        onFinish('success', studentCode);
        break;

      case 401:
        onFinish('not-signed-in');
        break;

      case 403:
        onFinish('account-deleted');
        break;

      case 404:
        onFinish('invalid-grade-code');
        break;

      case 409:
        onFinish('account-already-exists');
        break;

      default:
        onFinish('unknown-error');
    }
  });
}