import React from 'react';
import './GradeRenameDialog.css';

import { Dialog, DialogTitle, DialogContent, TextField, CircularProgress, Alert, DialogActions, Button } from '@mui/material';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import renameGrade from '../../../utils/apiCaller/gradeManager/GradeRenamer';

export default class GradeRenameDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      waiting: false,
      error: null,
      canBeSent: false
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'GradeRenameDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  render() {
    return (
      <Dialog
        open={
          this.props.open
        }
        onClose={
          () => this.handleClose()
        }
        fullWidth
      >
        <DialogTitle
          className='dialog-title'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </DialogTitle>
        <DialogContent>
          <TextField
            id='newGradeName'
            type='text'
            label={
              this.state.languageFile && this.state.languageFile.newGradeName
            }
            autoComplete='none'
            required
            disabled={
              this.state.waiting
            }
            color='warning'
            margin='normal'
            fullWidth
            variant='outlined'
            inputProps={{
              maxLength: 255
            }}
            onChange={
              (e) => this.setState({
                canBeSent: e.target.value.length !== 0
              })
            }
            autoFocus
          />
          {
            (this.state.waiting || this.state.error) && (
              <div
                className='grade-rename-dialog-status-container'
              >
                {this.state.waiting && (
                  <CircularProgress
                    color='warning'
                  />
                )}
                {this.state.error === 'no-connection' && (
                  <Alert
                    severity='error'
                  >
                    {this.state.languageFile && this.state.languageFile.noConnectionError}
                  </Alert>
                )}
                {this.state.error === 'unknown-error' && (
                  <Alert
                    severity='error'
                  >
                    {this.state.languageFile && this.state.languageFile.unknownError}
                  </Alert>
                )}
                {this.state.error === 'invalid-grade-code' && (
                  <Alert
                    severity='error'
                  >
                    {this.state.languageFile && this.state.languageFile.gradeCodeError}
                  </Alert>
                )}
              </div>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button
            color='warning'
            disabled={
              this.state.waiting || !this.state.canBeSent
            }
            onClick={
              () => this.handleSubmit()
            }
          >
            {this.state.languageFile && this.state.languageFile.rename}
          </Button>
          <Button
            color='warning'
            disabled={
              this.state.waiting
            }
            onClick={
              () => this.handleClose()
            }
          >
            {this.state.languageFile && this.state.languageFile.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleClose() {
    this.setState({
      waiting: false,
      error: null,
      canBeSent: false
    });

    this.props.onClose();
  }

  handleSubmit() {
    this.setState({
      waiting: true,
      error: null
    });

    let newGradeName = document.getElementById('newGradeName').value;
    renameGrade(this.props.grade, newGradeName, (status) => {
      this.setState({
        waiting: false
      });

      if (status !== 'success') {
        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.setState({
              error: status
            });
        }
        return;
      }

      this.handleClose();
      this.props.onGradeRenamed(newGradeName);
    });
  }
}