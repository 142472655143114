import React from 'react';

import { Typography, TextField, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';

export default class WritingQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageFile: null,
      answer: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'WritingQuestion', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
         nextProps.question !== this.props.question
      || nextProps.correctAnswer !== this.props.correctAnswer
      || nextState !== this.state
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.question !== prevProps.question || this.props.correctAnswer !== prevProps.correctAnswer) {
      document.querySelector('#answer').value = '';
      this.setState({
        answer: null
      });
    }
  }

  render() {
    return (
      <div
        className='writing-question'
      >
        <Typography
          className='question'
          variant='h4'
        >
          {this.props.question}
        </Typography>
        <div
          className='answer-container'
        >
          <TextField
            id='answer'
            type='text'
            label={
              this.state.languageFile && this.state.languageFile.answer
            }
            autoComplete='none'
            disabled={
              this.state.answer
            }
            margin='normal'
            fullWidth
            variant='outlined'
            inputProps={{
              maxLength: 255
            }}
            autoFocus
            inputRef={
              (input) => {
                if (input)
                  input.focus();
              }
            }
          />
          <Button
            className='answer-check'
            variant='contained'
            disabled={
              this.state.answer
            }
            onClick={
              () => this.handleCheckClicked()
            }
          >
            {this.state.languageFile && this.state.languageFile.check}
          </Button>
        </div>
        {this.state.answer && (
          <>
            {
              this.state.answer.correct
                ? (
                  <>
                    <Typography
                      className='correct-answer'
                    >
                      {this.state.languageFile && this.state.languageFile.correctAnswer}
                    </Typography>
                    <Typography
                      className='correct-answer'
                    >
                      {this.props.question} - {
                        typeof this.props.correctAnswer === 'object'
                          ? this.props.displayCorrectAnswer
                          : this.props.correctAnswer
                      }
                    </Typography>
                  </>
                )
                : (
                  <>
                    <Typography
                      className='wrong-answer'
                    >
                      {this.state.languageFile && this.state.languageFile.incorrectAnswer}
                    </Typography>
                    <Typography
                      className='wrong-answer'
                    >
                      {this.props.question} - {
                        typeof this.props.correctAnswer === 'object'
                          ? this.props.displayCorrectAnswer
                          : this.props.correctAnswer
                      }
                    </Typography>
                  </>
                )
            }
            <div
              className='next-link-container'
            >
              <Typography
                className='next-link'
                onClick={
                  () => this.handleNextClicked()
                }
              >
                <ArrowForwardIcon
                  className='next-link-icon'
                />
                {this.state.languageFile && this.state.languageFile.next}
              </Typography>
            </div>
          </>
        )}
      </div>
    );
  }

  handleCheckClicked() {
    const answer = document.querySelector('#answer').value;
    this.setState({
      answer: {
        correct: this.checkAnswer(answer, this.props.correctAnswer)
      }
    });
  }

  handleNextClicked() {
    this.props.onNextQuestionRequested();
  }

  checkAnswer(answer) {
    switch (typeof this.props.correctAnswer) {
      case 'string':
        return this.compareAnswer(answer, this.props.correctAnswer);

      case 'object':
        for (let currentAnswer of this.props.correctAnswer) {
          if (this.compareAnswer(answer, currentAnswer))
            return true;
        }

        break;

      default:
    }

    return false;
  }

  compareAnswer(answer, correctAnswer) {
    const answerWords = answer
      .trim()
      .toLowerCase()
      .replaceAll('?', '')
      .replaceAll('.', '')
      .replaceAll(',', '')
      .split(' ')
      .filter((word) => word.length !== 0);

    const correctAnswerWords = correctAnswer
      .trim()
      .replaceAll('?', '')
      .replaceAll('.', '')
      .replaceAll(',', '')
      .toLowerCase()
      .split(' ')
      .filter((word) => word.length !== 0);

    if (answerWords.length !== correctAnswerWords.length)
      return false;

    for (let i = 0; i < answerWords.length; i++) {
      if (answerWords[i] !== correctAnswerWords[i])
        return false;
    }

    return true;
  }
}