import React from 'react';
import { Link } from 'react-router-dom';

import { Card, Typography, IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import './Lesson.css';

export default class Lesson extends React.Component {
  render() {
    return this.props.refresh
      ? (
        <a
          className='lesson-link'
          href={
            this.props.lessonURL
          }
        >
          <Card
            className='lesson'
          >
            <div
              className='lesson-information'
            >
              <Typography
                variant='h5'
              >
                {this.props.title}
              </Typography>
              <Typography
                variant='body1'
              >
                {this.props.description}
              </Typography>
            </div>
            <IconButton
              className='button'
              title='Przejdź do lekcji'
            >
              <ArrowForwardIcon/>
            </IconButton>
          </Card>
        </a>
      ) : (
        <Link
          className='lesson-link'
          to={
            this.props.lessonURL
          }
        >
          <Card
            className='lesson'
          >
            <div
              className='lesson-information'
            >
              <Typography
                variant='h5'
              >
                {this.props.title}
              </Typography>
              <Typography
                variant='body1'
              >
                {this.props.description}
              </Typography>
            </div>
            <IconButton
              className='button'
              title='Przejdź do lekcji'
            >
              <ArrowForwardIcon/>
            </IconButton>
          </Card>
        </Link>
      );
  }
}