function setCookie(key, value) {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(value);
    document.cookie = `${encodedKey}=${encodedValue}`;
}

function getCookie(key) {
    let encodedKey = encodeURIComponent(key);
    let cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
        let keyValue = cookie.split("=");
        let currentKey = keyValue[0];
        let currentValue = keyValue[1];

        if (currentKey === encodedKey)
            return encodeURIComponent(currentValue);
    }
}

function areCookiesAccepted() {
    return getCookie("cookies") === "true";
}

function acceptCookies() {
    setCookie("cookies", "true");
}

export { areCookiesAccepted, acceptCookies };