import React from "react";
import { useNavigate } from "react-router-dom";

import { Typography, Alert } from "@mui/material";

import "./ClassroomCreator.css";
import ClassroomCreationForm from "../../components/forms/classroomCreationForm/ClassroomCreationForm";

import getAccountType from "../../utils/apiCaller/accountManager/AccountTypeGetter";

class ClassroomCreatorComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            error: null
        };
    }

    componentDidMount() {
        getAccountType((status, accountType) => {
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                        let navigate = this.props.navigate;
                        navigate("/signin");
                        break;

                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            if (accountType !== "teacher") {
                let navigate = this.props.navigate;
                navigate("/cockpit");
            }
        });
    }

    render() {
        return (
            <main 
              className="classroom-creator"
            >
                <Typography 
                  className="title" 
                  variant="h4"
                >
                  Tworzenie nowej klasy
                </Typography>
                {!this.state.error ? (
                    <ClassroomCreationForm 
                      onCriticalError={
                        this.props.onCriticalError
                      }
                    />
                ) : (
                    <>
                        {this.state.error === "no-connection" && (
                            <Alert 
                              severity="error"
                            >
                              Wystąpił błąd podczas łączenia z serwerem. Sprawdź swoje połączenie z internetem.
                            </Alert>
                        )}
                        {this.state.error === "unknown-error" && (
                            <Alert 
                              severity="error"
                            >
                              Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.
                            </Alert>
                        )}
                    </>
                )}
            </main>
        );
    }
}

export default function ClassroomCreator(props) {
    return <ClassroomCreatorComponent {...props} navigate={useNavigate()}/>
};