import React from "react";
import { useNavigate } from "react-router-dom";

import { TextField, Button, CircularProgress, Alert } from "@mui/material";

import "./PasswordChangeForm.css";

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import recoverySetPassword from "../../../utils/apiCaller/accountManager/RecoveryPasswordSetter";

class PasswordChangeFormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          languageFile: null,
            waiting: false,
            error: null
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'PasswordChangeForm', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });
    }

    render() {
        return (
            <div 
              className="password-change-form-container"
            >
                <form 
                  className="password-change-form" 
                  onSubmit={
                    (e) => this.handleSubmit(e)
                  }
                >
                    <div 
                      className="fields"
                    >
                        <TextField 
                          name="password" 
                          type="password"
                          label={
                            this.state.languageFile && this.state.languageFile.password
                          }
                          required 
                          disabled={
                            this.state.waiting
                          } 
                          margin="normal" 
                          fullWidth 
                          variant="outlined"
                          inputRef={
                            (input) => input && input.focus()
                          }
                          inputProps={{
                            maxLength: 255
                          }}
                        />
                        <TextField 
                          name="passwordRepeat" 
                          type="password" 
                          label={
                            this.state.languageFile && this.state.languageFile.passwordRepeat
                          }
                          required 
                          disabled={
                            this.state.waiting
                          } 
                          margin="normal" 
                          fullWidth 
                          variant="outlined"
                          inputProps={{
                            maxLength: 255
                          }}
                        />
                    </div>
                    <Button 
                      type="submit" 
                      disabled={
                        this.state.waiting
                      } 
                      fullWidth 
                      variant="contained"
                    >
                      {this.state.languageFile && this.state.languageFile.change}
                    </Button>
                </form>
                {this.state.waiting && (
                    <CircularProgress/>
                )}
                {this.state.error === "invalid-password-repeat" && (
                    <Alert 
                      severity="error"
                    >
                      {this.state.languageFile && this.state.languageFile.passwordError}
                    </Alert>
                )}
                {this.state.error === "no-connection" && (
                    <Alert 
                      severity="error"
                    >
                      {this.state.languageFile && this.state.languageFile.noConnectionError}
                    </Alert>
                )}
                {this.state.error === "unknown-error" && (
                    <Alert 
                      severity="error"
                    >
                      {this.state.languageFile && this.state.languageFile.unknownError}
                    </Alert>
                )}
                {this.state.error === "invalid-recovery-code" && (
                    <Alert 
                      severity="error"
                    >
                      {this.state.languageFile && this.state.languageFile.recoveryCodeError}
                    </Alert>
                )}
            </div>
        );
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({
            waiting: true,
            error: null
        });

        let fields = e.target.elements;
        if (fields["password"].value !== fields["passwordRepeat"].value) {
            this.setState({
                waiting: false,
                error: "invalid-password-repeat"
            });
            return;
        }

        recoverySetPassword(this.props.recoveryCode, fields["password"].value, (status) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                this.setState({ error: status });
                return;
            }

            let navigate = this.props.navigate;
            navigate("/changepassword/success");
        });
    }
}

export default function PasswordChangeForm(props) {
    return <PasswordChangeFormComponent {...props} navigate={useNavigate()}/>
};