import { callApi } from "../ApiCaller";

function getAccountType(onFinish) {
    callApi("getAccountType", {}, (callStatus, httpStatus, accountType) => {
        if (callStatus !== "success") {
            onFinish(callStatus, null);
            return;
        }

        switch (httpStatus) {
            case 200:
                onFinish("success", accountType);
                break;

            case 401:
                onFinish("not-signed-in", null);
                break;

            case 403:
                onFinish("account-deleted", null);
                break;

            default:
                onFinish("unknown-error", null);
        }
    });
}

export default getAccountType;