import { callApi } from "../ApiCaller";

function setClassroomLinkEnabled(classroomCode, enabled, onFinish) {
    callApi("setClassroomLinkEnabled", {
        classroomCode: classroomCode,
        enabled: enabled
    }, (callStatus, httpStatus, response) => {
        if (callStatus !== "success") {
            onFinish(callStatus);
            return;
        }

        switch (httpStatus) {
            case 200:
                onFinish("success");
                break;

            case 401:
                onFinish("not-signed-in");
                break;

            case 403:
                onFinish("account-deleted");
                break;

            case 404:
                onFinish("invalid-classroom-code");
                break;

            default:
                onFinish("unknown-error");
        }
    });
}

export default setClassroomLinkEnabled;