import React from "react";
import { useNavigate, Link } from "react-router-dom";

import { Typography } from "@mui/material";

import "./AccountCreationSuccess.css";

import increaseTrafficCounter from '../../utils/apiCaller/TrafficCounterIncreaser';
import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import isSignedIn from "../../utils/apiCaller/accountManager/SignInStatusChecker";

class AccountCreationSuccessComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null
    };
  }

    componentDidMount() {
      increaseTrafficCounter((status) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }
      });

      getLanguageFile(this.props.language, 'AccountCreationSuccess', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });

        isSignedIn((status, signedIn) => {
            if (status !== "success") {
                this.props.onCriticalError(status);
                return;
            }

            if (signedIn) {
                let navigate = this.props.navigate;
                navigate("/cockpit");
            }
        });
    }

    render() {
        return (
            <main 
              className="account-creation-success"
            >
                <Typography 
                  className="title" 
                  variant="h4"
                >
                  {this.state.languageFile && this.state.languageFile.prompt}
                </Typography>
                <Typography 
                  variant="h5"
                >
                  {this.state.languageFile && this.formatText(this.state.languageFile.description)}
                </Typography>
            </main>
        );
    }

  formatText(text) {
    //Text splitted with the "&here;" marker
    const splittedText = text.split('&here;');

    //The formatted text
    return [
      splittedText[0], //The first part of the text before the "here" link
      <Link to='/' className='link'>{this.state.languageFile && this.state.languageFile.here}</Link>, //The "here" link
      splittedText[1] //The second part of the text after the "here" link
    ];
  }
}

export default function AccountCreationSuccess(props) {
    return <AccountCreationSuccessComponent {...props} navigate={useNavigate()}/>
};