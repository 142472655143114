import HttpError from '../HttpError';

//Get a lesson file
export default function getLessonFile(language, lesson, section, onFinish) {
  fetch(`/languages/${encodeURIComponent(language)}/lessons/${encodeURIComponent(lesson)}/${encodeURIComponent(section)}.json`, {
    headers: { "Content-Type": "charset=UTF-8" }
  }).then((response) => {
    if (response.status !== 200)
      throw new HttpError(response.status);

    return response.json();
  }).then((file) => {
    onFinish('success', file);
  }).catch((error) => {
    if (error instanceof TypeError || error instanceof HttpError) {
      onFinish('no-connection');
      return;
    }

    onFinish('unknown-error');
  });
}