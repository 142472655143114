import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

import Flashcard from '../../flashcard/Flashcard';

import './FlashcardDialog.css';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';

export default class FlashcardDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      side: 'front'
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'FlashcardDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  render() {
    return (
      <Dialog
        open={
          this.props.open
        }
        onClose={
          () => this.handleClose()
        }
        fullWidth
      >
        <DialogTitle
          className='dialog-title'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </DialogTitle>
        <DialogContent>
          <div
            className='flashcard-dialog-container'
          >
            {this.props.word && (
              <Flashcard
                language={
                  this.props.language
                }
                word={
                  this.props.word
                }
                translation={
                  this.props.translation
                }
                example={
                  this.props.example
                }
                exampleTranslation={
                  this.props.exampleTranslation
                }
                wordAudio={
                  this.props.wordAudio
                }
                translationAudio={
                  this.props.translationAudio
                }
                exampleAudio={
                  this.props.exampleAudio
                }
                exampleTranslationAudio={
                  this.props.exampleTranslationAudio
                }
                side={
                  this.state.side
                }
                onClick={
                  () => this.flipFlashcard()
                }
                dialog
                visible
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color={
              this.props.color
                ? this.props.color
                : 'primary'
            }
            onClick={
              () => this.handleClose()
            }
          >
            {this.state.languageFile && this.state.languageFile.ok}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleClose() {
    this.setState({
      side: 'front'
    });

    this.props.onClose();
  }

  flipFlashcard() {
    this.setState({
      side: this.state.side === 'back'
        ? 'front'
        : 'back'
    });
  }
}