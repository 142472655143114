import { callApi } from "../ApiCaller";

function signIn(email, password, onFinish) {
    callApi("signIn", {
        email: email,
        password: password
    }, (callStatus, httpStatus) => {
        if (callStatus !== "success") {
            onFinish(callStatus);
            return;
        }

        switch (httpStatus) {
            case 200:
                onFinish("success");
                break;

            case 401:
                onFinish("invalid-credentials");
                break;

            case 403:
                onFinish("email-not-verified");
                break;

            default:
                onFinish("unknown-error");
        }
    });
}

export default signIn;