import React from 'react';

import { Dialog, DialogTitle, DialogContent, TextField, CircularProgress, Alert, DialogActions, Button } from '@mui/material';

import './WordCreationDialog.css';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import createWord from '../../../utils/apiCaller/wordManager/WordCreator';

export default class WordCreationDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      waiting: false,
      error: null,
      canBeSent: false
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'WordCreationDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }
  
  render() {
    return (
      <Dialog
        open={
          this.props.open
        }
        onClose={
          () => this.handleClose()
        }
        fullWidth
      >
        <DialogTitle
          className='dialog-title'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </DialogTitle>
        <DialogContent>
          <TextField
            id='display-word'
            type='text'
            label={
              this.state.languageFile && this.state.languageFile.displayWord
            }
            autoComplete='none'
            disabled={
              this.state.waiting
            }
            margin='normal'
            fullWidth
            variant='outlined'
            color='secondary'
            inputProps={{
              maxLength: 255
            }}
            onChange={
              () => this.handleChange()
            }
            autoFocus
          />
          <TextField
            id='display-translation'
            type='text'
            label={
              this.state.languageFile && this.state.languageFile.displayTranslation
            }
            autoComplete='none'
            disabled={
              this.state.waiting
            }
            margin='normal'
            fullWidth
            variant='outlined'
            color='secondary'
            inputProps={{
              maxLength: 255
            }}
            onChange={
              () => this.handleChange()
            }
          />
          {(this.state.waiting || this.state.error) && (
            <div
              className='word-creation-dialog-status-container'
            >
              {this.state.waiting && (
                <CircularProgress
                  color='secondary'
                />
              )}
              {this.state.error === 'no-connection' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.noConnectionError}
                </Alert>
              )}
              {this.state.error === 'unknown-error' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.unknownError}
                </Alert>
              )}
              {this.state.error === 'invalid-word-set-code' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.wordSetCodeError}
                </Alert>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color='secondary'
            disabled={
              this.state.waiting || !this.state.canBeSent
            }
            onClick={
              () => this.handleSubmit()
            }
          >
            {this.state.languageFile && this.state.languageFile.add}
          </Button>
          <Button
            color='secondary'
            disabled={
              this.state.waiting
            }
            onClick={
              () => this.handleClose()
            }
          >
            {this.state.languageFile && this.state.languageFile.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleChange() {
    this.setState({
      canBeSent: (
           document.querySelector('#display-word').value.length !== 0
        && document.querySelector('#display-translation').value.length !== 0
      )
    });
  }

  handleClose() {
    this.setState({
      waiting: false,
      error: null,
      canBeSent: false
    });

    this.props.onClose();
  }

  handleSubmit() {
    this.setState({
      waiting: true,
      error: null
    });

    const displayWord = document.querySelector('#display-word').value;
    const displayTranslation = document.querySelector('#display-translation').value;
    createWord(this.props.classroom, this.props.wordSet.wordSetCode, displayWord, displayTranslation, (status, wordCode) => {
      this.setState({
        waiting: false
      });

      if (status !== 'success') {
        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.setState({
              error: status
            });
        }

        return;
      }

      this.props.onWordCreated({
        wordCode: wordCode,
        displayWord: displayWord,
        displayTranslation: displayTranslation
      });

      this.handleClose();
    });
  }
}