import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';

import LearnGreetings from './LearnGreetings';
import LearnFarewells from './LearnFarewells';
import LearnIntroductions from './LearnIntroductions';
import LearnWellBeing from './LearnWellBeing';
import LearnPersonalLife from './LearnPersonalLife';
import LearnMeetingScheduling from './LearnMeetingScheduling';
import LearnQuestions from './LearnQuestions';
import LearnUsefulPhrases from './LearnUsefulPhrases';
import LearnWeather from './LearnWeather';

import '../LearnLesson.css';

import increaseTrafficCounter from '../../../utils/apiCaller/TrafficCounterIncreaser';
import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';

class LearnLesson1Component extends React.Component {
  constructor(props) {
    super(props);

    const lessonLanguage = new URLSearchParams(window.location.search).get('language');
    this.state = {
      languageFile: null,
      lessonLanguage: lessonLanguage,
      stage: 0,
      stages: [
        <LearnGreetings
          language={
            props.language
          }
          lessonLanguage={
            lessonLanguage
          }
          onStageFinished={
            () => this.handleStageFinished()
          }
          onCriticalError={
            (error) => props.onCriticalError(error)
          }
        />,
        <LearnFarewells
          language={
            props.language
          }
          lessonLanguage={
            lessonLanguage
          }
          onStageFinished={
            () => this.handleStageFinished()
          }
          onCriticalError={
            (error) => props.onCriticalError(error)
          }
        />,
        <LearnIntroductions
          language={
            props.language
          }
          lessonLanguage={
            lessonLanguage
          }
          onStageFinished={
            () => this.handleStageFinished()
          }
          onCriticalError={
            (error) => props.onCriticalError(error)
          }
        />,
        <LearnWellBeing
          language={
            props.language
          }
          lessonLanguage={
            lessonLanguage
          }
          onStageFinished={
            () => this.handleStageFinished()
          }
          onCriticalError={
            (error) => props.onCriticalError(error)
          }
        />,
        <LearnPersonalLife
          language={
            props.language
          }
          lessonLanguage={
            lessonLanguage
          }
          onStageFinished={
            () => this.handleStageFinished()
          }
          onCriticalError={
            (error) => props.onCriticalError(error)
          }
        />,
        <LearnMeetingScheduling
          language={
            props.language
          }
          lessonLanguage={
            lessonLanguage
          }
          onStageFinished={
            () => this.handleStageFinished()
          }
          onCriticalError={
            (error) => props.onCriticalError(error)
          }
        />,
        <LearnQuestions
          language={
            props.language
          }
          lessonLanguage={
            lessonLanguage
          }
          onStageFinished={
            () => this.handleStageFinished()
          }
          onCriticalError={
            (error) => props.onCriticalError(error)
          }
        />,
        <LearnUsefulPhrases
          language={
            props.language
          }
          lessonLanguage={
            lessonLanguage
          }
          onStageFinished={
            () => this.handleStageFinished()
          }
          onCriticalError={
            (error) => props.onCriticalError(error)
          }
        />,
        <LearnWeather
          language={
            props.language
          }
          lessonLanguage={
            lessonLanguage
          }
          onStageFinished={
            () => this.handleStageFinished()
          }
          onCriticalError={
            (error) => props.onCriticalError(error)
          }
        />
      ]
    };
  }

  componentDidMount() {
    increaseTrafficCounter((status) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }
    });

    getLanguageFile(this.props.language, 'LearnLesson1', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  render() {
    return (
      <main
        className='learn-lesson'
      >
        <div
          className='title-bar'
        >
          <Typography
            variant='h4'
          >
            {this.state.languageFile && this.state.languageFile[this.state.lessonLanguage]}
          </Typography>
          <Typography
            variant='h5'
          >
            {this.state.languageFile && this.state.languageFile.description}
          </Typography>
        </div>
        <div
          className='learn-container'
        >
          <div
            className='learn-subcontainer'
          >
            {
              this.state.stages[this.state.stage]
            }
          </div>
        </div>
      </main>
    );
  }

  handleStageFinished() {
    if (this.state.stage + 1 === this.state.stages.length) {
      this.props.navigate('/learn');
      return;
    }

    this.setState({
      stage: this.state.stage + 1
    });
  }
}

export default function LearnLesson1(props) {
  return <LearnLesson1Component
    {
      ...props
    }
    navigate={
      useNavigate()
    }
  />
}