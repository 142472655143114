import { callFormDataApi } from "../ApiCaller";

function createMaterial(classroomCode, content, attachments, onFinish) {
    let formData = new FormData();
    formData.append("classroomCode", classroomCode);
    formData.append("content", content);
    for (let attachment of attachments)
        formData.append("attachments[]", attachment);

    callFormDataApi("createMaterial", formData, (callStatus, httpStatus, material) => {
        if (callStatus !== "success") {
            onFinish(callStatus);
            return;
        }

        switch (httpStatus) {
            case 200:
                onFinish("success", JSON.parse(material));
                break;

            case 401:
                onFinish("not-signed-in");
                break;

            case 403:
                onFinish("account-deleted");
                break;

            case 404:
                onFinish("invalid-classroom-code");
                break;

            default:
                onFinish("unknown-error");
        }
    });
}

export default createMaterial;