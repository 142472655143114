function formatMaterial(content) {
    let result = [];
    for (let i = 0; i < content.length;) {
        let foundLink = content.indexOf("https://", i);
        if (foundLink === -1) {
            result.push(content.substring(i));
            break;
        }

        result.push(content.substring(i, foundLink));
        i = foundLink;

        let linkTarget;
        let foundWhitespace = content.indexOf(" ", i);
        if (foundWhitespace === -1) {
            linkTarget = content.substring(i);
            result.push(<a className="link" href={linkTarget} rel="noreferrer" target="_blank">{linkTarget}</a>)
            break;
        }

        linkTarget = content.substring(i, foundWhitespace);
        result.push(<a className="link" href={linkTarget} rel="noreferrer" target="_blank">{linkTarget}</a>);
        i = foundWhitespace;
    }

    return result;
}

export default formatMaterial;