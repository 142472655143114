import React from "react";

import { Typography, Button } from "@mui/material";

import "./CookieAlert.css";

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import { areCookiesAccepted, acceptCookies } from "../../utils/CookieManager";

class CookieAlert extends React.Component {
    constructor() {
        super();
        this.state = {
          languageFile: null,
          open: !areCookiesAccepted(), /* We need to determine whether the dialog component should actually be rendered, so the closing animation doesn't make it randomly pop up when cookies are already accepted */
          cookiesAccepted: areCookiesAccepted()
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'CookieAlert', (status, file) => {
        if (status !== 'success') {
          //Certain browsers (for example Brave) or adblockers block requests that include the words "cookie alert".
          //Since we are trying to fetch a file named "CookieAlert.json" here, it gets blocked which leads to the "no-connection" error.
          //Unfortunately there's no way to determine whether a request simply couldn't be completed due to internet connection problems or whether it was blocked by the client.
          //The only solution here is to simply ignore the "no-connection" errors and not report them to the user.
          if (status === 'no-connection') {
            return;
          }

          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });
    }

    render() {
        return (
          (this.state.languageFile && this.state.open) && (
            <div 
              className={
                `
                  cookie-alert
                  ${this.state.cookiesAccepted && 'closed' /* Launch the closing animation if the cookies are accepted */}
                `
              }
            >
                <div 
                  className="cookie-alert-content"
                >
                    <Typography 
                      variant="h6" 
                      sx={{
                        marginBottom: "0.25em"
                      }}
                    >
                      {this.state.languageFile && this.state.languageFile.prompt}
                    </Typography>
                    <Typography 
                      variant="body1"
                    >
                      {this.state.languageFile && this.formatText(this.state.languageFile.description)}
                    </Typography>
                    <div 
                      className="button-container"
                    >
                        <Button 
                          className="button" 
                          variant="outlined" 
                          onClick={
                            () => this.handleAcceptCookies()
                          }
                        >
                          {this.state.languageFile && this.state.languageFile.ok}
                        </Button>
                    </div>
                </div>
            </div>
          )
        );
    }

    formatText(text) {
      //Text splitted with the new-line character
      const splittedText = text.split('\n');
  
      //The formatted text
      return [
        splittedText[0], //The first part of the text before the new-line character
        <br/>, //New line
        splittedText[1] //The second part of the text after the new-line character
      ];
    }

    handleAcceptCookies() {
        acceptCookies();
        this.setState({
            cookiesAccepted: true
        });
    }
}

export default CookieAlert;