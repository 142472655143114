import React from "react";
import { Link } from "react-router-dom";

import { List, ListItem, ListItemButton, Avatar, ListItemText, Button, Typography, IconButton } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import GroupIcon from '@mui/icons-material/Group';

import "./ClassroomList.css";
import ClassroomJoinDialog from "../dialogs/classroomJoinDialog/ClassroomJoinDialog";
import ClassroomCreationDialog from "../dialogs/classroomCreationDialog/ClassroomCreationDialog";

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import enumerateClassrooms from "../../utils/apiCaller/classroomManager/ClassroomEnumerator";
import { UPDATE_INTERVAL_TIME } from "../../utils/UpdateIntervalTime";
import fetchClassroomListUpdate from "../../utils/apiCaller/classroomManager/ClassroomListUpdateFetcher";

class ClassroomList extends React.Component {
    constructor() {
        super();
        this.state = {
          languageFile: null,
            classroomList: null,
            updateInterval: null,
            classroomListVisible: true,
            classroomJoinDialogOpen: false,
            classroomCreationDialogOpen: false,
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'ClassroomList', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });

        enumerateClassrooms((status, classrooms) => {
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.props.onError(status);
                        break;
                }
                return;
            }

            this.setState({ classroomList: classrooms });
            this.props.onClassroomListLengthChanged(classrooms.length);

            if (this.state.updateInterval)
                clearInterval(this.state.updateInterval);

            this.setState({
                updateInterval: setInterval(() => {
                    fetchClassroomListUpdate((status, updateAvailable, pendingUpdate) => {
                        if (status !== "success") {
                            this.props.onCriticalError(status);
                            return;
                        }
                        
                        if (updateAvailable) {
                            let addedClassrooms = [];
                            let removedClassrooms = [];
                            let renamedClassrooms = [];
                            for (let update of pendingUpdate) {
                                switch (update["operation"]) {
                                    case "add":
                                        addedClassrooms = [
                                            ...addedClassrooms,
                                            {
                                                classroomCode: update["classroomCode"],
                                                classroomName: update["classroomName"],
                                                classroomNameShort: update['classroomNameShort']
                                            }
                                        ];
                                        break;

                                    case "remove":
                                        removedClassrooms.push(update["classroomCode"]);
                                        break;

                                    case "rename":
                                        renamedClassrooms.push({
                                            classroomCode: update["classroomCode"],
                                            classroomName: update["classroomName"],
                                            classroomNameShort: update['classroomNameShort']
                                        });
                                        break;

                                    default:
                                }
                            }

                            let newClassroomList = [
                                ...this.state.classroomList.filter((classroom) => {
                                    return removedClassrooms.indexOf(classroom.classroomCode) === -1;
                                }),
                                ...addedClassrooms
                            ].map((classroom) => {
                                let renamedClassroom = renamedClassrooms.find((temporaryClassroom) => temporaryClassroom.classroomCode === classroom.classroomCode);
                                if (renamedClassroom)
                                    return renamedClassroom;

                                return classroom;
                            });

                            this.setState({ classroomList: newClassroomList });
                            this.props.onClassroomListLengthChanged(newClassroomList.length);
                        }
                    })
                }, UPDATE_INTERVAL_TIME)
            });
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.updateInterval);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.openClassroom !== this.props.openClassroom || nextProps.newClassroomInformation !== this.props.newClassroomInformation || nextProps.classroomDeleteScheduled !== this.props.classroomDeleteScheduled || nextState !== this.state;
    }

    componentDidUpdate(prevProps) {
        if (this.props.newClassroomInformation !== prevProps.newClassroomInformation && this.props.newClassroomInformation) {
            this.setState({ classroomList: this.state.classroomList.map(classroom => {
                if (classroom.classroomCode === this.props.openClassroom)
                    return {
                      ...classroom,
                      classroomName: this.props.newClassroomInformation.classroomName,
                      classroomNameShort: this.props.newClassroomInformation.classroomNameShort
                    };

                return classroom;
            })});
        }

        if (this.props.classroomDeleteScheduled && this.props.classroomDeleteScheduled !== prevProps.classroomDeleteScheduled) {
            this.setState({ classroomList: this.state.classroomList.filter((classroom) => {
                return classroom.classroomCode !== this.props.openClassroom;
            })});

            this.props.onClassroomDeleteFinished();
      }
    }

    render() {
        return (
          <>
            {this.state.classroomList && (
              <div 
                className={`classroom-list-container 
                  ${this.props.openClassroom 
                    ? "open" 
                    : null} 
                  ${!this.state.classroomListVisible 
                    ? 'hidden' 
                    : null} 
                  ${
                    ((this.props.accountType === "student" || this.props.accountType === 'school_student') && this.state.classroomList.length === 0) 
                      ? "student-classroom-list" 
                      : null}`
                }
              >
                <div 
                  className={
                    `classroom-list-subcontainer
                      ${!this.state.classroomListVisible 
                        ? 'hidden' 
                        : null}`
                  }
                >
                    <div 
                      className="classroom-list-title-container"
                    >
                      <IconButton 
                        title={
                          this.state.languageFile && this.state.languageFile.hideClassrooms
                        }
                        className="hide-button" 
                        onClick={
                          ()=> {
                            this.handleHideClassroomList()
                          }
                        }
                      >
                        <KeyboardDoubleArrowLeftIcon />
                      </IconButton>
                      <Typography 
                        variant="h6" 
                        className="title"
                      >
                        {this.state.languageFile && this.state.languageFile.classrooms}
                      </Typography>
                      <div 
                        className="right-alignment"
                      >
                        <IconButton>
                          <KeyboardDoubleArrowLeftIcon />
                        </IconButton>
                      </div>
                    </div>
                    <List 
                      className="classroom-list" 
                      fullWidth
                    >
                        {this.state.classroomList && (
                            this.state.classroomList.map(
                              (classroom) => (
                                <ListItem 
                                  disablePadding 
                                  fullWidth 
                                  selected={
                                    classroom.classroomCode === this.props.openClassroom
                                  }
                                >
                                    <Link 
                                      className="button-link" 
                                      to={
                                        `?classroom=${encodeURIComponent(classroom.classroomCode)}&tab=materials`
                                      } 
                                      onClick={
                                        () => this.props.onOpenClassroom(classroom.classroomCode)
                                      }
                                    >
                                        <ListItemButton 
                                          className='classroom-button'
                                          fullWidth
                                          disableGutters
                                        >
                                          <Avatar
                                            className='classroom-avatar'
                                            sx={
                                              classroom.classroomNameShort
                                                ? (
                                                  classroom.classroomNameShort.length >= 4
                                                    ? {
                                                      fontSize: '0.6rem'
                                                    }
                                                    : classroom.classroomNameShort.length >= 3
                                                    ? {
                                                      fontSize: '0.8rem'
                                                    }
                                                    : null
                                                )
                                                : null
                                            }
                                          >
                                            {classroom.classroomNameShort
                                              ? classroom.classroomNameShort
                                              : <GroupIcon/>
                                            }
                                          </Avatar>
                                            <ListItemText 
                                              className='classroom-name'
                                              primary={
                                                classroom.classroomName
                                              }
                                            />
                                        </ListItemButton>
                                    </Link>
                                </ListItem>
                            )
                        ))}
                    </List>
                    <div 
                      className="button-container"
                    >
                      { (this.props.accountType === 'teacher' || this.props.accountType === 'school_teacher')  
                        ? <div className="button-link">
                            <Button 
                              variant="contained" 
                              fullWidth 
                              color="secondary"
                              onClick={
                                () => {
                                  this.handleCreateClassroom()
                                }
                              }
                              disabled={
                                this.props.accountType === 'school_teacher'
                              }
                            >
                              {this.state.languageFile && this.state.languageFile.createClassroom}
                            </Button>
                          </div>
                        : (this.props.accountType === 'student' || this.props.accountType === 'school_student') 
                          ? <div 
                              className="button-link"
                            >
                              <Button 
                                variant="contained" 
                                fullWidth
                                onClick={
                                  () => {
                                    this.handleJoinClassroom()
                                  }
                                }
                                disabled={
                                  this.props.accountType === 'school_student'
                                }
                              >
                                {this.state.languageFile && this.state.languageFile.joinClassroom}
                              </Button>
                            </div>   
                          : null                         
                      }
                    </div>
                </div>
                <div 
                  className={`show-button-container 
                    ${!this.state.classroomListVisible 
                      ? 'visible' 
                      : null}`
                  }
                >
                  <div
                    className='show-button-subcontainer'
                  >
                    <IconButton
                      className='show-button' 
                      title={
                        this.state.languageFile && this.state.languageFile.showClassrooms
                      }
                      onClick={
                        ()=> {
                          this.handleShowClassroomList()
                        }
                      }
                    >
                      <KeyboardDoubleArrowRightIcon />
                    </IconButton>   
                  </div>
                  <List 
                    className="classroom-list" 
                    fullWidth
                  >
                    {this.state.classroomList && (
                        this.state.classroomList.map(
                          (classroom) => (
                            <ListItem 
                              disablePadding 
                              fullWidth 
                              selected={
                                classroom.classroomCode === this.props.openClassroom
                              }
                            >
                                <Link 
                                  className="button-link" 
                                  to={
                                    `?classroom=${encodeURIComponent(classroom.classroomCode)}&tab=materials`
                                  } 
                                  onClick={
                                    () => this.props.onOpenClassroom(classroom.classroomCode)
                                  }
                                >
                                    <ListItemButton 
                                      className='classroom-button'
                                      fullWidth
                                      disableGutters
                                    >
                                      <Avatar
                                        className='classroom-avatar'
                                        sx={
                                          classroom.classroomNameShort
                                            ? (
                                              classroom.classroomNameShort.length >= 4
                                                ? {
                                                  fontSize: '0.6rem'
                                                }
                                                : classroom.classroomNameShort.length >= 3
                                                ? {
                                                  fontSize: '0.8rem'
                                                }
                                                : null
                                            )
                                            : null
                                        }
                                      >
                                        {classroom.classroomNameShort
                                          ? classroom.classroomNameShort
                                          : <GroupIcon/>
                                        }
                                      </Avatar>
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                        )
                    ))}
                  </List>
                </div>
              </div>
            )}
            <ClassroomJoinDialog
              language={
                this.props.language
              }
              open={
                this.state.classroomJoinDialogOpen
              }
              onClose={
                () => {
                  this.closeJoinClassroomDialog()
                }
              }
              onCriticalError={
                (error) => {
                  this.props.onCriticalError(error)
                }
              }
            />
            <ClassroomCreationDialog
              language={
                this.props.language
              }
              open={
                this.state.classroomCreationDialogOpen
              }
              onClose={
                () => {
                  this.closeClassroomCreationDialog()
                }
              }
              onCriticalError={
                (error) => {
                  this.props.onCriticalError(error)
                }
              }
              onClassroomCreated={
                (classroomCode, classroomName, classroomNameShort)=>{
                  const newClassroomList = [
                    {
                      classroomCode: classroomCode,
                      classroomName: classroomName,
                      classroomNameShort: classroomNameShort
                    }, 
                    ...this.state.classroomList
                  ];
                  this.setState({
                    classroomList: newClassroomList
                  });
                  this.props.onClassroomListLengthChanged(newClassroomList.length);
                  this.props.onOpenClassroom(classroomCode);
                }
              }
            />
          </>
        );
    }

    closeClassroomCreationDialog(){
      this.setState({
        classroomCreationDialogOpen: false
      });
    }

    handleCreateClassroom(){
      this.setState({
        classroomCreationDialogOpen: true
      });
    }

    closeJoinClassroomDialog(){
      this.setState({
        classroomJoinDialogOpen: false
      });
    }

    handleJoinClassroom(){
      this.setState({
        classroomJoinDialogOpen: true
      }); 
    }

    handleShowClassroomList(){
      this.setState({
        classroomListVisible: true
      });
    }

    handleHideClassroomList(){
      this.setState({
        classroomListVisible: false
      });
    }
}

export default ClassroomList;