import { callApi } from "../ApiCaller";

function getClassroomInformation(classroomCode, onFinish) {
    callApi("getClassroomInformation", {
        classroomCode: classroomCode
    }, (callStatus, httpStatus, classroomInformation) => {
        if (callStatus !== "success") {
            onFinish(callStatus, null);
            return;
        }
        
        switch (httpStatus) {
            case 200:
                onFinish("success", JSON.parse(classroomInformation));
                break;

            case 401:
                onFinish("not-signed-in", null);
                break;

            case 403:
                onFinish("account-deleted", null);
                break;

            case 404:
                onFinish("invalid-classroom-code", null);
                break;

            default:
                onFinish("unknown-error", null);
        }
    });
}

export default getClassroomInformation;