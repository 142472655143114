import React from 'react';

import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import './DailyFunFact.css';

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import getDailyFunFact from '../../utils/apiCaller/dailyFunFacts/DailyFunFactGetter';

export default class DailyFunFact extends React.Component {
    constructor() {
        super();
        this.state = {
            languageFile: null,
            open: true,
            funFact: null
        };
    }

    componentDidMount() {
        getLanguageFile(this.props.language, 'DailyFunFact', (status, file) => {
            if (status !== 'success') {
                this.props.onCriticalError(status);
                return;
            }

            this.setState({
                languageFile: file
            });
        });

        getDailyFunFact(this.props.language, (status, funFact) => {
            if (status !== 'success') {
                this.props.onCriticalError(status);
                return;
            }

            this.setState({
                funFact: funFact
            });
        });
    }

    render() {
        return (
            <>
                {/* daily-fun-fact-container is responsible for hiding the browser's scrollbar during the window's animation */}
                <div
                    className='daily-fun-fact-container'
                >
                    {/* Only show the window when a fun-fact had been fetched from the server, so an empty window doesn't appear */}
                    {(this.state.funFact) && (
                        <Box
                            className={
                                `
                                    daily-fun-fact
                                    ${!this.state.open && 'closed' /* The 'closed' class enables the closing animation */}
                                `
                            }
                            sx={{
                                backgroundColor: this.props.darkMode
                                    ? 'primary.dark'
                                    : 'primary.light'
                            }}
                        >
                            <div
                                className='title-container'
                            >
                                <Typography
                                    className='title'
                                    variant='h6'
                                >
                                    {this.state.languageFile && this.state.languageFile.dailyFunFact}
                                </Typography>
                                <IconButton
                                    className='close-button'
                                    onClick={
                                        () => this.close()
                                    }
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </div>
                            <Typography
                                variant='body1'
                            >
                                {this.state.funFact}
                            </Typography>
                        </Box>
                    )}
                </div>
            </>
        );
    }

    close() {
        this.setState({
            open: false
        });
    }
}