import React from 'react';
import { CircularProgress, Dialog, DialogContent, DialogTitle, TextField, Alert, DialogActions, Button } from '@mui/material';
import requestClassroomJoin from '../../../utils/apiCaller/classroomManager/ClassroomJoinRequester';
import ClassroomJoinRequestSentDialog from '../classroomJoinRequestSentDialog/ClassroomJoinRequestSentDialog';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';

import './ClassroomJoinDialog.css';

export default class ClassroomJoinDialog extends React.Component{
  constructor() {
    super();
    this.state = {
      languageFile: null,
      waiting: false,
      error: null,
      canBeSent: false,
      joinRequestSentDialogOpen: false
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'ClassroomJoinDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  render(){
    return (
      <>
        <Dialog
          open={
            this.props.open
          }
          onClose={
            () => {
              this.handleClose()
            }
          }
          fullWidth
        >
          <DialogTitle
            className='dialog-title'
          >
            {this.state.languageFile && this.state.languageFile.prompt}
          </DialogTitle>
          <DialogContent>
            <TextField
              id='join-link'
              type='text'
              label={
                this.state.languageFile && this.state.languageFile.classroomLink
              }
              autoComplete='none'
              disabled={
                this.state.waiting
              }
              margin='normal'
              fullWidth
              variant='outlined'
              inputProps={{
                maxLength: 255
              }}
              onChange={
                (e) => {
                  this.setState({
                    canBeSent: (e.target.value.startsWith('https://www.novalang.pl/joinclassroom?join_code='))
                  })
                }
              }
              autoFocus
            />
            {(this.state.waiting || this.state.error) && 
              <div className='classroom-join-dialog-status-container'>
                {this.state.waiting && (
                  <CircularProgress />
                )}
                {this.state.error === "no-connection" && (
                  <Alert 
                      severity="error"
                  >
                    {this.state.languageFile && this.state.languageFile.noConnectionError}
                  </Alert>
                )}
                {this.state.error === "unknown-error" && (
                  <Alert 
                    severity="error"
                  >
                    {this.state.languageFile && this.state.languageFile.unknownError}
                  </Alert>
                )}
                {this.state.error === "invalid-join-code" && (
                  <Alert 
                    severity="error"
                  >
                    {this.state.languageFile && this.state.languageFile.joinCodeError}
                  </Alert>
                )}
                {this.state.error === "already-in-classroom" && (
                  <Alert 
                    severity="error"
                  >
                    {this.state.languageFile && this.state.languageFile.alreadyInClassroomError}
                  </Alert>
                )}
              </div>
            }
          </DialogContent>
          <DialogActions>
            <Button 
              disabled={
                this.state.waiting || !this.state.canBeSent 
              }
              onClick={
                () => {this.handleSubmit()}
              }
            >
              {this.state.languageFile && this.state.languageFile.send}
            </Button>
            <Button 
              disabled={
                this.state.waiting
              }
              onClick={
                () => {this.handleClose()}
              }
            >
              {this.state.languageFile && this.state.languageFile.cancel}
            </Button>
          </DialogActions>
        </Dialog>
        <ClassroomJoinRequestSentDialog
          language={
            this.props.language
          }
          open={
            this.state.joinRequestSentDialogOpen
          }
          onClose={
            () => {
              this.closeJoinRequestSentDialog();
            }
          }
          onCriticalError={
            (error) => {
              this.props.onCriticalError(error);
            }
          }
        />
      </>
    );
  }

  closeJoinRequestSentDialog(){
    this.setState({
      joinRequestSentDialogOpen: false
    });
  }

  handleClose(){
    this.setState({
      waiting: false,
      error: null,
      canBeSent: false
    });
    this.props.onClose();
  }

  handleSubmit(){
    this.setState({
      waiting: true,
      error: null 
    });

    const joinLink = document.querySelector('#join-link').value;
    const urlSearchParams = new URLSearchParams(new URL(joinLink).search);

    const joinCode = urlSearchParams.get('join_code');
    requestClassroomJoin(joinCode, (status) => {
      this.setState({
        waiting: false
      });
      if(status !== 'success'){
        switch(status){
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;
          default:
            this.setState({
              error: status
            });
        }
      } else {
        this.handleClose();
        this.setState({
          joinRequestSentDialogOpen: true
        });
      }
    });
  }
}
