import React from 'react';

import { Dialog, DialogTitle, DialogContent, TextField, CircularProgress, Alert, DialogActions, Button } from '@mui/material';

import './WordSetRenameDialog.css';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import renameWordSet from '../../../utils/apiCaller/wordSetManager/WordSetRenamer';

export default class WordSetRenameDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      waiting: false,
      error: null,
      canBeSent: false
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'WordSetRenameDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }
  
  render() {
    return (
      <Dialog
        open={
          this.props.open
        }
        onClose={
          () => this.handleClose()
        }
        fullWidth
      >
        <DialogTitle
          className='dialog-title'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </DialogTitle>
        <DialogContent>
          <TextField
            id='new-word-set-name'
            type='text'
            label={
              this.state.languageFile && this.state.languageFile.newWordSetName
            }
            autoComplete='none'
            required
            disabled={
              this.state.waiting
            }
            color='secondary'
            margin='normal'
            fullWidth
            variant='outlined'
            inputProps={{
              maxLength: 255
            }}
            onChange={
              () => this.handleChange()
            }
            autoFocus
            defaultValue={
              this.props.wordSet && this.props.wordSet.wordSetName
            }
          />
          {(this.state.waiting || this.state.error) && (
            <div
              className='word-set-rename-dialog-status-container'
            >
              {this.state.waiting && (
                <CircularProgress
                  color='secondary'
                />
              )}
              {this.state.error === 'no-connection' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.noConnectionError}
                </Alert>
              )}
              {this.state.error === 'unknown-error' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.unknownError}
                </Alert>
              )}
              {this.state.error === 'invalid-word-set-code' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.wordSetCodeError}
                </Alert>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color='secondary'
            disabled={
              this.state.waiting || !this.state.canBeSent
            }
            onClick={
              () => this.handleSubmit()
            }
          >
            {this.state.languageFile && this.state.languageFile.rename}
          </Button>
          <Button
            color='secondary'
            disabled={
              this.state.waiting
            }
            onClick={
              () => this.handleClose()
            }
          >
            {this.state.languageFile && this.state.languageFile.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleChange() {
    const newWordSetName = document.querySelector('#new-word-set-name').value;
    this.setState({
      canBeSent:
           newWordSetName.length !== 0
        && newWordSetName !== this.props.wordSet.wordSetName
    });
  }

  handleClose() {
    this.setState({
      waiting: false,
      error: null,
      canBeSent: false
    });

    this.props.onClose();
  }

  handleSubmit() {
    this.setState({
      waiting: true,
      error: null
    });

    const newWordSetName = document.querySelector('#new-word-set-name').value;
    renameWordSet(this.props.classroom, this.props.wordSet.wordSetCode, newWordSetName, (status) => {
      this.setState({
        waiting: false
      });
      
      if (status !== 'success') {
        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.setState({
              error: status
            });
        }

        return;
      }

      this.props.onWordSetRenamed(newWordSetName);
      this.handleClose();
    });
  }
}