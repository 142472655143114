import React from 'react';
import { useNavigate } from 'react-router-dom';
import './GradeCreationDialog.css';

import { Dialog, DialogTitle, DialogContent, TextField, CircularProgress, Alert, DialogActions, Button } from '@mui/material';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import createGrade from '../../../utils/apiCaller/gradeManager/GradeCreator';

class GradeCreationDialogComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      waiting: false,
      error: null,
      canBeSent: false
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'GradeCreationDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  render() {
    return (
      <Dialog
        open={
          this.props.open
        }
        onClose={
          () => this.handleClose()
        }
        fullWidth
      >
        <DialogTitle
          className='dialog-title'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </DialogTitle>
        <DialogContent>
          <TextField
            id='grade-name'
            type='text'
            label={
              this.state.languageFile && this.state.languageFile.gradeName
            }
            autoComplete='none'
            disabled={
              this.state.waiting
            }
            margin='normal'
            fullWidth
            variant='outlined'
            color='warning'
            inputProps={{
              maxLength: 255
            }}
            onChange={
              () => this.handleChange()
            }
            autoFocus
          />
          {
            (this.state.waiting || this.state.error) && (
              <div
                className='grade-creation-dialog-status-container'
              >
                {
                  this.state.waiting && (
                    <CircularProgress
                      color='warning'
                    />
                  )
                }
                {
                  this.state.error === 'no-connection' && (
                    <Alert
                      severity='error'
                    >
                      {this.state.languageFile && this.state.languageFile.noConnectionError}
                    </Alert>
                  )
                }
                {
                  this.state.error === 'unknown-error' && (
                    <Alert
                      severity='error'
                    >
                      {this.state.languageFile && this.state.languageFile.unknownError}
                    </Alert>
                  )
                }
              </div>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button
            color='warning'
            disabled={
              this.state.waiting || !this.state.canBeSent
            }
            onClick={
              () => this.handleSubmit()
            }
          >
            {this.state.languageFile && this.state.languageFile.create}
          </Button>
          <Button
            color='warning'
            disabled={
              this.state.waiting
            }
            onClick={
              () => this.handleClose()
            }
          >
            {this.state.languageFile && this.state.languageFile.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleSubmit() {
    this.setState({
      waiting: true,
      error: null
    });

    const gradeName = document.querySelector('#grade-name').value;
    createGrade(gradeName, (status, gradeCode) => {
      this.setState({
        waiting: false
      });

      if (status !== 'success') {
        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.setState({
              error: status
            });
        }
      } else {
        this.props.onGradeCreated(gradeCode, gradeName);
        this.handleClose();
        this.props.navigate(
          `/cockpit?grade=${encodeURIComponent(gradeCode)}`
        );
      }
    });
  }

  handleClose() {
    this.setState({
      waiting: false,
      error: null,
      canBeSent: false
    });
    this.props.onClose();
  }

  handleChange() {
    this.setState({
      canBeSent: document.querySelector('#grade-name').value.length !== 0
    });
  }
}

export default function GradeCreationDialog(props) {
  return (
    <GradeCreationDialogComponent
      {...props}
      navigate={useNavigate()}
    />
  );
}