import React from 'react';
import { useNavigate } from 'react-router-dom';
import './GradeView.css';

import { AppBar, Toolbar, IconButton, Box, Typography, Menu, MenuItem, Tabs, Tab } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import GradeClassroomListView from '../gradeClassroomListView/GradeClassroomListView';
import MemberListView from '../memberListView/MemberListView';
import GradeRenameDialog from '../dialogs/gradeRenameDialog/GradeRenameDialog';
import GradeDeleteDialog from '../dialogs/gradeDeleteDialog/GradeDeleteDialog';

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import getGradeInformation from '../../utils/apiCaller/gradeManager/GradeInformationGetter';
import { UPDATE_INTERVAL_TIME } from '../../utils/UpdateIntervalTime';
import fetchGradeInformationUpdate from '../../utils/apiCaller/gradeManager/GradeInformationUpdateFetcher';

class GradeViewComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      error: null,
      gradeInformation: null,
      gradeMenuOpen: null,
      openTab: this.getTab(new URLSearchParams(window.location.search).get('tab')),
      renameDialogOpen: false,
      deleteDialogOpen: false,
      gradeInformationUpdateInterval: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'GradeView', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });

    this.attemptGradeInformationDownload();
  }

  componentWillUnmount() {
    clearInterval(this.state.gradeInformationUpdateInterval);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
         nextProps.theme !== this.props.theme
      || nextProps.grade !== this.props.grade
      || (nextProps.tabResetPending !== this.props.tabResetPending && nextProps.tabResetPending)
      || nextState !== this.state
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.tabResetPending !== prevProps.tabResetPending && this.props.tabResetPending) {
      this.openTab('classrooms');
      this.props.onTabReset();
    }

    if (this.props.grade !== prevProps.grade)
      this.attemptGradeInformationDownload();
  }

  render() {
    return (
      <div
        className='grade-view'
      >
        <AppBar
          className='title-bar'
          position='static'
          sx={{
            backgroundColor: this.props.theme.palette.mode === 'dark'
              ? null
              : 'warning.dark'
          }}
        >
          <Toolbar
            className='toolbar'
          >
            <IconButton
              className='arrow-back'
              onClick={
                () => this.handleClose()
              }
            >
              <ArrowBackIcon
                className='icon'
              />
            </IconButton>
            <Box
              className='left-box'
            >
              {this.state.gradeInformation && (
                <Typography>
                  {this.state.gradeInformation.gradeName}
                </Typography>
              )}
            </Box>
            <IconButton
              title={
                this.state.languageFile && this.state.languageFile.moreOptions
              }
              onClick={
                () => this.openGradeMenu()
              }
            >
              <MoreVertIcon
                className='icon'
                id='more-vert'
              />
            </IconButton>
            <Menu
              anchorEl={
                document.getElementById('more-vert')
              }
              anchorOrigin={
                {
                  vertical: 'top',
                  horizontal: 'right'
                }
              }
              open={
                this.state.gradeMenuOpen
              }
              onClose={
                () => this.closeGradeMenu()
              }
            >
              <MenuItem
                onClick={
                  () => this.openRenameDialog()
                }
              >
                {this.state.languageFile && this.state.languageFile.rename}
              </MenuItem>
              <MenuItem
                onClick={
                  () => this.openDeleteDialog()
                }
              >
                {this.state.languageFile && this.state.languageFile.delete}
              </MenuItem>
            </Menu>
          </Toolbar>
          <Tabs
            TabIndicatorProps={{
              style: {
                background: 'white'
              }
            }}
            value={
              this.state.openTab
            }
          >
            <Tab
              className='tab'
              label={
                this.state.languageFile && this.state.languageFile.classrooms
              }
              value='classrooms'
              onClick={
                () => this.openTab('classrooms')
              }
            />
            <Tab
              className='tab'
              label={
                this.state.languageFile && this.state.languageFile.members
              }
              value='members'
              onClick={
                () => this.openTab('members')
              }
            />
          </Tabs>
        </AppBar>
        {
          this.state.gradeInformation && (
            <>
              {
                this.state.openTab === 'classrooms' && (
                  <GradeClassroomListView
                    language={
                      this.props.language
                    }
                    grade={
                      this.props.grade
                    }
                    onCriticalError={
                      (error) => this.props.onCriticalError(error)
                    }
                  />
                )
              }
              {
                this.state.openTab === 'members' && (
                  <MemberListView
                    language={
                      this.props.language
                    }
                    accountType='school_admin'
                    grade={
                      this.props.grade
                    }
                    onCriticalError={
                      (error) => this.props.onCriticalError(error)
                    }
                  />
                )
              }
            </> 
          )
        }
        <GradeRenameDialog
          language={
            this.props.language
          }
          open={
            this.state.renameDialogOpen
          }
          grade={
            this.props.grade
          }
          onClose={
            () => this.closeRenameDialog()
          }
          onGradeRenamed={
            (newGradeName) => this.handleGradeRenamed(newGradeName)
          }
          onCancel={
            () => this.closeRenameDialog()
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
        <GradeDeleteDialog
          language={
            this.props.language
          }
          open={
            this.state.deleteDialogOpen
          }
          grade={
            this.props.grade
          }
          onClose={
            () => this.closeDeleteDialog()
          }
          onGradeDeleted={
            () => this.handleGradeDeleted()
          }
          onCancel={
            () => this.closeDeleteDialog()
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
      </div>
    );
  }

  attemptGradeInformationDownload() {
    getGradeInformation(this.props.grade, (status, gradeInformation) => {
      if (status !== 'success') {
        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.setState({
              error: status
            });
        }
        return;
      }

      this.setState({
        gradeInformation: {
          gradeName: gradeInformation.gradeName
        }
      });

      if (this.state.gradeInformationUpdateInterval)
        clearInterval(this.state.gradeInformationUpdateInterval);

      this.setState({
        gradeInformationUpdateInterval: setInterval(() => {
          fetchGradeInformationUpdate(this.props.grade, (status, updateAvailable, pendingUpdate) => {
            if (status !== 'success') {
              switch (status) {
                case 'invalid-fetch-grade-code':
                  let navigate = this.props.navigate;
                  navigate('/cockpit');
                  this.props.onClose();
                  break;

                default:
                  this.props.onCriticalError(status);
              }
              return;
            }

            if (updateAvailable) {
              this.setState({
                gradeInformation: {
                  ...this.state.gradeInformation,
                  gradeName: pendingUpdate.gradeName
                }
              });
            }
          });
        }, UPDATE_INTERVAL_TIME)
      });
    });
  }

  handleClose() {
    let navigate = this.props.navigate;
    navigate('/cockpit');
    this.props.onClose();
  }

  openGradeMenu() {
    this.setState({
      gradeMenuOpen: true
    });
  }

  closeGradeMenu() {
    this.setState({
      gradeMenuOpen: false
    });
  }

  openRenameDialog() {
    this.setState({
      gradeMenuOpen: false,
      renameDialogOpen: true
    });
  }

  closeRenameDialog() {
    this.setState({
      renameDialogOpen: false
    });
  }

  openDeleteDialog() {
    this.setState({
      gradeMenuOpen: false,
      deleteDialogOpen: true
    });
  }

  closeDeleteDialog() {
    this.setState({
      deleteDialogOpen: false
    });
  }

  handleGradeRenamed(newGradeName) {
    this.setState({
      gradeInformation: {
        ...this.state.gradeInformation,
        gradeName: newGradeName
      }
    });
    this.props.onGradeRenamed(newGradeName);
  }

  handleGradeDeleted() {
    this.props.onGradeDeleted();
  }

  getTab(tab) {
    switch (tab) {
      case 'classrooms':
      case 'members':
        return tab;

      default:
        return 'classrooms';
    }
  }

  openTab(tab) {
    this.setState({
      openTab: this.getTab(tab)
    });

    let navigate = this.props.navigate;
    navigate(`/cockpit?grade=${encodeURIComponent(this.props.grade)}&tab=${encodeURIComponent(tab)}`);
  }
}

export default function GradeView(props) {
  return <GradeViewComponent
    {...props}
    theme={useTheme()}
    navigate={useNavigate()}
  />
}