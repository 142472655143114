import { callApi } from "../ApiCaller";

function createAccount(accountType, email, username, password, onFinish) {
    callApi("createAccount", {
        accountType: accountType,
        email: email,
        username: username,
        password: password
    }, (callStatus, httpStatus) => {
        if (callStatus !== "success") {
            onFinish(callStatus);
            return;
        }

        switch (httpStatus) {
            case 200:
                onFinish("success");
                break;

            case 403:
                onFinish('domain-taken');
                break;

            case 409:
                onFinish("account-already-exists");
                break;

            default:
                onFinish("unknown-error");
        }
    });
}

export default createAccount;