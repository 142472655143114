import React from "react";

import { Dialog, DialogTitle, DialogContent, DialogContentText, CircularProgress, Alert, DialogActions, Button } from "@mui/material";

import "./ClassroomLeaveDialog.css";

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import leaveClassroom from "../../../utils/apiCaller/classroomManager/ClassroomLeaver";

class ClassroomLeaveDialog extends React.Component {
    constructor() {
        super();
        this.state = {
          languageFile: null,
            waiting: false,
            error: null
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'ClassroomLeaveDialog', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });
    }

    render() {
        return (
            <Dialog 
              className="classroom-leave-dialog" 
              open={
                this.props.open
              } 
              onClose={
                () => this.handleClose()
              } 
              fullWidth
            >
                <DialogTitle 
                  className="dialog-title"
                >
                  {this.state.languageFile && this.state.languageFile.prompt}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                      {this.state.languageFile && this.state.languageFile.description}
                    </DialogContentText>
                    {
                      (this.state.waiting || this.state.error) && (
                          <div 
                            className="status-container"
                          >
                              {this.state.waiting && (
                                  <CircularProgress/>
                              )}
                              {this.state.error === "no-connection" && (
                                  <Alert 
                                    severity="error"
                                  >
                                    {this.state.languageFile && this.state.languageFile.noConnectionError}
                                  </Alert>
                              )}
                              {this.state.error === "unknown-error" && (
                                  <Alert 
                                    severity="error"
                                  >
                                    {this.state.languageFile && this.state.languageFile.unknownError}
                                  </Alert>
                              )}
                              {this.state.error === "invalid-classroom-code" && (
                                  <Alert 
                                    severity="error"
                                  >
                                    {this.state.languageFile && this.state.languageFile.classroomCodeError}
                                  </Alert>
                              )}
                          </div>
                      )
                    }
                </DialogContent>
                <DialogActions>
                    <Button 
                      disabled={
                        this.state.waiting
                      } 
                      onClick={
                        () => this.handleSubmit()
                      }
                    >
                      {this.state.languageFile && this.state.languageFile.yes}
                    </Button>
                    <Button 
                      disabled={
                        this.state.waiting
                      } 
                      onClick={
                        () => this.handleClose()
                      }
                    >
                      {this.state.languageFile && this.state.languageFile.no}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleClose() {
        this.setState({
            waiting: false,
            error: null
        });

        this.props.onClose();
    }

    handleSubmit() {
        this.setState({
            waiting: true,
            error: null
        });

        leaveClassroom(this.props.classroom, (status) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            this.handleClose();
            this.props.onClassroomLeft();
        });
    }
}

export default ClassroomLeaveDialog;