import { callApi } from "../ApiCaller";

function getClassroomJoinCode(classroomCode, onFinish) {
    callApi("getClassroomJoinCode", {
        classroomCode: classroomCode
    }, (callStatus, httpStatus, classroomJoinCode) => {
        if (callStatus !== "success") {
            onFinish(callStatus);
            return;
        }

        switch (httpStatus) {
            case 200:
                onFinish("success", classroomJoinCode);
                break;

            case 401:
                onFinish("not-signed-in");
                break;

            case 403:
                onFinish("account-deleted");
                break;

            case 404:
                onFinish("invalid-classroom-code");
                break;

            case 409:
                onFinish("classroom-link-not-enabled");
                break;

            default:
                onFinish("unknown-error");
        }
    });
}

export default getClassroomJoinCode;