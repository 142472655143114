import React from "react";
import { Routes, Route } from "react-router-dom";
import AdaptiveRouter from './utils/AdaptiveRouter';

import { ThemeProvider, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";

import Header from "./components/header/Header";
import Homepage from "./pages/homepage/Homepage";
import AccountTypeMenu from "./pages/accountTypeMenu/AccountTypeMenu";
import StudentAccountCreator from "./pages/studentAccountCreator/StudentAccountCreator";
import TeacherAccountCreator from "./pages/teacherAccountCreator/TeacherAccountCreator";
import AccountCreationSuccess from "./pages/accountCreationSuccess/AccountCreationSuccess";
import EmailVerification from "./pages/emailVerification/EmailVerification";
import EmailVerificationSuccess from "./pages/emailVerificationSuccess/EmailVerificationSuccess";
import SignIn from "./pages/signIn/SignIn";
import AccountDelete from "./pages/accountDelete/AccountDelete";
import AccountDeleteSuccess from "./pages/accountDeleteSuccess/AccountDeleteSuccess";
import PasswordRecovery from "./pages/passwordRecovery/PasswordRecovery";
import PasswordRecoverySent from "./pages/passwordRecoverySent/PasswordRecoverySent";
import PasswordChange from "./pages/passwordChange/PasswordChange";
import PasswordChangeSuccess from "./pages/passwordChangeSuccess/PasswordChangeSuccess";
import Cockpit from "./pages/cockpit/Cockpit";
import ClassroomCreator from "./pages/classroomCreator/ClassroomCreator";
import ClassroomJoiner from "./pages/classroomJoiner/ClassroomJoiner";
import ClassroomJoinSuccess from "./pages/classroomJoinSuccess/ClassroomJoinSuccess";
import SettingsView from "./pages/settingsView/SettingsView";
import SignOut from "./pages/signOut/SignOut";
import Store from './pages/store/Store';
import Dictionary from './pages/dictionary/Dictionary';
import DictionaryLesson1 from './pages/dictionary/lesson1/DictionaryLesson1';
import DictionaryLesson2 from './pages/dictionary/lesson2/DictionaryLesson2';
import DictionaryLesson3 from './pages/dictionary/lesson3/DictionaryLesson3';
import DictionaryLesson4 from './pages/dictionary/lesson4/DictionaryLesson4';
import DictionaryLesson5 from './pages/dictionary/lesson5/DictionaryLesson5';
import DictionaryLesson6 from './pages/dictionary/lesson6/DictionaryLesson6';
import DictionaryLesson7 from './pages/dictionary/lesson7/DictionaryLesson7';
import DictionaryLesson8 from './pages/dictionary/lesson8/DictionaryLesson8';
import Learn from './pages/learn/Learn';
import LearnLesson1 from './pages/learn/lesson1/LearnLesson1';
import LearnLesson2 from './pages/learn/lesson2/LearnLesson2';
import LearnLesson3 from './pages/learn/lesson3/LearnLesson3';
import LearnLesson4 from './pages/learn/lesson4/LearnLesson4';
import LearnLesson5 from './pages/learn/lesson5/LearnLesson5';
import LearnLesson6 from './pages/learn/lesson6/LearnLesson6';
import LearnLesson7 from './pages/learn/lesson7/LearnLesson7';
import LearnLesson8 from './pages/learn/lesson8/LearnLesson8';
import Flashcards from './pages/flashcards/Flashcards';
import FlashcardsLesson1 from './pages/flashcards/lesson1/FlashcardsLesson1';
import FlashcardsLesson2 from './pages/flashcards/lesson2/FlashcardsLesson2';
import FlashcardsLesson3 from './pages/flashcards/lesson3/FlashcardsLesson3';
import FlashcardsLesson4 from './pages/flashcards/lesson4/FlashcardsLesson4';
import FlashcardsLesson5 from './pages/flashcards/lesson5/FlashcardsLesson5';
import FlashcardsLesson6 from './pages/flashcards/lesson6/FlashcardsLesson6';
import FlashcardsLesson7 from './pages/flashcards/lesson7/FlashcardsLesson7';
import FlashcardsLesson8 from './pages/flashcards/lesson8/FlashcardsLesson8';
import MatchingGame from './pages/matchingGame/MatchingGame';
import MatchingGameLesson1 from './pages/matchingGame/lesson1/MatchingGameLesson1';
import MatchingGameLesson2 from './pages/matchingGame/lesson2/MatchingGameLesson2';
import MatchingGameLesson3 from './pages/matchingGame/lesson3/MatchingGameLesson3';
import MatchingGameLesson4 from './pages/matchingGame/lesson4/MatchingGameLesson4';
import MatchingGameLesson5 from './pages/matchingGame/lesson5/MatchingGameLesson5';
import MatchingGameLesson6 from './pages/matchingGame/lesson6/MatchingGameLesson6';
import MatchingGameLesson7 from './pages/matchingGame/lesson7/MatchingGameLesson7';
import MatchingGameLesson8 from './pages/matchingGame/lesson8/MatchingGameLesson8';
import Quiz from './pages/quiz/Quiz';
import QuizLesson1 from './pages/quiz/lesson1/QuizLesson1';
import QuizLesson2 from './pages/quiz/lesson2/QuizLesson2';
import QuizLesson3 from './pages/quiz/lesson3/QuizLesson3';
import QuizLesson4 from './pages/quiz/lesson4/QuizLesson4';
import QuizLesson5 from './pages/quiz/lesson5/QuizLesson5';
import QuizLesson6 from './pages/quiz/lesson6/QuizLesson6';
import QuizLesson7 from './pages/quiz/lesson7/QuizLesson7';
import QuizLesson8 from './pages/quiz/lesson8/QuizLesson8';
import Writing from './pages/writing/Writing';
import WritingLesson1 from './pages/writing/lesson1/WritingLesson1';
import WritingLesson2 from './pages/writing/lesson2/WritingLesson2';
import WritingLesson3 from './pages/writing/lesson3/WritingLesson3';
import WritingLesson4 from './pages/writing/lesson4/WritingLesson4';
import WritingLesson5 from './pages/writing/lesson5/WritingLesson5';
import WritingLesson6 from './pages/writing/lesson6/WritingLesson6';
import WritingLesson7 from './pages/writing/lesson7/WritingLesson7';
import WritingLesson8 from './pages/writing/lesson8/WritingLesson8';
import Redirect from "./pages/redirect/Redirect";
import CriticalErrorDialogs from "./components/dialogs/criticalErrorDialogs/CriticalErrorDialogs";
import CookieAlert from "./components/cookieAlert/CookieAlert";
import "./App.css";
import './components/dialogs/GlobalDialogStyle.css';
import getSystemLanguage from './utils/LanguageDetector';
import isSignedIn from "./utils/apiCaller/accountManager/SignInStatusChecker";
import getAccountType from "./utils/apiCaller/accountManager/AccountTypeGetter";

class AppComponent extends React.Component {
  constructor() {
    super();
    let theme = localStorage.getItem('theme');
    if(!theme){
      localStorage.setItem('theme', 'system');
      theme = 'system';
    }
    this.state = {
      language: getSystemLanguage(), //UI language
      error: null,
      theme: theme,
      signedIn: false
    };
  }

  componentDidMount(){
    isSignedIn((status, signedIn) => {
      if(status !== 'success'){
        this.onCriticalError(status);
      }
      else if(signedIn){
        this.setState(
          {signedIn: true}
        );
        getAccountType((status, accountType) => {
          if(status !== 'success'){
            this.onCriticalError(status)
          }
          else if(accountType === 'teacher' || accountType === 'school_teacher'){
            document.getElementsByName('theme-color')[0].setAttribute('content', '#ab47bc');
          }
          else if(accountType === 'school_admin'){
            document.getElementsByName('theme-color')[0].setAttribute('content', '#ef5350');
          }
        });
      }
    });
  }

  render() {
    return (
      <ThemeProvider 
        theme={
          createTheme({
            palette: {
              mode: (this.state.theme === 'dark' || this.state.theme === 'light')
                ? this.state.theme 
                : this.props.prefersDarkScheme
                  ? "dark" 
                  : "light"
            } 
          })
        }
      >
        <CssBaseline/>
        <div 
          className={
            `body theme-${this.state.theme}`
          }
        >
          <AdaptiveRouter>
            { process.env.REACT_APP_PLATFORM==='web' 
              ? (
                <Routes>
                  <Route
                    path='/'
                    element={
                      <Header
                        language={
                          this.state.language
                        }
                        homepageMode={
                          true
                        }
                        onCriticalError={
                          (error) => this.onCriticalError(error)
                        }
                      />
                    }
                  />
                  <Route
                    path='*'
                    element={
                      <Header
                        language={
                          this.state.language
                        }
                        homepageMode={
                          false
                        }
                        onCriticalError={
                          (error) => this.onCriticalError(error)
                        }
                      />
                    }
                  />
                </Routes>
              )
            : process.env.REACT_APP_PLATFORM==='app' 
            ? <Routes>
              <Route 
                path='/' 
              />
              <Route 
                path='/signup/*' 
              />
              <Route 
                path='/signin' 
              />
              <Route
                path='/passwordrecovery/*'
              />
              {!this.state.signedIn && (
                <Route 
                  path='/settings'
                />
              )}
              <Route 
                path='*' 
                element={<Header 
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                } 
              />
            </Routes> 
            : console.error(`Invalid REACT_APP_PLATFORM: ${process.env.REACT_APP_PLATFORM}`) }
            <Routes>
              <Route 
                path="/" 
                element={<Homepage
                    language={
                      this.state.language
                    } 
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route 
                path="/signup" 
                element={<AccountTypeMenu
                  language={
                    this.state.language
                  }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route 
                path="/signup/student" 
                element={<StudentAccountCreator
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route 
                path="/signup/teacher" 
                element={<TeacherAccountCreator
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route 
                path="/signup/success" 
                element={<AccountCreationSuccess
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route 
                path="/verifyemail" 
                element={<EmailVerification
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route 
                path="/verifyemail/success" 
                element={<EmailVerificationSuccess
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route 
                path="/signin" 
                element={<SignIn
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route 
                path="/deleteaccount" 
                element={<AccountDelete
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route 
                path="/deleteaccount/success" 
                element={<AccountDeleteSuccess
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route 
                path="/passwordrecovery" 
                element={<PasswordRecovery
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route 
                path="/passwordrecovery/sent" 
                element={<PasswordRecoverySent
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route 
                path="/changepassword" 
                element={<PasswordChange
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route 
                path="/changepassword/success" 
                element={<PasswordChangeSuccess
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route 
                path="/cockpit" 
                element={<Cockpit
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route 
                path="/createclassroom" 
                element={<ClassroomCreator 
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route 
                path="/joinclassroom" 
                element={<ClassroomJoiner
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route 
                path="/joinclassroom/success" 
                element={<ClassroomJoinSuccess
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route 
                path="/settings" 
                element={<SettingsView
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                    onThemeChanged={
                      (theme) => {
                        this.setState({
                          theme: theme
                        });
                      }
                    }
                    signedIn={this.state.signedIn}
                  />
                }
              />
              <Route 
                path="/signout" 
                element={<SignOut
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/store'
                element={
                  <Store
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/dictionary'
                element={
                  <Dictionary
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/dictionary/lesson1'
                element={
                  <DictionaryLesson1
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/dictionary/lesson2'
                element={
                  <DictionaryLesson2
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/dictionary/lesson3'
                element={
                  <DictionaryLesson3
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/dictionary/lesson4'
                element={
                  <DictionaryLesson4
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/dictionary/lesson5'
                element={
                  <DictionaryLesson5
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/dictionary/lesson6'
                element={
                  <DictionaryLesson6
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/dictionary/lesson7'
                element={
                  <DictionaryLesson7
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/dictionary/lesson8'
                element={
                  <DictionaryLesson8
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/learn'
                element={
                  <Learn
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/learn/lesson1'
                element={
                  <LearnLesson1
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/learn/lesson2'
                element={
                  <LearnLesson2
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/learn/lesson3'
                element={
                  <LearnLesson3
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/learn/lesson4'
                element={
                  <LearnLesson4
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/learn/lesson5'
                element={
                  <LearnLesson5
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/learn/lesson6'
                element={
                  <LearnLesson6
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/learn/lesson7'
                element={
                  <LearnLesson7
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/learn/lesson8'
                element={
                  <LearnLesson8
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/flashcards'
                element={
                  <Flashcards
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/flashcards/lesson1'
                element={
                  <FlashcardsLesson1
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/flashcards/lesson2'
                element={
                  <FlashcardsLesson2
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/flashcards/lesson3'
                element={
                  <FlashcardsLesson3
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/flashcards/lesson4'
                element={
                  <FlashcardsLesson4
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/flashcards/lesson5'
                element={
                  <FlashcardsLesson5
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/flashcards/lesson6'
                element={
                  <FlashcardsLesson6
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/flashcards/lesson7'
                element={
                  <FlashcardsLesson7
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/flashcards/lesson8'
                element={
                  <FlashcardsLesson8
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/match'
                element={
                  <MatchingGame
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/match/lesson1'
                element={
                  <MatchingGameLesson1
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/match/lesson2'
                element={
                  <MatchingGameLesson2
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/match/lesson3'
                element={
                  <MatchingGameLesson3
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/match/lesson4'
                element={
                  <MatchingGameLesson4
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/match/lesson5'
                element={
                  <MatchingGameLesson5
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/match/lesson6'
                element={
                  <MatchingGameLesson6
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/match/lesson7'
                element={
                  <MatchingGameLesson7
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/match/lesson8'
                element={
                  <MatchingGameLesson8
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/quiz'
                element={
                  <Quiz
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/quiz/lesson1'
                element={
                  <QuizLesson1
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/quiz/lesson2'
                element={
                  <QuizLesson2
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/quiz/lesson3'
                element={
                  <QuizLesson3
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/quiz/lesson4'
                element={
                  <QuizLesson4
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/quiz/lesson5'
                element={
                  <QuizLesson5
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/quiz/lesson6'
                element={
                  <QuizLesson6
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/quiz/lesson7'
                element={
                  <QuizLesson7
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/quiz/lesson8'
                element={
                  <QuizLesson8
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/writing'
                element={
                  <Writing
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/writing/lesson1'
                element={
                  <WritingLesson1
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/writing/lesson2'
                element={
                  <WritingLesson2
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/writing/lesson3'
                element={
                  <WritingLesson3
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/writing/lesson4'
                element={
                  <WritingLesson4
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/writing/lesson5'
                element={
                  <WritingLesson5
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/writing/lesson6'
                element={
                  <WritingLesson6
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/writing/lesson7'
                element={
                  <WritingLesson7
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route
                path='/writing/lesson8'
                element={
                  <WritingLesson8
                    language={
                      this.state.language
                    }
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
              <Route 
                path="*" 
                element={<Redirect 
                    onCriticalError={
                      (error) => this.onCriticalError(error)
                    }
                  />
                }
              />
            </Routes>
            <CriticalErrorDialogs
              language={
                this.state.language
              }
              error={
                this.state.error
              } 
              onClose={
                () => this.onCriticalError(null)
              }
              onCriticalError={
                (error) => this.onCriticalError(error)
              }
            />
          </AdaptiveRouter>
          { process.env.REACT_APP_PLATFORM === 'web' && (
            <CookieAlert
              language={
                this.state.language
              }
              onCriticalError={
                (error) => this.onCriticalError(error)
              }
            />
          ) }
        </div>
      </ThemeProvider>
    );
  }

  onCriticalError(error) {
    if (error === null) {
      this.setState({ error: null });
      return;
    }

    if (!this.state.error)
      this.setState({ error: error });
  }
}

export default function App(props) {
  return <AppComponent {...props} prefersDarkScheme={useMediaQuery("(prefers-color-scheme: dark)")}/>
};