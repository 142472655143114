import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { List, ListItemButton, ListItemText, Box, Tabs, Tab, AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import "./SettingsView.css";
import increaseTrafficCounter from '../../utils/apiCaller/TrafficCounterIncreaser';
import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import isSignedIn from "../../utils/apiCaller/accountManager/SignInStatusChecker";
import getAccountType from "../../utils/apiCaller/accountManager/AccountTypeGetter";


import AccountSettings from "../../components/settings/accountSettings/AccountSettings";
import AppearanceSettings from '../../components/settings/appearanceSettings/AppearanceSettings';
import { useTheme } from "@mui/material/styles";

class SettingsViewComponent extends React.Component {
    constructor() {
        super();
        this.state = {
          languageFile: null,
          accountType: null,
          color: null,
          currentTab: null
        };
    }

    componentDidMount() {
      increaseTrafficCounter((status) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }
      });

      getLanguageFile(this.props.language, 'SettingsView', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });

        isSignedIn((status, signedIn) => {
          if(status !== 'success'){
            this.props.onCriticalError(status);
          } else {
            if(signedIn){
              getAccountType((status, accountType) => {
                if (status !== "success") {
                    switch (status) {
                    case "not-signed-in":
                      let navigate = this.props.navigate;
                      navigate("/signin");
                      break;
    
                    default:
                      this.props.onCriticalError(status);
                    }
                    return;
                }
    
                this.setState({ accountType: accountType });
                switch (accountType) {
                    case "student":
                    case "school_student":
                        this.setState({ color: "primary" });
                        break;
    
                    case "teacher":
                    case 'school_teacher':
                        this.setState({ color: "secondary" });
                        break;

                    case 'school_admin':
                      this.setState({ color: 'warning' });
                      break;
    
                    default:
                }
              });
              this.setState({
                currentTab: 'account'
              });
            } else {
              this.setState({
                color: 'primary',
                currentTab: 'appearance'
              });
            }
          }
        });
    }

    componentWillUnmount() {
      if(this.state.updateInterval){
        clearInterval(this.state.updateInterval);
      }
    }

    render() {
        return (
            <main 
              className="settings-view-container"
            >
              {(process.env.REACT_APP_PLATFORM === 'app' && !this.props.signedIn) && (
                <AppBar
                  position='static'
                  sx={{
                    backgroundColor: this.props.theme.palette.mode === 'dark' 
                      ? null 
                      : `${this.state.color}.dark`
                  }}
                >
                  <Toolbar>
                    <Link
                      to='/'
                      className="back-icon"
                    >
                      <IconButton>
                        <ArrowBackIcon sx={{
                          color: "white"
                        }}/>
                      </IconButton>
                    </Link>
                    <Typography
                      variant='h5'
                      className='setts-title'
                    >
                      {this.state.languageFile && this.state.languageFile.prompt}
                    </Typography>
                  </Toolbar>
                </AppBar>
              )}
              <Box
                className='settings-view'
              >
                <List
                  className="setts-list-desk"
                >
                  <Typography
                    variant='h6'
                    className="list-title"
                  >
                    {this.state.languageFile && this.state.languageFile.prompt}
                  </Typography>
                  {this.state.accountType && (
                    <>
                      <ListItemButton
                        selected={
                          this.state.currentTab === 'account'
                        }
                        onClick={
                          () => {
                            this.openTab('account')
                          }
                        }
                      >
                        <ListItemText>
                          {this.state.languageFile && this.state.languageFile.account}
                        </ListItemText>
                      </ListItemButton>
                    </>
                  )}
                  <ListItemButton
                    selected={
                      this.state.currentTab === 'appearance'
                    }
                    onClick={
                      () => {
                        this.openTab('appearance')
                      }
                    }
                  >
                    <ListItemText>
                      {this.state.languageFile && this.state.languageFile.appearance}
                    </ListItemText>
                  </ListItemButton>
                </List>
                <Box
                  className="setts-view"
                >
                  {this.state.color && (
                    <>
                      <AppBar
                        className="setts-list-mob"
                        position='static'
                        sx={{
                          backgroundColor: this.props.theme.palette.mode === 'dark' 
                            ? null 
                            : `${this.state.color}.dark`
                        }}
                      >
                        <Tabs
                          TabIndicatorProps={{
                            style: {
                              background: 'white'
                            }
                          }} 
                          value={
                            this.state.currentTab
                          }
                        >
                          {this.state.accountType && (
                            <Tab 
                              className="tab-title"
                              label={
                                this.state.languageFile && this.state.languageFile.account
                              }
                              value='account'
                              onClick={
                                () => {
                                  this.openTab('account')
                                }
                              }
                            />
                          )}
                          <Tab 
                            className="tab-title"
                            label={
                              this.state.languageFile && this.state.languageFile.appearance
                            }
                            value='appearance'
                            onClick={
                              () => {
                                this.openTab('appearance')
                              }
                            }
                          />
                        </Tabs>
                      </AppBar>
                      {this.state.currentTab === 'account' && (
                        <AccountSettings
                          language={
                            this.props.language
                          }
                          accountType={
                            this.state.accountType
                          }
                          color={
                            this.state.color
                          }
                          onCriticalError={
                            (error) => this.props.onCriticalError(error)
                          }
                        />
                      )}
                      {this.state.currentTab === 'appearance' && (
                        <AppearanceSettings
                          language={
                            this.props.language
                          }
                          color={
                            this.state.color
                          }
                          onThemeChanged={
                            (theme) => {
                              this.props.onThemeChanged(theme);
                            }
                          }
                          onCriticalError={
                            (error) => this.props.onCriticalError(error)
                          }
                        />
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </main>
        );
    }

    openTab(tab){
      this.setState({
        currentTab: tab
      });
    }
}

export default function SettingsView(props) {
    return <SettingsViewComponent {...props} navigate={useNavigate()} theme={useTheme()} />
};