import React from 'react';
import { Link } from 'react-router-dom';

import { Dialog, DialogTitle, DialogContent, Card, Typography, IconButton, Menu, MenuItem, CircularProgress, Alert, DialogActions, Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';

import './WordSetDialog.css';
import WordEditDialog from '../wordEditDialog/WordEditDialog';
import WordDeletionDialog from '../wordDeletionDialog/WordDeletionDialog';
import WordCreationDialog from '../wordCreationDialog/WordCreationDialog';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import enumerateWords from '../../../utils/apiCaller/wordSetManager/WordEnumerator';
import { UPDATE_INTERVAL_TIME } from '../../../utils/UpdateIntervalTime';
import fetchWordListUpdate from '../../../utils/apiCaller/wordManager/WordListUpdateFetcher';

export default class WordSetDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageFile: null,
      color: (props.accountType === 'student' || props.accountType === 'school_student')
        ? 'primary'
        : (props.accountType === 'teacher' || props.accountType === 'school_teacher')
          ? 'secondary'
          : null,
      wordList: null,
      wordCreationDialogOpen: false,
      openWordMenu: null,
      openEditDialog: null,
      openDeleteDialog: null,
      practiceMenuOpen: false,
      updateInterval: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'WordSetDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.updateInterval);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
         nextProps.wordSet !== this.props.wordSet
      || nextState !== this.state
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.wordSet && this.props.wordSet !== prevProps.wordSet) {
      if (this.state.updateInterval)
        clearInterval(this.state.updateInterval);

      this.attemptWordListDownload();
    }
  }

  render() {
    return (
      <>
        <Dialog
          open={
            this.props.open
          }
          onClose={
            () => this.handleClose()
          }
          fullWidth
        >
          <DialogTitle
            className='dialog-title'
          >
            {this.state.languageFile && this.state.languageFile.prompt}
          </DialogTitle>
          <DialogContent>
            {this.state.wordList && (
              <>
                <div
                  className='word-set-dialog-word-list'
                >
                  {this.state.wordList.map((word) => (
                    <Card
                      className='word'
                    >
                      <div
                        class='information'
                      >
                        <Typography
                          variant='body1'
                        >
                          {word.displayWord}
                        </Typography>
                      </div>
                      {(this.props.accountType === 'teacher' || this.props.accountType === 'school_teacher') && (
                        <>
                          <IconButton
                            title={
                              this.state.languageFile && this.state.languageFile.moreOptions
                            }
                            onClick={
                              () => this.openWordMenu(word)
                            }
                          >
                            <MoreVertIcon
                              className='icon'
                              id={
                                `${word.wordCode}-more-vert`
                              }
                            />
                          </IconButton>
                          <Menu
                            anchorEl={
                              document.getElementById(`${word.wordCode}-more-vert`)
                            }
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right'
                            }}
                            open={
                              this.state.openWordMenu === word.wordCode
                            }
                            onClose={
                              () => this.closeWordMenu()
                            }
                          >
                            <MenuItem
                              onClick={
                                () => this.openEditDialog(word)
                              }
                            >
                              {this.state.languageFile && this.state.languageFile.edit}
                            </MenuItem>
                            <MenuItem
                              onClick={
                                () => this.openDeleteDialog(word)
                              }
                            >
                              {this.state.languageFile && this.state.languageFile.delete}
                            </MenuItem>
                          </Menu>
                        </>
                      )}
                    </Card>
                  ))}
                </div>
                {(this.props.accountType === 'teacher' || this.props.accountType === 'school_teacher') && (
                  <div
                    className='word-set-dialog-word-creation-panel'
                  >
                    <IconButton
                      className='add-word-button'
                      title={
                        this.state.languageFile && this.state.languageFile.addWord
                      }
                      disabled={
                        this.state.waiting
                      }
                      onClick={
                        () => this.openWordCreationDialog()
                      }
                    >
                      <AddIcon/>
                    </IconButton>
                  </div>
                )}
              </>
            )}
            {(this.state.waiting || this.state.error) && (
              <div
                className='word-set-dialog-status-container'
              >
                {this.state.waiting && (
                  <CircularProgress
                    color={
                      this.state.color
                    }
                  />
                )}
                {this.state.error === 'no-connection' && (
                  <Alert
                    severity='error'
                  >
                    {this.state.languageFile && this.state.languageFile.noConnectionError}
                  </Alert>
                )}
                {this.state.error === 'unknown-error' && (
                  <Alert
                    severity='error'
                  >
                    {this.state.languageFile && this.state.languageFile.unknownError}
                  </Alert>
                )}
                {this.state.error === 'invalid-word-set-code' && (
                  <Alert
                    severity='error'
                  >
                    {this.state.languageFile && this.state.languageFile.wordSetCodeError}
                  </Alert>
                )}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            {(this.props.accountType === 'student' || this.props.accountType === 'school_student') && (
              <>
                <Button
                  id='practice-button'
                  variant='contained'
                  color='primary'
                  disabled={
                    this.state.waiting
                  }
                  onClick={
                    () => this.openPracticeMenu()
                  }
                >
                  {this.state.languageFile && this.state.languageFile.practice}
                </Button>
                <Menu
                  anchorEl={
                    document.getElementById('practice-button')
                  }
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={
                    this.state.practiceMenuOpen
                  }
                  onClose={
                    () => this.closePracticeMenu()
                  }
                >
                  <div
                    className={
                      `word-set-dialog-practice-menu ${this.props.theme.palette.mode}`
                    }
                  >
                    <Link
                      className='word-set-dialog-link'
                      to={
                        `/learn?classroom=${this.props.classroom}&wordset=${this.props.wordSet && encodeURIComponent(this.props.wordSet.wordSetCode)}`
                      }
                    >
                      <MenuItem>
                        {this.state.languageFile && this.state.languageFile.learningMode}
                      </MenuItem>
                    </Link>
                    <Link
                      className='word-set-dialog-link'
                      to={
                        `/flashcards?classroom=${this.props.classroom}&wordset=${this.props.wordSet && encodeURIComponent(this.props.wordSet.wordSetCode)}`
                      }
                    >
                      <MenuItem>
                        {this.state.languageFile && this.state.languageFile.flashcards}
                      </MenuItem>
                    </Link>
                    <Link
                      className='word-set-dialog-link'
                      to={
                        `/match?classroom=${this.props.classroom}&wordset=${this.props.wordSet && encodeURIComponent(this.props.wordSet.wordSetCode)}`
                      }
                    >
                      <MenuItem>
                        {this.state.languageFile && this.state.languageFile.matchingGame}
                      </MenuItem>
                    </Link>
                    <Link
                      className='word-set-dialog-link'
                      to={
                        `/quiz?classroom=${this.props.classroom}&wordset=${this.props.wordSet && encodeURIComponent(this.props.wordSet.wordSetCode)}`
                      }
                    >
                      <MenuItem>
                        {this.state.languageFile && this.state.languageFile.quiz}
                      </MenuItem>
                    </Link>
                    <Link
                      className='word-set-dialog-link'
                      to={
                        `/writing?classroom=${this.props.classroom}&wordset=${this.props.wordSet && encodeURIComponent(this.props.wordSet.wordSetCode)}`
                      }
                    >
                      <MenuItem>
                        {this.state.languageFile && this.state.languageFile.writing}
                      </MenuItem>
                    </Link>
                  </div>
                </Menu>
              </>
            )}
            <Button
              color={
                this.state.color
              }
              disabled={
                this.state.waiting
              }
              onClick={
                () => this.handleClose()
              }
            >
              {this.state.languageFile && this.state.languageFile.ok}
            </Button>
          </DialogActions>
        </Dialog>
        <WordEditDialog
          language={
            this.props.language
          }
          open={
            this.state.openEditDialog
          }
          classroom={
            this.props.classroom
          }
          wordSet={
            this.props.wordSet
          }
          word={
            this.state.openEditDialog
          }
          displayWord={
            this.state.openEditDialog && this.state.openEditDialog.displayWord
          }
          displayTranslation={
            this.state.openEditDialog && this.state.openEditDialog.displayTranslation
          }
          onClose={
            () => this.closeEditDialog()
          }
          onWordEdited={
            (newWord) => this.handleWordEdited(newWord)
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
        <WordDeletionDialog
          language={
            this.props.language
          }
          open={
            this.state.openDeleteDialog
          }
          classroom={
            this.props.classroom
          }
          wordSet={
            this.props.wordSet
          }
          word={
            this.state.openDeleteDialog
          }
          onClose={
            () => this.closeDeleteDialog()
          }
          onWordDeleted={
            () => this.handleWordDeleted()
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
        <WordCreationDialog
          language={
            this.props.language
          }
          open={
            this.state.wordCreationDialogOpen
          }
          classroom={
            this.props.classroom
          }
          wordSet={
            this.props.wordSet
          }
          onClose={
            () => this.closeWordCreationDialog()
          }
          onWordCreated={
            (word) => this.handleWordCreated(word)
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
      </>
    );
  }

  attemptWordListDownload() {
    this.setState({
      waiting: true,
      error: null,
      wordList: null
    });

    enumerateWords(this.props.classroom, this.props.wordSet.wordSetCode, (status, words) => {
      this.setState({
        waiting: false
      });

      if (status !== 'success') {
        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.setState({
              error: status
            });
        }

        return;
      }

      this.setState({
        wordList: words,
        updateInterval: setInterval(() => {
          fetchWordListUpdate(this.props.classroom, this.props.wordSet.wordSetCode, (status, updateAvailable, pendingUpdate) => {
            if (status !== 'success') {
              switch (status) {
                case 'invalid-fetch-word-set-code':
                  break;

                default:
                  this.props.onCriticalError(status);
              }

              return;
            }

            if (updateAvailable) {
              let addedWords = [];
              let removedWords = [];
              for (let update of pendingUpdate) {
                switch (update['operation']) {
                  case 'add':
                    addedWords = [
                      ...addedWords,
                      {
                        wordCode: update['wordCode'],
                        word: update['word']
                      },
                    ];
                    break;

                  case 'remove':
                    removedWords.push(update['wordCode']);
                    break;

                  default:
                }
              }

              this.setState({
                wordList: [
                  ...this.state.wordList.filter((word) => {
                    return removedWords.indexOf(word.wordCode) === -1
                  }),
                  ...addedWords,
                ]
              });
            }
          });
        }, UPDATE_INTERVAL_TIME)
      });
    });
  }

  handleClose() {
    this.props.onClose();
  }

  openWordCreationDialog() {
    this.setState({
      wordCreationDialogOpen: true
    });
  }

  closeWordCreationDialog() {
    this.setState({
      wordCreationDialogOpen: false
    });
  }

  openWordMenu(word) {
    this.setState({
      openWordMenu: word.wordCode
    });
  }

  closeWordMenu() {
    this.setState({
      openWordMenu: null
    });
  }

  openEditDialog(word) {
    this.setState({
      openWordMenu: null,
      openEditDialog: word
    });
  }

  closeEditDialog() {
    this.setState({
      openEditDialog: null
    });
  }

  openDeleteDialog(word) {
    this.setState({
      openWordMenu: null,
      openDeleteDialog: word
    });
  }

  closeDeleteDialog() {
    this.setState({
      openDeleteDialog: null
    });
  }

  openPracticeMenu() {
    this.setState({
      practiceMenuOpen: true
    });
  }

  closePracticeMenu() {
    this.setState({
      practiceMenuOpen: false
    });
  }

  handleWordCreated(word) {
    this.setState({
      wordList: [
        ...this.state.wordList,
        word
      ]
    });
  }

  handleWordEdited(newWord) {
    this.setState({
      wordList: this.state.wordList.map((word) => {
        if (word.wordCode === this.state.openEditDialog.wordCode) {
          return {
            ...word,
            displayWord: newWord.newDisplayWord,
            displayTranslation: newWord.newDisplayTranslation
          };
        }

        return word;
      })
    });
  }

  handleWordDeleted() {
    this.setState({
      wordList: this.state.wordList.filter((word) => {
        return word.wordCode !== this.state.openDeleteDialog.wordCode
      })
    });
  }
}