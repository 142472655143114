import React from "react";

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';

class AccountDeleteRequestedDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'AccountDeleteRequestedDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

    render() {
        return (
            <Dialog 
              className="account-delete-requested-dialog" 
              open={
                this.props.open
              } 
              onClose={
                () => this.handleClose()
              } 
              fullWidth
            >
                <DialogTitle 
                  className="dialog-title"
                >
                  {this.state.languageFile && this.state.languageFile.prompt}
                </DialogTitle>
                <DialogContent 
                  className="account-delete-requested-dialog-content"
                >
                    <DialogContentText>
                      {this.state.languageFile && this.state.languageFile.description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                      color={
                        this.props.color
                      } 
                      onClick={
                        () => this.handleClose()
                      }
                    >
                      {this.state.languageFile && this.state.languageFile.ok}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleClose() {
        this.props.onClose();
    }
}

export default AccountDeleteRequestedDialog;