import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogContentText, CircularProgress, Alert, DialogActions, Button } from '@mui/material';

import './TaskAnswerDeleteDialog.css';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import deleteTaskAnswer from '../../../utils/apiCaller/taskManager/TaskAnswerDeleter';

export default class TaskAnswerDeleteDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      waiting: false,
      error: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'TaskAnswerDeleteDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  render() {
    return (
      <Dialog
        open={
          this.props.open
        }
        onClose={
          () => this.handleClose()
        }
        fullWidth
      >
        <DialogTitle
          className='dialog-title'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.state.languageFile && this.state.languageFile.description}
          </DialogContentText>
          {(this.state.waiting || this.state.error) && (
            <div
              className='task-answer-delete-dialog-status-container'
            >
              {this.state.waiting && (
                <CircularProgress
                  color='primary'
                />
              )}
              {this.state.error === 'no-connection' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.noConnectionError}
                </Alert>
              )}
              {this.state.error === 'unknown-error' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.unknownError}
                </Alert>
              )}
              {this.state.error === 'invalid-task-code' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.taskCodeError}
                </Alert>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            disabled={
              this.state.waiting
            }
            onClick={
              () => this.handleSubmit()
            }
          >
            {this.state.languageFile && this.state.languageFile.yes}
          </Button>
          <Button
            color='primary'
            disabled={
              this.state.waiting
            }
            onClick={
              () => this.handleClose()
            }
          >
            {this.state.languageFile && this.state.languageFile.no}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleClose() {
    this.setState({
      waiting: false,
      error: null
    });

    this.props.onClose();
  }

  handleSubmit() {
    this.setState({
      waiting: true,
      error: null
    });

    deleteTaskAnswer(this.props.classroom, this.props.task, (status) => {
      this.setState({
        waiting: false
      });

      if (status !== 'success') {
        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.setState({
              error: status
            });
        }
        return;
      }

      this.handleClose();
      this.props.onTaskAnswerDeleted();
    });
  }
}