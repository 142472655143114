import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography, CircularProgress, Alert } from '@mui/material';

import WritingQuestion from './WritingQuestion';
import Lesson from '../../components/lesson/Lesson';

import './Writing.css';

import increaseTrafficCounter from '../../utils/apiCaller/TrafficCounterIncreaser';
import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import getWordSetInformation from '../../utils/apiCaller/wordSetManager/WordSetInformationGetter';
import enumerateWords from '../../utils/apiCaller/wordSetManager/WordEnumerator';

class WritingComponent extends React.Component {
  constructor() {
    super();

    const lessonLanguage = new URLSearchParams(window.location.search).get('language');
    const classroom = new URLSearchParams(window.location.search).get('classroom');
    const wordSet = new URLSearchParams(window.location.search).get('wordset');
    this.state = {
      languageFile: null,
      lessonLanguage: lessonLanguage,
      classroom: classroom,
      wordSet: wordSet,
      waiting: false,
      error: null,
      wordSetInformation: null,
      questions: null,
      question: 0
    };
  }

  componentDidMount() {
    increaseTrafficCounter((status) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }
    });

    getLanguageFile(this.props.language, 'Writing', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });

    if (this.state.classroom && this.state.wordSet) {
      this.setState({
        waiting: true
      });

      getWordSetInformation(this.state.classroom, this.state.wordSet, (status, wordSetInformation) => {
        if (status !== 'success') {
          this.setState({
            waiting: false
          });

          switch (status) {
            case 'not-signed-in':
            case 'account-deleted':
              this.props.onCriticalError(status);
              break;

            default:
              this.setState({
                error: status
              });
          }

          return;
        }

        enumerateWords(this.state.classroom, this.state.wordSet, (_status, words) => {
          this.setState({
            waiting: false
          });
  
          if (_status !== 'success') {
            switch (_status) {
              case 'not-signed-in':
              case 'account-deleted':
                this.props.onCriticalError(_status);
                break;
  
              default:
                this.setState({
                  error: _status
                });
            }
  
            return;
          }
  
          this.setState({
            wordSetInformation: wordSetInformation,
            questions: words.map((word) => ({
              question: word.displayTranslation,
              correctAnswer: word.displayWord
            }))
          });
        });
      });
    }
  }

  render() {
    return (
      (this.state.classroom && this.state.wordSet) ? (
        <>
          {(this.state.waiting || this.state.error) && (
            <div
              className='writing-status-container'
            >
              {this.state.waiting && (
                <CircularProgress
                  color='primary'
                />
              )}
              {this.state.error === 'no-connection' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.noConnectionError}
                </Alert>
              )}
              {this.state.error === 'unknown-error' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.unknownError}
                </Alert>
              )}
              {this.state.error === 'invalid-word-set-code' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.wordSetCodeError}
                </Alert>
              )}
            </div>
          )}
          {this.state.questions && (
            <main
              className='writing-lesson'
            >
              <div
                className='title-bar'
              >
                <Typography
                  variant='h4'
                >
                  {this.state.wordSetInformation && this.state.wordSetInformation.classroomName}
                </Typography>
                <Typography
                  variant='h5'
                >
                  {this.state.wordSetInformation && this.state.languageFile[this.state.wordSetInformation.classroomLanguage]}
                </Typography>
              </div>
              <div
                className='writing-container'
              >
                <div
                  className='writing-subcontainer'
                >
                  <div
                    className='writing-section'
                  >
                    <Typography
                      className='section-title'
                      variant='h5'
                    >
                      {this.state.wordSetInformation && this.state.wordSetInformation.wordSetName}
                    </Typography>
                    <div
                      className='section-container'
                    >
                      <WritingQuestion
                        language={
                          this.props.language
                        }
                        question={
                          this.state.questions[this.state.question].question
                        }
                        correctAnswer={
                          this.state.questions[this.state.question].correctAnswer
                        }
                        onNextQuestionRequested={
                          () => this.handleNextQuestionRequested()
                        }
                        onCriticalError={
                          (error) => this.props.onCriticalError(error)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </main>
          )}
        </>
      ) : (
        <main
          className='writing'
        >
          <div
            className='title-bar'
          >
            <Typography
              variant='h4'
            >
              {this.state.languageFile && this.state.languageFile.prompt}
            </Typography>
            <Typography
              variant='h5'
            >
              {this.state.languageFile && this.state.languageFile[this.state.lessonLanguage]}
            </Typography>
          </div>
          <div
            className='lesson-list-container'
          >
            <div
              className='lesson-list-subcontainer'
            >
              {this.state.lessonLanguage
                ? (
                  <>
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.lesson1
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.lesson1Description
                      }
                      lessonURL={
                        `/writing/lesson1?language=${encodeURIComponent(this.state.lessonLanguage)}`
                      }
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.lesson2
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.lesson2Description
                      }
                      lessonURL={
                        `/writing/lesson2?language=${encodeURIComponent(this.state.lessonLanguage)}`
                      }
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.lesson3
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.lesson3Description
                      }
                      lessonURL={
                        `/writing/lesson3?language=${encodeURIComponent(this.state.lessonLanguage)}`
                      }
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.lesson4
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.lesson4Description
                      }
                      lessonURL={
                        `/writing/lesson4?language=${encodeURIComponent(this.state.lessonLanguage)}`
                      }
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.lesson5
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.lesson5Description
                      }
                      lessonURL={
                        `/writing/lesson5?language=${encodeURIComponent(this.state.lessonLanguage)}`
                      }
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.lesson6
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.lesson6Description
                      }
                      lessonURL={
                        `/writing/lesson6?language=${encodeURIComponent(this.state.lessonLanguage)}`
                      }
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.lesson7
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.lesson7Description
                      }
                      lessonURL={
                        `/writing/lesson7?language=${encodeURIComponent(this.state.lessonLanguage)}`
                      }
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.lesson8
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.lesson8Description
                      }
                      lessonURL={
                        `/writing/lesson8?language=${encodeURIComponent(this.state.lessonLanguage)}`
                      }
                    />
                  </>
                ) : (
                  <>
                    {this.props.language !== 'english' && (
                      <Lesson
                        title={
                          this.state.languageFile && this.state.languageFile.prompt
                        }
                        description={
                          this.state.languageFile && this.state.languageFile.english
                        }
                        lessonURL='/writing?language=english'
                        refresh
                      />
                    )}
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.chinese
                      }
                      lessonURL='/writing?language=chinese'
                      refresh
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.german
                      }
                      lessonURL='/writing?language=german'
                      refresh
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.spanish
                      }
                      lessonURL='/writing?language=spanish'
                      refresh
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.french
                      }
                      lessonURL='/writing?language=french'
                      refresh
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.arabic
                      }
                      lessonURL='/writing?language=arabic'
                      refresh
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.russian
                      }
                      lessonURL='/writing?language=russian'
                      refresh
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.italian
                      }
                      lessonURL='/writing?language=italian'
                      refresh
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.japanese
                      }
                      lessonURL='/writing?language=japanese'
                      refresh
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.hebrew
                      }
                      lessonURL='/writing?language=hebrew'
                      refresh
                    />
                    {this.props.language !== 'polish' && (
                      <Lesson
                        title={
                          this.state.languageFile && this.state.languageFile.prompt
                        }
                        description={
                          this.state.languageFile && this.state.languageFile.polish
                        }
                        lessonURL='/writing?language=polish'
                        refresh
                      />
                    )}
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.latin
                      }
                      lessonURL='/writing?language=latin'
                      refresh
                    />
                  </>
                )
              }
            </div>
          </div>
        </main>
      )
    );
  }

  handleNextQuestionRequested() {
    if (this.state.question + 1 === this.state.questions.length) {
      this.props.navigate('/cockpit');
      return;
    }

    this.setState({
      question: this.state.question + 1
    });
  }
}

export default function Writing(props) {
  return <WritingComponent
    {
      ...props
    }
    navigate={
      useNavigate()
    }
  />
}