export default function checkPassword(password) {
  let status = {
    hasEightCharacters: password.length >= 8,
    hasLowercaseLetter: false,
    hasUppercaseLetter: false,
    hasDigit: false,
    hasSymbol: false
  };

  for (let character of password) {
    if (character.toUpperCase() !== character) {
      status.hasLowercaseLetter = true;
      continue;
    }

    if (character.toLowerCase() !== character) {
      status.hasUppercaseLetter = true;
      continue;
    }

    if (/\d/.test(character)) {
      status.hasDigit = true;
      continue;
    }

    const charCode = character.charCodeAt(0);
    if (charCode >= 33 && charCode <= 126)
      status.hasSymbol = true;
  }

  return status;
}