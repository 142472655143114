import React from "react";
import { useNavigate } from "react-router-dom";

import { Typography, CircularProgress, Alert } from "@mui/material";

import "./ClassroomJoiner.css";

import increaseTrafficCounter from '../../utils/apiCaller/TrafficCounterIncreaser';
import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import getAccountType from "../../utils/apiCaller/accountManager/AccountTypeGetter";
import requestClassroomJoin from "../../utils/apiCaller/classroomManager/ClassroomJoinRequester";

class ClassroomJoinerComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            languageFile: null,
            waiting: false,
            error: null
        };
    }

    componentDidMount() {
        increaseTrafficCounter((status) => {
            if (status !== 'success') {
                this.props.onCriticalError(status);
                return;
            }
        });

        getLanguageFile(this.props.language, 'ClassroomJoiner', (status, file) => {
            if (status !== 'success') {
                this.props.onCriticalError(status);
                return;
            }

            this.setState({
                languageFile: file
            });
        });

        getAccountType((status, accountType) => {
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                        let navigate = this.props.navigate;
                        navigate("/signin");
                        break;

                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            if (accountType !== "student") {
                let navigate = this.props.navigate;
                navigate("/cockpit");
                return;
            }

            this.attemptJoin();
        });
    }

    render() {
        return (
            <main 
              className="classroom-joiner"
            >
                <Typography 
                  className="title" 
                  variant="h4"
                >
                  {this.state.languageFile && this.state.languageFile.prompt}
                </Typography>
                <div 
                  className="center-container"
                >
                    {this.state.waiting && (
                        <CircularProgress/>
                    )}
                    {this.state.error === "no-connection" && (
                        <Alert 
                          severity="error"
                        >
                          {this.state.languageFile && this.state.languageFile.noConnectionError}
                        </Alert>
                    )}
                    {this.state.error === "unknown-error" && (
                        <Alert 
                          severity="error"
                        >
                          {this.state.languageFile && this.state.languageFile.unknownError}
                        </Alert>
                    )}
                    {this.state.error === "invalid-join-code" && (
                        <Alert 
                          severity="error"
                        >
                          {this.state.languageFile && this.state.languageFile.joinCodeError}
                        </Alert>
                    )}
                    {this.state.error === "already-in-classroom" && (
                        <Alert 
                          severity="error"
                        >
                          {this.state.languageFile && this.state.languageFile.alreadyInClassroomError}
                        </Alert>
                    )}
                </div>
            </main>
        );
    }

    attemptJoin() {
        this.setState({
            waiting: true,
            error: null
        });

        let joinCode = new URLSearchParams(window.location.search).get("join_code");
        requestClassroomJoin(joinCode, (status) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            let navigate = this.props.navigate;
            navigate("/joinclassroom/success");            
        });
    }
}

export default function ClassroomJoiner(props) {
    return <ClassroomJoinerComponent {...props} navigate={useNavigate()}/>
}