import { callApi } from "../ApiCaller";

function getEmail(onFinish) {
    callApi("getEmail", {}, (callStatus, httpStatus, email) => {
        if (callStatus !== "success") {
            onFinish(callStatus);
            return;
        }

        switch (httpStatus) {
            case 200:
                onFinish("success", email);
                break;

            case 401:
                onFinish("not-signed-in");
                break;

            case 403:
                onFinish("account-deleted");
                break;

            default:
                onFinish("unknown-error");
        }
    });
}

export default getEmail;