import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { Typography, Alert, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./PasswordRecovery.css";
import PasswordRecoveryForm from "../../components/forms/passwordRecoveryForm/PasswordRecoveryForm";

import increaseTrafficCounter from '../../utils/apiCaller/TrafficCounterIncreaser';
import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import isSignedIn from "../../utils/apiCaller/accountManager/SignInStatusChecker";
import { Box } from "@mui/system";

class PasswordRecoveryComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            error: null
        };
    }

    componentDidMount() {
      increaseTrafficCounter((status) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }
      });

      getLanguageFile(this.props.language, 'PasswordRecovery', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });

        isSignedIn((status, signedIn) => {
            if (status !== "success") {
                this.setState({ error: status });
                return;
            }

            if (signedIn) {
                let navigate = this.props.navigate;
                navigate("/cockpit");
            }
        });
    }

    render() {
        return (
            <main 
              className="password-recovery"
            >
                <Box className='title-container'>
                  {process.env.REACT_APP_PLATFORM === 'app' && (
                    <Link
                        to='/signin'
                        className="back-icon"
                    >
                        <IconButton>
                            <ArrowBackIcon 
                                fontSize='large'
                            />
                        </IconButton>
                    </Link>
                  )}
                  <Typography 
                    className="title" 
                    variant="h4"
                  >
                    {this.state.languageFile && this.state.languageFile.prompt}
                  </Typography>
                  {process.env.REACT_APP_PLATFORM === 'app' && (
                    <Box
                        className="right-alignment"
                    >
                        <IconButton>
                            <ArrowBackIcon 
                                fontSize='large'
                            />
                        </IconButton>
                    </Box>
                  )}
                </Box>
                <Box 
                    className='vertical-center'
                >   
                    <Box
                        className="content"
                    >
                      {!this.state.error ? (
                        <PasswordRecoveryForm
                          language={
                            this.props.language
                          }
                          onCriticalError={
                            (error) => this.props.onCriticalError(error)
                          }
                        />
                      ) : (
                        <>
                          {this.state.error === "no-connection" && (
                            <Alert 
                              severity="error"
                            >
                              {this.state.languageFile && this.state.languageFile.noConnectionError}
                            </Alert>
                          )}
                          {this.state.error === "unknown-error" && (
                            <Alert 
                              severity="error"
                            >
                              {this.state.languageFile && this.state.languageFile.unknownError}
                            </Alert>
                          )}
                        </>
                      )}  
                    </Box>
                </Box>
            </main>
        );
    }
}

export default function PasswordRecovery(props) {
    return <PasswordRecoveryComponent {...props} navigate={useNavigate()}/>
};