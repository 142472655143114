import React from 'react';

import { Dialog, DialogTitle, DialogContent, TextField, FormControl, InputLabel, Select, MenuItem, Avatar, Typography, IconButton, CircularProgress, Alert, DialogActions, Button } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';

import './GradeClassroomEditDialog.css';
import StudentAssignmentDialog from '../studentAssignmentDialog/StudentAssignmentDialog';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import enumerateTeacherAccounts from '../../../utils/apiCaller/domainManager/TeacherAccountEnumerator';
import getGradeClassroomStudentList from '../../../utils/apiCaller/gradeManager/GradeClassroomStudentListGetter';
import editGradeClassroom from '../../../utils/apiCaller/gradeManager/GradeClassroomEditor';

export default class GradeClassroomEditDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      waiting: false,
      error: null,
      canBeSent: false,
      teacherAccountList: null,
      studentAccountList: null,
      studentAssignmentDialogOpen: false
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'GradeClassroomEditDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
         nextProps.open !== this.props.open
      || nextState !== this.state
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open && this.props.open) {
      this.setState({
        waiting: true,
        error: null
      });

      enumerateTeacherAccounts((status, teachers) => {
        if (status !== 'success') {
          switch (status) {
            case 'not-signed-in':
            case 'account-deleted':
              this.props.onCriticalError(status);
              break;

            default:
              this.setState({
                waiting: false,
                error: status
              });
          }
          return;
        }

        this.setState({
          teacherAccountList: teachers
        });

        getGradeClassroomStudentList(this.props.grade, this.props.classroom, (status, students) => {
          this.setState({
            waiting: false
          });

          if (status !== 'success') {
            switch (status) {
              case 'not-signed-in':
              case 'account-deleted':
                this.props.onCriticalError(status);
                break;

              default:
                this.setState({
                  error: status
                });
            }
            return;
          }

          this.setState({
            studentAccountList: students
          });
        });
      });
    }
  }

  render() {
    return (
      <Dialog
        open={
          this.props.open
        }
        onClose={
          () => this.handleClose()
        }
        fullWidth
      >
        <DialogTitle
          className='dialog-title'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </DialogTitle>
        <DialogContent>
          {this.state.teacherAccountList && (
            <>
              <TextField
                id='newClassroomName'
                type='text'
                label={
                  this.state.languageFile && this.state.languageFile.newClassroomName
                }
                autoComplete='none'
                required
                disabled={
                  this.state.waiting
                }
                color='warning'
                margin='normal'
                fullWidth
                variant='outlined'
                inputProps={{
                  maxLength: 255
                }}
                onChange={
                  () => this.handleChange(null)
                }
                autoFocus
                defaultValue={
                  this.props.classroomName
                }
              />
              <TextField
                id='newClassroomNameShort'
                type='text'
                label={
                  this.state.languageFile && this.state.languageFile.newClassroomNameShort
                }
                autoComplete='none'
                required
                disabled={
                  this.state.waiting
                }
                margin='normal'
                fullWidth
                variant='outlined'
                color='warning'
                inputProps={{
                  maxLength: 4
                }}
                onChange={
                  () => this.handleChange(null)
                }
                defaultValue={
                  this.props.classroomNameShort
                }
              />
              <FormControl
                disabled={
                  this.state.waiting
                }
                margin='normal'
                fullWidth
                color='warning'
              >
                <InputLabel>
                  {this.state.languageFile && this.state.languageFile.teacher}
                </InputLabel>
                <Select
                  inputProps={{
                    id: 'teacher'
                  }}
                  label={
                    this.state.languageFile && this.state.languageFile.teacher
                  }
                  fullWidth
                  onChange={
                    (e) => this.handleChange(e.target.value)
                  }
                  defaultValue={
                    this.props.teacherId
                  }
                >
                  {
                    this.state.teacherAccountList.map((teacher) => (
                      <MenuItem
                        value={teacher.id}
                      >
                        <div
                          className='grade-classroom-edit-dialog-teacher'
                        >
                          <Avatar
                            className='grade-classroom-edit-dialog-teacher-avatar'
                            src={
                              teacher.profilePicture
                                ? `/profilePictures/${encodeURIComponent(encodeURIComponent(teacher.profilePicture))}`
                                : null
                            }
                          />
                          <div>
                            <Typography
                              variant='body1'
                            >
                              {teacher.username}
                            </Typography>
                            <Typography
                              variant='body1'
                            >
                              {teacher.email}
                            </Typography>
                          </div>
                        </div>
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
              <div
                className='grade-classroom-edit-dialog-button-container'
              >
                <IconButton
                  title={
                    this.state.languageFile && this.state.languageFile.manageStudents
                  }
                  onClick={
                    () => this.openStudentAssignmentDialog()
                  }
                >
                  <GroupIcon/>
                </IconButton>
              </div>
            </>
          )}
          {
            (this.state.waiting || this.state.error) && (
              <div
                className='status-container'
              >
                {this.state.waiting && (
                  <CircularProgress
                    color='warning'
                  />
                )}
                {this.state.error === 'no-connection' && (
                  <Alert
                    severity='error'
                  >
                    {this.state.languageFile && this.state.languageFile.noConnectionError}
                  </Alert>
                )}
                {this.state.error === 'unknown-error' && (
                  <Alert
                    severity='error'
                  >
                    {this.state.languageFile && this.state.languageFile.unknownError}
                  </Alert>
                )}
                {this.state.error === 'invalid-classroom-code' && (
                  <Alert
                    severity='error'
                  >
                    {this.state.languageFile && this.state.languageFile.classroomCodeError}
                  </Alert>
                )}
              </div>
            )
          }
          <StudentAssignmentDialog
            language={
              this.props.language
            }
            editMode={
              true
            }
            grade={
              this.props.grade
            }
            classroom={
              this.props.classroom
            }
            open={
              this.state.studentAssignmentDialogOpen
            }
            onClose={
              () => this.closeStudentAssignmentDialog()
            }
            studentAccountList={
              this.state.studentAccountList
            }
            onStudentAssignmentChanged={
              (studentCode, assigned) => {
                const newStudentList = [...this.state.studentAccountList];
                const student = newStudentList.find((currentStudent) => currentStudent.studentCode === studentCode);
                if (!student)
                  return;

                student.assigned = assigned;
                this.setState({
                  studentAccountList: newStudentList
                });
              }
            }
            onCriticalError={
              (error) => this.props.onCriticalError(error)
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            color='warning'
            disabled={
              this.state.waiting || !this.state.canBeSent
            }
            onClick={
              () => this.handleSubmit()
            }
          >
            {this.state.languageFile && this.state.languageFile.change}
          </Button>
          <Button
            color='warning'
            disabled={
              this.state.waiting
            }
            onClick={
              () => this.handleClose()
            }
          >
            {this.state.languageFile && this.state.languageFile.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleClose() {
    this.setState({
      waiting: false,
      error: null,
      canBeSent: false
    });

    this.props.onClose();
  }

  handleSubmit() {
    this.setState({
      waiting: true,
      error: null
    });

    const newClassroomName = document.querySelector('#newClassroomName').value;
    const newClassroomNameShort = document.querySelector('#newClassroomNameShort').value;
    const newTeacherId = document.querySelector('#teacher').value;
    const teacher = this.state.teacherAccountList.find((currentTeacher) => currentTeacher.id === newTeacherId);
    const teacherProfilePicture = teacher.profilePicture;
    const teacherUsername = teacher.username;
    const teacherEmail = teacher.email;

    editGradeClassroom(this.props.grade, this.props.classroom, newClassroomName, newClassroomNameShort, newTeacherId, (status) => {
      this.setState({
        waiting: false
      });

      if (status !== 'success') {
        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.setState({
              error: status
            });
        }
        return;
      }

      this.handleClose();
      this.props.onClassroomEdited(newClassroomName, newClassroomNameShort, newTeacherId, teacherProfilePicture, teacherUsername, teacherEmail);
    });
  }

  handleChange(teacher) {
    const newClassroomName = document.querySelector('#newClassroomName').value;
    const newClassroomNameShort = document.querySelector('#newClassroomNameShort').value;
    this.setState({
      canBeSent:
           newClassroomName.length !== 0
        && newClassroomNameShort.length !== 0
        && (
             newClassroomName !== this.props.classroomName
          || newClassroomNameShort !== this.props.classroomNameShort
          || (
               (!teacher && document.querySelector('#teacher').value !== this.props.teacherId)
            || (teacher && teacher !== this.props.teacherId)
          )
        )
    });
  }

  openStudentAssignmentDialog() {
    this.setState({
      studentAssignmentDialogOpen: true
    });
  }

  closeStudentAssignmentDialog() {
    this.setState({
      studentAssignmentDialogOpen: false
    });
  }
}