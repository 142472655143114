import React from "react";

import { CircularProgress, Card, Box, Avatar, Typography, IconButton, Menu, MenuItem, Chip, Fab } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";

import "./MaterialView.css";
import MaterialDeleteDialog from "../dialogs/materialDeleteDialog/MaterialDeleteDialog";
import MaterialCreationDialog from "../dialogs/materialCreationDialog/MaterialCreationDialog";
import VideoPlayerDialog from "../dialogs/videoPlayerDialog/VideoPlayerDialog";

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import enumerateMaterials from "../../utils/apiCaller/materialManager/MaterialEnumerator";
import formatMaterial from "../../utils/MaterialFormatter";
import { UPDATE_INTERVAL_TIME } from "../../utils/UpdateIntervalTime";
import fetchMaterialListUpdate from "../../utils/apiCaller/materialManager/MaterialListUpdateFetcher";

class MaterialView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          languageFile: null,
            waiting: false,
            materialList: null,
            openMateralMenu: null,
            openDeleteDialog: null,
            materialCreationDialogOpen: false,
            openVideo: null,
            color: (props.accountType === "student" || props.accountType === 'school_student') ?
                "primary"
            : (props.accountType === "teacher" || props.accountType === 'school_teacher') ?
                "secondary"
            : null,
            updateInterval: null
        };
    }
    
    componentDidMount() {
      getLanguageFile(this.props.language, 'MaterialView', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });

        this.attemptMaterialDownload();
    }

    componentWillUnmount() {
        clearInterval(this.state.updateInterval);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.classroom !== this.props.classroom || nextProps.teacherName !== this.props.teacherName || nextProps.teacherProfilePicture !== this.props.teacherProfilePicture || nextState !== this.state;
    }

    componentDidUpdate(prevProps) {
        if (this.props.classroom !== prevProps.classroom)
            this.attemptMaterialDownload();
    }

    render() {
        return (
            <>
                {this.state.waiting && (
                    <div 
                      className="material-view-status-container"
                    >
                        <CircularProgress 
                          color={
                            this.state.color
                          }
                        />
                    </div>
                )}
                {this.state.materialList && (
                    <>
                        {this.state.materialList.length === 0 && (
                            <div 
                              className="material-view-message-horizontal-center"
                            >
                                <div 
                                  className="material-view-message-vertical-center"
                                >
                                    <Typography 
                                      className="material-view-message material-view-message-title" 
                                      variant="h4"
                                    >
                                      {this.state.languageFile && this.state.languageFile.noMaterials}
                                    </Typography>
                                    {(this.props.accountType === "teacher" || this.props.accountType === 'school_teacher') && (
                                        <Typography 
                                          className="material-view-message" 
                                          variant="h5"
                                        >
                                          {this.state.languageFile && this.state.languageFile.noMaterialsDescription}
                                        </Typography>
                                    )}
                                </div>
                            </div>
                        )}
                        <div 
                          className="material-view"
                        >
                            <>
                                {this.state.materialList.map((material) => (
                                    <Card 
                                      className="material"
                                    >
                                        <Box 
                                          className="material-title-bar"
                                        >
                                            <Box 
                                              className="author"
                                            >
                                                <Avatar 
                                                  className="avatar" 
                                                  src={
                                                    this.props.teacherProfilePicture 
                                                      ? `/profilePictures/${encodeURIComponent(encodeURIComponent(this.props.teacherProfilePicture))}` 
                                                      : null
                                                  }
                                                />
                                                <Typography 
                                                  variant="body1"
                                                >
                                                  {this.props.teacherName}
                                                </Typography>
                                            </Box>
                                            {(this.props.accountType === "teacher" || this.props.accountType === 'school_teacher') && (
                                                <>
                                                    <IconButton 
                                                      title={
                                                        this.state.languageFile && this.state.languageFile.moreOptions
                                                      }
                                                      onClick={
                                                        () => this.openMaterialMenu(material)
                                                      }
                                                    >
                                                        <MoreVertIcon 
                                                          className="icon" 
                                                          id={
                                                            `${material.materialCode}-more-vert`
                                                          }
                                                        />
                                                    </IconButton>
                                                    <Menu 
                                                      anchorEl={
                                                        document.getElementById(`${material.materialCode}-more-vert`)
                                                      } 
                                                      anchorOrigin={{ 
                                                        vertical: "top", 
                                                        horizontal: "right" 
                                                      }} 
                                                      open={
                                                        this.state.openMaterialMenu === material.materialCode
                                                      } 
                                                      onClose={
                                                        () => this.closeMaterialMenu()
                                                      }
                                                    >
                                                        <MenuItem 
                                                          onClick={
                                                            () => this.openDeleteDialog(material)
                                                          }
                                                        >
                                                          {this.state.languageFile && this.state.languageFile.delete}
                                                        </MenuItem>
                                                    </Menu>
                                                </>
                                            )}
                                        </Box>
                                        <Typography 
                                          variant="body1"
                                        >
                                          {formatMaterial(material.content)}
                                        </Typography>
                                        <div 
                                          className="attachment-list"
                                        >                                       
                                            {material.attachments.map(
                                              (attachment) => (
                                                !attachment.filename.endsWith(".mp4") && 
                                                    (
                                                        <a 
                                                          className="attachment-link" 
                                                          href={
                                                            `/attachments/${encodeURIComponent(encodeURIComponent(attachment.attachmentCode))}`
                                                          } 
                                                          download={
                                                            attachment.filename
                                                          }
                                                        >
                                                            <Chip 
                                                              className="attachment" 
                                                              label={
                                                                attachment.filename
                                                              }
                                                            />
                                                        </a>
                                                    )
                                              )
                                            )}
                                            <Box>
                                              {material.attachments.map((attachment) => (
                                                attachment.filename.endsWith('.mp4') &&
                                                  <Box 
                                                    className="video-container" 
                                                    id={
                                                      `video-container-${attachment.attachmentCode}`
                                                    }
                                                  >
                                                    <video 
                                                      controls
                                                      className="video-player" 
                                                      onPlaying={
                                                        () => this.handleVideoChange(attachment.attachmentCode,  true)
                                                      }   
                                                      onPause={
                                                        () => this.handleVideoChange(attachment.attachmentCode, false)
                                                      } 
                                                      onEnded={
                                                        () => this.handleVideoChange(attachment.attachmentCode, false)
                                                      }
                                                    >
                                                      <source 
                                                        src={
                                                          `/attachments/${encodeURIComponent(encodeURIComponent(attachment.attachmentCode))}`
                                                        }
                                                      />
                                                    </video>
                                                    <Typography 
                                                      variant="body1" 
                                                      className="video-filename"
                                                    >
                                                      {attachment.filename}
                                                    </Typography>
                                                  </Box> 
                                              ))}
                                            </Box>
                                        </div>
                                    </Card>
                                ))}
                                {(this.props.accountType === "teacher" || this.props.accountType === 'school_teacher') && (
                                    <Fab 
                                      title={
                                        this.state.languageFile && this.state.languageFile.addMaterial
                                      }
                                      className="floating-action-button" 
                                      color="secondary" 
                                      onClick={
                                        () => this.openMaterialCreationDialog()
                                      }
                                    >
                                        <AddIcon/>
                                    </Fab>
                                )}
                                <MaterialDeleteDialog
                                  language={
                                    this.props.language
                                  }
                                  open={
                                    this.state.openDeleteDialog
                                  } 
                                  classroom={
                                    this.props.classroom
                                  } 
                                  material={
                                    this.state.openDeleteDialog
                                  } 
                                  onClose={
                                    () => this.closeDeleteDialog()
                                  } 
                                  onMaterialDeleted={
                                    () => this.handleMaterialDeleted()
                                  } 
                                  onCancel={
                                    () => this.closeDeleteDialog()
                                  } 
                                  onCriticalError={
                                    (error) => this.props.onCriticalError(error)
                                  }
                                />
                                <MaterialCreationDialog
                                  language={
                                    this.props.language
                                  }
                                  open={
                                    this.state.materialCreationDialogOpen
                                  } 
                                  classroom={
                                    this.props.classroom
                                  } 
                                  onClose={
                                    () => this.closeMaterialCreationDialog()
                                  } 
                                  onMaterialCreated={
                                    (material) => this.handleMaterialCreated(material)
                                  } 
                                  onCriticalError={
                                    (error) => this.props.onCriticalError(error)
                                  }
                                />
                                <VideoPlayerDialog 
                                  open={
                                    this.state.openVideo
                                  } 
                                  attachmentCode={
                                    this.state.openVideo 
                                      ? this.state.openVideo.attachmentCode 
                                      : null
                                  } 
                                  filename={
                                    this.state.openVideo 
                                      ? this.state.openVideo.filename 
                                      : null
                                    } 
                                  onClose={
                                    () => this.closeVideoPlayerDialog()
                                  }
                                />
                            </>
                        </div>
                    </>
                )}
            </>
        );
    }

    handleVideoChange(attachmentCode, playing){
      let videoContainer = document.getElementById(`video-container-${attachmentCode}`);

      if(playing){
        videoContainer.classList.add('playing');
      } else {
        videoContainer.classList.remove('playing');
      }
    }

    attemptMaterialDownload() {
        this.setState({
            waiting: true,
            materialList: null
        });

        enumerateMaterials(this.props.classroom, (status, materials) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                //TODO: React to status
                return;
            }

            this.setState({ materialList: materials });
            this.setState({
                updateInterval: setInterval(() => {
                    fetchMaterialListUpdate(this.props.classroom, (status, updateAvailable, pendingUpdate) => {
                        if (status !== "success") {
                            switch (status) {
                                case "invalid-fetch-classroom-code":
                                    break;

                                default:
                                    this.props.onCriticalError(status);
                            }
                            return;
                        }

                        if (updateAvailable) {
                            let addedMaterials = [];
                            let removedMaterials = [];
                            for (let update of pendingUpdate) {
                                switch (update["operation"]) {
                                    case "add":
                                        addedMaterials = [
                                            {
                                                materialCode: update["materialCode"],
                                                content: update["content"],
                                                attachments: update["attachments"]
                                            },
                                            ...addedMaterials
                                        ];
                                        break;

                                    case "remove":
                                        removedMaterials.push(update["materialCode"]);
                                        break;

                                    default:
                                }
                            }

                            this.setState({ materialList: [
                                ...addedMaterials,
                                ...this.state.materialList.filter((material) => {
                                    return removedMaterials.indexOf(material.materialCode) === -1;
                                })
                            ]});
                        }
                    });
                }, UPDATE_INTERVAL_TIME)
            });
        });
    }

    openMaterialMenu(material) {
        this.setState({ openMaterialMenu: material.materialCode });
    }

    closeMaterialMenu() {
        this.setState({ openMaterialMenu: null });
    }

    openDeleteDialog(material) {
        this.setState({
            openMaterialMenu: null,
            openDeleteDialog: material.materialCode
        });
    }

    closeDeleteDialog() {
        this.setState({ openDeleteDialog: null });
    }

    openMaterialCreationDialog() {
        this.setState({ materialCreationDialogOpen: true });
    }

    closeMaterialCreationDialog() {
        this.setState({ materialCreationDialogOpen: false });
    }

    handleMaterialCreated(material) {
        this.setState({ materialList: [
            material,
            ...this.state.materialList
        ]});
    }

    openVideoPlayerDialog(attachment) {
        this.setState({ openVideo: {
            attachmentCode: attachment.attachmentCode,
            filename: attachment.filename
        }});
    }
    
    closeVideoPlayerDialog() {
        this.setState({ openVideo: null });
    }

    handleMaterialDeleted() {
        this.setState({ materialList: this.state.materialList.filter((material) => {
            return material.materialCode !== this.state.openDeleteDialog;
        })});

        this.setState({ openDeleteDialog: null });
    }
}

export default MaterialView;