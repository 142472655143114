import React from "react";

import { Dialog, DialogTitle, DialogContent, TextField, CircularProgress, Alert, DialogActions, Button } from "@mui/material";

import "./PasswordDialog.css";

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import setPassword from "../../../utils/apiCaller/accountManager/PasswordSetter";

class PasswordDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          languageFile: null,
          waiting: false,
          error: null,
          canBeSent: false
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'PasswordDialog', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });
    }

    render() {
        return (
            <Dialog 
              className="password-dialog" 
              open={
                this.props.open
              } 
              onClose={
                () => this.handleClose()
              } 
              fullWidth
            >
                <DialogTitle 
                  className="dialog-title"
                >
                  {this.state.languageFile && this.state.languageFile.prompt}
                </DialogTitle>
                <DialogContent 
                  className="password-dialog-content"
                >
                    <TextField 
                      id="currentPassword" 
                      type="password" 
                      label={
                        this.state.languageFile && this.state.languageFile.currentPassword
                      }
                      required 
                      disabled={
                        this.state.waiting
                      } 
                      margin="normal" 
                      fullWidth 
                      variant="outlined" 
                      color={
                        this.props.color
                      } 
                      onChange={
                        () => this.handleChange()
                      } 
                      autoFocus
                      inputProps={{
                        maxLength: 255
                      }}
                    />  
                    <TextField 
                      id="newPassword" 
                      type="password" 
                      label={
                        this.state.languageFile && this.state.languageFile.newPassword
                      }
                      required 
                      disabled={
                        this.state.waiting
                      } 
                      margin="normal" 
                      fullWidth 
                      variant="outlined" 
                      color={
                        this.props.color
                      } 
                      onChange={
                        () => this.handleChange()
                      }
                      inputProps={{
                        maxLength: 255
                      }}
                    />
                    <TextField 
                      id="passwordRepeat" 
                      type="password" 
                      label={
                        this.state.languageFile && this.state.languageFile.passwordRepeat
                      }
                      required 
                      disabled={
                        this.state.waiting
                      } 
                      margin="normal" 
                      fullWidth 
                      variant="outlined" 
                      color={
                        this.props.color
                      } 
                      onChange={
                        () => this.handleChange()
                      }
                      inputProps={{
                        maxLength: 255
                      }}
                    />
                    {(this.state.waiting || this.state.error) && (
                        <div 
                          className="password-dialog-status-container"
                        >
                            {this.state.waiting && (
                                <CircularProgress 
                                  color={this.props.color}
                                />
                            )}
                            {this.state.error === "invalid-password-repeat" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.passwordError}
                                </Alert>
                            )}
                            {this.state.error === "no-connection" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.noConnectionError}
                                </Alert>
                            )}
                            {this.state.error === "unknown-error" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.unknownError}
                                </Alert>
                            )}
                            {this.state.error === "invalid-password" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.invalidPasswordError}
                                </Alert>
                            )}
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button 
                      color={
                        this.props.color
                      } 
                      disabled={
                        this.state.waiting || !this.state.canBeSent
                      } 
                      onClick={
                        () => this.handleSubmit()
                      }
                    >
                      {this.state.languageFile && this.state.languageFile.change}
                    </Button>
                    <Button 
                      color={
                        this.props.color
                      } 
                      disabled={
                        this.state.waiting
                      } 
                      onClick={
                        () => this.handleClose()
                      }
                    >
                      {this.state.languageFile && this.state.languageFile.cancel}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleChange(){
      this.setState({
        canBeSent: 
          document.querySelector('#currentPassword').value.length !== 0 && 
          document.querySelector('#newPassword').value.length !== 0 && 
          document.querySelector('#passwordRepeat').value.length !== 0
      });
    }

    handleClose() {
        this.setState({
            waiting: false,
            error: null,
            canBeSent: false
        });

        this.props.onClose();
    }

    handleSubmit() {
        this.setState({
            waiting: true,
            error: null
        });

        let currentPassword = document.getElementById("currentPassword").value;
        let newPassword = document.getElementById("newPassword").value;
        let passwordRepeat = document.getElementById("passwordRepeat").value;
        if (newPassword !== passwordRepeat) {
            this.setState({
                waiting: false,
                error: "invalid-password-repeat"
            });
            return;
        }
        
        setPassword(currentPassword, newPassword, (status) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            this.handleClose();
        });
    }
}

export default PasswordDialog;