import React from 'react';
import QRCode from 'qrcode';

import './QrCodeDialog.css';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';

export default class QrCodeDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      qrCode: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'QrCodeDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
         nextProps.open !== this.props.open
      || nextState !== this.state
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && this.props.open !== prevProps.open) {
      this.generateQrCode(this.props.joinCode);
    }
  }

  async generateQrCode(joinCode) {
    this.setState({
      qrCode: await QRCode.toDataURL(`https://www.novalang.pl/joinclassroom?join_code=${encodeURIComponent(joinCode)}`)
    });
  }
  
  render() {
    return (
      <Dialog
        open={
          this.props.open
        }
        onClose={
          () => this.handleClose()
        }
        fullWidth
      >
        <DialogTitle
          className='dialog-title'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </DialogTitle>
        <DialogContent>
          <div
            className='qrcode-dialog-container'
          >
            <img
              className='qrcode'
              src={
                this.state.qrCode
              }
              alt=''
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color='secondary'
            onClick={
              () => this.handleClose()
            }
          >
            {this.state.languageFile && this.state.languageFile.ok}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleClose() {
    this.props.onClose();
  }
}