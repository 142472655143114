import { callApi } from "../ApiCaller";

function deleteClassroom(classroomCode, onFinish) {
    callApi("deleteClassroom", {
        classroomCode: classroomCode
    }, (callStatus, httpStatus) => {
        if (callStatus !== "success") {
            onFinish(callStatus);
            return;
        }

        switch (httpStatus) {
            case 200:
                onFinish("success");
                break;

            case 401:
                onFinish("not-signed-in");
                break;

            case 403:
                onFinish("account-deleted");
                break;

            case 404:
                onFinish("invalid-classroom-code");
                break;

            default:
                onFinish("unknown-error");
        }
    });
}

export default deleteClassroom;