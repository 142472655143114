import React from 'react';

import { Typography } from '@mui/material';

import './Dictionary.css';
import DictionaryLessonCard from '../../components/dictionaryLessonCard/DictionaryLessonCard';
import Lesson from '../../components/lesson/Lesson';

import increaseTrafficCounter from '../../utils/apiCaller/TrafficCounterIncreaser';
import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';

export default class Dictionary extends React.Component {
  constructor() {
    super();

    const lessonLanguage = new URLSearchParams(window.location.search).get('language');
    this.state = {
      languageFile: null,
      lessonLanguage: lessonLanguage
    };
  }

  componentDidMount() {
    increaseTrafficCounter((status) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }
    });

    getLanguageFile(this.props.language, 'Dictionary', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  render() {
    return (
      <main
        className='dictionary'
      >
        <div
          className='title-bar'
        >
          <Typography
            variant='h4'
          >
            {this.state.languageFile && this.state.languageFile.prompt}
          </Typography>
          <Typography
            variant='h5'
          >
            {this.state.languageFile && this.state.languageFile[this.state.lessonLanguage]}
          </Typography>
        </div>
        <div
          className='dictionary-container'
        >
          <div
            className='dictionary-subcontainer'
          >
            {this.state.lessonLanguage
              ? (
                <>
                  <DictionaryLessonCard
                    language={
                      this.props.language
                    }
                    title={
                      this.state.languageFile && this.state.languageFile.lesson1
                    }
                    description={
                      this.state.languageFile && this.state.languageFile.lesson1Description
                    }
                    dictionaryURL={
                      `/dictionary/lesson1?language=${encodeURIComponent(this.state.lessonLanguage)}`
                    }
                    onCriticalError={
                      (error) => this.props.onCriticalError(error)
                    }
                  />
                  <DictionaryLessonCard
                    language={
                      this.props.language
                    }
                    title={
                      this.state.languageFile && this.state.languageFile.lesson2
                    }
                    description={
                      this.state.languageFile && this.state.languageFile.lesson2Description
                    }
                    dictionaryURL={
                      `/dictionary/lesson2?language=${encodeURIComponent(this.state.lessonLanguage)}`
                    }
                    onCriticalError={
                      (error) => this.props.onCriticalError(error)
                    }
                  />
                  <DictionaryLessonCard
                    language={
                      this.props.language
                    }
                    title={
                      this.state.languageFile && this.state.languageFile.lesson3
                    }
                    description={
                      this.state.languageFile && this.state.languageFile.lesson3Description
                    }
                    dictionaryURL={
                      `/dictionary/lesson3?language=${encodeURIComponent(this.state.lessonLanguage)}`
                    }
                    onCriticalError={
                      (error) => this.props.onCriticalError(error)
                    }
                  />
                  <DictionaryLessonCard
                    language={
                      this.props.language
                    }
                    title={
                      this.state.languageFile && this.state.languageFile.lesson4
                    }
                    description={
                      this.state.languageFile && this.state.languageFile.lesson4Description
                    }
                    dictionaryURL={
                      `/dictionary/lesson4?language=${encodeURIComponent(this.state.lessonLanguage)}`
                    }
                    onCriticalError={
                      (error) => this.props.onCriticalError(error)
                    }
                  />
                  <DictionaryLessonCard
                    language={
                      this.props.language
                    }
                    title={
                      this.state.languageFile && this.state.languageFile.lesson5
                    }
                    description={
                      this.state.languageFile && this.state.languageFile.lesson5Description
                    }
                    dictionaryURL={
                      `/dictionary/lesson5?language=${encodeURIComponent(this.state.lessonLanguage)}`
                    }
                    onCriticalError={
                      (error) => this.props.onCriticalError(error)
                    }
                  />
                  <DictionaryLessonCard
                    language={
                      this.props.language
                    }
                    title={
                      this.state.languageFile && this.state.languageFile.lesson6
                    }
                    description={
                      this.state.languageFile && this.state.languageFile.lesson6Description
                    }
                    dictionaryURL={
                      `/dictionary/lesson6?language=${encodeURIComponent(this.state.lessonLanguage)}`
                    }
                    onCriticalError={
                      (error) => this.props.onCriticalError(error)
                    }
                  />
                  <DictionaryLessonCard
                    language={
                      this.props.language
                    }
                    title={
                      this.state.languageFile && this.state.languageFile.lesson7
                    }
                    description={
                      this.state.languageFile && this.state.languageFile.lesson7Description
                    }
                    dictionaryURL={
                      `/dictionary/lesson7?language=${encodeURIComponent(this.state.lessonLanguage)}`
                    }
                    onCriticalError={
                      (error) => this.props.onCriticalError(error)
                    }
                  />
                  <DictionaryLessonCard
                    language={
                      this.props.language
                    }
                    title={
                      this.state.languageFile && this.state.languageFile.lesson8
                    }
                    description={
                      this.state.languageFile && this.state.languageFile.lesson8Description
                    }
                    dictionaryURL={
                      `/dictionary/lesson8?language=${encodeURIComponent(this.state.lessonLanguage)}`
                    }
                    onCriticalError={
                      (error) => this.props.onCriticalError(error)
                    }
                  />
                </>
              ) : (
                <>
                  {this.props.language !== 'english' && (
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.english
                      }
                      lessonURL='/dictionary?language=english'
                      refresh
                    />
                  )}
                  <Lesson
                    title={
                      this.state.languageFile && this.state.languageFile.prompt
                    }
                    description={
                      this.state.languageFile && this.state.languageFile.chinese
                    }
                    lessonURL='/dictionary?language=chinese'
                    refresh
                  />
                  <Lesson
                    title={
                      this.state.languageFile && this.state.languageFile.prompt
                    }
                    description={
                      this.state.languageFile && this.state.languageFile.german
                    }
                    lessonURL='/dictionary?language=german'
                    refresh
                  />
                  <Lesson
                    title={
                      this.state.languageFile && this.state.languageFile.prompt
                    }
                    description={
                      this.state.languageFile && this.state.languageFile.spanish
                    }
                    lessonURL='/dictionary?language=spanish'
                    refresh
                  />
                  <Lesson
                    title={
                      this.state.languageFile && this.state.languageFile.prompt
                    }
                    description={
                      this.state.languageFile && this.state.languageFile.french
                    }
                    lessonURL='/dictionary?language=french'
                    refresh
                  />
                  <Lesson
                    title={
                      this.state.languageFile && this.state.languageFile.prompt
                    }
                    description={
                      this.state.languageFile && this.state.languageFile.arabic
                    }
                    lessonURL='/dictionary?language=arabic'
                    refresh
                  />
                  <Lesson
                    title={
                      this.state.languageFile && this.state.languageFile.prompt
                    }
                    description={
                      this.state.languageFile && this.state.languageFile.russian
                    }
                    lessonURL='/dictionary?language=russian'
                    refresh
                  />
                  <Lesson
                    title={
                      this.state.languageFile && this.state.languageFile.prompt
                    }
                    description={
                      this.state.languageFile && this.state.languageFile.italian
                    }
                    lessonURL='/dictionary?language=italian'
                    refresh
                  />
                  <Lesson
                    title={
                      this.state.languageFile && this.state.languageFile.prompt
                    }
                    description={
                      this.state.languageFile && this.state.languageFile.japanese
                    }
                    lessonURL='/dictionary?language=japanese'
                    refresh
                  />
                  <Lesson
                    title={
                      this.state.languageFile && this.state.languageFile.prompt
                    }
                    description={
                      this.state.languageFile && this.state.languageFile.hebrew
                    }
                    lessonURL='/dictionary?language=hebrew'
                    refresh
                  />
                  {this.props.language !== 'polish' && (
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.polish
                      }
                      lessonURL='/dictionary?language=polish'
                      refresh
                    />
                  )}
                  <Lesson
                    title={
                      this.state.languageFile && this.state.languageFile.prompt
                    }
                    description={
                      this.state.languageFile && this.state.languageFile.latin
                    }
                    lessonURL='/dictionary?language=latin'
                    refresh
                  />
                </>
              )}
          </div>
        </div>
      </main>
    );
  }
}