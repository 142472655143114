import React from 'react';

import { Dialog, DialogTitle, DialogContent, TextField, CircularProgress, Alert, DialogActions, Button } from '@mui/material';

import './WordEditDialog.css';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import editWord from '../../../utils/apiCaller/wordManager/WordEditor';

export default class WordEditDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      waiting: false,
      error: null,
      canBeSent: false
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'WordEditDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }
      
      this.setState({
        languageFile: file
      });
    });
  }

  render() {
    return (
      <Dialog
        open={
          this.props.open
        }
        onClose={
          () => this.handleClose()
        }
        fullWidth
      >
        <DialogTitle
          className='dialog-title'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </DialogTitle>
        <DialogContent>
          <TextField
            id='new-display-word'
            type='text'
            label={
              this.state.languageFile && this.state.languageFile.newDisplayWord
            }
            autoComplete='none'
            disabled={
              this.state.waiting
            }
            margin='normal'
            fullWidth
            variant='outlined'
            color='secondary'
            inputProps={{
              maxLength: 255
            }}
            onChange={
              () => this.handleChange()
            }
            autoFocus
            defaultValue={
              this.props.word && this.props.word.displayWord
            }
          />
          <TextField
            id='new-display-translation'
            type='text'
            label={
              this.state.languageFile && this.state.languageFile.newDisplayTranslation
            }
            autoComplete='none'
            disabled={
              this.state.waiting
            }
            margin='normal'
            fullWidth
            variant='outlined'
            color='secondary'
            inputProps={{
              maxLength: 255
            }}
            onChange={
              () => this.handleChange()
            }
            defaultValue={
              this.props.word && this.props.word.displayTranslation
            }
          />
          {(this.state.waiting || this.state.error) && (
            <div
              className='word-edit-dialog-status-container'
            >
              {this.state.waiting && (
                <CircularProgress
                  color='secondary'
                />
              )}
              {this.state.error === 'no-connection' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.noConnectionError}
                </Alert>
              )}
              {this.state.error === 'unknown-error' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.unknownError}
                </Alert>
              )}
              {this.state.error === 'invalid-word-code' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.wordCodeError}
                </Alert>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color='secondary'
            disabled={
              this.state.waiting || !this.state.canBeSent
            }
            onClick={
              () => this.handleSubmit()
            }
          >
            {this.state.languageFile && this.state.languageFile.edit}
          </Button>
          <Button
            color='secondary'
            disabled={
              this.state.waiting
            }
            onClick={
              () => this.handleClose()
            }
          >
            {this.state.languageFile && this.state.languageFile.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleChange() {
    this.setState({
      canBeSent: (
        (
             document.querySelector('#new-display-word').value.length !== 0
          && document.querySelector('#new-display-translation').value.length !== 0
        )
        && (
             document.querySelector('#new-display-word').value !== this.props.word.displayWord
          || document.querySelector('#new-display-translation').value !== this.props.word.displayTranslation
        )
      )
    });
  }

  handleClose() {
    this.setState({
      waiting: false,
      error: null,
      canBeSent: false
    });

    this.props.onClose();
  }

  handleSubmit() {
    this.setState({
      waiting: true,
      error: null
    });

    const newDisplayWord = document.querySelector('#new-display-word').value;
    const newDisplayTranslation = document.querySelector('#new-display-translation').value;
    editWord(this.props.classroom, this.props.wordSet.wordSetCode, this.props.word.wordCode, newDisplayWord, newDisplayTranslation, (status) => {
      this.setState({
        waiting: false
      });

      if (status !== 'success') {
        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.setState({
              error: status
            });
        }

        return;
      }

      this.props.onWordEdited({
        newDisplayWord: newDisplayWord,
        newDisplayTranslation: newDisplayTranslation
      });

      this.handleClose();
    });
  }
}