import React from "react";

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";

import "./CriticalErrorDialogs.css";

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';

class CriticalErrorDialogs extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'CriticalErrorDialogs', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

    render() {
        return (
            <>
                <Dialog 
                  open={
                    this.props.error === "no-connection"
                  } 
                  onClose={
                    () => this.props.onClose()
                  }
                >
                    <DialogTitle 
                      className="dialog-title"
                    >
                      {this.state.languageFile
                        ? this.state.languageFile.noConnectionPrompt
                        : 'Could not connect to the server' //Fallback English text
                      }
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                          {this.state.languageFile
                            ? this.state.languageFile.noConnectionDescription
                            : 'Check your internet connection.' //Fallback English text
                          }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                          variant="text" 
                          onClick={
                            () => window.location.reload()
                          }
                        >
                          {this.state.languageFile
                            ? this.state.languageFile.refresh
                            : 'Refresh' //Fallback English text
                          }
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog 
                  open={
                    this.props.error === "unknown-error"
                  } 
                  onClose={
                    () => this.props.onClose()
                  }
                >
                    <DialogTitle 
                      className="dialog-title"
                    >
                      {this.state.languageFile
                        ? this.state.languageFile.unknownErrorPrompt
                        : 'An unexpected error occurred' //Fallback English text
                      }
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                          {this.state.languageFile
                            ? this.state.languageFile.unknownErrorDescription
                            : 'Please try again later.' //Fallback English text
                          }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                          variant="text" 
                          onClick={
                            () => window.location.reload()
                          }
                        >
                          {this.state.languageFile
                            ? this.state.languageFile.refresh
                            : 'Refresh' //Fallback English text
                          }
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog 
                  open={
                    this.props.error === "not-signed-in"
                  } 
                  onClose={
                    () => this.props.onClose()
                  }
                >
                    <DialogTitle 
                      className="dialog-title"
                    >
                      {this.state.languageFile
                        ? this.state.languageFile.signedOutPrompt
                        : 'You have logged out in another tab' //Fallback English text
                      }
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                          {this.state.languageFile
                            ? this.state.languageFile.signedOutDescription
                            : 'Log back into your account to continue.' //Fallback English text
                          }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                          variant="text" 
                          onClick={
                            () => this.handleNotSignedIn()
                          }
                        >
                          {this.state.languageFile
                            ? this.state.languageFile.signIn
                            : 'Log in' //Fallback English text
                          }
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog 
                  open={
                    this.props.error === "account-deleted"
                  } 
                  onClose={
                    () => this.props.onClose()
                  }
                >
                    <DialogTitle 
                      className="dialog-title"
                    >
                      {this.state.languageFile
                        ? this.state.languageFile.accountDeletedPrompt
                        : 'Your account has been deleted' //Fallback English text
                      }
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                          {this.state.languageFile
                            ? this.state.languageFile.accountDeletedDescription
                            : 'This account was deleted from another tab or device.' //Fallback English text
                          }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                          variant="text" 
                          onClick={
                            () => this.handleAccountDeleted()
                          }
                        >
                          {this.state.languageFile
                            ? this.state.languageFile.homepage
                            : 'Homepage' //Fallback English text
                          }
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    handleNotSignedIn() {
        this.props.onClose();
        document.location.href = "/signin";
    }

    handleAccountDeleted() {
        document.cookie = "PHPSESSID=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
        this.props.onClose();

        document.location.href = "/";
    }
}

export default CriticalErrorDialogs;