import React from 'react';

import { Dialog, DialogTitle, DialogContent, Card, Avatar, Typography, CircularProgress, Alert, DialogActions, Button } from '@mui/material';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import getTaskAnswerList from '../../../utils/apiCaller/taskManager/TaskAnswerListGetter';

import './TaskAnswerListDialog.css';
import TaskAnswerDialog from '../taskAnswerDialog/TaskAnswerDialog';

export default class TaskAnswerListDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      waiting: false,
      error: null,
      answerList: null,
      openAnswerDialog: null,
      updateInterval: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'TaskAnswerListDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
         nextProps.open !== this.props.open
      || nextState !== this.state
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open) {
      if (this.props.open) {
        this.setState({
          waiting: true
        });

        getTaskAnswerList(this.props.classroom, this.props.task, (status, answerList) => {
          this.setState({
            waiting: false
          });

          if (status !== 'success') {
            switch (status) {
              case 'not-signed-in':
              case 'account-deleted':
                this.props.onCriticalError(status);
                break;
  
              default:
                this.setState({
                  error: status
                });
            }
  
            return;
          }

          this.setState({
            answerList: answerList
          });
        });
      } else {
        clearInterval(this.state.updateInterval);
        this.setState({
          updateInterval: null
        });
      }
    }
  }

  render() {
    return (
      <>
        <Dialog
          open={
            this.props.open
          }
          onClose={
            () => this.handleClose()
          }
          fullWidth
        >
          <DialogTitle
            className='dialog-title'
          >
            {this.state.languageFile && this.state.languageFile.prompt}
          </DialogTitle>
          <DialogContent>
            {this.state.answerList && (
              this.state.answerList.map((answer) => (
                <Card
                  className='task-answer-list-dialog-answer'
                >
                  <div
                    className='student-information'
                  >
                    <Avatar
                      className='avatar'
                      src={
                        answer.profilePicture
                          ? `/profilePictures/${encodeURIComponent(encodeURIComponent(answer.profilePicture))}`
                          : null
                      }
                    />
                    <div>
                      <Typography
                        variant='body1'
                      >
                        {answer.username}
                      </Typography>
                    </div>
                  </div>
                  {answer.answerCode
                    ? (
                      <Button
                        color='secondary'
                        variant='contained'
                        onClick={
                          () => this.openAnswerDialog(answer)
                        }
                      >
                        {this.state.languageFile && this.state.languageFile.open}
                      </Button>
                    )
                    : (
                      <Button
                        disabled
                      >
                        {this.state.languageFile && this.state.languageFile.notHandedIn}
                      </Button>
                    )
                  }
                </Card>
              ))
            )}
            {(this.state.waiting || this.state.error) && (
              <div
                className='task-answer-list-dialog-status-container'
              >
                {this.state.waiting && (
                  <CircularProgress
                    color='secondary'
                  />
                )}
                {this.state.error === 'no-connection' && (
                  <Alert
                    severity='error'
                  >
                    {this.state.languageFile && this.state.languageFile.noConnectionError}
                  </Alert>
                )}
                {this.state.error === 'unknown-error' && (
                  <Alert
                    severity='error'
                  >
                    {this.state.languageFile && this.state.languageFile.unknownError}
                  </Alert>
                )}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color='secondary'
              disabled={
                this.state.waiting
              }
              onClick={
                () => this.handleClose()
              }
            >
              {this.state.languageFile && this.state.languageFile.ok}
            </Button>
          </DialogActions>
        </Dialog>
        <TaskAnswerDialog
          language={
            this.props.language
          }
          accountType='teacher'
          open={
            this.state.openAnswerDialog
          }
          classroom={
            this.props.classroom
          }
          task={
            this.props.task
          }
          profilePicture={
            this.state.openAnswerDialog
              ? this.state.openAnswerDialog.profilePicture
              : null
          }
          username={
            this.state.openAnswerDialog
              ? this.state.openAnswerDialog.username
              : null
          }
          answer={
            this.state.openAnswerDialog
              ? this.state.openAnswerDialog.answerCode
              : null
          }
          onClose={
            () => this.closeAnswerDialog()
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
      </>
    );
  }

  handleClose() {
    this.setState({
      waiting: false,
      error: null,
      answerList: null
    });

    this.props.onClose();
  }

  openAnswerDialog(answer) {
    this.setState({
      openAnswerDialog: answer
    });
  }

  closeAnswerDialog() {
    this.setState({
      openAnswerDialog: null
    });
  }
}