import { callApi } from '../ApiCaller';

export default function getDomainName(onFinish) {
  callApi('getDomainName', {}, (callStatus, httpStatus, domainName) => {
    if (callStatus !== 'success') {
      onFinish(callStatus);
      return;
    }

    switch (httpStatus) {
      case 200:
        onFinish('success', domainName);
        break;

      case 401:
        onFinish('not-signed-in');
        break;

      case 403:
        onFinish('account-deleted');
        break;

      default:
        onFinish('unknown-error');
    }
  });
}