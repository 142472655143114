import React from 'react';

import { Typography, CircularProgress, Card, Avatar, IconButton, Menu, MenuItem, Fab } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GroupIcon from '@mui/icons-material/Group';
import AddIcon from '@mui/icons-material/Add';

import './GradeClassroomListView.css';
import GradeClassroomEditDialog from '../dialogs/gradeClassroomEditDialog/GradeClassroomEditDialog';
import GradeClassroomDeleteDialog from '../dialogs/gradeClassroomDeleteDialog/GradeClassroomDeleteDialog';
import GradeClassroomCreationDialog from '../dialogs/gradeClassroomCreationDialog/GradeClassroomCreationDialog';

import enumerateGradeClassrooms from '../../utils/apiCaller/gradeManager/GradeClassroomEnumerator';
import { UPDATE_INTERVAL_TIME } from '../../utils/UpdateIntervalTime';
import fetchGradeClassroomListUpdate from '../../utils/apiCaller/gradeManager/GradeClassroomListUpdateFetcher';

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';

class GradeClassroomListView extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      waiting: false,
      error: null,
      classroomList: null,
      openClassroomMenu: null,
      openEditDialog: null,
      currentClassroomName: null,
      currentClassroomNameShort: null,
      currentTeacherId: null,
      openDeleteDialog: null,
      classroomCreationDialogOpen: false,
      updateInterval: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'GradeClassroomListView', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });

    this.attemptGradeClassroomListDownload();
  }

  componentWillUnmount() {
    clearInterval(this.state.updateInterval);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
         nextProps.grade !== this.props.grade
      || nextState !== this.state
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.grade !== prevProps.grade)
      this.attemptGradeClassroomListDownload();
  }

  render() {
    return (
      <>
        <Typography
          className='grade-classroom-list-view-title'
          variant='h4'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </Typography>
        {this.state.waiting && (
          <div
            className='grade-classroom-list-view-status-container'
          >
            <CircularProgress
              color='warning'
            />
          </div>
        )}
        {this.state.classroomList && (
          <>
            {this.state.classroomList.length === 0 && (
              <div
                className='grade-classroom-list-view-message-horizontal-center'
              >
                <div
                  className='grade-classroom-list-view-message-vertical-center'
                >
                  <Typography
                    className='grade-classroom-list-view-message grade-view-message-title'
                    variant='h4'
                  >
                    {this.state.languageFile && this.state.languageFile.noClassrooms}
                  </Typography>
                  <Typography
                    className='grade-classroom-list-view-message'
                    variant='h5'
                  >
                    {this.state.languageFile && this.state.languageFile.noClassroomsDescription}
                  </Typography>
                </div>
              </div>
            )}
            <div
              className='grade-classroom-list-view'
            >
              <>
                {this.state.classroomList.map((classroom) => (
                  <Card
                    className='classroom'
                  >
                    <div
                      className='information'
                    >
                      <Avatar
                        className='classroom-avatar'
                        sx={
                          classroom.classroomNameShort
                            ? (
                              classroom.classroomNameShort.length >= 4
                                ? {
                                  fontSize: '0.6rem'
                                }
                                : classroom.classroomNameShort.length >= 3
                                ? {
                                  fontSize: '0.8rem'
                                }
                                : null
                            )
                            : null
                        }
                      >
                        {classroom.classroomNameShort
                          ? classroom.classroomNameShort
                          : <GroupIcon/>
                        }
                      </Avatar>
                      <div
                        className='details'
                      >
                        <Typography
                          variant='body1'
                        >
                          {classroom.classroomName}
                        </Typography>
                        <Typography
                          variant='body1'
                        >
                          {this.state.languageFile && this.formatText(this.state.languageFile.language, classroom.language)}
                        </Typography>
                      </div>
                    </div>
                    <div
                      className='teacher-information'
                    >
                      <Avatar
                        className='avatar'
                        src={
                          classroom.teacherProfilePicture
                            ? `/profilePictures/${encodeURIComponent(encodeURIComponent(classroom.teacherProfilePicture))}`
                            : null
                        }
                      />
                      <div>
                        <Typography
                          variant='body1'
                        >
                          {classroom.teacherUsername}
                        </Typography>
                        <Typography
                          variant='body1'
                        >
                          {classroom.teacherEmail}
                        </Typography>
                      </div>
                    </div>
                    <IconButton
                      title={
                        this.state.languageFile && this.state.languageFile.moreOptions
                      }
                      onClick={
                        () => this.openClassroomMenu(classroom)
                      }
                    >
                      <MoreVertIcon
                        className='icon'
                        id={
                          `${classroom.classroomCode}-more-vert`
                        }
                      />
                    </IconButton>
                    <Menu
                      anchorEl={
                        document.getElementById(`${classroom.classroomCode}-more-vert`)
                      }
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      open={
                        this.state.openClassroomMenu === classroom.classroomCode
                      }
                      onClose={
                        () => this.closeClassroomMenu()
                      }
                    >
                      <MenuItem
                        onClick={
                          () => this.openEditDialog(classroom)
                        }
                      >
                        {this.state.languageFile && this.state.languageFile.edit}
                      </MenuItem>
                      <MenuItem
                        onClick={
                          () => this.openDeleteDialog(classroom)
                        }
                      >
                        {this.state.languageFile && this.state.languageFile.delete}
                      </MenuItem>
                    </Menu>
                  </Card>
                ))}
                <Fab
                  title={
                    this.state.languageFile && this.state.languageFile.addClassrooms
                  }
                  className='floating-action-button'
                  color='warning'
                  onClick={
                    () => this.openClassroomCreationDialog()
                  }
                >
                  <AddIcon/>
                </Fab>
                <GradeClassroomEditDialog
                  language={
                    this.props.language
                  }
                  open={
                    this.state.openEditDialog
                  }
                  grade={
                    this.props.grade
                  }
                  classroom={
                    this.state.openEditDialog
                  }
                  classroomName={
                    this.state.currentClassroomName
                  }
                  classroomNameShort={
                    this.state.currentClassroomNameShort
                  }
                  teacherId={
                    this.state.currentTeacherId
                  }
                  onClose={
                    () => this.closeEditDialog()
                  }
                  onClassroomEdited={
                    (newClassroomName, newClassroomNameShort, teacherId, teacherProfilePicture, teacherUsername, teacherEmail) => this.handleClassroomEdited(newClassroomName, newClassroomNameShort, teacherId, teacherProfilePicture, teacherUsername, teacherEmail)
                  }
                  onCriticalError={
                    (error) => this.props.onCriticalError(error)
                  }
                />
                <GradeClassroomDeleteDialog
                  language={
                    this.props.language
                  }
                  open={
                    this.state.openDeleteDialog
                  }
                  grade={
                    this.props.grade
                  }
                  classroom={
                    this.state.openDeleteDialog
                  }
                  onClose={
                    () => this.closeDeleteDialog()
                  }
                  onClassroomDeleted={
                    () => this.handleClassroomDeleted()
                  }
                  onCriticalError={
                    (error) => this.props.onCriticalError(error)
                  }
                />
                <GradeClassroomCreationDialog
                  language={
                    this.props.language
                  }
                  open={
                    this.state.classroomCreationDialogOpen
                  }
                  grade={
                    this.props.grade
                  }
                  onClose={
                    () => this.closeClassroomCreationDialog()
                  }
                  onClassroomCreated={
                    (classroomCode, classroomName, classroomNameShort, language, teacherId, teacherProfilePicture, teacherUsername, teacherEmail) => 
                      this.handleClassroomCreated(
                        classroomCode,
                        classroomName,
                        classroomNameShort,
                        language,
                        teacherId,
                        teacherProfilePicture,
                        teacherUsername,
                        teacherEmail
                      )
                  }
                  onCriticalError={
                    (error) => this.props.onCriticalError(error)
                  }
                />
              </>
            </div>
          </>
        )}
      </>
    );
  }

  formatText(text, language) {
    //The formatted text
    return text.replace('&language;', this.state.languageFile && this.state.languageFile[language]);
  }

  attemptGradeClassroomListDownload() {
    this.setState({
      waiting: true,
      error: null,
      classroomList: null
    });

    enumerateGradeClassrooms(this.props.grade, (status, classrooms) => {
      this.setState({
        waiting: false,
        error: null
      });

      if (status !== 'success') {
        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.setState({
              error: status
            });
        }
        return;
      }

      this.setState({
        classroomList: classrooms,
        updateInterval: setInterval(() => {
          fetchGradeClassroomListUpdate(this.props.grade, (status, updateAvailable, pendingUpdate) => {
            if (status !== 'success') {
              switch (status) {
                case 'invalid-fetch-grade-code':
                  break;

                default:
                  this.props.onCriticalError(status);
              }
              return;
            }

            if (updateAvailable) {
              let addedClassrooms = [];
              let removedClassrooms = [];
              let renamedClassrooms = [];
              for (let update of pendingUpdate) {
                switch (update['operation']) {
                  case 'add':
                    addedClassrooms = [
                      {
                        classroomCode: update['classroomCode'],
                        classroomName: update['classroomName'],
                        language: update['language'],
                        classroomNameShort: update['classroomNameShort'],
                        teacherId: update['teacherId'],
                        teacherProfilePicture: update['teacherProfilePicture'],
                        teacherUsername: update['teacherUsername'],
                        teacherEmail: update['teacherEmail']
                      },
                      ...addedClassrooms
                    ];
                    break;

                  case 'remove':
                    removedClassrooms.push(update['classroomCode']);
                    break;

                  case 'rename':
                    renamedClassrooms.push({
                      classroomCode: update['classroomCode'],
                      classroomName: update['classroomName'],
                      language: update['language'],
                      classroomNameShort: update['classroomNameShort'],
                      teacherId: update['teacherId'],
                      teacherProfilePicture: update['teacherProfilePicture'],
                      teacherUsername: update['teacherUsername'],
                      teacherEmail: update['teacherEmail']
                    });
                    break;

                  default:
                }
              }

              let newClassroomList = [
                ...addedClassrooms,
                ...this.state.classroomList.filter((classroom) => {
                  return removedClassrooms.indexOf(classroom.classroomCode) === -1
                })
              ].map((classroom) => {
                let renamedClassroom = renamedClassrooms.find((temporaryClassroom) => temporaryClassroom.classroomCode === classroom.classroomCode);
                if (renamedClassroom)
                  return renamedClassroom;

                return classroom;
              });

              this.setState({
                classroomList: newClassroomList
              });
            }
          });
        }, UPDATE_INTERVAL_TIME)
      });
    });
  }

  openClassroomMenu(classroom) {
    this.setState({
      openClassroomMenu: classroom.classroomCode
    });
  }

  closeClassroomMenu() {
    this.setState({
      openClassroomMenu: null
    });
  }

  openEditDialog(classroom) {
    this.setState({
      openClassroomMenu: null,
      openEditDialog: classroom.classroomCode,
      currentClassroomName: classroom.classroomName,
      currentClassroomNameShort: classroom.classroomNameShort,
      currentTeacherId: classroom.teacherId
    });
  }

  closeEditDialog() {
    this.setState({
      openEditDialog: null,
      currentClassroomName: null,
      currentClassroomNameShort: null,
      currentTeacherId: null
    });
  }

  openDeleteDialog(classroom) {
    this.setState({
      openClassroomMenu: null,
      openDeleteDialog: classroom.classroomCode
    });
  }

  closeDeleteDialog() {
    this.setState({
      openDeleteDialog: null
    });
  }

  openClassroomCreationDialog() {
    this.setState({
      classroomCreationDialogOpen: true
    });
  }

  closeClassroomCreationDialog() {
    this.setState({
      classroomCreationDialogOpen: false
    });
  }

  handleClassroomCreated(classroomCode, classroomName, classroomNameShort, language, teacherId, teacherProfilePicture, teacherUsername, teacherEmail) {
    this.setState({
      classroomList: [
        {
          classroomCode: classroomCode,
          classroomName: classroomName,
          classroomNameShort: classroomNameShort,
          language: language,
          teacherId: teacherId,
          teacherProfilePicture: teacherProfilePicture,
          teacherUsername: teacherUsername,
          teacherEmail: teacherEmail
        },
        ...this.state.classroomList
      ]
    });
  }

  handleClassroomEdited(newClassroomName, newClassroomNameShort, newTeacherId, teacherProfilePicture, teacherUsername, teacherEmail) {
    this.setState({
      classroomList: this.state.classroomList.map((classroom) => {
        if (classroom.classroomCode !== this.state.openEditDialog)
          return classroom;

        return {
          ...classroom,
          classroomName: newClassroomName,
          classroomNameShort: newClassroomNameShort,
          teacherId: newTeacherId,
          teacherProfilePicture: teacherProfilePicture,
          teacherUsername: teacherUsername,
          teacherEmail: teacherEmail
        };
      })
    });

    this.setState({
      openEditDialog: null
    });
  }

  handleClassroomDeleted() {
    this.setState({
      classroomList: this.state.classroomList.filter((classroom) => {
        return classroom.classroomCode !== this.state.openDeleteDialog;
      })
    });

    this.setState({
      openDeleteDialog: null
    });
  }
}

export default GradeClassroomListView;