import { callApi } from "../ApiCaller";

function requestClassroomJoin(joinCode, onFinish) {
    callApi("requestClassroomJoin", {
        joinCode: joinCode
    }, (callStatus, httpStatus) => {
        if (callStatus !== "success") {
            onFinish(callStatus);
            return;
        }
        
        switch (httpStatus) {
            case 200:
                onFinish("success");
                break;

            case 401:
                onFinish("not-signed-in");
                break;

            case 403:
                onFinish("account-deleted");
                break;

            case 404:
                onFinish("invalid-join-code");
                break;

            case 409:
                onFinish("already-in-classroom");
                break;

            default:
                onFinish("unknown-error");
        }
    });
}

export default requestClassroomJoin;