import React from "react";

import { Typography, Card, Avatar, CircularProgress, Alert } from "@mui/material";

import "./TeacherView.css";

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import getClassroomTeacherInformation from "../../utils/apiCaller/classroomManager/ClassroomTeacherInformationGetter";

class TeacherView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          languageFile: null,
            waiting: false,
            error: null,
            teacherInformation: null,
            color: (props.accountType === "student" || props.accountType === 'school_student') ?
                "primary"
            : (props.accountType === "teacher" || props.accountType === 'school_teacher') ?
                "secondary"
            : null
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'TeacherView', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });

        this.setState({
            waiting: true,
            error: null
        });

        getClassroomTeacherInformation(this.props.classroom, (status, teacherInformation) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            this.setState({ teacherInformation: teacherInformation });
        });
    }

    render() {
        return (
            <div 
              className="teacher-view"
            >
                <Typography 
                  className="subtitle" 
                  variant="h4"
                >
                  {this.state.languageFile && this.state.languageFile.prompt}
                </Typography>
                {this.state.teacherInformation && (
                    <Card 
                      className="teacher"
                    >
                        <Avatar 
                          className="avatar" 
                          src={
                            this.state.teacherInformation.teacherProfilePicture 
                              ? `/profilePictures/${encodeURIComponent(encodeURIComponent(this.state.teacherInformation.teacherProfilePicture))}` 
                              : null
                          }
                        />
                        <Typography 
                          variant="body1"
                        >
                          {this.state.teacherInformation.teacherName}
                        </Typography>
                    </Card>
                )}
                {(this.state.waiting || this.state.error) && (
                    <div 
                      className="status-container"
                    >
                        {this.state.waiting && (
                            <CircularProgress 
                              color={
                                this.state.color
                              }
                            />
                        )}
                        {this.state.error === "no-connection" && (
                            <Alert 
                              severity="error"
                            >
                              {this.state.languageFile && this.state.languageFile.noConnectionError}
                            </Alert>
                        )}
                        {this.state.error === "unknown-error" && (
                            <Alert 
                              severity="error"
                            >
                              {this.state.languageFile && this.state.languageFile.unknownError}
                            </Alert>
                        )}
                        {this.state.error === "invalid-classroom-code" && (
                            <Alert 
                              severity="error"
                            >
                              {this.state.languageFile && this.state.languageFile.classroomCodeError}
                            </Alert>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default TeacherView;