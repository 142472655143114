import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';

import QuizPlaces from './QuizPlaces';
import QuizMedicalStaff from './QuizMedicalStaff';
import QuizMedicines from './QuizMedicines';
import QuizDiseasesAndWounds from './QuizDiseasesAndWounds';
import QuizSymptoms from './QuizSymptoms';
import QuizExaminationsAndTreatment from './QuizExaminationsAndTreatment';

import '../QuizLesson.css';

import increaseTrafficCounter from '../../../utils/apiCaller/TrafficCounterIncreaser';
import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';

class QuizLesson5Component extends React.Component {
  constructor(props) {
    super(props);

    const lessonLanguage = new URLSearchParams(window.location.search).get('language');
    this.state = {
      languageFile: null,
      lessonLanguage: lessonLanguage,
      stage: 0,
      stages: [
        <QuizPlaces
          language={
            props.language
          }
          lessonLanguage={
            lessonLanguage
          }
          onStageFinished={
            () => this.handleStageFinished()
          }
          onCriticalError={
            (error) => props.onCriticalError(error)
          }
        />,
        <QuizMedicalStaff
          language={
            props.language
          }
          lessonLanguage={
            lessonLanguage
          }
          onStageFinished={
            () => this.handleStageFinished()
          }
          onCriticalError={
            (error) => props.onCriticalError(error)
          }
        />,
        <QuizMedicines
          language={
            props.language
          }
          lessonLanguage={
            lessonLanguage
          }
          onStageFinished={
            () => this.handleStageFinished()
          }
          onCriticalError={
            (error) => props.onCriticalError(error)
          }
        />,
        <QuizDiseasesAndWounds
          language={
            props.language
          }
          lessonLanguage={
            lessonLanguage
          }
          onStageFinished={
            () => this.handleStageFinished()
          }
          onCriticalError={
            (error) => props.onCriticalError(error)
          }
        />,
        <QuizSymptoms
          language={
            props.language
          }
          lessonLanguage={
            lessonLanguage
          }
          onStageFinished={
            () => this.handleStageFinished()
          }
          onCriticalError={
            (error) => props.onCriticalError(error)
          }
        />,
        <QuizExaminationsAndTreatment
          language={
            props.language
          }
          lessonLanguage={
            lessonLanguage
          }
          onStageFinished={
            () => this.handleStageFinished()
          }
          onCriticalError={
            (error) => props.onCriticalError(error)
          }
        />
      ]
    };
  }

  componentDidMount() {
    increaseTrafficCounter((status) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }
    });

    getLanguageFile(this.props.language, 'QuizLesson5', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  render() {
    return (
      <main
        className='quiz-lesson'
      >
        <div
          className='title-bar'
        >
          <Typography
            variant='h4'
          >
            {this.state.languageFile && this.state.languageFile[this.state.lessonLanguage]}
          </Typography>
          <Typography
            variant='h5'
          >
            {this.state.languageFile && this.state.languageFile.description}
          </Typography>
        </div>
        <div
          className='quiz-container'
        >
          <div
            className='quiz-subcontainer'
          >
            {
              this.state.stages[this.state.stage]
            }
          </div>
        </div>
      </main>
    );
  }

  handleStageFinished() {
    if (this.state.stage + 1 === this.state.stages.length) {
      this.props.navigate('/quiz');
      return;
    }

    this.setState({
      stage: this.state.stage + 1
    });
  }
}

export default function QuizLesson5(props) {
  return <QuizLesson5Component
    {
      ...props
    }
    navigate={
      useNavigate()
    }
  />
}