import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography, CircularProgress, Alert } from '@mui/material';

import Flashcard from '../../components/flashcard/Flashcard';
import Lesson from '../../components/lesson/Lesson';

import './MatchingGame.css';

import increaseTrafficCounter from '../../utils/apiCaller/TrafficCounterIncreaser';
import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import getAccountType from '../../utils/apiCaller/accountManager/AccountTypeGetter';
import getWordSetInformation from '../../utils/apiCaller/wordSetManager/WordSetInformationGetter';
import enumerateWords from '../../utils/apiCaller/wordSetManager/WordEnumerator';

class MatchingGameComponent extends React.Component {
  constructor() {
    super();

    const lessonLanguage = new URLSearchParams(window.location.search).get('language');
    const classroom = new URLSearchParams(window.location.search).get('classroom');
    const wordSet = new URLSearchParams(window.location.search).get('wordset');
    this.state = {
      languageFile: null,
      lessonLanguage: lessonLanguage,
      classroom: classroom,
      wordSet: wordSet,
      waiting: false,
      error: null,
      wordSetInformation: null,
      cards: [],
      selectedCard: null,
      removedCards: 0,
      wrongCards: null,
      wrongCardTimeout: null,
      color: null
    };
  }

  componentDidMount() {
    increaseTrafficCounter((status) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }
    });

    getLanguageFile(this.props.language, 'MatchingGame', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });

    getAccountType((status, accountType) => {
      if (status !== 'success')
        return;

      switch (accountType) {
        case 'student':
        case 'school_student':
          this.setState({
            color: 'primary'
          });
          break;

        case 'teacher':
        case 'school_teacher':
          this.setState({
            color: 'secondary'
          });
          break;

        case 'school_admin':
          this.setState({
            color: 'warning'
          });
          break;

        default:
      }
    });

    if (this.state.classroom && this.state.wordSet) {
      this.setState({
        waiting: true
      });

      getWordSetInformation(this.state.classroom, this.state.wordSet, (status, wordSetInformation) => {
        if (status !== 'success') {
          this.setState({
            waiting: false
          });

          switch (status) {
            case 'not-signed-in':
            case 'account-deleted':
              this.props.onCriticalError(status);
              break;

            default:
              this.setState({
                error: status
              });
          }
          
          return;
        }

        enumerateWords(this.state.classroom, this.state.wordSet, (_status, words) => {
          this.setState({
            waiting: false
          });
  
          if (_status !== 'success') {
            switch (_status) {
              case 'not-signed-in':
              case 'account-deleted':
                this.props.onCriticalError(_status);
                break;
  
              default:
                this.setState({
                  error: _status
                });
            }
  
            return;
          }
  
          this.setState({
            wordSetInformation: wordSetInformation,
            cards: this.randomizeCards(words)
          });
        });
      });
    }
  }

  randomizeCards(words) {
    const cardCount = words.length * 2;
    let cards = [];
    for (let i = 0; i < words.length; i++) {
      let cardIndex = Math.floor(Math.random() * cardCount);
      while (cards[cardIndex])
        cardIndex = (cardIndex + 1) % cardCount;

      cards[cardIndex] = {
        type: 'word',
        id: i,
        content: words[i].displayWord,
        visible: true
      };

      cardIndex = Math.floor(Math.random() * cardCount);
      while (cards[cardIndex])
        cardIndex = (cardIndex + 1) % cardCount;

      cards[cardIndex] = {
        type: 'translation',
        id: i,
        content: words[i].displayTranslation,
        visible: true
      };
    }

    return cards;
  }

  render() {
    return (
      (this.state.classroom && this.state.wordSet) ? (
        <>
          {(this.state.waiting || this.state.error) && (
            <div
              className='matching-game-status-container'
            >
              {this.state.waiting && (
                <CircularProgress
                  color='primary'
                />
              )}
              {this.state.error === 'no-connection' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.noConnectionError}
                </Alert>
              )}
              {this.state.error === 'unknown-error' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.unknownError}
                </Alert>
              )}
              {this.state.error === 'invalid-word-set-code' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.wordSetCodeError}
                </Alert>
              )}
            </div>
          )}
          {this.state.cards && (
            <main
              className='matching-game-lesson'
            >
              <div
                className='title-bar'
              >
                <Typography
                  variant='h4'
                >
                  {this.state.wordSetInformation && this.state.wordSetInformation.classroomName}
                </Typography>
                <Typography
                  variant='h5'
                >
                  {this.state.wordSetInformation && this.state.languageFile[this.state.wordSetInformation.classroomLanguage]}
                </Typography>
              </div>
              <div
                className='matching-game-container'
              >
                <div
                  className='matching-game-subcontainer'
                >
                  <div
                    className='matching-game-section'
                  >
                    <Typography
                      className='section-title'
                      variant='h5'
                    >
                      {this.state.wordSetInformation && this.state.wordSetInformation.wordSetName}
                    </Typography>
                    <div
                      className='section-container'
                    >
                      {this.state.cards.map((card) => (
                        <Flashcard
                          word={
                            card.content
                          }
                          selected={
                            this.state.selectedCard === card
                          }
                          visible={
                            card.visible
                          }
                          color={
                            this.state.color
                          }
                          wrong={
                            this.state.wrongCards && this.state.wrongCards.indexOf(card) !== -1
                          }
                          onClick={
                            () => this.handleCardClick(card)
                          }
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </main>
          )}
        </>
      ) : (
        <main
          className='matching-game'
        >
          <div
            className='title-bar'
          >
            <Typography
              variant='h4'
            >
              {this.state.languageFile && this.state.languageFile.prompt}
            </Typography>
            <Typography
              variant='h5'
            >
              {this.state.languageFile && this.state.languageFile[this.state.lessonLanguage]}
            </Typography>
          </div>
          <div
            className='lesson-list-container'
          >
            <div
              className='lesson-list-subcontainer'
            >
              {this.state.lessonLanguage
                ? (
                  <>
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.lesson1
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.lesson1Description
                      }
                      lessonURL={
                        `/match/lesson1?language=${encodeURIComponent(this.state.lessonLanguage)}`
                      }
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.lesson2
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.lesson2Description
                      }
                      lessonURL={
                        `/match/lesson2?language=${encodeURIComponent(this.state.lessonLanguage)}`
                      }
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.lesson3
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.lesson3Description
                      }
                      lessonURL={
                        `/match/lesson3?language=${encodeURIComponent(this.state.lessonLanguage)}`
                      }
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.lesson4
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.lesson4Description
                      }
                      lessonURL={
                        `/match/lesson4?language=${encodeURIComponent(this.state.lessonLanguage)}`
                      }
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.lesson5
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.lesson5Description
                      }
                      lessonURL={
                        `/match/lesson5?language=${encodeURIComponent(this.state.lessonLanguage)}`
                      }
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.lesson6
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.lesson6Description
                      }
                      lessonURL={
                        `/match/lesson6?language=${encodeURIComponent(this.state.lessonLanguage)}`
                      }
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.lesson7
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.lesson7Description
                      }
                      lessonURL={
                        `/match/lesson7?language=${encodeURIComponent(this.state.lessonLanguage)}`
                      }
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.lesson8
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.lesson8Description
                      }
                      lessonURL={
                        `/match/lesson8?language=${encodeURIComponent(this.state.lessonLanguage)}`
                      }
                    />
                  </>
                ) : (
                  <>
                    {this.props.language !== 'english' && (
                      <Lesson
                        title={
                          this.state.languageFile && this.state.languageFile.prompt
                        }
                        description={
                          this.state.languageFile && this.state.languageFile.english
                        }
                        lessonURL='/match?language=english'
                        refresh
                      />
                    )}
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.chinese
                      }
                      lessonURL='/match?language=chinese'
                      refresh
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.german
                      }
                      lessonURL='/match?language=german'
                      refresh
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.spanish
                      }
                      lessonURL='/match?language=spanish'
                      refresh
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.french
                      }
                      lessonURL='/match?language=french'
                      refresh
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.arabic
                      }
                      lessonURL='/match?language=arabic'
                      refresh
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.russian
                      }
                      lessonURL='/match?language=russian'
                      refresh
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.italian
                      }
                      lessonURL='/match?language=italian'
                      refresh
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.japanese
                      }
                      lessonURL='/match?language=japanese'
                      refresh
                    />
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.hebrew
                      }
                      lessonURL='/match?language=hebrew'
                      refresh
                    />
                    {this.props.language !== 'polish' && (
                      <Lesson
                        title={
                          this.state.languageFile && this.state.languageFile.prompt
                        }
                        description={
                          this.state.languageFile && this.state.languageFile.polish
                        }
                        lessonURL='/match?language=polish'
                        refresh
                      />
                    )}
                    <Lesson
                      title={
                        this.state.languageFile && this.state.languageFile.prompt
                      }
                      description={
                        this.state.languageFile && this.state.languageFile.latin
                      }
                      lessonURL='/match?language=latin'
                      refresh
                    />
                  </>
                )}
            </div>
          </div>
        </main>
      )
    );
  }

  handleCardClick(card) {
    if (this.state.selectedCard) {
      if (card !== this.state.selectedCard) {
        if (card.id === this.state.selectedCard.id) {
          if (this.state.removedCards + 2 === this.state.cards.length) {
            this.props.navigate('/cockpit');
            return;
          }

          this.setState({
            cards: this.state.cards.map((currentCard) => {
              if (currentCard === this.state.selectedCard || currentCard === card) {
                return {
                  ...currentCard,
                  visible: false
                };
              }

              return currentCard;
            }),
            selectedCard: null,
            removedCards: this.state.removedCards + 2
          });
        } else {
          if (this.state.wrongCardTimeout)
            clearTimeout(this.state.wrongCardTimeout);
          
          this.setState({
            wrongCards: [
              this.state.selectedCard,
              card
            ],
            wrongCardTimeout: setTimeout(() => {
              this.setState({
                wrongCards: null,
                wrongCardTimeout: null
              });
            }, 100),
            selectedCard: null
          });
        }
      } else {
        this.setState({
          selectedCard: null
        });
      }
    } else {
      this.setState({
        selectedCard: card
      });
    }
  }
}

export default function MatchingGame(props) {
  return <MatchingGameComponent
    {
      ...props
    }
    navigate={
      useNavigate()
    }
  />
}