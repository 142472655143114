import React from "react";

import { Typography, Switch, TextField, IconButton, CircularProgress, Alert } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import "./MeetForm.css";

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import isMeetLinkEnabled from "../../../utils/apiCaller/classroomManager/MeetLinkStatusChecker";
import getMeetCode from "../../../utils/apiCaller/classroomManager/MeetCodeGetter";
import setMeetLinkEnabled from "../../../utils/apiCaller/classroomManager/MeetLinkStatusSetter";
import setMeetCode from "../../../utils/apiCaller/classroomManager/MeetCodeSetter";
import { UPDATE_INTERVAL_TIME } from "../../../utils/UpdateIntervalTime";
import fetchMeetLinkInformationUpdate from "../../../utils/apiCaller/classroomManager/MeetLinkInformationUpdateFetcher";

class MeetForm extends React.Component {
    constructor() {
        super();
        this.state = {
          languageFile: null,
            waiting: false,
            error: null,
            meetLinkEnabled: null,
            meetLink: null,
            meetCode: null,
            updateInterval: null
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'MeetForm', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });

        this.setState({ waiting: true });
        isMeetLinkEnabled(this.props.classroom, (status, meetLinkEnabled) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            if (meetLinkEnabled) {
                this.setState({ waiting: true });
                getMeetCode(this.props.classroom, (status, meetCode) => {
                    this.setState({ waiting: false });
                    if (status !== "success") {
                        switch (status) {
                            case "not-signed-in":
                            case "account-deleted":
                                this.props.onCriticalError(status);
                                break;

                            default:
                                this.setState({ error: status });
                        }
                        return;
                    }

                    this.setState({
                        meetLinkEnabled: meetLinkEnabled,
                        meetLink: meetCode ? `https://meet.google.com/${encodeURIComponent(meetCode)}` : "",
                        meetCode: meetCode
                    });

                    this.setupUpdateInterval();
                });
            } else {
                this.setState({ meetLinkEnabled: meetLinkEnabled });
                this.setupUpdateInterval();
            }
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.updateInterval);
    }

    setupUpdateInterval() {
        if (this.state.updateInterval)
            clearInterval(this.state.updateInterval);

        this.setState({
            updateInterval: setInterval(() => {
                fetchMeetLinkInformationUpdate(this.props.classroom, (status, updateAvailable, pendingUpdate) => {
                    if (status !== "success") {
                        switch (status) {
                            case "invalid-fetch-classroom-code":
                                break;

                            default:
                                this.props.onCriticalError(status);
                        }
                        return;
                    }

                    if (updateAvailable) {
                        this.setState({
                            meetLinkEnabled: pendingUpdate.meetLinkEnabled,
                            meetLink: pendingUpdate.meetCode ? `https://meet.google.com/${encodeURIComponent(pendingUpdate.meetCode)}` : "",
                            meetCode: pendingUpdate.meetCode
                        });
                    }
                });
            }, UPDATE_INTERVAL_TIME)
        })
    }

    render() {
        return (
            <div 
              className="meet-link-form-container"
            >
                <Typography 
                  className="subtitle" 
                  variant="h4"
                >
                  {this.state.languageFile && this.state.languageFile.prompt}
                </Typography>
                {this.state.meetLinkEnabled !== null && (
                  <>
                    {this.props.accountType === 'teacher' && (
                      <Typography 
                        variant='body1' 
                        className="desc"
                      >
                        {this.state.languageFile && this.state.languageFile.description}
                      </Typography>
                    )}
                    <div 
                      className="meet-link-form"
                    >
                        <Switch 
                          title={
                            this.state.languageFile && this.state.languageFile.switchDescription
                          }
                          color="secondary" 
                          disabled={
                            this.props.accountType === 'school_teacher' || this.state.waiting
                          } 
                          checked={
                            this.state.meetLinkEnabled
                          } 
                          onChange={
                            (e) => this.handleMeetLinkEnabled(e)
                          }
                        />
                        {this.state.meetLinkEnabled && (
                            <>
                                <TextField 
                                  className="meet-link" 
                                  disabled={
                                    this.props.accountType === 'school_teacher' || this.state.waiting
                                  } 
                                  placeholder={
                                    this.state.languageFile && this.state.languageFile.meetingLink
                                  }
                                  value={
                                    this.state.meetLink
                                  } 
                                  onChange={
                                    (e) => this.setState(
                                      { 
                                        meetLink: e.target.value 
                                      }
                                    )
                                  } 
                                  fullWidth 
                                  inputProps={{ 
                                    maxLength: 255 
                                  }} 
                                  color="secondary"
                                />
                                <IconButton 
                                  title={
                                    this.state.languageFile && this.state.languageFile.accept
                                  }
                                  disabled={
                                    this.props.accountType === 'school_teacher' || this.state.waiting
                                  } 
                                  onClick={
                                    () => this.handleSetMeetCode()
                                  }
                                >
                                    <CheckIcon/>
                                </IconButton>
                            </>
                        )}
                    </div>
                  </>
                )}
                {(this.state.waiting || this.state.error) && (
                    <div 
                      className="status-container"
                    >
                        {this.state.waiting && (
                            <CircularProgress 
                              color="secondary"
                            />
                        )}
                        {this.state.error === "invalid-link" && (
                            <Alert 
                              severity="error"
                            >
                              {this.state.languageFile && this.state.languageFile.linkError}
                            </Alert>
                        )}
                        {this.state.error === "no-connection" && (
                            <Alert 
                              severity="error"
                            >
                              {this.state.languageFile && this.state.languageFile.noConnectionError}
                            </Alert>
                        )}
                        {this.state.error === "unknown-error" && (
                            <Alert 
                              severity="error"
                            >
                              {this.state.languageFile && this.state.languageFile.unknownError}
                            </Alert>
                        )}
                        {this.state.error === "invalid-classroom-code" && (
                            <Alert 
                              severity="error"
                            >
                              {this.state.languageFile && this.state.languageFile.classroomCodeError}
                            </Alert>
                        )}
                    </div>
                )}
            </div>
        );
    }

    handleMeetLinkEnabled(e) {
        this.setState({
            waiting: true,
            error: null
        });

        let enabled = e.target.checked;
        setMeetLinkEnabled(this.props.classroom, enabled, (status) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            if (enabled) {
                this.setState({ waiting: true });
                getMeetCode(this.props.classroom, (status, meetCode) => {
                    this.setState({ waiting: false });
                    if (status !== "success") {
                        switch (status) {
                            case "not-signed-in":
                            case "account-deleted":
                                this.props.onCriticalError(status);
                                break;

                            default:
                                this.setState({ error: status });
                        }
                        return;
                    }

                    this.setState({
                        meetLinkEnabled: enabled,
                        meetLink: meetCode ? `https://meet.google.com/${encodeURIComponent(meetCode)}` : "",
                        meetCode: meetCode
                    });
                    this.props.onLinkEnabled(enabled, meetCode);
                });
            } else {
                this.setState({ meetLinkEnabled: enabled });
                this.props.onLinkEnabled(enabled, null);
            }
        });
    }

    handleSetMeetCode() {
        this.setState({
            waiting: true,
            error: null
        });

        let meetLink = this.state.meetLink;
        if (!meetLink.toLowerCase().startsWith("https://meet.google.com/")) {
            this.setState({
                waiting: false,
                error: "invalid-link"
            });
            return;
        }

        let meetCode = meetLink.substr("https://meet.google.com/".length);
        setMeetCode(this.props.classroom, meetCode, (status) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            this.setState({ meetCode: meetCode });
            this.props.onMeetCodeChange(meetCode);
        });
    }
}

export default MeetForm;