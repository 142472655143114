import { callApi } from "../ApiCaller";

function getUsername(onFinish) {
    callApi("getUsername", {}, (callStatus, httpStatus, username) => {
        if (callStatus !== "success") {
            onFinish(callStatus, null);
            return;
        }

        switch (httpStatus) {
            case 200:
                onFinish("success", username);
                break;

            case 401:
                onFinish("not-signed-in", null);
                break;

            case 403:
                onFinish("account-deleted", null);
                break;

            default:
                onFinish("unknown-error", null);
        }
    });
}

export default getUsername;