import React from "react";
import { FilePicker } from "react-file-picker";

import { Dialog, DialogTitle, DialogContent, Avatar, CircularProgress, Alert, DialogActions, Button } from "@mui/material";

import "./ProfilePictureDialog.css";

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import setProfilePicture from "../../../utils/apiCaller/accountManager/ProfilePictureSetter";

class ProfilePictureDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          languageFile: null,
            waiting: false,
            error: null,
            temporaryProfilePicture: null
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'ProfilePictureDialog', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });
    }

    render() {
        return (
            <>
                <Dialog 
                  className="profile-picture-dialog" 
                  open={this.props.open} 
                  onClose={() => this.handleClose()} 
                  fullWidth
                >
                    <DialogTitle 
                      className="dialog-title"
                    >
                      {this.state.languageFile && this.state.languageFile.prompt}
                    </DialogTitle>
                    <DialogContent 
                      className="profile-picture-dialog-content"
                    >
                        <Avatar 
                          className="avatar" 
                          src={this.state.temporaryProfilePicture 
                            ? URL.createObjectURL(this.state.temporaryProfilePicture) 
                            : (this.props.profilePicture 
                              ? `/profilePictures/${encodeURIComponent(encodeURIComponent(this.props.profilePicture))}` 
                              : null)}
                        />
                        <div 
                          className="button-container"
                        >
                            <FilePicker 
                              onChange={(file) => this.setTemporaryProfilePicture(file)} 
                              onError={() => this.setState({ error: "invalid-file-size" })}
                              maxSize={
                                (2 * 1024 * 1024) / 1000000
                              }
                            >
                                <Button 
                                  color={this.props.color} 
                                  disabled={this.state.waiting} 
                                  variant="contained"
                                >
                                  {this.state.languageFile && this.state.languageFile.choose}
                                </Button>
                            </FilePicker>
                        </div>
                        {(this.state.waiting || this.state.error) && (
                            <div 
                              className="status-container"
                            >
                                {this.state.waiting && (
                                    <CircularProgress 
                                      color={this.props.color}
                                    />
                                )}
                                {this.state.error === "invalid-file-size" && (
                                    <Alert 
                                      severity="error"
                                    >
                                      {this.state.languageFile && this.state.languageFile.fileSizeError}
                                    </Alert>
                                )}
                                {this.state.error === "no-connection" && (
                                    <Alert 
                                      severity="error"
                                    >
                                      {this.state.languageFile && this.state.languageFile.noConnectionError}
                                    </Alert>
                                )}
                                {this.state.error === "unknown-error" && (
                                    <Alert 
                                      severity="error"
                                    >
                                      {this.state.languageFile && this.state.languageFile.unknownError}
                                    </Alert>
                                )}
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button 
                          color={this.props.color} 
                          disabled={this.state.waiting || !this.state.temporaryProfilePicture} 
                          onClick={
                            () => this.handleSubmit()
                          }
                        >
                          {this.state.languageFile && this.state.languageFile.change}
                        </Button>
                        <Button 
                          color={this.props.color} 
                          disabled={this.state.waiting} 
                          onClick={
                            () => this.handleClose()
                          }
                        >
                          {this.state.languageFile && this.state.languageFile.cancel}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    handleClose() {
        this.setState({
            waiting: false,
            error: null,
            temporaryProfilePicture: null
        });

        this.props.onClose();
    }

    handleSubmit() {
        this.setState({
            waiting: true,
            error: null
        });

        setProfilePicture(this.state.temporaryProfilePicture, (status) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            this.props.onProfilePictureSet();
            this.handleClose();
        });
    }

    setTemporaryProfilePicture(file) {
        this.setState({
            error: null,
            temporaryProfilePicture: file
        });
    }
}

export default ProfilePictureDialog;