import { callApi } from "../ApiCaller";

function confirmAccountDelete(deleteCode, password, onFinish) {
    callApi("confirmAccountDelete", {
        deleteCode: deleteCode,
        password: password
    }, (callStatus, httpStatus) => {
        if (callStatus !== "success") {
            onFinish(callStatus);
            return;
        }

        switch (httpStatus) {
            case 200:
                onFinish("success");
                break;

            case 401:
                onFinish("not-signed-in");
                break;

            case 403:
                onFinish("account-deleted");
                break;

            case 404:
                onFinish("invalid-password");
                break;

            default:
                onFinish("unknown-error");
        }
    });
}

export default confirmAccountDelete;