import React from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";

export default class AdaptiveRouter extends React.Component {
    render(){
        return (
            <>
                {navigator.userAgent.indexOf("Electron") !== -1 ? (
                    <HashRouter>{ this.props.children }</HashRouter>
                ) : (
                    <BrowserRouter>{ this.props.children }</BrowserRouter>
                )}
            </>
        );
     }
}