import { callApi } from "../ApiCaller";

function createClassroom(classroomName, classroomNameShort, language, place, description, rules, onFinish) {
    callApi("createClassroom", {
        classroomName: classroomName,
        classroomNameShort: classroomNameShort,
        language: language,
        place: place,
        description: description,
        rules: rules
    }, (callStatus, httpStatus, classroomCode) => {
        if (callStatus !== "success") {
            onFinish(callStatus);
            return;
        }

        switch (httpStatus) {
            case 200:
                onFinish("success", classroomCode);
                break;

            case 401:
                onFinish("not-signed-in");
                break;

            case 403:
                onFinish("account-deleted");
                break;

            default:
                onFinish("unknown-error");
        }
    });
}

export default createClassroom;