import React from 'react';
import { Link } from 'react-router-dom';
import './GradeList.css';

import { IconButton, Typography, List, ListItemButton, ListItemText, Divider, Button } from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import TeacherAccountManagementDialog from '../dialogs/teacherAccountManagementDialog/TeacherAccountManagementDialog';
import GradeCreationDialog from '../dialogs/gradeCreationDialog/GradeCreationDialog';

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import enumerateGrades from '../../utils/apiCaller/gradeManager/GradeEnumerator';
import { UPDATE_INTERVAL_TIME } from '../../utils/UpdateIntervalTime';
import fetchGradeListUpdate from '../../utils/apiCaller/gradeManager/GradeListUpdateFetcher';

export default class GradeList extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      gradeList: null,
      updateInterval: null,
      gradeListVisible: true,
      teacherAccountManagementDialogOpen: false,
      gradeCreationDialogOpen: false
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'GradeList', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });

    enumerateGrades((status, grades) => {
      if (status !== 'success') {
        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.props.onError(status);
            break;
        }
        return;
      }

      this.setState({ gradeList: grades });
      this.props.onGradeListLengthChanged(grades.length);

      if (this.state.updateInterval)
        clearInterval(this.state.updateInterval);

      this.setState({
        updateInterval: setInterval(() => {
          fetchGradeListUpdate((status, updateAvailable, pendingUpdate) => {
            if (status !== 'success') {
              this.props.onCriticalError(status);
              return;
            }

            if (updateAvailable) {
              let addedGrades = [];
              let removedGrades = [];
              let renamedGrades = [];
              for (let update of pendingUpdate) {
                switch (update['operation']) {
                  case 'add':
                    addedGrades = [
                      {
                        gradeCode: update['gradeCode'],
                        gradeName: update['gradeName']
                      },
                      ...addedGrades,
                    ];
                    break;

                  case 'remove':
                    removedGrades.push(update['gradeCode']);
                    break;

                  case 'rename':
                    renamedGrades.push({
                      gradeCode: update['gradeCode'],
                      gradeName: update['gradeName']
                    });
                    break;

                  default:
                }
              }

              let newGradeList = [
                ...addedGrades,
                ...this.state.gradeList.filter((grade) => {
                  return removedGrades.indexOf(grade.gradeCode) === -1
                })
              ].map((grade) => {
                let renamedGrade = renamedGrades.find((temporaryGrade) => temporaryGrade.gradeCode === grade.gradeCode);
                if (renamedGrade)
                  return renamedGrade;

                return grade;
              });

              this.setState({ gradeList: newGradeList });
              this.props.onGradeListLengthChanged(newGradeList.length);
            }
          });
        }, UPDATE_INTERVAL_TIME)
      });
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.updateInterval);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
         nextProps.openGrade !== this.props.openGrade
      || nextProps.newGradeName !== this.props.newGradeName
      || nextProps.gradeDeleteScheduled !== this.props.classroomDeleteScheduled
      || nextState !== this.state
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.newGradeName && this.props.newGradeName !== prevProps.newGradeName) {
      this.setState({
        gradeList: this.state.gradeList.map(
          grade => {
            if (grade.gradeCode === this.props.openGrade) {
              return {
                ...grade,
                gradeName: this.props.newGradeName
              };
            }

            return grade;
          }
        )
      });
    }

    if (this.props.gradeDeleteScheduled && this.props.gradeDeleteScheduled !== prevProps.gradeDeleteScheduled) {
      this.setState({
        gradeList: this.state.gradeList.filter(
          (grade) => {
            return grade.gradeCode !== this.props.openGrade;
          }
        )
      });

      this.props.onGradeDeleteFinished();
    }
  }

  render() {
    return (
      <>
        {this.state.gradeList && (
          <div
            className={
              `grade-list-container
                ${this.props.openGrade
                  && 'open'
                }
                ${!this.state.gradeListVisible
                  && 'hidden'
                }
              `
            }
          >
            <div
              className={
                `grade-list-subcontainer
                  ${!this.state.gradeListVisible
                    && 'hidden'
                  }
                `
              }
            >
              <div
                className='grade-list-title-container'
              >
                <IconButton
                  title={
                    this.state.languageFile && this.state.languageFile.hideGrades
                  }
                  className='hide-button'
                  onClick={
                    () => this.handleHideGradeList()
                  }
                >
                  <KeyboardDoubleArrowLeftIcon />
                </IconButton>
                <Typography
                  variant='h6'
                  className='title'
                >
                  {this.state.languageFile && this.state.languageFile.grades}
                </Typography>
                <div
                  className='right-alignment'
                >
                  <IconButton>
                    <KeyboardDoubleArrowLeftIcon />
                  </IconButton>
                </div>
              </div>
              <List
                className='classroom-list'
                fullWidth
              >
                <ListItemButton
                  className='manage-teacher-accounts-button'
                  disablePadding
                  fullWidth
                  onClick={
                    () => this.handleManageTeacherAccounts()
                  }
                >
                  <ListItemText
                    primary={
                      this.state.languageFile && this.state.languageFile.manageTeacherAccounts
                    }
                  />
                </ListItemButton>
                <Divider
                  className='divider'
                />
                {this.state.gradeList && (
                  this.state.gradeList.map(
                    (grade) => (
                      <Link
                        className='button-link'
                        to={
                          `?grade=${encodeURIComponent(grade.gradeCode)}&tab=classrooms`
                        }
                        onClick={
                          () => this.props.onOpenGrade(grade.gradeCode)
                        }
                      >
                        <ListItemButton
                          disablePadding
                          fullWidth
                          selected={
                            grade.gradeCode === this.props.openGrade
                          }
                        >
                          <ListItemText
                            primary={
                              grade.gradeName
                            }
                          />
                        </ListItemButton>
                      </Link>
                    )
                  )
                )}
              </List>
              <div
                className='button-container'
              >
                <div
                  className='button-link'
                >
                  <Button
                    variant='contained'
                    fullWidth
                    color='warning'
                    onClick={
                      () => this.handleCreateGrade()
                    }
                  >
                    {this.state.languageFile && this.state.languageFile.createGrade}
                  </Button>
                </div>
              </div>
            </div>
            <div
              className={
                `show-button-container
                  ${!this.state.gradeListVisible
                    && 'visible'
                  }
                `
              }
            >
              <IconButton
                title={
                  this.state.languageFile && this.state.languageFile.showGrades
                }
                onClick={
                  () => this.handleShowGradeList()
                }
              >
                <KeyboardDoubleArrowRightIcon />
              </IconButton>
            </div>
          </div>
        )}
        <TeacherAccountManagementDialog
          language={
            this.props.language
          }
          open={
            this.state.teacherAccountManagementDialogOpen
          }
          onClose={
            () => this.closeTeacherAccountManagementDialog()
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
        <GradeCreationDialog
          language={
            this.props.language
          }
          open={
            this.state.gradeCreationDialogOpen
          }
          onClose={
            () => this.closeGradeCreationDialog()
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
          onGradeCreated={
            (gradeCode, gradeName) => {
              const newGradeList = [
                {
                  gradeCode: gradeCode,
                  gradeName: gradeName
                },
                ...this.state.gradeList
              ];
              this.setState({
                gradeList: newGradeList
              });
              this.props.onGradeListLengthChanged(newGradeList.length);
              this.props.onOpenGrade(gradeCode);
            }
          }
        />
      </>
    );
  }

  closeTeacherAccountManagementDialog() {
    this.setState({
      teacherAccountManagementDialogOpen: false
    });
  }

  handleManageTeacherAccounts() {
    this.setState({
      teacherAccountManagementDialogOpen: true
    });
  }

  closeGradeCreationDialog() {
    this.setState({
      gradeCreationDialogOpen: false
    });
  }

  handleCreateGrade() {
    this.setState({
      gradeCreationDialogOpen: true
    });
  }

  handleShowGradeList() {
    this.setState({
      gradeListVisible: true
    });
  }

  handleHideGradeList() {
    this.setState({
      gradeListVisible: false
    });
  }
}