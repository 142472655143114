import React from "react";

import { Typography, Switch, TextField, IconButton, CircularProgress, Alert } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import "./ClassroomLinkForm.css";

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import isClassroomLinkEnabled from "../../../utils/apiCaller/classroomManager/ClassroomLinkStatusChecker";
import getClassroomJoinCode from "../../../utils/apiCaller/classroomManager/ClassroomJoinCodeGetter";
import setClassroomLinkEnabled from "../../../utils/apiCaller/classroomManager/ClassroomLinkStatusSetter";
import generateNewClassroomJoinCode from "../../../utils/apiCaller/classroomManager/ClassroomJoinCodeGenerator";
import { UPDATE_INTERVAL_TIME } from "../../../utils/UpdateIntervalTime";
import fetchClassroomLinkInformationUpdate from "../../../utils/apiCaller/classroomManager/ClassroomLinkInformationUpdateFetcher";

class ClassroomLinkForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          languageFile: null,
            waiting: false,
            error: null,
            classroomLinkEnabled: props.accountType === 'school_teacher' ? false : null,
            classroomJoinCode: null,
            updateInterval: null
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'ClassroomLinkForm', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });

      if (this.props.accountType === 'school_teacher')
        return;

      this.setState({ waiting: true });
      isClassroomLinkEnabled(this.props.classroom, (status, classroomLinkEnabled) => {
          this.setState({ waiting: false });
          if (status !== "success") {
              switch (status) {
                  case "not-signed-in":
                  case "account-deleted":
                      this.props.onCriticalError(status);
                      break;

                  default:
                      this.setState({ error: status });
              }
              return;
          }

          if (classroomLinkEnabled) {
              this.setState({ waiting: true });
              getClassroomJoinCode(this.props.classroom, (status, classroomJoinCode) => {
                  this.setState({ waiting: false });
                  if (status !== "success") {
                      switch (status) {
                          case "not-signed-in":
                          case "account-deleted":
                              this.props.onCriticalError(status);
                              break;

                          default:
                              this.setState({ error: status });
                      }
                      return;
                  }

                  this.setState({
                      classroomLinkEnabled: classroomLinkEnabled,
                      classroomJoinCode: classroomJoinCode
                  });
              });
          } else
              this.setState({ classroomLinkEnabled: classroomLinkEnabled });

          if (this.state.updateInterval)
              clearInterval(this.state.updateInterval);

          this.setState({
              updateInterval: setInterval(() => {
                  fetchClassroomLinkInformationUpdate(this.props.classroom, (status, updateAvailable, pendingUpdate) => {
                      if (status !== "success") {
                          switch (status) {
                              case "invalid-fetch-classroom-code":
                                  break;

                              default:
                                  this.props.onCriticalError(status);
                          }
                          return;
                      }

                      if (updateAvailable) {
                          this.setState({
                              classroomLinkEnabled: pendingUpdate.classroomLinkEnabled,
                              classroomJoinCode: pendingUpdate.joinCode
                          });
                      }
                  });
              }, UPDATE_INTERVAL_TIME)
          });
      });
    }

    componentWillUnmount() {
        clearInterval(this.state.updateInterval);
    }

    render() {
        return (
            <div 
              className="classroom-link-form-container"
            >
                <Typography 
                  className="subtitle" 
                  variant="h4"
                >
                  {this.state.languageFile && this.state.languageFile.prompt}
                </Typography>
                {
                  this.state.classroomLinkEnabled !== null && (
                    <>
                      {this.props.accountType === 'teacher' && (
                        <Typography 
                          variant='body1' 
                          className="desc"
                        >
                          {this.state.languageFile && this.state.languageFile.description}
                        </Typography>
                        
                      )}
                      <div 
                        className="classroom-link-form"
                      >
                          <Switch 
                            title={
                              this.state.languageFile && this.state.languageFile.switchDescription
                            }
                            color="secondary" 
                            disabled={
                              this.props.accountType === 'school_teacher' || this.state.waiting
                            } 
                            checked={
                              this.state.classroomLinkEnabled
                            } 
                            onChange={
                              (e) => this.handleClassroomLinkEnabled(e)
                            }
                          />
                          {this.state.classroomLinkEnabled && (
                              <>
                                  <TextField 
                                    className="classroom-link" 
                                    disabled
                                    value={
                                      `https://www.novalang.pl/joinclassroom?join_code=${encodeURIComponent(this.state.classroomJoinCode)}`
                                    } 
                                    fullWidth
                                  />
                                  <IconButton 
                                    title={
                                      this.state.languageFile && this.state.languageFile.copyLink
                                    }
                                    disabled={
                                      this.state.waiting
                                    } 
                                    onClick={
                                      () => this.handleCopyJoinCode()
                                    }
                                  >
                                      <ContentCopyIcon/>
                                  </IconButton>
                                  <IconButton 
                                    title={
                                      this.state.languageFile && this.state.languageFile.generateNew
                                    }
                                    disabled={
                                      this.state.waiting
                                    } 
                                    onClick={
                                      () => this.handleGenerateNewJoinCode()
                                    }
                                  >
                                      <AddCircleIcon/>
                                  </IconButton>
                              </>
                          )}
                      </div>
                    </>
                  )
                }
                {(this.state.waiting || this.state.error) && (
                    <div 
                      className="status-container"
                    >    
                        {this.state.waiting && (
                            <CircularProgress 
                              color="secondary"
                            />
                        )}
                        {this.state.error === "no-connection" && (
                            <Alert 
                              severity="error"
                            >
                              {this.state.languageFile && this.state.languageFile.noConnectionError}
                            </Alert>
                        )}
                        {this.state.error === "unknown-error" && (
                            <Alert 
                              severity="error"
                            >
                              {this.state.languageFile && this.state.languageFile.unknownError}
                            </Alert>
                        )}
                        {this.state.error === "invalid-classroom-code" && (
                            <Alert 
                              severity="error"
                            >
                              {this.state.languageFile && this.state.languageFile.classroomCodeError}
                            </Alert>
                        )}
                        {this.state.error === "classroom-link-not-enabled" && (
                            <Alert 
                              severity="error"
                            >
                              {this.state.languageFile && this.state.languageFile.linkNotEnabledError}
                            </Alert>
                        )}
                    </div>
                )}
            </div>
        );
    }

    handleClassroomLinkEnabled(e) {
        this.setState({
            waiting: true,
            error: null
        });

        let enabled = e.target.checked;
        setClassroomLinkEnabled(this.props.classroom, enabled, (status) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            if (enabled) {
                this.setState({ waiting: true });
                getClassroomJoinCode(this.props.classroom, (status, classroomJoinCode) => {
                    this.setState({ waiting: false });
                    if (status !== "success") {
                        switch (status) {
                            case "not-signed-in":
                            case "account-deleted":
                                this.props.onCriticalError(status);
                                break;

                            default:
                                this.setState({ error: status });
                        }
                        return;
                    }

                    this.setState({
                        classroomLinkEnabled: enabled,
                        classroomJoinCode: classroomJoinCode
                    });
                    this.props.onJoinCodeChanged(classroomJoinCode);
                });
            } else {
                this.setState({ classroomLinkEnabled: enabled });
              this.props.onJoinCodeChanged(null);  
            }
        });
    }

    handleCopyJoinCode() {
        navigator.clipboard.writeText(`https://www.novalang.pl/joinclassroom?join_code=${encodeURIComponent(this.state.classroomJoinCode)}`);
    }

    handleGenerateNewJoinCode() {
        this.setState({
            waiting: true,
            error: null
        });

        generateNewClassroomJoinCode(this.props.classroom, (status, classroomJoinCode) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            this.setState({ classroomJoinCode: classroomJoinCode });
            this.props.onJoinCodeChanged(classroomJoinCode);
        });
    }
}

export default ClassroomLinkForm;