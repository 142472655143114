import { callApi } from '../ApiCaller';

export default function fetchTeacherAccountListUpdate(onFinish) {
  callApi('fetchTeacherAccountListUpdate', {}, (callStatus, httpStatus, pendingUpdate) => {
    if (callStatus !== 'success') {
      onFinish(callStatus);
      return;
    }

    switch (httpStatus) {
      case 200:
        onFinish('success', true, JSON.parse(pendingUpdate));
        break;

      case 401:
        onFinish('not-signed-in');
        break;

      case 403:
        onFinish('account-deleted');
        break;

      case 404:
        onFinish('success', false);
        break;

      default:
        onFinish('unknown-error');
    }
  });
}