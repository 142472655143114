function encodeBody(body) {
    let encodedBody = [];
    for (let [key, value] of Object.entries(body)) {
        if (typeof value === 'object')
            encodedBody.push(`${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`);
        else
            encodedBody.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }

    return encodedBody.join("&");
}

export default encodeBody;