import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';

import WritingCityAndPlacesInTheCity from './WritingCityAndPlacesInTheCity';
import WritingTransportTourismAndSightseeing from './WritingTransportTourismAndSightseeing';
import WritingLesson4UsefulPhrases from './WritingLesson4UsefulPhrases';

import '../WritingLesson.css';

import increaseTrafficCounter from '../../../utils/apiCaller/TrafficCounterIncreaser';
import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';

class WritingLesson4Component extends React.Component {
  constructor(props) {
    super(props);

    const lessonLanguage = new URLSearchParams(window.location.search).get('language');
    this.state = {
      languageFile: null,
      lessonLanguage: lessonLanguage,
      stage: 0,
      stages: [
        <WritingCityAndPlacesInTheCity
          language={
            props.language
          }
          lessonLanguage={
            lessonLanguage
          }
          onStageFinished={
            () => this.handleStageFinished()
          }
          onCriticalError={
            (error) => props.onCriticalError(error)
          }
        />,
        <WritingTransportTourismAndSightseeing
          language={
            props.language
          }
          lessonLanguage={
            lessonLanguage
          }
          onStageFinished={
            () => this.handleStageFinished()
          }
          onCriticalError={
            (error) => props.onCriticalError(error)
          }
        />,
        <WritingLesson4UsefulPhrases
          language={
            props.language
          }
          lessonLanguage={
            lessonLanguage
          }
          onStageFinished={
            () => this.handleStageFinished()
          }
          onCriticalError={
            (error) => props.onCriticalError(error)
          }
        />
      ]
    };
  }

  componentDidMount() {
    increaseTrafficCounter((status) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }
    });

    getLanguageFile(this.props.language, 'WritingLesson4', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  render() {
    return (
      <main
        className='writing-lesson'
      >
        <div
          className='title-bar'
        >
          <Typography
            variant='h4'
          >
            {this.state.languageFile && this.state.languageFile[this.state.lessonLanguage]}
          </Typography>
          <Typography
            variant='h5'
          >
            {this.state.languageFile && this.state.languageFile.description}
          </Typography>
        </div>
        <div
          className='writing-container'
        >
          <div
            className='writing-subcontainer'
          >
            {
              this.state.stages[this.state.stage]
            }
          </div>
        </div>
      </main>
    );
  }

  handleStageFinished() {
    if (this.state.stage + 1 === this.state.stages.length) {
      this.props.navigate('/writing');
      return;
    }

    this.setState({
      stage: this.state.stage + 1
    });
  }
}

export default function WritingLesson4(props) {
  return <WritingLesson4Component
    {
      ...props
    }
    navigate={
      useNavigate()
    }
  />
}