import React from "react";

import { Dialog, DialogTitle, DialogContent } from "@mui/material";

import "./VideoPlayerDialog.css";

class VideoPlayerDialog extends React.Component {
    render() {
        return (
            <Dialog 
              open={this.props.open} 
              onClose={() => this.handleClose()} 
              fullWidth
            >
                <DialogTitle>
                  {this.props.filename}
                </DialogTitle>
                <DialogContent>
                    <video 
                      className="video-player" 
                      controls 
                      autoPlay
                    >
                        <source 
                          src={`/attachments/${encodeURIComponent(encodeURIComponent(this.props.attachmentCode))}`}
                        />
                    </video>
                </DialogContent>
            </Dialog>
        );
    }

    handleClose() {
        this.props.onClose();
    }
}

export default VideoPlayerDialog;