import React from 'react';

import { CircularProgress, Typography, Select, MenuItem, Card, Avatar, IconButton, Menu, Chip, Button, Fab } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';

import './TaskView.css';
import TaskDeleteDialog from '../dialogs/taskDeleteDialog/TaskDeleteDialog';
import TaskCreationDialog from '../dialogs/taskCreationDialog/TaskCreationDialog';
import TaskHandInDialog from '../dialogs/taskHandInDialog/TaskHandInDialog';
import TaskAnswerDialog from '../dialogs/taskAnswerDialog/TaskAnswerDialog';
import TaskAnswerDeleteDialog from '../dialogs/taskAnswerDeleteDialog/TaskAnswerDeleteDialog';
import TaskAnswerListDialog from '../dialogs/taskAnswerListDialog/TaskAnswerListDialog';

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import enumerateTasks from '../../utils/apiCaller/taskManager/TaskEnumerator';
import formatMaterial from '../../utils/MaterialFormatter';
import { UPDATE_INTERVAL_TIME } from '../../utils/UpdateIntervalTime';
import fetchTaskListUpdate from '../../utils/apiCaller/taskManager/TaskListUpdateFetcher';

export default class TaskView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageFile: null,
      waiting: false,
      error: null,
      color: (props.accountType === 'student' || props.accountType === 'school_student')
        ? 'primary'
        : (props.accountType === 'teacher' || props.accountType === 'school_teacher')
          ? 'secondary'
          : null,
      taskList: null,
      openTaskMenu: null,
      openAnswerMenu: null,
      taskCreationDialogOpen: false,
      openDeleteDialog: null,
      openHandInDialog: null,
      openAnswerDialog: null,
      openAnswerDeleteDialog: null,
      openAnswerListDialog: null,
      filter: 'all',
      updateInterval: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'TaskView', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });

    this.attemptTaskDownload();
  }

  componentWillUnmount() {
    clearInterval(this.state.updateInterval);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
         nextProps.classroom !== this.props.classroom
      || nextProps.teacherName !== this.props.teacherName
      || nextProps.teacherProfilePicture !== this.props.teacherProfilePicture
      || nextState !== this.state
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.classroom !== prevProps.classroom) {
      if (this.state.updateInterval)
        clearInterval(this.state.updateInterval);

      this.attemptTaskDownload();
    }
  }

  render() {
    return (
      <>
        {this.state.waiting && (
          <div
            className='task-view-status-container'
          >
            <CircularProgress
              color={
                this.state.color
              }
            />
          </div>
        )}
        {this.state.taskList && (
          <>
            {this.state.taskList.length === 0 && (
              <div
                className='task-view-message-horizontal-center'
              >
                <div
                  className='task-view-message-vertical-center'
                >
                  <Typography
                    className='task-view-message task-view-message-title'
                    variant='h4'
                  >
                    {this.state.languageFile && this.state.languageFile.noTasks}
                  </Typography>
                  {(this.props.accountType === 'teacher' || this.props.accountType === 'school_teacher') && (
                    <Typography
                      className='task-view-message'
                      variant='h5'
                    >
                      {this.state.languageFile && this.state.languageFile.noTasksDescription}
                    </Typography>
                  )}
                </div>
              </div>
            )}
            <div
              className='task-view'
            >
              {this.state.taskList.length !== 0 && (
                <div
                  className='filter-select-container'
                >
                  <Select
                    color={
                      this.state.color
                    }
                    value={
                      this.state.filter
                    }
                    onChange={
                      (e) => this.setState({
                        filter: e.target.value
                      })
                    }
                  >
                    <MenuItem
                      value='all'
                    >
                      {this.state.languageFile && this.state.languageFile.all}
                    </MenuItem>
                    <MenuItem
                      value='thisMonth'
                    >
                      {this.state.languageFile && this.state.languageFile.thisMonth}
                    </MenuItem>
                    {(this.props.accountType === 'student' || this.props.accountType === 'school_student') && (
                      <MenuItem
                        value='missing'
                      >
                        {this.state.languageFile && this.state.languageFile.overdue}
                      </MenuItem>
                    )}
                  </Select>
                </div>
              )}
              <>
                {this.state.taskList.map((task) => (
                  ((
                       this.state.filter === 'all'
                    || (this.state.filter === 'thisMonth' && (task.due.M - 1 === new Date().getMonth() && parseInt(task.due.Y) === new Date().getFullYear())))
                    || (this.state.filter === 'missing' && !task.handedIn)
                  ) && (
                    <Card
                      className='task'
                    >
                      <div
                        className='task-title-bar'
                      >
                        <div
                          className='author'
                        >
                          <Avatar
                            className='avatar'
                            src={
                              this.props.teacherProfilePicture
                                ? `/profilePictures/${encodeURIComponent(encodeURIComponent(this.props.teacherProfilePicture))}`
                                : null
                            }
                          />
                          <Typography
                            variant='body1'
                          >
                            {this.props.teacherName}
                          </Typography>
                        </div>
                        {task.due.Y >= 0 && (
                          <Typography
                            className='task-due'
                            variant='body1'
                          >
                            {this.state.languageFile && this.formatDeadline(this.state.languageFile.deadline, task.due)}
                          </Typography>
                        )}
                        {(this.props.accountType === 'teacher' || this.props.accountType === 'school_teacher') && (
                          <>
                            <IconButton
                              title={
                                this.state.languageFile && this.state.languageFile.moreOptions
                              }
                              onClick={
                                () => this.openTaskMenu(task)
                              }
                            >
                              <MoreVertIcon
                                className='icon'
                                id={
                                  `${task.taskCode}-more-vert`
                                }
                              />
                            </IconButton>
                            <Menu
                              anchorEl={
                                document.getElementById(`${task.taskCode}-more-vert`)
                              }
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                              open={
                                this.state.openTaskMenu === task.taskCode
                              }
                              onClose={
                                () => this.closeTaskMenu()
                              }
                            >
                              <MenuItem
                                onClick={
                                  () => this.openDeleteDialog(task)
                                }
                              >
                                {this.state.languageFile && this.state.languageFile.delete}
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </div>
                      <Typography
                        variant='body1'
                      >
                        {formatMaterial(task.content)}
                      </Typography>
                      <div
                        className='attachment-list'
                      >
                        {task.attachments.map((attachment) => (
                          !attachment.filename.endsWith('.mp4') && (
                            <a
                              className='attachment-link'
                              href={
                                `/attachments/tasks/${encodeURIComponent(encodeURIComponent(attachment.attachmentCode))}`
                              }
                              download={
                                attachment.filename
                              }
                            >
                              <Chip
                                className='attachment'
                                label={
                                  attachment.filename
                                }
                              />
                            </a>
                          )
                        ))}
                        <div>
                          {task.attachments.map((attachment) => (
                            attachment.filename.endsWith('.mp4') && (
                              <div
                                className='video-container'
                                id={
                                  `video-container-${attachment.attachmentCode}`
                                }
                              >
                                <video
                                  controls
                                  className='video-player'
                                  onPlaying={
                                    () => this.handleVideoChange(attachment.attachmentCode, true)
                                  }
                                  onPause={
                                    () => this.handleVideoChange(attachment.attachmentCode, false)
                                  }
                                  onEnded={
                                    () => this.handleVideoChange(attachment.attachmentCode, false)
                                  }
                                >
                                  <source
                                    src={
                                      `/attachments/tasks/${encodeURIComponent(encodeURIComponent(attachment.attachmentCode))}`
                                    }
                                  />
                                </video>
                                <Typography
                                  variant='body1'
                                  className='video-filename'
                                >
                                  {attachment.filename}
                                </Typography>
                              </div>
                            )
                          ))}
                        </div>
                      </div>
                      <div
                        className='task-answer-container'
                      >
                        {(this.props.accountType === 'student' || this.props.accountType === 'school_student')
                          ? task.handedIn
                            ? (
                              <>
                                <Button
                                  id={
                                    `${task.taskCode}-answer-button`
                                  }
                                  variant='outlined'
                                  color='primary'
                                  onClick={
                                    () => this.openAnswerMenu(task)
                                  }
                                >
                                  {this.state.languageFile && this.state.languageFile.handedIn}
                                </Button>
                                <Menu
                                  anchorEl={
                                    document.getElementById(`${task.taskCode}-answer-button`)
                                  }
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                  }}
                                  open={
                                    this.state.openAnswerMenu === task.taskCode
                                  }
                                  onClose={
                                    () => this.closeAnswerMenu()
                                  }
                                >
                                  <MenuItem
                                    onClick={
                                      () => this.openAnswerDialog(task)
                                    }
                                  >
                                    {this.state.languageFile && this.state.languageFile.showHandedInTask}
                                  </MenuItem>
                                  <MenuItem
                                    onClick={
                                      () => this.openAnswerDeleteDialog(task)
                                    }
                                  >
                                    {this.state.languageFile && this.state.languageFile.undoHandIn}
                                  </MenuItem>
                                </Menu>
                              </>
                            )
                            : (
                              <Button
                                variant='contained'
                                color='primary'
                                onClick={
                                  () => this.openHandInDialog(task)
                                }
                              >
                                {this.state.languageFile && this.state.languageFile.handInTask}
                              </Button>
                            )
                          : (this.props.accountType === 'teacher' || this.props.accountType === 'school_teacher') && (
                            <Button
                              variant={
                                task.handedInCount === task.studentCount
                                  ? 'contained'
                                  : 'outlined'
                              }
                              color='secondary'
                              onClick={
                                () => this.openAnswerListDialog(task)
                              }
                            >
                              {this.state.languageFile && this.formatHandInCounter(this.state.languageFile.handInCounter, task.handedInCount, task.studentCount)}
                            </Button>
                          )
                        }
                      </div>
                    </Card>
                  )
                ))}
                {(this.props.accountType === 'teacher' || this.props.accountType === 'school_teacher') && (
                  <Fab
                    title={
                      this.state.languageFile && this.state.languageFile.addTask
                    }
                    className='floating-action-button'
                    color='secondary'
                    onClick={
                      () => this.openTaskCreationDialog()
                    }
                  >
                    <AddIcon/>
                  </Fab>
                )}
              </>
            </div>
          </>
        )}
        <TaskDeleteDialog
          language={
            this.props.language
          }
          open={
            this.state.openDeleteDialog
          }
          classroom={
            this.props.classroom
          }
          task={
            this.state.openDeleteDialog
          }
          onClose={
            () => this.closeDeleteDialog()
          }
          onTaskDeleted={
            () => this.handleTaskDeleted()
          }
          onCancel={
            () => this.closeDeleteDialog()
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
        <TaskCreationDialog
          language={
            this.props.language
          }
          open={
            this.state.taskCreationDialogOpen
          }
          classroom={
            this.props.classroom
          }
          onClose={
            () => this.closeTaskCreationDialog()
          }
          onTaskCreated={
            (task) => this.handleTaskCreated(task)
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
        <TaskHandInDialog
          language={
            this.props.language
          }
          open={
            this.state.openHandInDialog
          }
          classroom={
            this.props.classroom
          }
          task={
            this.state.openHandInDialog
          }
          onClose={
            () => this.closeHandInDialog()
          }
          onTaskHandedIn={
            () => this.handleTaskHandedIn()
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
        <TaskAnswerDialog
          language={
            this.props.language
          }
          open={
            this.state.openAnswerDialog
          }
          classroom={
            this.props.classroom
          }
          task={
            this.state.openAnswerDialog
          }
          onClose={
            () => this.closeAnswerDialog()
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
        <TaskAnswerDeleteDialog
          language={
            this.props.language
          }
          open={
            this.state.openAnswerDeleteDialog
          }
          classroom={
            this.props.classroom
          }
          task={
            this.state.openAnswerDeleteDialog
          }
          onClose={
            () => this.closeAnswerDeleteDialog()
          }
          onTaskAnswerDeleted={
            () => this.handleTaskAnswerDeleted()
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
        <TaskAnswerListDialog
          language={
            this.props.language
          }
          open={
            this.state.openAnswerListDialog
          }
          classroom={
            this.props.classroom
          }
          task={
            this.state.openAnswerListDialog
          }
          onClose={
            () => this.closeAnswerListDialog()
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
      </>
    );
  }

  formatDeadline(text, deadline) {
    //The formatted text
    return text.replace('&deadline;',
      new Date(deadline.Y, deadline.M - 1, deadline.D, deadline.h, deadline.m)
        .toLocaleString(navigator.language, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        }
      )
    );
  }

  formatHandInCounter(text, handInCount, studentCount) {
    //The formatted text
    return text.replace('&handincount;', `${handInCount}/${studentCount}`);
  }

  handleVideoChange(attachmentCode, playing) {
    const videoContainer = document.getElementById(`video-container-${attachmentCode}`);
    if (playing)
      videoContainer.classList.add('playing');
    else
      videoContainer.classList.remove('playing');
  }

  attemptTaskDownload() {
    this.setState({
      waiting: true,
      taskList: null
    });

    enumerateTasks(this.props.classroom, (status, tasks) => {
      this.setState({
        waiting: false
      });

      if (status !== 'success') {
        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.setState({
              error: status
            });
        }
        return;
      }

      this.setState({
        taskList: tasks,
        updateInterval: setInterval(() => {
          fetchTaskListUpdate(this.props.classroom, (status, updateAvailable, pendingUpdate) => {
            if (status !== 'success') {
              switch (status) {
                case 'invalid-fetch-classroom-code':
                  break;
                
                default:
                  this.props.onCriticalError(status);
              }
              return;
            }

            if (updateAvailable) {
              let addedTasks = [];
              let removedTasks = [];
              for (let update of pendingUpdate) {
                switch (update['operation']) {
                  case 'add':
                    addedTasks = [
                      {
                        taskCode: update['taskCode'],
                        content: update['content'],
                        attachments: update['attachments'],
                        due: update['due'],
                        handedInCount: update['handedInCount'],
                        studentCount: update['studentCount']
                      },
                      ...addedTasks
                    ];
                    break;

                  case 'remove':
                    removedTasks.push(update['taskCode']);
                    break;

                  default:
                }
              }
              
              this.setState({
                taskList: [
                  ...addedTasks,
                  ...this.state.taskList.filter((task) => {
                    return removedTasks.indexOf(task.taskCode) === -1
                  })
                ]
              });
            }
          });
        }, UPDATE_INTERVAL_TIME)
      });
    });
  }

  openTaskMenu(task) {
    this.setState({
      openTaskMenu: task.taskCode
    });
  }

  closeTaskMenu() {
    this.setState({
      openTaskMenu: null
    });
  }

  openAnswerMenu(task) {
    this.setState({
      openAnswerMenu: task.taskCode
    });
  }

  closeAnswerMenu() {
    this.setState({
      openAnswerMenu: null
    });
  }

  openDeleteDialog(task) {
    this.setState({
      openTaskMenu: null,
      openDeleteDialog: task.taskCode
    });
  }

  closeDeleteDialog() {
    this.setState({
      openDeleteDialog: null
    });
  }

  openTaskCreationDialog() {
    this.setState({
      taskCreationDialogOpen: true
    });
  }

  closeTaskCreationDialog() {
    this.setState({
      taskCreationDialogOpen: false
    });
  }

  openHandInDialog(task) {
    this.setState({
      openHandInDialog: task.taskCode
    });
  }

  closeHandInDialog() {
    this.setState({
      openHandInDialog: null
    });
  }

  openAnswerDialog(task) {
    this.setState({
      openAnswerMenu: null,
      openAnswerDialog: task.taskCode
    });
  }

  closeAnswerDialog() {
    this.setState({
      openAnswerDialog: null
    });
  }

  openAnswerDeleteDialog(task) {
    this.setState({
      openAnswerMenu: null,
      openAnswerDeleteDialog: task.taskCode
    });
  }

  closeAnswerDeleteDialog() {
    this.setState({
      openAnswerDeleteDialog: null
    });
  }

  openAnswerListDialog(task) {
    this.setState({
      openAnswerListDialog: task.taskCode
    });
  }

  closeAnswerListDialog() {
    this.setState({
      openAnswerListDialog: null
    });
  }

  handleTaskCreated(task) {
    this.setState({
      taskList: [
        {
          ...task,
          handedInCount: 0
        },
        ...this.state.taskList
      ]
    });
  }

  handleTaskDeleted() {
    this.setState({
      taskList: this.state.taskList.filter((task) => {
        return task.taskCode !== this.state.openDeleteDialog
      })
    });

    this.setState({
      openDeleteDialog: null
    })
  }

  handleTaskHandedIn() {
    this.setState({
      taskList: this.state.taskList.map((task) => {
        if (task.taskCode === this.state.openHandInDialog) {
          return {
            ...task,
            handedIn: true
          };
        }

        return task;
      })
    });

    this.setState({
      openHandInDialog: null
    });
  }

  handleTaskAnswerDeleted() {
    this.setState({
      taskList: this.state.taskList.map((task) => {
        if (task.taskCode === this.state.openAnswerDeleteDialog) {
          return {
            ...task,
            handedIn: false
          };
        }

        return task;
      })
    });

    this.setState({
      openAnswerDeleteDialog: null
    });
  }
}