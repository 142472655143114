import { MenuItem, Select, Typography } from '@mui/material';
import React from 'react';

import './AppearanceSettings.css';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';

export default class AppearanceSettings extends React.Component{
  constructor() {
    super();
    let theme = localStorage.getItem('theme');
    if(!theme){
      localStorage.setItem('theme', 'system');
      theme = 'system';
    }
    this.state = {
      languageFile: null,
      theme: theme
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'AppearanceSettings', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  render(){
    return(
      <div
        className='appearance-setts'
      >
        <Typography 
          variant='h4'
          className='appearance-title'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </Typography>
        <div className='theme-section'>
          <Typography
            variant='body1'
            className='theme-title'
          >
            {this.state.languageFile && this.state.languageFile.theme}
          </Typography>
          <Select 
            color={
              this.props.color
            }
            value={
              this.state.theme
            }
            onChange={
              (e) => {
                this.setTheme(e.target.value);
              }
            }     
          >
            <MenuItem 
              value='light'
            >
              {this.state.languageFile && this.state.languageFile.light}
            </MenuItem>
            <MenuItem 
              value='dark'
            >
              {this.state.languageFile && this.state.languageFile.dark}
            </MenuItem>
            <MenuItem 
              value='system'
            >
              {this.state.languageFile && this.state.languageFile.system}
            </MenuItem>
          </Select>
        </div>
      </div>
    );
  }
  setTheme(theme){
    localStorage.setItem('theme', theme);
    this.setState({
      theme: theme
    });
    this.props.onThemeChanged(theme);
  }
}