import React from "react";

import { Typography, Card, Box, Avatar, IconButton, Menu, MenuItem, Fab, CircularProgress, Alert } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from '@mui/icons-material/Add';

import "./StudentList.css";
import StudentAccountCreationDialog from "../dialogs/studentAccountCreationDialog/StudentAccountCreationDialog";
import StudentAccountEditDialog from "../dialogs/studentAccountEditDialog/StudentAccountEditDialog";
import StudentRemoveDialog from "../dialogs/studentRemoveDialog/StudentRemoveDialog";

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import getGradeStudentList from '../../utils/apiCaller/gradeManager/GradeStudentListGetter';
import getStudentList from "../../utils/apiCaller/classroomManager/StudentListGetter";
import { UPDATE_INTERVAL_TIME } from "../../utils/UpdateIntervalTime";
import fetchGradeStudentListUpdate from '../../utils/apiCaller/gradeManager/GradeStudentListUpdateFetcher';
import fetchStudentListUpdate from "../../utils/apiCaller/classroomManager/StudentListUpdateFetcher";

class StudentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          languageFile: null,
            waiting: false,
            error: null,
            studentList: null,
            openStudentMenu: null,
            openEditDialog: null,
            openRemoveDialog: null,
            currentStudentEmail: null,
            currentStudentUsername: null,
            studentAccountCreationDialogOpen: false,
            color: (props.accountType === "student" || props.accountType === 'school_student') ?
                "primary"
            : (props.accountType === "teacher" || props.accountType === 'school_teacher') ?
                "secondary"
            : (props.accountType === 'school_admin') ?
              'warning'
            : null,
            updateInterval: null
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'StudentList', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });

        this.attemptStudentListDownload();
    }

    componentWillUnmount() {
        clearInterval(this.state.updateInterval);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.classroom !== this.props.classroom || nextProps.updatePending !== this.props.updatePending || nextState !== this.state;
    }

    componentDidUpdate(prevProps) {
        if (this.props.classroom !== prevProps.classroom || (this.props.updatePending && !prevProps.updatePending))
            this.attemptStudentListDownload();
    }

    render() {
        return (
            <>
                <div 
                  className="student-list"
                >
                    <Typography 
                      className="subtitle" 
                      variant="h4"
                    >
                      {this.state.languageFile && this.state.languageFile.prompt}
                    </Typography>
                    {this.state.studentList && ( 
                      <>
                        {this.state.studentList.length === 0 
                          ? <Typography 
                            className="desc" 
                            variant="body1"
                          >
                            {this.state.languageFile && this.state.languageFile.noStudents} {this.props.accountType === 'teacher' && this.state.languageFile && this.state.languageFile.noStudentsInvite}
                          </Typography>
                          : this.state.studentList.map((student) => (
                              <Card 
                                className="student"
                              >
                                  <Box 
                                    className="information"
                                  >
                                      <Avatar 
                                        className="avatar" 
                                        src={
                                          student.profilePicture 
                                            ? `/profilePictures/${encodeURIComponent(encodeURIComponent(student.profilePicture))}` 
                                            : null
                                        }
                                      />
                                      <Box>
                                          <Typography 
                                            variant="body1"
                                          >
                                            {student.username}
                                          </Typography>
                                          <Typography 
                                            variant="body1"
                                          >
                                            {student.email}
                                          </Typography>
                                      </Box>
                                  </Box>
                                  {(this.props.accountType === "teacher" || this.props.accountType === 'school_teacher' || this.props.accountType === 'school_admin') && (
                                      <>
                                          <IconButton 
                                            title={
                                              this.state.languageFile && this.state.languageFile.moreOptions
                                            }
                                            onClick={
                                              () => this.openStudentMenu(student)
                                            }
                                          >
                                              <MoreVertIcon 
                                                className="icon" 
                                                id={
                                                  `${student.studentCode}-more-vert`
                                                }
                                              />
                                          </IconButton>
                                          <Menu 
                                            anchorEl={
                                              document.getElementById(`${student.studentCode}-more-vert`)
                                            } 
                                            anchorOrigin={{ 
                                              vertical: "top", 
                                              horizontal: "right" 
                                            }} 
                                            open={
                                              this.state.openStudentMenu === student.studentCode
                                            } 
                                            onClose={
                                              () => this.closeStudentMenu()
                                            }
                                          >
                                            {this.props.accountType === 'school_admin' && (
                                              <MenuItem
                                                onClick={
                                                  () => this.openEditDialog(student)
                                                }
                                              >
                                                {this.state.languageFile && this.state.languageFile.edit}
                                              </MenuItem>
                                            )}
                                              <MenuItem 
                                                onClick={
                                                  () => this.openRemoveDialog(student)
                                                }
                                                disabled={
                                                  this.props.accountType === 'school_teacher'
                                                }
                                              >
                                                {this.state.languageFile && this.state.languageFile.delete}
                                              </MenuItem>
                                          </Menu>
                                      </>
                                  )}
                              </Card>
                          ))
                        }
                        {this.props.accountType === 'school_admin' && (
                          <Fab
                            title={
                              this.state.languageFile && this.state.languageFile.createAccount
                            }
                            className='floating-action-button'
                            color='warning'
                            onClick={
                              () => this.openStudentAccountCreationDialog()
                            }
                          >
                            <AddIcon />
                          </Fab>
                        )}
                      </>
                    )}
                    {(this.state.waiting || this.state.error) && (
                        <div 
                          className="status-container"
                        >
                            {this.state.waiting && (
                                <CircularProgress 
                                  color={
                                    this.state.color
                                  }
                                />
                            )}
                            {this.state.error === "no-connection" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.noConnectionError}
                                </Alert>
                            )}
                            {this.state.error === "unknown-error" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.unknownError}
                                </Alert>
                            )}
                            {this.state.error === "invalid-classroom-code" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.classroomCodeError}
                                </Alert>
                            )}
                        </div>
                    )}
                </div>
                <StudentAccountCreationDialog
                  language={
                    this.props.language
                  }
                  open={
                    this.state.studentAccountCreationDialogOpen
                  }
                  grade={
                    this.props.grade
                  }
                  onClose={
                    () => this.closeStudentAccountCreationDialog()
                  }
                  onStudentAccountCreated={
                    (studentCode, email, username) => this.handleStudentAccountCreated(studentCode, email, username)
                  }
                  onCriticalError={
                    (error) => this.props.onCriticalError(error)
                  }
                />
                <StudentAccountEditDialog
                  language={
                    this.props.language
                  }
                  open={
                    this.state.openEditDialog
                  }
                  grade={
                    this.props.grade
                  }
                  student={
                    this.state.openEditDialog
                  }
                  email={
                    this.state.currentStudentEmail
                  }
                  username={
                    this.state.currentStudentUsername
                  }
                  onClose={
                    () => this.closeEditDialog()
                  }
                  onStudentAccountEdited={
                    (studentCode, newEmail, newUsername) => this.handleStudentAccountEdited(studentCode, newEmail, newUsername)
                  }
                  onCriticalError={
                    (error) => this.props.onCriticalError(error)
                  }
                />
                <StudentRemoveDialog
                  language={
                    this.props.language
                  }
                  open={
                    this.state.openRemoveDialog
                  }
                  accountType={
                    this.props.accountType
                  }
                  grade={
                    this.props.grade
                  }
                  classroom={
                    this.props.classroom
                  } 
                  student={
                    this.state.openRemoveDialog
                  } 
                  onClose={
                    () => this.closeRemoveDialog()
                  } 
                  onStudentRemoved={
                    () => this.handleStudentRemoved()
                  } 
                  onCriticalError={
                    (error) => this.props.onCriticalError(error)
                  }
                />
            </>
        );
    }

    attemptStudentListDownload() {
        this.setState({
            waiting: true,
            error: null
        });

        if (this.props.accountType === 'school_admin') {
          getGradeStudentList(this.props.grade, (status, studentList) => {
            this.setState({
              waiting: false
            });

            if (status !== 'success') {
              switch (status) {
                case 'not-signed-in':
                case 'account-deleted':
                  this.props.onCriticalError(status);
                  break;

                default:
                  this.setState({
                    error: status
                  });
              }
              return;
            }

            if (this.state.updateInterval)
              clearInterval(this.state.updateInterval);

            this.setState({
              studentList: studentList,
              updateInterval: setInterval(() => {
                fetchGradeStudentListUpdate(this.props.grade, (status, updateAvailable, pendingUpdate) => {
                  if (status !== 'success') {
                    switch (status) {
                      case 'invalid-fetch-grade-code':
                        break;

                      default:
                        this.props.onCriticalError(status);
                    }
                    return;
                  }

                  if (updateAvailable) {
                    let addedStudents = [];
                    let removedStudents = [];
                    let renamedStudents = [];
                    for (let update of pendingUpdate) {
                        switch (update['operation']) {
                            case 'add':
                                addedStudents.push({
                                    studentCode: update['studentCode'],
                                    email: update['email'],
                                    username: update['username'],
                                    profilePicture: update['profilePicture']
                                });
                                break;

                            case 'remove':
                                removedStudents.push(update['studentCode']);
                                break;

                            case 'rename':
                              renamedStudents.push({
                                studentCode: update['studentCode'],
                                email: update['email'],
                                username: update['username'],
                                profilePicture: update['profilePicture']
                              });
                              break;

                            default:
                        }
                    }

                    let newStudentList = [
                      ...addedStudents,
                      ...this.state.studentList.filter((student) => {
                        return removedStudents.indexOf(student.studentCode) === -1
                      })
                    ].map((student) => {
                      let renamedStudent = renamedStudents.find((temporaryStudent) => temporaryStudent.studentCode === student.studentCode);
                      if (renamedStudent)
                        return renamedStudent;

                      return student;
                    });

                    this.setState({ studentList: newStudentList });
                  }
                });
              }, UPDATE_INTERVAL_TIME)
            });
          });
        } else {
          getStudentList(this.props.classroom, (status, studentList) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            this.setState({ studentList: studentList });
            this.props.onUpdate();

            if (this.state.updateInterval)
                clearInterval(this.state.updateInterval);

            this.setState({
                updateInterval: setInterval(() => {
                    fetchStudentListUpdate(this.props.classroom, (status, updateAvailable, pendingUpdate) => {
                        if (status !== "success") {
                            switch (status) {
                                case "invalid-fetch-classroom-code":
                                    break;

                                default:
                                    this.props.onCriticalError(status);
                            }
                            return;
                        }

                        if (updateAvailable) {
                            let addedStudents = [];
                            let removedStudents = [];
                            for (let update of pendingUpdate) {
                                switch (update["operation"]) {
                                    case "add":
                                        addedStudents.push({
                                            studentCode: update["studentCode"],
                                            email: this.props.accountType === "teacher" ? update["email"] : null,
                                            username: update["username"],
                                            profilePicture: update["profilePicture"]
                                        });
                                        break;

                                    case "remove":
                                        removedStudents.push(update["studentCode"]);
                                        break;

                                    default:
                                }
                            }

                            this.setState({ studentList: [
                                ...this.state.studentList.filter((student) => {
                                    return removedStudents.indexOf(student.studentCode) === -1;
                                }),
                                ...addedStudents
                            ]});
                        }
                    });
                }, UPDATE_INTERVAL_TIME)
            });
          });
        }
    }

    openStudentMenu(student) {
        this.setState({ openStudentMenu: student.studentCode });
    }

    closeStudentMenu() {
        this.setState({ openStudentMenu: null });
    }

    openEditDialog(student) {
      this.setState({
        openStudentMenu: null,
        openEditDialog: student.studentCode,
        currentStudentEmail: student.email,
        currentStudentUsername: student.username
      });
    }

    closeEditDialog() {
      this.setState({
        openEditDialog: null,
        currentStudentEmail: null,
        currentStudentUsername: null
      });
    }

    openRemoveDialog(student) {
        this.setState({
            openStudentMenu: null,
            openRemoveDialog: student.studentCode
        });
    }

    closeRemoveDialog() {
        this.setState({ openRemoveDialog: null });
    }

    handleStudentRemoved() {
        this.setState({ studentList: this.state.studentList.filter((student) => {
            return student.studentCode !== this.state.openRemoveDialog;
        }) });
        
        this.setState({ openRemoveDialog: null });
    }

    openStudentAccountCreationDialog() {
      this.setState({
        studentAccountCreationDialogOpen: true
      });
    }

    closeStudentAccountCreationDialog() {
      this.setState({
        studentAccountCreationDialogOpen: false
      });
    }

    handleStudentAccountCreated(studentCode, email, username) {
      this.setState({
        studentList: [
          {
            studentCode: studentCode,
            email: email,
            username: username,
            profilePicture: null
          },
          ...this.state.studentList,
        ]
      });
    }

    handleStudentAccountEdited(studentCode, newEmail, newUsername) {
      this.setState({
        studentList: this.state.studentList.map((student) => {
          if (student.studentCode === studentCode) {
            return {
              ...student,
              email: newEmail,
              username: newUsername
            };
          }

          return student;
        })
      });
    }
}

export default StudentList;