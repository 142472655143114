import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';

import MatchingGamePlaces from './MatchingGamePlaces';
import MatchingGameMedicalStaff from './MatchingGameMedicalStaff';
import MatchingGameMedicines from './MatchingGameMedicines';
import MatchingGameDiseasesAndWounds from './MatchingGameDiseasesAndWounds';
import MatchingGameSymptoms from './MatchingGameSymptoms';
import MatchingGameExaminationsAndTreatment from './MatchingGameExaminationsAndTreatment';

import '../MatchingGameLesson.css';

import increaseTrafficCounter from '../../../utils/apiCaller/TrafficCounterIncreaser';
import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import getAccountType from '../../../utils/apiCaller/accountManager/AccountTypeGetter';

class MatchingGameLesson5Component extends React.Component {
  constructor() {
    super();

    const lessonLanguage = new URLSearchParams(window.location.search).get('language');
    this.state = {
      languageFile: null,
      lessonLanguage: lessonLanguage,
      stages: 6,
      stage: 0,
      color: null
    };
  }

  componentDidMount() {
    increaseTrafficCounter((status) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }
    });

    getLanguageFile(this.props.language, 'MatchingGameLesson5', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });

    getAccountType((status, accountType) => {
      if (status !== 'success')
        return;

      switch (accountType) {
        case 'student':
        case 'school_student':
          this.setState({
            color: 'primary'
          });
          break;

        case 'teacher':
        case 'school_teacher':
          this.setState({
            color: 'secondary'
          });
          break;

        case 'school_admin':
          this.setState({
            color: 'warning'
          });
          break;

        default:
      }
    });
  }

  render() {
    return (
      <main
        className='matching-game-lesson'
      >
        <div
          className='title-bar'
        >
          <Typography
            variant='h4'
          >
            {this.state.languageFile && this.state.languageFile[this.state.lessonLanguage]}
          </Typography>
          <Typography
            variant='h5'
          >
            {this.state.languageFile && this.state.languageFile.description}
          </Typography>
        </div>
        <div
          className='matching-game-container'
        >
          <div
            className='matching-game-subcontainer'
          >
            {this.state.stage === 0 && (
              <MatchingGamePlaces
                language={
                  this.props.language
                }
                lessonLanguage={
                  this.state.lessonLanguage
                }
                color={
                  this.state.color
                }
                onFinish={
                  () => this.handleStageFinish()
                }
                onCriticalError={
                  (error) => this.props.onCriticalError(error)
                }
              />
            )}
            {this.state.stage === 1 && (
              <MatchingGameMedicalStaff
                language={
                  this.props.language
                }
                lessonLanguage={
                  this.state.lessonLanguage
                }
                color={
                  this.state.color
                }
                onFinish={
                  () => this.handleStageFinish()
                }
                onCriticalError={
                  (error) => this.props.onCriticalError(error)
                }
              />
            )}
            {this.state.stage === 2 && (
              <MatchingGameMedicines
                language={
                  this.props.language
                }
                lessonLanguage={
                  this.state.lessonLanguage
                }
                color={
                  this.state.color
                }
                onFinish={
                  () => this.handleStageFinish()
                }
                onCriticalError={
                  (error) => this.props.onCriticalError(error)
                }
              />
            )}
            {this.state.stage === 3 && (
              <MatchingGameDiseasesAndWounds
                language={
                  this.props.language
                }
                lessonLanguage={
                  this.state.lessonLanguage
                }
                color={
                  this.state.color
                }
                onFinish={
                  () => this.handleStageFinish()
                }
                onCriticalError={
                  (error) => this.props.onCriticalError(error)
                }
              />
            )}
            {this.state.stage === 4 && (
              <MatchingGameSymptoms
                language={
                  this.props.language
                }
                lessonLanguage={
                  this.state.lessonLanguage
                }
                color={
                  this.state.color
                }
                onFinish={
                  () => this.handleStageFinish()
                }
                onCriticalError={
                  (error) => this.props.onCriticalError(error)
                }
              />
            )}
            {this.state.stage === 5 && (
              <MatchingGameExaminationsAndTreatment
                language={
                  this.props.language
                }
                lessonLanguage={
                  this.state.lessonLanguage
                }
                color={
                  this.state.color
                }
                onFinish={
                  () => this.handleStageFinish()
                }
                onCriticalError={
                  (error) => this.props.onCriticalError(error)
                }
              />
            )}
          </div>
        </div>
      </main>
    );
  }

  handleStageFinish() {
    if (this.state.stage + 1 === this.state.stages) {
      this.props.navigate('/match');
      return;
    }

    this.setState({
      stage: this.state.stage + 1
    });
  }
}

export default function MatchingGameLesson5(props) {
  return (
    <MatchingGameLesson5Component
      {
        ...props
      }
      navigate={
        useNavigate()
      }
    />
  );
}