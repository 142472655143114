import React from "react";
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";

import { Typography } from "@mui/material";

import "./ClassroomJoinSuccess.css";

import increaseTrafficCounter from '../../utils/apiCaller/TrafficCounterIncreaser';
import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import getAccountType from "../../utils/apiCaller/accountManager/AccountTypeGetter";

class ClassroomJoinSuccessComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            languageFile: null
        };
    }

    componentDidMount() {
        increaseTrafficCounter((status) => {
            if (status !== 'success') {
                this.props.onCriticalError(status);
                return;
            }
        });

        getLanguageFile(this.props.language, 'ClassroomJoinSuccess', (status, file) => {
            if (status !== 'success') {
                this.props.onCriticalError(status);
                return;
            }

            this.setState({
                languageFile: file
            });
        });

        getAccountType((status, accountType) => {
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                        let navigate = this.props.navigate;
                        navigate("/signin");
                        break;
                        
                    default:
                        this.setState({ error: status });
                        break;
                }
                return;
            }

            if (accountType !== "student") {
                let navigate = this.props.navigate;
                navigate("/cockpit");
                return;
            }
        });
    }

    render() {
        return (
            <main 
              className="classroom-join-success"
            >
                <Typography 
                  className="title" 
                  variant="h4"
                >
                  {this.state.languageFile && this.state.languageFile.prompt}
                </Typography>
                <Typography 
                  variant="h5"
                >
                  {this.state.languageFile && this.formatText(this.state.languageFile.description)}
                </Typography>
            </main>
        );
    }

    formatText(text) {
        //Text splitted with the "&here;" marker
        const splittedText = text.split('&here;');

        //The formatted text
        return [
            splittedText[0], //The first part of the text before the "here" link
            <Link className='link' to='/cockpit'>{this.state.languageFile && this.state.languageFile.here}</Link>, //The "here" link
            splittedText[1] //The second part of the text after the "here" link
        ];
    }
}

export default function ClassroomJoinSuccess(props) {
    return <ClassroomJoinSuccessComponent {...props} navigate={useNavigate()}/>
};