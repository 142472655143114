import React from 'react';

import { Card, Typography, IconButton } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

import './Flashcard.css';

import { isFirstWordLong } from '../../utils/StringUtils';

export default class Flashcard extends React.Component {
  constructor() {
    super();
    this.state = {
      audioPlaying: false
    };
  }

  render() {
    return (
      <Card
        className={
          `
            flashcard
            ${this.state.audioPlaying && 'audio-playing'}
            ${this.props.dialog && `flashcard-dialog`}
            ${!this.props.visible && `hidden`}
          `
        }
        sx={{
          backgroundColor: this.props.wrong
            ? 'error.dark'
            : this.props.selected
              ? this.props.color
                ? `${this.props.color}.dark`
                : 'primary.dark'
              : null
          ,
          color: this.props.selected
            ? 'white'
            : null
        }}
        onClick={
          !this.state.audioPlaying && this.props.onClick
        }
      >
        <div
          className='flashcard-subcontainer'
        >  
          <Typography
            className={
              `
                flashcard-word
                ${
                  this.props.side === 'back'
                    ? this.props.translation.length >= 24
                      ? `flashcard-word-very-long flashcard-word-long`
                      : this.props.translation.length >= 18
                        ? `flashcard-word-longer flashcard-word-long`
                        : this.props.translation.length >= 12
                          ? `flashcard-word-long`
                          : isFirstWordLong(this.props.translation) && `flashcard-first-word-long`
                    : this.props.word.length >= 24
                      ? `flashcard-word-very-long flashcard-word-long`
                      : this.props.word.length >= 18
                        ? `flashcard-word-longer flashcard-word-long`
                        : this.props.word.length >= 12
                          ? `flashcard-word-long`
                          : isFirstWordLong(this.props.word) && `flashcard-first-word-long`
                }
              `
            }
            variant='h6'
          >
            {
              this.props.side === 'back'
                ? this.props.translation
                : this.props.word
            }
          </Typography>
          {
               (
                    (this.props.side === 'back' && this.props.translationAudio)
                 || (this.props.side !== 'back' && this.props.wordAudio)
               )
            && (
              <div
                className='listen-button-container'
              >
                <IconButton
                  disabled={
                    this.state.audioPlaying
                  }
                  onClick={
                    (e) => {
                      e.stopPropagation();
                      this.handleListenClicked();
                    }
                  }
                >
                  <VolumeUpIcon
                    className='listen-button-icon'
                  />
                </IconButton>
              </div>
            )
          }
          {this.props.example && (
            <Typography
              className={
                `
                  flashcard-word
                  ${
                    this.props.side === 'back'
                      ? `Przykład: ${this.props.exampleTranslation}`.length >= 16 && `flashcard-word-long`
                      : `Przykład: ${this.props.example}`.length >= 16 && `flashcard-word-long`
                  }
                `
              }             
            >
              {
                this.props.side === 'back'
                  ? `Przykład: ${this.props.exampleTranslation}`
                  : `Przykład: ${this.props.example}`
              }
            </Typography>
          )}
        </div>
      </Card>
    );
  }

  handleListenClicked() {
    const audio = new Audio(
      this.props.side === 'back'
        ? this.props.translationAudio
        : this.props.wordAudio
    );

    //After the word/translation audio is done playing, play the "Example" word if there's an example available
    audio.addEventListener('ended', () => {
      const example = (this.props.side === 'back')
        ? this.props.exampleTranslationAudio
        : this.props.exampleAudio;

      if (example) {
        //The "Example" word
        const exampleWordAudio = new Audio(`/audio/${this.props.language}/example.mp3`);

        //After the "Example" word is done playing, play the actual example
        exampleWordAudio.addEventListener('ended', () => {
          const exampleAudio = new Audio(example);

          exampleAudio.addEventListener('ended', () => {
            this.setState({
              audioPlaying: false
            });
          });

          exampleAudio.play();
        });

        exampleWordAudio.play();
      } else {
        this.setState({
          audioPlaying: false
        });
      }
    });

    this.setState({
      audioPlaying: true
    }, () => {
      audio.play();
    });
  }
}