import React from "react";
import { useNavigate } from "react-router-dom";

import { Typography, CircularProgress, Alert } from "@mui/material";

import "./AccountDelete.css";
import AccountDeleteForm from "../../components/forms/accountDeleteForm/AccountDeleteForm";

import increaseTrafficCounter from '../../utils/apiCaller/TrafficCounterIncreaser';
import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import getAccountType from "../../utils/apiCaller/accountManager/AccountTypeGetter";
import doesAccountDeleteCodeExist from "../../utils/apiCaller/accountManager/AccountDeleteCodeChecker";

class AccountDeleteComponent extends React.Component {
    constructor() {
        super();
        this.state = {
          languageFile: null,
            accountType: null,
            waiting: false,
            error: null,
            deleteCode: null
        };
    }

    componentDidMount() {
      increaseTrafficCounter((status) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }
      });
      
      getLanguageFile(this.props.language, 'AccountDelete', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });

        getAccountType((status, accountType) => {
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                        let navigate = this.props.navigate;
                        navigate("/signin");
                        break;

                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            this.setState({ accountType: accountType });
            switch (accountType) {
                case "student":
                    this.setState({ color: "primary" });
                    break;

                case "teacher":
                    this.setState({ color: "secondary" });
                    break;

                case 'school_admin':
                  this.setState({ color: 'warning' });
                  break;

                default:
            }

            this.setState({
                waiting: true,
                error: null
            });

            let deleteCode = new URLSearchParams(window.location.search).get("delete_code");
            doesAccountDeleteCodeExist(deleteCode, (status, deleteCodeExists) => {
                this.setState({ waiting: false });
                if (status !== "success") {
                    this.setState({ error: status });
                    return;
                }

                if (!deleteCodeExists) {
                    this.setState({ error: "invalid-delete-code" });
                    return;
                }

                this.setState({ deleteCode: deleteCode });
            });
        });
    }
    
    render() {
        return (
            <main 
              className="account-delete"
            >
                <Typography 
                  className="title" 
                  variant="h4"
                >
                  {this.state.languageFile && this.state.languageFile.prompt}
                </Typography>
                {this.state.deleteCode && (
                    <AccountDeleteForm
                      language={
                        this.props.language
                      }
                      accountType={
                        this.state.accountType
                      } 
                      deleteCode={
                        this.state.deleteCode
                      }
                      onCriticalError={
                        (error) => this.props.onCriticalError(error)
                      }
                    />
                )}
                <div 
                  className="center-container"
                >
                    {this.state.waiting && (
                        <CircularProgress 
                          color={
                            this.state.color 
                              ? this.state.color 
                              : "primary"
                          }
                        />
                    )}
                    {this.state.error === "no-connection" && (
                        <Alert 
                          severity="error"
                        >
                          {this.state.languageFile && this.state.languageFile.noConnectionError}
                        </Alert>
                    )}
                    {this.state.error === "unknown-error" && (
                        <Alert 
                          severity="error"
                        >
                          {this.state.languageFile && this.state.languageFile.unknownError}
                        </Alert>
                    )}
                    {this.state.error === "invalid-delete-code" && (
                        <Alert 
                          severity="error"
                        >
                          {this.state.languageFile && this.state.languageFile.deleteCodeError}
                        </Alert>
                    )}
                </div>
            </main>
        );
    }
}

export default function AccountDelete(props) {
  return <AccountDeleteComponent
    {
      ...props
    }
    navigate={
      useNavigate()
    }
  />
};