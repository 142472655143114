import React from 'react';

import { CircularProgress, Typography, Card, IconButton, Menu, MenuItem, Button, Fab } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';

import './WordSetView.css';
import WordSetDialog from '../dialogs/wordSetDialog/WordSetDialog';
import WordSetRenameDialog from '../dialogs/wordSetRenameDialog/WordSetRenameDialog';
import WordSetDeletionDialog from '../dialogs/wordSetDeletionDialog/WordSetDeletionDialog';
import WordSetCreationDialog from '../dialogs/wordSetCreationDialog/WordSetCreationDialog';

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import enumerateWordSets from '../../utils/apiCaller/wordSetManager/WordSetEnumerator';
import { UPDATE_INTERVAL_TIME } from '../../utils/UpdateIntervalTime';
import fetchWordSetListUpdate from '../../utils/apiCaller/wordSetManager/WordSetListUpdateFetcher';

export default class WordSetView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageFile: null,
      waiting: false,
      error: null,
      color: (props.accountType === 'student' || props.accountType === 'school_student')
        ? 'primary'
        : (props.accountType === 'teacher' || props.accountType === 'school_teacher')
          ? 'secondary'
          : null,
      wordSetList: null,
      openWordSetMenu: null,
      wordSetCreationDialogOpen: false,
      openWordSetDialog: null,
      openRenameDialog: null,
      openDeleteDialog: null,
      updateInterval: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'WordSetView', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });

    this.attemptWordSetDownload();
  }

  componentWillUnmount() {
    clearInterval(this.state.updateInterval);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
         nextProps.classroom !== this.props.classroom
      || nextState !== this.state
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.classroom !== prevProps.classroom) {
      if (this.state.updateInterval)
        clearInterval(this.state.updateInterval);

      this.attemptWordSetDownload();
    }
  }

  render() {
    return (
      <>
        {this.state.waiting && (
          <div
            className='word-set-view-status-container'
          >
            <CircularProgress
              color={
                this.state.color
              }
            />
          </div>
        )}
        {this.state.wordSetList && (
          <>
            {this.state.wordSetList.length === 0 && (
              <div
                className='word-set-view-message-horizontal-center'
              >
                <div
                  className='word-set-view-message-vertical-center'
                >
                  <Typography
                    className='word-set-view-message word-set-view-message-title'
                    variant='h4'
                  >
                    {this.state.languageFile && this.state.languageFile.noWordSets}
                  </Typography>
                  {(this.props.accountType === 'teacher' || this.props.accountType === 'school_teacher') && (
                    <Typography
                      className='word-set-view-message'
                      variant='h5'
                    >
                      {this.state.languageFile && this.state.languageFile.noWordSetsDescription}
                    </Typography>
                  )}
                </div>
              </div>
            )}
            <div
              className='word-set-view'
            >
              {this.state.wordSetList.map((wordSet) => (
                <Card
                  className='word-set'
                >
                  <div
                    className='word-set-title-bar'
                  >
                    <div
                      class='information'
                    >
                      <Typography
                        variant='body1'
                      >
                        {wordSet.wordSetName}
                      </Typography>
                    </div>
                    {(this.props.accountType === 'teacher' || this.props.accountType === 'school_teacher') && (
                      <>
                        <IconButton
                          title={
                            this.state.languageFile && this.state.languageFile.moreOptions
                          }
                          onClick={
                            () => this.openWordSetMenu(wordSet)
                          }
                        >
                          <MoreVertIcon
                            className='icon'
                            id={
                              `${wordSet.wordSetCode}-more-vert`
                            }
                          />
                        </IconButton>
                        <Menu
                          anchorEl={
                            document.getElementById(`${wordSet.wordSetCode}-more-vert`)
                          }
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                          open={
                            this.state.openWordSetMenu === wordSet.wordSetCode
                          }
                          onClose={
                            () => this.closeWordSetMenu()
                          }
                        >
                          <MenuItem
                            onClick={
                              () => {
                                this.openRenameDialog(wordSet)
                              }
                            }
                          >
                            {this.state.languageFile && this.state.languageFile.rename}
                          </MenuItem>
                          <MenuItem
                            onClick={
                              () => {
                                this.openDeleteDialog(wordSet)
                              }
                            }
                          >
                            {this.state.languageFile && this.state.languageFile.delete}
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </div>
                  {(this.props.accountType === 'student' || this.props.accountType === 'school_student') && (
                    <div
                      className='open-button-container'
                    >
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={
                          () => this.openWordSetDialog(wordSet)
                        }
                      >
                        {this.state.languageFile && this.state.languageFile.open}
                      </Button>
                    </div>
                  )}
                  {(this.props.accountType === 'teacher' || this.props.accountType === 'school_teacher') && (
                    <div
                      className='open-button-container'
                    >
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={
                          () => this.openWordSetDialog(wordSet)
                        }
                      >
                        {this.state.languageFile && this.state.languageFile.edit}
                      </Button>
                    </div>
                  )}
                </Card>
              ))}
              {(this.props.accountType === 'teacher' || this.props.accountType === 'school_teacher') && (
                <Fab
                  title={
                    this.state.languageFile && this.state.languageFile.addWordSet
                  }
                  className='floating-action-button'
                  color='secondary'
                  onClick={
                    () => {
                      this.openWordSetCreationDialog()
                    }
                  }
                >
                  <AddIcon/>
                </Fab>
              )}
            </div>
          </>
        )}
        <WordSetDialog
          theme={
            this.props.theme
          }
          language={
            this.props.language
          }
          accountType={
            this.props.accountType
          }
          open={
            this.state.openWordSetDialog
          }
          classroom={
            this.props.classroom
          }
          wordSet={
            this.state.openWordSetDialog
          }
          onClose={
            () => this.closeWordSetDialog()
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
        <WordSetRenameDialog
          language={
            this.props.language
          }
          open={
            this.state.openRenameDialog
          }
          classroom={
            this.props.classroom
          }
          wordSet={
            this.state.openRenameDialog
          }
          onClose={
            () => this.closeRenameDialog()
          }
          onWordSetRenamed={
            (newWordSetName) => this.handleWordSetRenamed(newWordSetName)
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
        <WordSetDeletionDialog
          language={
            this.props.language
          }
          open={
            this.state.openDeleteDialog
          }
          classroom={
            this.props.classroom
          }
          wordSet={
            this.state.openDeleteDialog
          }
          onClose={
            () => this.closeDeleteDialog()
          }
          onWordSetDeleted={
            () => this.handleWordSetDeleted()
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
        <WordSetCreationDialog
          language={
            this.props.language
          }
          open={
            this.state.wordSetCreationDialogOpen
          }
          classroom={
            this.props.classroom
          }
          onClose={
            () => this.closeWordSetCreationDialog()
          }
          onWordSetCreated={
            (wordSet) => this.handleWordSetCreated(wordSet)
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
      </>
    );
  }

  attemptWordSetDownload() {
    this.setState({
      waiting: true,
      error: null,
      wordSetList: null
    });

    enumerateWordSets(this.props.classroom, (status, wordSets) => {
      this.setState({
        waiting: false
      });

      if (status !== 'success') {
        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.setState({
              error: status
            });
        }

        return;
      }

      this.setState({
        wordSetList: wordSets,
        updateInterval: setInterval(() => {
          fetchWordSetListUpdate(this.props.classroom, (status, updateAvailable, pendingUpdate) => {
            if (status !== 'success') {
              switch (status) {
                case 'invalid-fetch-classroom-code':
                  break;

                default:
                  this.props.onCriticalError(status);
              }

              return;
            }

            if (updateAvailable) {
              let addedWordSets = [];
              let removedWordSets = [];
              for (let update of pendingUpdate) {
                switch (update['operation']) {
                  case 'add':
                    addedWordSets = [
                      {
                        wordSetCode: update['wordSetCode'],
                        wordSetName: update['wordSetName']
                      },
                      ...addedWordSets
                    ];
                    break;

                  case 'remove':
                    removedWordSets.push(update['wordSetCode']);
                    break;

                  default:
                }
              }

              this.setState({
                wordSetList: [
                  ...addedWordSets,
                  ...this.state.wordSetList.filter((wordSet) => {
                    return removedWordSets.indexOf(wordSet.wordSetCode) === -1
                  })
                ]
              });
            }
          });
        }, UPDATE_INTERVAL_TIME)
      });
    });
  }

  openWordSetMenu(wordSet) {
    this.setState({
      openWordSetMenu: wordSet.wordSetCode
    });
  }

  closeWordSetMenu() {
    this.setState({
      openWordSetMenu: null
    });
  }

  openWordSetDialog(wordSet) {
    this.setState({
      openWordSetMenu: null,
      openWordSetDialog: wordSet
    });
  }

  closeWordSetDialog() {
    this.setState({
      openWordSetDialog: null
    });
  }

  openRenameDialog(wordSet) {
    this.setState({
      openWordSetMenu: null,
      openRenameDialog: wordSet
    });
  }

  closeRenameDialog() {
    this.setState({
      openRenameDialog: null
    });
  }

  openDeleteDialog(wordSet) {
    this.setState({
      openWordSetMenu: null,
      openDeleteDialog: wordSet
    });
  }

  closeDeleteDialog() {
    this.setState({
      openDeleteDialog: null
    });
  }

  openWordSetCreationDialog() {
    this.setState({
      wordSetCreationDialogOpen: true
    });
  }

  closeWordSetCreationDialog() {
    this.setState({
      wordSetCreationDialogOpen: false
    });
  }

  handleWordSetRenamed(newWordSetName) {
    this.setState({
      wordSetList: this.state.wordSetList.map((wordSet) => {
        if (wordSet.wordSetCode === this.state.openRenameDialog.wordSetCode) {
          return {
            ...wordSet,
            wordSetName: newWordSetName
          };
        }

        return wordSet;
      })
    });
  }

  handleWordSetDeleted() {
    this.setState({
      wordSetList: this.state.wordSetList.filter((wordSet) => {
        return wordSet.wordSetCode !== this.state.openDeleteDialog.wordSetCode;
      })
    }); 
  }

  handleWordSetCreated(wordSet) {
    this.setState({
      wordSetList: [
        wordSet,
        ...this.state.wordSetList
      ]
    });
  }
}