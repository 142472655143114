import React from "react";
import { useNavigate } from "react-router-dom";
import adaptiveNavigate from "../../utils/AdaptiveNavigator";

import { AppBar, Toolbar, Box, IconButton, Typography, Menu, MenuItem, Tabs, Tab, Badge } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import QrCodeIcon from '@mui/icons-material/QrCode';
import VideoCallIcon from "@mui/icons-material/VideoCall";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import "./ClassroomView.css";
import MaterialView from "../materialView/MaterialView";
import TaskView from '../taskView/TaskView';
import WordSetView from '../wordSetView/WordSetView';
import MemberListView from "../memberListView/MemberListView";
import MeetView from "../meetView/MeetView";
import ClassroomEditDialog from "../dialogs/classroomEditDialog/ClassroomEditDialog";
import ClassroomDeleteDialog from "../dialogs/classroomDeleteDialog/ClassroomDeleteDialog";
import ClassroomLeaveDialog from "../dialogs/classroomLeaveDialog/ClassroomLeaveDialog";
import QrCodeDialog from '../dialogs/qrCodeDialog/QrCodeDialog';

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import getClassroomInformation from "../../utils/apiCaller/classroomManager/ClassroomInformationGetter";
import getJoinRequestCount from "../../utils/apiCaller/classroomManager/JoinRequestCountGetter";
import { UPDATE_INTERVAL_TIME } from "../../utils/UpdateIntervalTime";
import fetchClassroomInformationUpdate from "../../utils/apiCaller/classroomManager/ClassroomInformationUpdateFetcher";
import fetchJoinRequestCountUpdate from "../../utils/apiCaller/classroomManager/JoinRequestCountUpdateFetcher";

class ClassroomViewComponent extends React.Component {
    constructor() {
        super();
        this.state = {
          languageFile: null,
            classroomInformation: null,
            classroomMenuOpen: false,
            openTab: this.getTab(new URLSearchParams(window.location.search).get("tab")),
            joinRequestCount: null,
            editDialogOpen: false,
            deleteDialogOpen: false,
            leaveDialogOpen: false,
            classroomInformationUpdateInterval: null,
            joinRequestCountUpdateInterval: null,
            qrCodeDialogOpen: false
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'ClassroomView', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });

        this.attemptClassroomInformationDownload();
    }

    componentWillUnmount() {
        clearInterval(this.state.classroomInformationUpdateInterval);
        clearInterval(this.state.joinRequestCountUpdateInterval);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.theme !== this.props.theme || nextProps.classroom !== this.props.classroom || (nextProps.tabResetPending !== this.props.tabResetPending && nextProps.tabResetPending) || nextState !== this.state;
    }

    componentDidUpdate(prevProps) {
        if (this.props.tabResetPending !== prevProps.tabResetPending && this.props.tabResetPending) {
            this.openTab("materials");
            this.props.onTabReset();
        }

        if (this.props.classroom !== prevProps.classroom)
            this.attemptClassroomInformationDownload();
    }

    render() {
        return (
            <div 
              className="classroom-view"
            >
                <AppBar 
                  className="title-bar" 
                  position="static" 
                  sx={{ 
                    backgroundColor: this.props.theme.palette.mode === "dark" 
                    ? null 
                    : ((this.props.accountType === "teacher" || this.props.accountType === 'school_teacher') 
                      ? "secondary.dark" 
                      : "primary.dark")
                  }}
                >
                    <Toolbar 
                      className="toolbar"
                    >
                        <IconButton 
                          className="arrow-back" 
                          onClick={
                            () => this.handleClose()
                          }
                        >
                            <ArrowBackIcon 
                              className="icon"
                            />
                        </IconButton>
                        <Box 
                          className="left-box"
                        >
                            {this.state.classroomInformation && (
                                <Box>
                                    <Typography>
                                      {this.state.classroomInformation.classroomName}
                                    </Typography>
                                    <Typography>
                                      {this.state.languageFile && this.formatText(this.state.languageFile.language, this.state.classroomInformation.language)}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                        {this.state.classroomInformation && (
                          <>
                            {this.props.accountType === 'teacher' && (
                              <IconButton
                                title={
                                  this.state.languageFile && this.state.languageFile.showQR
                                }
                                disabled={
                                  !this.state.classroomInformation.joinCode
                                }
                                onClick={
                                  () => this.showQRCode()
                                }
                              >
                                <QrCodeIcon
                                  className={`
                                    icon
                                    ${
                                      this.state.classroomInformation.joinCode
                                        ? null
                                        : 'icon-disabled'
                                    }
                                  `}
                                />
                              </IconButton>
                            )}
                            <IconButton 
                              title={
                                this.state.languageFile && this.state.languageFile.joinMeeting
                              }
                              disabled={
                                !this.state.classroomInformation.meetLinkEnabled || !this.state.classroomInformation.meetCode
                              } 
                              onClick={
                                () => this.openCall()
                              }
                            >
                                <VideoCallIcon 
                                  className={`icon 
                                    ${
                                      (this.state.classroomInformation.meetLinkEnabled && this.state.classroomInformation.meetCode) 
                                      ? null 
                                      : "icon-disabled"
                                    }`
                                  }
                                />
                            </IconButton>
                          </>
                        )}
                        <IconButton 
                          title={
                            this.state.languageFile && this.state.languageFile.moreOptions
                          }
                          onClick={
                            () => this.openClassroomMenu()
                          }
                        >
                            <MoreVertIcon 
                              className="icon" 
                              id="more-vert"
                            />
                        </IconButton>
                        <Menu 
                          anchorEl={
                            document.getElementById("more-vert")
                          } 
                          anchorOrigin={
                            { 
                              vertical: "top", 
                              horizontal: "right" 
                            }
                          } 
                          open={
                            this.state.classroomMenuOpen
                          } 
                          onClose={
                            () => this.closeClassroomMenu()
                          }
                        >
                            {(this.props.accountType === "teacher" || this.props.accountType === 'school_teacher') 
                              ? (
                                <>
                                    <MenuItem 
                                      onClick={
                                        () => this.openEditDialog()
                                      }
                                      disabled={
                                        this.props.accountType === 'school_teacher'
                                      }
                                    >
                                      {this.state.languageFile && this.state.languageFile.edit}
                                    </MenuItem>
                                    <MenuItem 
                                      onClick={
                                        () => this.openDeleteDialog()
                                      }
                                      disabled={
                                        this.props.accountType === 'school_teacher'
                                      }
                                    >
                                      {this.state.languageFile && this.state.languageFile.delete}
                                    </MenuItem>
                                </>
                            ) : (
                                (this.props.accountType === "student" || this.props.accountType === 'school_student') 
                                  ? (
                                    <MenuItem 
                                      onClick={
                                        () => this.openLeaveDialog()
                                      }
                                      disabled={
                                        this.props.accountType === 'school_student'
                                      }
                                    >
                                      {this.state.languageFile && this.state.languageFile.leave}
                                    </MenuItem>
                                  ) 
                                  : null
                            )}
                        </Menu>
                    </Toolbar>
                    <Tabs 
                      TabIndicatorProps={
                        {
                          style: {background: "white"}
                        }
                      } 
                      value={
                        this.state.openTab
                      }
                    >
                        <Tab 
                          className="tab" 
                          label={
                            this.state.languageFile && this.state.languageFile.materials
                          }
                          value="materials" 
                          onClick={
                            () => this.openTab("materials")
                          }
                        />
                        <Tab
                          className='tab'
                          label={
                            this.state.languageFile && this.state.languageFile.tasks
                          }
                          value='tasks'
                          onClick={
                            () => this.openTab('tasks')
                          }
                        />
                        <Tab
                          className='tab'
                          label={
                            this.state.languageFile && this.state.languageFile.wordSets
                          }
                          value='wordsets'
                          onClick={
                            () => this.openTab('wordsets')
                          }
                        />
                        <Tab 
                          className="tab" 
                          label={
                              <Badge 
                                badgeContent={
                                  this.state.joinRequestCount
                                } 
                                sx={{
                                    "& .MuiBadge-badge": {
                                        backgroundColor: "#D32F2F",
                                        color: "white"
                                    }
                                }}
                              >
                                {this.state.languageFile && this.state.languageFile.members}
                              </Badge>
                            } 
                            value="members" 
                            onClick={
                              () => this.openTab("members")
                            }
                        />
                        {(this.props.accountType === "teacher" || this.props.accountType === 'school_teacher') && (
                            <Tab 
                              className="tab" 
                              label="Google Meet" 
                              value="meet"
                              onClick={
                                () => this.openTab("meet")
                              }
                            />
                        )}
                    </Tabs>
                </AppBar>
                {
                  (this.state.classroomInformation && this.state.openTab === "materials") && (
                    <MaterialView
                      language={
                        this.props.language
                      }
                      accountType={
                        this.props.accountType
                      } 
                      teacherName={
                        this.state.classroomInformation.teacherName
                      } 
                      teacherProfilePicture={
                        this.state.classroomInformation.teacherProfilePicture
                      } 
                      classroom={
                        this.props.classroom
                      }
                      onCriticalError={
                        (error) => this.props.onCriticalError(error)
                      }
                    />
                  )
                }
                {
                  (this.state.classroomInformation && this.state.openTab === 'tasks') && (
                    <TaskView
                      language={
                        this.props.language
                      }
                      accountType={
                        this.props.accountType
                      }
                      teacherName={
                        this.state.classroomInformation.teacherName
                      }
                      teacherProfilePicture={
                        this.state.classroomInformation.teacherProfilePicture
                      }
                      classroom={
                        this.props.classroom
                      }
                      onCriticalError={
                        (error) => this.props.onCriticalError(error)
                      }
                    />
                  )
                }
                {
                  (this.state.classroomInformation && this.state.openTab === 'wordsets') && (
                    <WordSetView
                      theme={
                        this.props.theme
                      }
                      language={
                        this.props.language
                      }
                      accountType={
                        this.props.accountType
                      }
                      classroom={
                        this.props.classroom
                      }
                      onCriticalError={
                        (error) => this.props.onCriticalError(error)
                      }
                    />
                  )
                }
                {
                  (this.state.classroomInformation && this.state.openTab === "members") && (
                    <MemberListView
                      language={
                        this.props.language
                      }
                      accountType={
                        this.props.accountType
                      } 
                      classroom={
                        this.props.classroom
                      } 
                      onJoinRequestProcessed={
                        () => this.handleJoinRequestProcessed()
                      } 
                      onCriticalError={
                        (error) => this.props.onCriticalError(error)
                      }
                      onJoinCodeChanged={
                        (joinCode) => this.setState({
                          classroomInformation: {
                            ...this.state.classroomInformation,
                            joinCode: joinCode
                          }
                        })
                      }
                    />
                  )
                }
                {
                  (this.state.classroomInformation && this.state.openTab === "meet") && (
                    <MeetView
                      language={
                        this.props.language
                      }
                      accountType={
                        this.props.accountType
                      } 
                      classroom={
                        this.props.classroom
                      } 
                      onLinkEnabled={
                        (enabled, meetCode) => this.handleMeetLinkEnabled(enabled, meetCode)
                      } 
                      onMeetCodeChange={
                        (meetCode) => this.handleMeetCodeChange(meetCode)
                      } 
                      onCriticalError={
                        (error) => this.props.onCriticalError(error)
                      }
                    />
                  )
                }
                <ClassroomEditDialog
                  language={
                    this.props.language
                  }
                  open={
                    this.state.editDialogOpen
                  } 
                  classroom={
                    this.props.classroom
                  }
                  classroomName={
                    this.state.classroomInformation
                      ? this.state.classroomInformation.classroomName
                      : null
                  }
                  classroomNameShort={
                    this.state.classroomInformation
                      ? this.state.classroomInformation.classroomNameShort
                      : null
                  }
                  onClose={
                    () => this.closeEditDialog()
                  }
                  onClassroomEdited={
                    (newClassroomName, newClassroomNameShort) => this.handleClassroomEdited(newClassroomName, newClassroomNameShort)
                  } 
                  onCancel={
                    () => this.closeEditDialog()
                  } 
                  onCriticalError={
                    (error) => this.props.onCriticalError(error)
                  }
                />
                <ClassroomDeleteDialog
                  language={
                    this.props.language
                  }
                  open={
                    this.state.deleteDialogOpen
                  } 
                  classroom={
                    this.props.classroom
                  } 
                  onClose={
                    () => this.closeDeleteDialog()
                  } 
                  onClassroomDeleted={
                    () => this.handleClassroomDeleted()
                  } 
                  onCancel={
                    () => this.closeDeleteDialog()
                  } 
                  onCriticalError={
                    (error) => this.props.onCriticalError(error)
                  }
                />
                <ClassroomLeaveDialog
                  language={
                    this.props.language
                  }
                  open={
                    this.state.leaveDialogOpen
                  } 
                  classroom={
                    this.props.classroom
                  } 
                  onClose={
                    () => this.closeLeaveDialog()
                  } 
                  onClassroomLeft={
                    () => this.handleClassroomLeft()
                  } 
                  onCriticalError={
                    (error) => this.props.onCriticalError(error)
                  }
                />
                {
                  (this.props.accountType === 'teacher' && this.state.classroomInformation) && (
                    <QrCodeDialog
                      language={
                        this.props.language
                      }
                      open={
                        this.state.qrCodeDialogOpen
                      }
                      joinCode={
                        this.state.classroomInformation.joinCode
                      }
                      onClose={
                        () => this.closeQrCodeDialog()
                      }
                      onCriticalError={
                        (error) => this.props.onCriticalError(error)
                      }
                    />
                  )
                }
            </div>
        );
    }

    formatText(text, language) {
      //The formatted text
      return text.replace('&language;', this.state.languageFile && this.state.languageFile[language]);
    }

    attemptClassroomInformationDownload() {
        getClassroomInformation(this.props.classroom, (status, classroomInformation) => {
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        //TODO: React to status
                        break;
                }
                return;
            }

            this.setState({ classroomInformation: {
                teacherName: classroomInformation.teacherName,
                teacherProfilePicture: classroomInformation.teacherProfilePicture,
                classroomName: classroomInformation.classroomName,
                joinCode: classroomInformation.joinCode,
                language: classroomInformation.language,
                meetLinkEnabled: classroomInformation.meetLinkEnabled,
                meetCode: classroomInformation.meetCode,
                classroomNameShort: classroomInformation.classroomNameShort
            }});

            if (this.state.classroomInformationUpdateInterval)
                clearInterval(this.state.classroomInformationUpdateInterval);

            this.setState({
                classroomInformationUpdateInterval: setInterval(() => {
                    fetchClassroomInformationUpdate(this.props.classroom, (status, updateAvailable, pendingUpdate) => {
                        if (status !== "success") {
                            switch (status) {
                                case "invalid-fetch-classroom-code":
                                    let navigate = this.props.navigate;
                                    navigate("/cockpit");
                                    this.props.onClose();
                                    break;

                                default:
                                    this.props.onCriticalError(status);
                            }
                        }

                        if (updateAvailable) {
                            this.setState({ classroomInformation: {
                                ...this.state.classroomInformation,
                                classroomName: pendingUpdate.classroomName,
                                meetLinkEnabled: pendingUpdate.meetLinkEnabled,
                                meetCode: pendingUpdate.meetCode
                            }});
                        }
                    });
                }, UPDATE_INTERVAL_TIME)
            });
        });

        if (this.props.accountType === "teacher") {
            getJoinRequestCount(this.props.classroom, (status, joinRequestCount) => {
                if (status !== "success") {
                    switch (status) {
                        case "not-signed-in":
                        case "account-deleted":
                            this.props.onCriticalError(status);
                            break;
    
                        default:
                            //TODO: React to status
                            break;
                    }
                    return;
                }
    
                this.setState({ joinRequestCount: joinRequestCount });

                if (this.state.joinRequestCountUpdateInterval)
                    clearInterval(this.state.joinRequestCountUpdateInterval);

                this.setState({
                    joinRequestCountUpdateInterval: setInterval(() => {
                        fetchJoinRequestCountUpdate(this.props.classroom, (status, updateAvailable, pendingUpdate) => {
                            if (status !== "success") {
                                switch (status) {
                                    case "invalid-fetch-classroom-code":
                                        let navigate = this.props.navigate;
                                        navigate("/cockpit");
                                        this.props.onClose();
                                        break;

                                    default:
                                        this.props.onCriticalError(status);
                                }
                                return;
                            }

                            if (updateAvailable) {
                                this.setState({ joinRequestCount: pendingUpdate["joinRequestCount"] });
                            }
                        });
                    }, UPDATE_INTERVAL_TIME)
                });
            });
        }
    }

    handleClose() {
        let navigate = this.props.navigate;
        navigate("/cockpit");
        
        this.props.onClose();
    }

    handleMeetLinkEnabled(enabled, meetCode) {
        this.setState({ classroomInformation: {
            ...this.state.classroomInformation,
            meetLinkEnabled: enabled,
            meetCode: meetCode
        }})
    }

    handleMeetCodeChange(meetCode) {
        this.setState({ classroomInformation: {
            ...this.state.classroomInformation,
            meetCode: meetCode
        }});
    }

    openCall() {
        window.open(`https://meet.google.com/${this.state.classroomInformation.meetCode}`, "_blank");
    }

    openClassroomMenu() {
        this.setState({ classroomMenuOpen: true });
    }

    closeClassroomMenu() {
        this.setState({ classroomMenuOpen: false });
    }

    openEditDialog() {
        this.setState({
            classroomMenuOpen: false,
            editDialogOpen: true
        });
    }

    closeEditDialog() {
        this.setState({ editDialogOpen: false });
    }

    openDeleteDialog() {
        this.setState({
            classroomMenuOpen: false,
            deleteDialogOpen: true
        });
    }

    closeDeleteDialog() {
        this.setState({ deleteDialogOpen: false });
    }

    openLeaveDialog() {
        this.setState({
            classroomMenuOpen: false,
            leaveDialogOpen: true
        });
    }

    closeLeaveDialog() {
        this.setState({ leaveDialogOpen: false });
    }

    handleClassroomEdited(newClassroomName, newClassroomNameShort) {
        this.setState({ classroomInformation: {
            ...this.state.classroomInformation,
            classroomName: newClassroomName,
            classroomNameShort: newClassroomNameShort
        }});
        this.props.onClassroomEdited(newClassroomName, newClassroomNameShort);
    }

    handleClassroomDeleted() {
        this.props.onClassroomDeleted();
    }

    handleClassroomLeft() {
        adaptiveNavigate(this.props.navigate, "/cockpit");
    }

    getTab(tab) {
        switch (tab) {
            case "materials":
            case 'tasks':
            case 'wordsets':
            case "members":
            case "meet":
                return tab;

            default:
                return "materials";
        }
    }

    openTab(tab) {
        this.setState({ openTab: this.getTab(tab) });

        let navigate = this.props.navigate;
        navigate(`/cockpit?classroom=${encodeURIComponent(this.props.classroom)}&tab=${encodeURIComponent(tab)}`);
    }

    handleJoinRequestProcessed() {
        this.setState({ joinRequestCount: this.state.joinRequestCount - 1 });
    }

    showQRCode() {
      this.setState({
        qrCodeDialogOpen: true
      });
    }

    closeQrCodeDialog() {
      this.setState({
        qrCodeDialogOpen: false
      });
    }
}

export default function ClassroomView(props) {
    return <ClassroomViewComponent {...props} theme={useTheme()} navigate={useNavigate()}/>
}