import React from "react";

import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, CircularProgress, Alert, DialogActions, Button } from "@mui/material";

import "./AccountDeleteDialog.css";
import AccountDeleteRequestedDialog from "../accountDeleteRequestedDialog/AccountDeleteRequestedDialog";

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import requestAccountDelete from "../../../utils/apiCaller/accountManager/AccountDeleteRequester";

class AccountDeleteDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          languageFile: null,
            waiting: false,
            error: null,
            canBeSent: false,
            accountDeleteRequestedDialogOpen: false
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'AccountDeleteDialog', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });
    }

    render() {
        return (
            <>
                <Dialog 
                  className="account-delete-dialog" 
                  open={this.props.open} 
                  onClose={
                    () => this.handleClose()
                  } 
                  fullWidth
                >
                    <DialogTitle 
                      className="dialog-title"
                    >
                      {this.state.languageFile && this.state.languageFile.prompt}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                          {this.state.languageFile && this.state.languageFile.description}
                        </DialogContentText>
                        <TextField 
                          id="accountDeletePassword" 
                          type="password" 
                          label={
                            this.state.languageFile && this.state.languageFile.password
                          }
                          required 
                          disabled={
                            this.state.waiting
                          } 
                          margin="normal" 
                          fullWidth 
                          variant="outlined" 
                          color={
                            this.props.color
                          } 
                          onChange={
                            (e) => this.setState(
                              {canBeSent: e.target.value.length !== 0}
                            )
                          } 
                          autoFocus
                          inputProps={{
                            maxLength: 255
                          }}
                        />
                        {
                          (this.state.waiting || this.state.error) && (
                              <div 
                                className="status-container"
                              >
                                  {this.state.waiting && (
                                      <CircularProgress 
                                        color={
                                          this.props.color
                                        }
                                      />
                                  )}
                                  {this.state.error === "no-connection" && (
                                      <Alert 
                                        severity="error"
                                      >
                                        {this.state.languageFile && this.state.languageFile.noConnectionError}
                                      </Alert>
                                  )}
                                  {this.state.error === "unknown-error" && (
                                      <Alert 
                                        severity="error"
                                      >
                                        {this.state.languageFile && this.state.languageFile.unknownError}
                                      </Alert>
                                  )}
                                  {this.state.error === "invalid-password" && (
                                      <Alert 
                                        severity="error"
                                      >
                                        {this.state.languageFile && this.state.languageFile.invalidPasswordError}
                                      </Alert>
                                  )}
                              </div>
                          )
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button 
                          color={
                            this.props.color
                          } 
                          disabled={
                            this.state.waiting || !this.state.canBeSent
                          } 
                          onClick={
                            () => this.handleSubmit()
                          }
                        >
                          {this.state.languageFile && this.state.languageFile.send}
                        </Button>
                        <Button 
                          color={
                            this.props.color
                          } 
                          disabled={
                            this.state.waiting
                          } 
                          onClick={
                            () => this.handleClose()
                          }
                        >
                          {this.state.languageFile && this.state.languageFile.cancel}
                        </Button>
                    </DialogActions>
                </Dialog>
                <AccountDeleteRequestedDialog
                  language={
                    this.props.language
                  }
                  color={
                    this.props.color
                  } 
                  open={
                    this.state.accountDeleteRequestedDialogOpen
                  } 
                  onClose={
                    () => this.closeAccountDeleteRequestedDialog()
                  } 
                  onCriticalError={
                    (error) => this.props.onCriticalError(error)
                  }
                />
            </>
        );
    }

    handleClose() {
        this.setState({
            waiting: false,
            error: null,
            canBeSent: false
        });

        this.props.onClose();
    }

    handleSubmit() {
        this.setState({
            waiting: true,
            error: null
        });

        let password = document.getElementById("accountDeletePassword").value;
        requestAccountDelete(password, (status) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            this.handleAccountDeleteRequested();
        });
    }

    handleAccountDeleteRequested() {
        this.openAccountDeleteRequestedDialog();
        this.handleClose();
    }

    openAccountDeleteRequestedDialog() {
        this.setState({ accountDeleteRequestedDialogOpen: true });
    }
    
    closeAccountDeleteRequestedDialog() {
        this.setState({ accountDeleteRequestedDialogOpen: false });
    }
}

export default AccountDeleteDialog;