import React from 'react';
import HomepageWeb from './web/HomepageWeb.jsx';
import HomepageApp from './app/HomepageApp.jsx';

export default class Homepage extends React.Component{
  render(){
    return(
      process.env.REACT_APP_PLATFORM === 'web' ? (
        <HomepageWeb {...this.props} />
      ) : (
        process.env.REACT_APP_PLATFORM === 'app' ? (
          <HomepageApp {...this.props} />
        ) : (
          console.error(`Invalid REACT_APP_PLATFORM: ${process.env.REACT_APP_PLATFORM}`)
        )
      )
    );
  }
} 