import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";

import "./EmailVerificationSuccess.css";

import increaseTrafficCounter from '../../utils/apiCaller/TrafficCounterIncreaser';
import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import getAccountType from "../../utils/apiCaller/accountManager/AccountTypeGetter";

class EmailVerificationSuccessComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null
    };
  }

    componentDidMount() {
      increaseTrafficCounter((status) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }
      });

      getLanguageFile(this.props.language, 'EmailVerificationSuccess', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });

      getAccountType((status, accountType) => {
        if (status !== 'success')
          return;

        if (accountType === 'school_student' || accountType === 'school_teacher') {
          const navigate = this.props.navigate;
          navigate('/cockpit');
        }
      });
    }

    render() {
        return (
            <main 
              className="email-verification-success"
            >
                <Typography 
                  className="title" 
                  variant="h4"
                >
                  {this.state.languageFile && this.state.languageFile.prompt}
                </Typography>
                <Typography 
                  variant="h5"
                >
                  {this.state.languageFile && this.formatText(this.state.languageFile.description)}
                </Typography>
            </main>
        );
    }

  formatText(text) {
    //Text splitted with the "&here;" marker
    const splittedText = text.split('&here;');

    //The formatted text
    return [
      splittedText[0], //The first part of the text before the "here" link
      <Link className='link' to='/signin'>{this.state.languageFile && this.state.languageFile.here}</Link>, //The "here" link
      splittedText[1] //The second part of the text after the "here" link
    ];
  }
}

export default function EmailVerificationSuccess(props) {
  return <EmailVerificationSuccessComponent {...props} navigate={useNavigate()} />
};