import { callFormDataApi } from '../ApiCaller';

export default function createTask(classroomCode, content, attachments, due, onFinish) {
  let formData = new FormData();
  formData.append('classroomCode', classroomCode);
  formData.append('content', content);
  formData.append('due', JSON.stringify(due));
  for (let attachment of attachments)
    formData.append('attachments[]', attachment);

  callFormDataApi('createTask', formData, (callStatus, httpStatus, task) => {
    if (callStatus !== 'success') {
      onFinish(callStatus);
      return;
    }

    switch (httpStatus) {
      case 200:
        onFinish('success', JSON.parse(task));
        break;

      case 401:
        onFinish('not-signed-in');
        break;

      case 403:
        onFinish('account-deleted');
        break;

      case 404:
        onFinish('invalid-classroom-code');
        break;

      default:
        onFinish('unknown-error');
    }
  });
}