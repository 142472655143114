import React from 'react';

import { Dialog, DialogTitle, DialogContent, TextField, CircularProgress, Alert, DialogActions, Button } from '@mui/material';

import './TeacherAccountPasswordDialog.css';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import setTeacherAccountPassword from '../../../utils/apiCaller/domainManager/TeacherAccountPasswordSetter';

export default class TeacherAccountPasswordDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      waiting: false,
      error: null,
      canBeSent: false
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'TeacherAccountPasswordDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  render() {
    return (
      <Dialog
        open={
          this.props.open
        }
        onClose={
          () => this.handleClose()
        }
        fullWidth
      >
        <DialogTitle
          className='dialog-title'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </DialogTitle>
        <DialogContent>
          <TextField
            id='newPassword'
            type='password'
            label={
              this.state.languageFile && this.state.languageFile.newPassword
            }
            required
            disabled={
              this.state.waiting
            }
            margin='normal'
            fullWidth
            variant='outlined'
            color='warning'
            onChange={
              () => this.handleChange()
            }
            autoFocus
            inputProps={{
              maxLength: 255
            }}
          />
          <TextField
            id='newPasswordRepeat'
            type='password'
            label={
              this.state.languageFile && this.state.languageFile.repeatPassword
            }
            required
            disabled={
              this.state.waiting
            }
            margin='normal'
            fullWidth
            variant='outlined'
            color='warning'
            onChange={
              () => this.handleChange()
            }
            inputProps={{
              maxLength: 255
            }}
          />
          {
            (this.state.waiting || this.state.error) && (
              <div
                className='teacher-account-password-dialog-status-container'
              >
                {this.state.waiting && (
                  <CircularProgress
                    color='warning'
                  />
                )}
                {this.state.error === 'no-connection' && (
                  <Alert
                    severity='error'
                  >
                    {this.state.languageFile && this.state.languageFile.noConnectionError}
                  </Alert>
                )}
                {this.state.error === 'unknown-error' && (
                  <Alert
                    severity='error'
                  >
                    {this.state.languageFile && this.state.languageFile.unknownError}
                  </Alert>
                )}
                {this.state.error === 'invalid-teacher-id' && (
                  <Alert
                    severity='error'
                  >
                    {this.state.languageFile && this.state.languageFile.accountDeletedError}
                  </Alert>
                )}
              </div>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button
            color='warning'
            disabled={
              this.state.waiting || !this.state.canBeSent
            }
            onClick={
              () => this.handleSubmit()
            }
          >
            {this.state.languageFile && this.state.languageFile.ok}
          </Button>
          <Button
            color='warning'
            disabled={
              this.state.waiting
            }
            onClick={
              () => this.handleClose()
            }
          >
            {this.state.languageFile && this.state.languageFile.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleChange() {
    const newPassword = document.querySelector('#newPassword').value;
    const newPasswordRepeat = document.querySelector('#newPasswordRepeat').value;
    this.setState({
      canBeSent:
           newPassword.length !== 0
        && newPasswordRepeat.length !== 0
        && newPassword === newPasswordRepeat
    });
  }

  handleClose() {
    this.setState({
      waiting: false,
      error: null,
      canBeSent: false
    });

    this.props.onClose();
  }

  handleSubmit() {
    this.setState({
      waiting: true,
      error: null
    });

    const newPassword = document.querySelector('#newPassword').value;
    setTeacherAccountPassword(this.props.teacherId, newPassword, (status) => {
      if (status !== 'success') {
        this.setState({
          waiting: false
        });

        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.setState({
              error: status
            });
        }

        return;
      }

      this.handleClose();
    });
  }
}