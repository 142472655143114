import React from "react";

import MeetForm from "../forms/meetForm/MeetForm";

class MeetView extends React.Component {
    constructor() {
        super();
        this.state = {
            waiting: false,
            error: null
        };
    }

    render() {
        return (
            <div 
              className="meet-view"
            >
                <MeetForm
                  language={
                    this.props.language
                  }
                  accountType={
                    this.props.accountType
                  }
                  classroom={
                    this.props.classroom
                  } 
                  onLinkEnabled={
                    (enabled, meetCode) => this.props.onLinkEnabled(enabled, meetCode)
                  } 
                  onMeetCodeChange={
                    (meetCode) => this.props.onMeetCodeChange(meetCode)
                  } 
                  onCriticalError={
                    (error) => this.props.onCriticalError(error)
                  }
                />
            </div>
        );
    }
}

export default MeetView;