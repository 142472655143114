import React from 'react';

import { Typography } from '@mui/material';

import WritingQuestion from '../WritingQuestion';

import { mapWritingSection } from '../../../utils/LessonUtils';
import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import getLessonFile from '../../../utils/apiCaller/languageManager/LessonFileGetter';

export default class WritingTransportTourismAndSightseeing extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      question: 0,
      questions: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'WritingTransportTourismAndSightseeing', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });

    getLessonFile(this.props.lessonLanguage, 'lesson4', 'TransportTourismAndSightseeing', (status, sourceFile) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      getLessonFile(this.props.language, 'lesson4', 'TransportTourismAndSightseeing', (status, translationFile) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          questions: mapWritingSection(sourceFile, translationFile)
        });
      });
    });
  }

  render() {
    return (
      <div
        className='writing-section'
      >
        <Typography
          className='section-title'
          variant='h5'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </Typography>
        <div
          className='section-container'
        >
          {this.state.questions && (
            <WritingQuestion
              language={
                this.props.language
              }
              question={
                this.state.questions[this.state.question].question
              }
              correctAnswer={
                this.state.questions[this.state.question].correctAnswer
              }
              displayCorrectAnswer={
                this.state.questions[this.state.question].displayCorrectAnswer
              }
              onNextQuestionRequested={
                () => this.handleNextQuestionRequested()
              }
              onCriticalError={
                (error) => this.props.onCriticalError(error)
              }
            />
          )}
        </div>
      </div>
    );
  }

  handleNextQuestionRequested() {
    if (this.state.question + 1 === this.state.questions.length) {
      this.props.onStageFinished();
      return;
    }

    this.setState({
      question: this.state.question + 1
    });
  }
}