import { callApi } from "../ApiCaller";

function requestEmailChange(email, onFinish) {
    callApi("requestEmailChange", {
        email: email
    }, (callStatus, httpStatus) => {
        if (callStatus !== "success") {
            onFinish(callStatus);
            return;
        }

        switch (httpStatus) {
            case 200:
                onFinish("success");
                break;

            case 401:
                onFinish("not-signed-in");
                break;

            case 403:
                onFinish("account-deleted");
                break;

            case 409:
                onFinish("email-already-used");
                break;

            default:
                onFinish("unknown-error");
        }
    });
}

export default requestEmailChange;