import React from "react";
import { useNavigate} from "react-router-dom";
import adaptiveNavigate from "../../../utils/AdaptiveNavigator";

import { TextField, Button, CircularProgress, Alert } from "@mui/material";

import "./AccountDeleteForm.css";

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import confirmAccountDelete from "../../../utils/apiCaller/accountManager/AccountDeleteConfirmer";

class AccountDeleteFormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          languageFile: null,
            waiting: false,
            error: null,
            color: props.accountType === "student" ?
                "primary"
            : props.accountType === "teacher" ?
                "secondary"
            : props.accountType === 'school_admin' ?
              'warning'
            : null
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'AccountDeleteForm', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });
    }
    
    render() {
        return (
            <div 
              className="account-delete-form-container"
            >
                <form 
                  className="account-delete-form" 
                  onSubmit={
                    (e) => this.handleSubmit(e)
                  }
                >
                    <div 
                      className="fields"
                    >
                        <TextField 
                          name="password" 
                          type="password" 
                          label={
                            this.state.languageFile && this.state.languageFile.password
                          } 
                          autoComplete="none" 
                          required 
                          disabled={
                            this.state.waiting
                          } 
                          margin="normal" 
                          fullWidth 
                          variant="outlined" 
                          color={
                            this.state.color
                          }
                          inputRef={
                            (input) => input && input.focus()
                          }
                          inputProps={{
                            maxLength: 255
                          }}
                        />
                    </div>
                    <Button 
                      type="submit" 
                      disabled={
                        this.state.waiting
                      } 
                      fullWidth 
                      variant="contained" 
                      color={
                        this.state.color
                      }
                    >
                      {this.state.languageFile && this.state.languageFile.delete}
                    </Button>
                </form>
                {this.state.waiting && (
                    <CircularProgress 
                      color={
                        this.state.color
                      }
                    />
                )}
                {this.state.error === "no-connection" && (
                    <Alert 
                      severity="error"
                    >
                      {this.state.languageFile && this.state.languageFile.noConnectionError}
                    </Alert>
                )}
                {this.state.error === "unknown-error" && (
                    <Alert 
                      severity="error"
                    >
                      {this.state.languageFile && this.state.languageFile.unknownError}
                    </Alert>
                )}
                {this.state.error === "invalid-password" && (
                    <Alert 
                      severity="error"
                    >
                      {this.state.languageFile && this.state.languageFile.invalidPasswordError}
                    </Alert>
                )}
            </div>
        );
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({
            waiting: true,
            error: null
        });

        let fields = e.target.elements;
        confirmAccountDelete(this.props.deleteCode, fields["password"].value, (status) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            adaptiveNavigate(this.props.navigate, "/deleteaccount/success");
        });
    }
}

export default function AccountDeleteForm(props){
    return <AccountDeleteFormComponent {...props} navigate = {useNavigate()} />
}