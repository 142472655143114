import React from 'react';

import { Typography } from '@mui/material';

import Flashcard from '../../../components/flashcard/Flashcard';

import { mapLearnSection } from '../../../utils/LessonUtils';
import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import getLessonFile from '../../../utils/apiCaller/languageManager/LessonFileGetter';

export default class MatchingGameTransportTourismAndSightseeing extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      cards: [],
      selectedCard: null,
      removedCards: 0,
      wrongCards: null,
      wrongCardTimeout: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'MatchingGameTransportTourismAndSightseeing', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });

    getLessonFile(this.props.lessonLanguage, 'lesson4', 'TransportTourismAndSightseeing', (status, sourceFile) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      getLessonFile(this.props.language, 'lesson4', 'TransportTourismAndSightseeing', (status, translationFile) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          cards: this.randomizeCards(mapLearnSection(sourceFile, translationFile))
        });
      });
    });
  }

  randomizeCards(words) {
    const cardCount = words.length * 2;
    let cards = [];
    for (let i = 0; i < words.length; i++) {
      let cardIndex = Math.floor(Math.random() * cardCount);
      while (cards[cardIndex])
        cardIndex = (cardIndex + 1) % cardCount;

      cards[cardIndex] = {
        type: 'word',
        id: i,
        content: words[i].word,
        visible: true
      };

      cardIndex = Math.floor(Math.random() * cardCount);
      while (cards[cardIndex])
        cardIndex = (cardIndex + 1) % cardCount;

      cards[cardIndex] = {
        type: 'translation',
        id: i,
        content: words[i].translation,
        visible: true
      };
    }

    return cards;
  }

  render() {
    return (
      <div
        className='matching-game-section'
      >
        <Typography
          className='section-title'
          variant='h5'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </Typography>
        <div
          className='section-container'
        >
          {this.state.cards.map((card) => (
            <Flashcard
              word={
                card.content
              }
              selected={
                this.state.selectedCard === card
              }
              visible={
                card.visible
              }
              color={
                this.props.color
              }
              wrong={
                this.state.wrongCards && this.state.wrongCards.indexOf(card) !== -1
              }
              onClick={
                () => this.handleCardClick(card)
              }
            />
          ))}
        </div>
      </div>
    );
  }

  handleCardClick(card) {
    if (this.state.selectedCard) {
      if (card !== this.state.selectedCard) {
        if (card.id === this.state.selectedCard.id) {
          if (this.state.removedCards + 2 === this.state.cards.length)
            this.props.onFinish();

          this.setState({
            cards: this.state.cards.map((currentCard) => {
              if (currentCard === this.state.selectedCard || currentCard === card) {
                return {
                  ...currentCard,
                  visible: false
                };
              }

              return currentCard;
            }),
            selectedCard: null,
            removedCards: this.state.removedCards + 2
          });
        } else {
          if (this.state.wrongCardTimeout)
            clearTimeout(this.state.wrongCardTimeout);
          
          this.setState({
            wrongCards: [
              this.state.selectedCard,
              card
            ],
            wrongCardTimeout: setTimeout(() => {
              this.setState({
                wrongCards: null,
                wrongCardTimeout: null
              });
            }, 100),
            selectedCard: null
          });
        }
      } else {
        this.setState({
          selectedCard: null
        });
      }
    } else {
      this.setState({
        selectedCard: card
      });
    }
  }
}