import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';

export default class ClassroomJoinRequestSentDialog extends React.Component{
  constructor() {
    super();
    this.state = {
      languageFile: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'ClassroomJoinRequestSentDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  render(){
    return(
      <Dialog
        open={
          this.props.open
        }
        onClose={
          () => {
            this.handleClose()
          }
        }
        fullWidth
      >
        <DialogTitle className='dialog-title'>
          {this.state.languageFile && this.state.languageFile.prompt}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.state.languageFile && this.state.languageFile.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={
              () => {
                this.handleClose()
              }
            }
          >
            {this.state.languageFile && this.state.languageFile.ok}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleClose(){
    this.props.onClose();
  }
}