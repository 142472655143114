import { callFormDataApi } from "../ApiCaller";

function setProfilePicture(profilePicture, onFinish) {
    let formData = new FormData();
    formData.append("profilePicture", profilePicture);
    callFormDataApi("setProfilePicture", formData, (callStatus, httpStatus) => {
        if (callStatus !== "success") {
            onFinish(callStatus);
            return;
        }

        switch (httpStatus) {
            case 200:
                onFinish("success");
                break;

            case 401:
                onFinish("not-signed-in");
                break;

            case 403:
                onFinish("account-deleted");
                break;

            default:
                onFinish("unknown-error");
        }
    });
}

export default setProfilePicture;