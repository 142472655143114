import React from "react";
import { Link, useNavigate } from "react-router-dom";
import adaptiveNavigate from "../../../utils/AdaptiveNavigator";

import { TextField, Button, CircularProgress, Alert } from "@mui/material";

import "./SignInForm.css";

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import signIn from "../../../utils/apiCaller/accountManager/SignIn";

class SignInFormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          languageFile: null,
            waiting: false,
            error: null
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'SignInForm', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });
    }

    render() {
        return (
            <div 
              className="sign-in-form-container"
            >
                <form 
                  className="sign-in-form" 
                  onSubmit={
                    (e) => this.handleSubmit(e)
                  }
                >
                    <div 
                      className="fields"
                    >
                        <TextField 
                          name="email" 
                          type="email" 
                          label={
                            this.state.languageFile && this.state.languageFile.email
                          }
                          required 
                          disabled={
                            this.state.waiting
                          } 
                          margin="normal" 
                          fullWidth 
                          variant="outlined" 
                          inputProps={{ 
                            maxLength: 255 
                          }}
                          inputRef={
                            (input) => input && input.focus()
                          }
                        />
                        <TextField 
                          name="password" 
                          type="password" 
                          label={
                            this.state.languageFile && this.state.languageFile.password
                          }
                          required 
                          disabled={
                            this.state.waiting
                          } 
                          margin="normal" 
                          fullWidth 
                          variant="outlined"
                          inputProps={{
                            maxLength: 255
                          }}
                        />
                    </div>
                    <Button 
                      type="submit" 
                      disabled={
                        this.state.waiting
                      } 
                      fullWidth 
                      variant="contained"
                    >
                      {this.state.languageFile && this.state.languageFile.signIn}
                    </Button>
                </form>
                <Link 
                  className="password-recovery" 
                  to="/passwordrecovery"
                >
                  {this.state.languageFile && this.state.languageFile.passwordRecovery}
                </Link>
                {this.state.waiting && (
                    <CircularProgress/>
                )}
                {this.state.error === "no-connection" && (
                    <Alert 
                      severity="error"
                    >
                      {this.state.languageFile && this.state.languageFile.noConnectionError}
                    </Alert>
                )}
                {this.state.error === "unknown-error" && (
                    <Alert 
                      severity="error"
                    >
                      {this.state.languageFile && this.state.languageFile.unknownError}
                    </Alert>
                )}
                {this.state.error === "invalid-credentials" && (
                    <Alert 
                      severity="error"
                    >
                      {this.state.languageFile && this.state.languageFile.invalidCredentialsError}
                    </Alert>
                )}
                {this.state.error === "email-not-verified" && (
                    <Alert 
                      severity="error"
                    >
                      {this.state.languageFile && this.state.languageFile.emailNotVerifiedError}
                    </Alert>
                )}
            </div>
        );
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({
            waiting: true,
            error: null
        });
        
        let fields = e.target.elements;
        signIn(fields["email"].value, fields["password"].value, (status) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                this.setState({ error: status });
                return;
            }

          adaptiveNavigate(this.props.navigate, '/cockpit');
        });
    }
}

export default function SignInForm(props){
    return <SignInFormComponent {...props} navigate = {useNavigate()} />
};