export function isFirstWordLong(string) {
  const words = (typeof string === 'object')
    ? //If the entry is an array, then it's a list of synonyms separated by " / ", so just take the first synonym into consideration
      string[0].props.children.split(' ')

    : string.split(' ');

  if (words.length === 0)
    return false;

  return words[0].length > 8;
}