import { callApi } from './ApiCaller';

export default function increaseTrafficCounter(onFinish) {
  callApi('increaseTrafficCounter', {}, (callStatus, httpStatus) => {
    if (callStatus !== 'success') {
      onFinish(callStatus);
      return;
    }

    switch (httpStatus) {
      case 200:
        onFinish('success');
        break;

      default:
        onFinish('unknown-error');
    }
  });
}