import React from "react";
import { useNavigate } from "react-router-dom";

import { Typography, CircularProgress, Alert } from "@mui/material";

import "./PasswordChange.css";
import PasswordChangeForm from "../../components/forms/passwordChangeForm/PasswordChangeForm";

import increaseTrafficCounter from '../../utils/apiCaller/TrafficCounterIncreaser';
import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import isSignedIn from "../../utils/apiCaller/accountManager/SignInStatusChecker";
import doesPasswordRecoveryCodeExist from "../../utils/apiCaller/accountManager/PasswordRecoveryCodeChecker";

class PasswordChangeComponent extends React.Component {
    constructor() {
        super();
        this.state = {
          languageFile: null,
            waiting: false,
            error: null,
            recoveryCode: null
        };
    }

    componentDidMount() {
      increaseTrafficCounter((status) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }
      });

      getLanguageFile(this.props.language, 'PasswordChange', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });

        isSignedIn((status, signedIn) => {
            if (status !== "success") {
                this.setState({ error: status });
                return;
            }

            if (signedIn) {
                let navigate = this.props.navigate;
                navigate("/cockpit");
                return;
            }

            this.setState({
                waiting: true,
                error: null
            });
    
            let recoveryCode = new URLSearchParams(window.location.search).get("recovery_code");
            doesPasswordRecoveryCodeExist(recoveryCode, (status, recoveryCodeExists) => {
                this.setState({ waiting: false });
                if (status !== "success") {
                    this.setState({ error: status });
                    return;
                }
    
                if (!recoveryCodeExists) {
                    this.setState({ error: "invalid-recovery-code" });
                    return;
                }
    
                this.setState({ recoveryCode: recoveryCode });
            });
        });
    }

    render() {
        return (
            <main 
              className="password-change"
            >
                <Typography 
                  className="title" 
                  variant="h4"
                >
                  {this.state.languageFile && this.state.languageFile.prompt}
                </Typography>
                {this.state.recoveryCode && (
                    <PasswordChangeForm
                      language={
                        this.props.language
                      }
                      recoveryCode={
                        this.state.recoveryCode
                      }
                      onCriticalError={
                        (error) => this.props.onCriticalError(error)
                      }
                    />
                )}
                <div 
                  className="center-container"
                >
                    {this.state.waiting && (
                        <CircularProgress/>
                    )}
                    {this.state.error === "no-connection" && (
                        <Alert 
                          severity="error"
                        >
                          {this.state.languageFile && this.state.languageFile.noConnectionError}
                        </Alert>
                    )}
                    {this.state.error === "unknown-error" && (
                        <Alert 
                          severity="error"
                        >
                          {this.state.languageFile && this.state.languageFile.unknownError}
                        </Alert>
                    )}
                    {this.state.error === "invalid-recovery-code" && (
                        <Alert 
                          severity="error"
                        >
                          {this.state.languageFile && this.state.languageFile.recoveryCodeError}
                        </Alert>
                    )}
                </div>
            </main>
        );
    }
}

export default function PasswordChange(props) {
    return <PasswordChangeComponent {...props} navigate={useNavigate()}/>
};