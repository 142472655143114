import React from "react";
import { Link } from "react-router-dom";

import { Typography, Button, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import "./HomepageWeb.css";
import DailyFunFact from "../../../components/dailyFunFact/DailyFunFact";

import increaseTrafficCounter from '../../../utils/apiCaller/TrafficCounterIncreaser';
import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import isSignedIn from "../../../utils/apiCaller/accountManager/SignInStatusChecker";
import getAccountType from '../../../utils/apiCaller/accountManager/AccountTypeGetter';

class HomepageWebComponent extends React.Component {
    constructor() {
        super();
        this.state = {
          languageFile: null,
            signedIn: false,
          color: null,
          stars: this.generateStars()
        };
    }

    generateStars() {
      const STARS_COUNT = 99;
      const stars = new Array(STARS_COUNT * 2);
      for (let i = 0; i < STARS_COUNT * 2; i++) {
        if (i < STARS_COUNT) {
          stars[i] = {
            layer: Math.floor(Math.random() * 3),
            position: {
              x: Math.random(),
              y: Math.random()
            }
          };
        } else {
          stars[i] = {
            layer: stars[i - STARS_COUNT].layer,
            position: {
              x: stars[i - STARS_COUNT].position.x - 1,
              y: stars[i - STARS_COUNT].position.y
            }
          }
        }
      }

      return stars;
    }

    componentDidMount() {
      increaseTrafficCounter((status) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }
      });

      getLanguageFile(this.props.language, 'Homepage', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });

        isSignedIn((status, signedIn) => {
            if (status !== "success") {
                this.props.onCriticalError(status);
                return;
            }

            this.setState({ signedIn: signedIn });

          if (signedIn) {
            getAccountType((status, accountType) => {
              if (status !== 'success') {
                this.props.onCriticalError(status);
                return;
              }

              switch (accountType) {
                case 'student':
                case 'school_student':
                  this.setState({
                    color: 'primary'
                  });
                  break;

                case 'teacher':
                case 'school_teacher':
                  this.setState({
                    color: 'secondary'
                  });
                  break;

                case 'school_admin':
                  this.setState({
                    color: 'warning'
                  });
                  break;
                
                default:
              }
            });
          }
        });
    }

    render() {
        return (
            <main 
              className="homepage-web"
            >
                <Box 
                  className="learn-section" 
                  sx={{
                    background: 'linear-gradient(0deg, #0B3360, #1565C0)'
                  }}
                >
                  <div
                    className='stars animation-0'
                  >
                    {this.state.stars.map((star) => (
                      star.layer === 0
                        ? (
                          <div
                            className='star'
                            style={{
                              left: `${star.position.x * 100}%`,
                              top: `${star.position.y * 100}%`
                            }}
                          >
                          </div>
                        ) : (
                          <></>
                        )
                    ))}
                  </div>
                  <div
                    className='stars animation-1'
                  >
                    {this.state.stars.map((star) => (
                      star.layer === 1
                      ? (
                        <div
                          className='star'
                          style={{
                            left: `${star.position.x * 100}%`,
                            top: `${star.position.y * 100}%`
                          }}
                        >
                        </div>
                      ) : (
                        <></>
                      )
                    ))}
                  </div>
                  <div
                    className='stars animation-2'
                  >
                    {this.state.stars.map((star) => (
                      star.layer === 2
                      ? (
                        <div
                          className='star'
                          style={{
                            left: `${star.position.x * 100}%`,
                            top: `${star.position.y * 100}%`
                          }}
                        >
                        </div>
                      ) : (
                        <></>
                      )
                    ))}
                  </div>
                    <div 
                      className="content"
                    >
                        <div 
                          className="text"
                        >
                            <Typography 
                              className="title" 
                              variant="h4"
                            >
                              {this.state.languageFile && this.state.languageFile.projectDescription}
                            </Typography>
                            <Typography 
                              variant="h5"
                            >
                              {this.state.languageFile && this.state.languageFile.projectExtendedDescription}
                            </Typography>
                        </div>
                        {
                          this.state.signedIn 
                          ? (
                              <Link 
                                className="button-link" 
                                to="/cockpit"
                              >
                                  <Button 
                                    className="learn-section-button" 
                                    variant="outlined" 
                                    fullWidth
                                  >
                                    {this.state.languageFile && this.state.languageFile.cockpit}
                                  </Button>
                              </Link>
                          ) 
                          : (
                              <Link 
                                className="button-link" 
                                to="/signup"
                              >
                                  <Button 
                                    className="learn-section-button" 
                                    variant="outlined" 
                                    fullWidth
                                  >
                                    {this.state.languageFile && this.state.languageFile.signUp}
                                  </Button>
                              </Link>
                          )
                        }
                    </div>
                </Box>
              <DailyFunFact
                language={
                  this.props.language
                }
                darkMode={
                  this.props.theme.palette.mode === 'dark'
                }
                onCriticalError={
                  (error) => this.props.onCriticalError(error)
                }
              />
              {/* section-container prevents the browser from displaying a horizontal scroll-bar during the slide-in animations from the right side */}
              {/* All sections are animated in the slide-in-from-left/slide-in-from-right sequence */}
              <div
                className='section-container'
              >
                <div
                  className='store-information slide-in-left'
                >
                  <div
                    className='content'
                  >
                    <div
                      className='store-description'
                    >
                      <Typography
                        className='title'
                        variant='h4'
                      >
                        {this.state.languageFile && this.state.languageFile.store}
                      </Typography>
                      <Typography
                        variant='h5'
                      >
                        {this.state.languageFile && this.state.languageFile.storeDescription}
                      </Typography>
                    </div>
                    <div
                      className='store-link-container'
                    >
                      <Link
                        className='store-link'
                        to='/store'
                      >
                        <Typography
                          className='store-link-text'
                          variant='h5'
                        >
                          <ArrowForwardIcon
                            className='store-link-icon'
                          />
                          {this.state.languageFile && this.state.languageFile.storeOpen}
                        </Typography>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='section-container'
              >
                <div
                  className='space-game-information slide-in-right'
                >
                  <div
                    className='content'
                  >
                    <div
                      className='space-game-description'
                    >
                      <Typography
                        className='title'
                        variant='h4'
                      >
                        Space Game
                      </Typography>
                      <Typography
                        variant='h5'
                      >
                        <img
                          className='space-game-screenshot'
                          src={`${process.env.PUBLIC_URL}/space-game.png`}
                          alt=''
                        />
                        {this.state.languageFile && this.state.languageFile.spaceGameDescription}
                      </Typography>
                    </div>
                    <div
                      className='space-game-link-container'
                    >
                      <a
                        className='space-game-link'
                        href='/space-game'
                      >
                        <Typography
                          className='space-game-link-text'
                          variant='h5'
                        >
                          <ArrowForwardIcon
                            className='space-game-link-icon'
                          />
                          {this.state.languageFile && this.state.languageFile.spaceGameOpen}
                        </Typography>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='section-container'
              >
                <div className='dictionary-information slide-in-left'>
                  <div className='content'>
                    <div className='dictionary-description'>
                      <Typography
                        className='title'
                        variant='h4'
                      >
                        {this.state.languageFile && this.state.languageFile.dictionary}
                      </Typography>
                      <Typography
                        variant='h5'
                      >
                        {this.state.languageFile && this.state.languageFile.dictionaryDescription}
                      </Typography>
                    </div>
                    <div
                      className='dictionary-link-container'
                    >
                      <Link
                        className='dictionary-link'
                        to='/dictionary'
                      >
                        <Typography
                          className='dictionary-link-text'
                          variant='h5'
                        >
                          <ArrowForwardIcon
                            className='dictionary-link-icon'
                          />
                          {this.state.languageFile && this.state.languageFile.dictionaryOpen}
                        </Typography>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='section-container'
              >
                <div
                  className='learn-information slide-in-right'
                >
                  <div
                    className='content'
                  >
                    <div
                      className='learn-description'
                    >
                      <Typography
                        className='title'
                        variant='h4'
                      >
                        {this.state.languageFile && this.state.languageFile.learn}
                      </Typography>
                      <Typography
                        variant='h5'
                      >
                        {this.state.languageFile && this.state.languageFile.learnDescription}
                      </Typography>
                    </div>
                    <div
                      className='learn-link-container'
                    >
                      <Link
                        className='learn-link'
                        to='/learn'
                      >
                        <Typography
                          className='learn-link-text'
                          variant='h5'
                        >
                          <ArrowForwardIcon
                            className='learn-link-icon'
                          />
                          {this.state.languageFile && this.state.languageFile.learnOpen}
                        </Typography>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='section-container'
              >
                <div
                  className='flashcard-information slide-in-left'
                >
                  <div
                    className='content'
                  >
                    <div
                      className='flashcard-description'
                    >
                      <Typography
                        className='title'
                        variant='h4'
                      >
                        {this.state.languageFile && this.state.languageFile.flashcards}
                      </Typography>
                      <Typography
                        variant='h5'
                      >
                        {this.state.languageFile && this.state.languageFile.flashcardsDescription}
                      </Typography>
                    </div>
                    <div
                      className='flashcard-link-container'
                    >
                      <Link
                        className='flashcard-link'
                        to='/flashcards'
                      >
                        <Typography
                          className='flashcard-link-text'
                          variant='h5'
                        >
                          <ArrowForwardIcon
                            className='flashcard-link-icon'
                          />
                          {this.state.languageFile && this.state.languageFile.flashcardsOpen}
                        </Typography>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='section-container'
              >
                <div
                  className='matching-game-information slide-in-right'
                >
                  <div
                    className='content'
                  >
                    <div
                      className='matching-game-description'
                    >
                      <Typography
                        className='title'
                        variant='h4'
                      >
                        {this.state.languageFile && this.state.languageFile.matchingGame}
                      </Typography>
                      <Typography
                        variant='h5'
                      >
                        {this.state.languageFile && this.state.languageFile.matchingGameDescription}
                      </Typography>
                    </div>
                    <div
                      className='matching-game-link-container'
                    >
                      <Link
                        className='matching-game-link'
                        to='/match'
                      >
                        <Typography
                          className='matching-game-link-text'
                          variant='h5'
                        >
                          <ArrowForwardIcon
                            className='matching-game-link-icon'
                          />
                          {this.state.languageFile && this.state.languageFile.matchingGameOpen}
                        </Typography>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='section-container'
              >
                <div
                  className='quiz-information slide-in-left'
                >
                  <div
                    className='content'
                  >
                    <div
                      className='quiz-description'
                    >
                      <Typography
                        className='title'
                        variant='h4'
                      >
                        {this.state.languageFile && this.state.languageFile.quiz}
                      </Typography>
                      <Typography
                        variant='h5'
                      >
                        {this.state.languageFile && this.state.languageFile.quizDescription}
                      </Typography>
                    </div>
                    <div
                      className='quiz-link-container'
                    >
                      <Link
                        className='quiz-link'
                        to='/quiz'
                      >
                        <Typography
                          className='quiz-link-text'
                          variant='h5'
                        >
                          <ArrowForwardIcon
                            className='quiz-link-icon'
                          />
                          {this.state.languageFile && this.state.languageFile.quizOpen}
                        </Typography>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='section-container'
              >
                <div
                  className='writing-information slide-in-right'
                >
                  <div
                    className='content'
                  >
                    <div
                      className='writing-description'
                    >
                      <Typography
                        className='title'
                        variant='h4'
                      >
                        {this.state.languageFile && this.state.languageFile.writing}
                      </Typography>
                      <Typography
                        variant='h5'
                      >
                        {this.state.languageFile && this.state.languageFile.writingDescription}
                      </Typography>
                    </div>
                    <div
                      className='writing-link-container'
                    >
                      <Link
                        className='writing-link'
                        to='/writing'
                      >
                        <Typography
                          className='writing-link-text'
                          variant='h5'
                        >
                          <ArrowForwardIcon
                            className='writing-link-icon'
                          />
                          {this.state.languageFile && this.state.languageFile.writingOpen}
                        </Typography>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </main>
        );
    }
}

export default function HomepageWeb(props) {
    return <HomepageWebComponent {...props} theme={useTheme()}/>
}