import React from "react";
import { useNavigate, Link } from "react-router-dom";

import { Typography, Alert, Box, IconButton } from "@mui/material";

import "./StudentAccountCreator.css";
import AccountCreationForm from "../../components/forms/accountCreationForm/AccountCreationForm";

import increaseTrafficCounter from '../../utils/apiCaller/TrafficCounterIncreaser';
import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import isSignedIn from "../../utils/apiCaller/accountManager/SignInStatusChecker";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

class StudentAccountCreatorComponent extends React.Component {
    constructor() {
        super();
        this.state = {
          languageFile: null,
            error: null
        };
    }
    
    componentDidMount() {
      increaseTrafficCounter((status) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }
      });

      getLanguageFile(this.props.language, 'StudentAccountCreator', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });

        isSignedIn((status, signedIn) => {
            if (status !== "success") {
                this.setState({ error: status });
                return;
            }

            if (signedIn) {
                let navigate = this.props.navigate;
                navigate("/cockpit");
            }
        });
    }

    render() {
        return (
            <main 
              className="student-account-creator"
            >
              <Box 
                className="title-container"
              >
                { process.env.REACT_APP_PLATFORM==='app' &&
                   <Link 
                    to='/signup' 
                    className="back-icon"
                   >
                    <IconButton>
                      <ArrowBackIcon 
                        fontSize="large" 
                      />
                    </IconButton>
                  </Link>
                }
                <Typography 
                  className="title" 
                  variant="h4"
                >
                  {this.state.languageFile && this.state.languageFile.prompt}
                </Typography>
                { process.env.REACT_APP_PLATFORM==='app' && 
                  <Box 
                    className="right-alignment"
                  >
                    <IconButton>
                      <ArrowBackIcon 
                        fontSize="large" 
                      />
                    </IconButton>
                  </Box>
                }
              </Box>
              <Box 
                className="vertical-center"
              >
                <Box 
                  className="content"
                >
                    {!this.state.error ? (
                        <AccountCreationForm
                          language={
                            this.props.language
                          } 
                          accountType="student"
                          onCriticalError={
                            (error) => this.props.onCriticalError(error)
                          }
                        />
                    ) : (
                        <>
                            {this.state.error === "no-connection" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.noConnectionError}
                                </Alert>
                            )}
                            {this.state.error === "unknown-error" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.unknownError}
                                </Alert>
                            )}
                        </>
                    )}
                </Box>
              </Box>
            </main>
        );
    }
}

export default function StudentAccountCreator(props) {
    return <StudentAccountCreatorComponent {...props} navigate={useNavigate()}/>
};