import React from "react";

import { Dialog, DialogTitle, DialogContent, TextField, CircularProgress, Alert, DialogActions, Button } from "@mui/material";

import "./ClassroomEditDialog.css";

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import editClassroom from "../../../utils/apiCaller/classroomManager/ClassroomEditor";

class ClassroomEditDialog extends React.Component {
    constructor() {
        super();
        this.state = {
          languageFile: null,
            waiting: false,
            error: null,
            canBeSent: false
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'ClassroomEditDialog', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });
    }

    render() {
        return (
            <Dialog 
              className="classroom-rename-dialog" 
              open={
                this.props.open
              } 
              onClose={
                () => this.handleClose()
              } 
              fullWidth
            >
                <DialogTitle
                  className="dialog-title"
                >
                  {this.state.languageFile && this.state.languageFile.prompt}
                </DialogTitle>
                <DialogContent>
                    <TextField 
                      id="newClassroomName" 
                      type="text" 
                      label={
                        this.state.languageFile && this.state.languageFile.newClassroomName
                      }
                      autoComplete="none" 
                      required 
                      disabled={
                        this.state.waiting
                      } 
                      color="secondary" 
                      margin="normal" 
                      fullWidth 
                      variant="outlined" 
                      inputProps={{ 
                        maxLength: 255 
                      }} 
                      onChange={
                        () => this.handleChange()
                      } 
                      autoFocus
                      defaultValue={
                        this.props.classroomName
                      }
                    />
                    <TextField
                      id='newClassroomNameShort'
                      type='text'
                      label={
                        this.state.languageFile && this.state.languageFile.newClassroomNameShort
                      }
                      autoComplete='none'
                      disabled={
                        this.state.waiting
                      }
                      margin='normal'
                      fullWidth
                      variant='outlined'
                      color='secondary'
                      inputProps={{
                        maxLength: 4
                      }}
                      onChange={
                        () => this.handleChange()
                      }
                      defaultValue={
                        this.props.classroomNameShort
                      }
                    />
                    {(this.state.waiting || this.state.error) && (
                        <div 
                          className="status-container"
                        >
                            {this.state.waiting && (
                                <CircularProgress 
                                  color="secondary"
                                />
                            )}
                            {this.state.error === "no-connection" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.noConnectionError}
                                </Alert>
                            )}
                            {this.state.error === "unknown-error" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.unknownError}
                                </Alert>
                            )}
                            {this.state.error === "invalid-classroom-code" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.classroomCodeError}
                                </Alert>
                            )}
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button 
                      color="secondary" 
                      disabled={
                        this.state.waiting || !this.state.canBeSent
                      } 
                      onClick={
                        () => this.handleSubmit()
                      }
                    >
                      {this.state.languageFile && this.state.languageFile.edit}
                    </Button>
                    <Button 
                      color="secondary" 
                      disabled={
                        this.state.waiting
                      } 
                      onClick={
                        () => this.handleClose()
                      }
                    >
                      {this.state.languageFile && this.state.languageFile.cancel}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleChange() {
      const newClassroomName = document.querySelector('#newClassroomName').value;
      const newClassroomNameShort = document.querySelector('#newClassroomNameShort').value;
      this.setState({
        canBeSent:
             newClassroomName.length !== 0
          && newClassroomNameShort.length !== 0
          && (
               newClassroomName !== this.props.classroomName
            || newClassroomNameShort !== this.props.classroomNameShort
          )
      })
    }

    handleClose() {
        this.setState({
            waiting: false,
            error: null,
            canBeSent: false
        });

        this.props.onClose();
    }

    handleSubmit() {
        this.setState({
            waiting: true,
            error: null
        });

        const newClassroomName = document.querySelector("#newClassroomName").value;
        const newClassroomNameShort = document.querySelector('#newClassroomNameShort').value;
        editClassroom(this.props.classroom, newClassroomName, newClassroomNameShort, (status) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            this.handleClose();
            this.props.onClassroomEdited(newClassroomName, newClassroomNameShort);
        });
    }
}

export default ClassroomEditDialog;