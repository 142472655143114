import { callApi } from "../ApiCaller";

function isSignedIn(onFinish) {
    callApi("isSignedIn", {}, (callStatus, httpStatus) => {
        if (callStatus !== "success") {
            onFinish(callStatus, null);
            return;
        }

        switch (httpStatus) {
            case 200:
                onFinish("success", true);
                break;

            case 401:
                onFinish("success", false);
                break;

            default:
                onFinish("unknown-error", null);
                break;
        }
    });
}

export default isSignedIn;