import React from "react";
import { Link } from "react-router-dom";

import { AppBar, Toolbar, Box, IconButton, Typography, Avatar, Menu, MenuItem, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import "./Header.css";

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import isSignedIn from "../../utils/apiCaller/accountManager/SignInStatusChecker";
import getUsername from "../../utils/apiCaller/accountManager/UsernameGetter";
import getProfilePicture from "../../utils/apiCaller/accountManager/ProfilePictureGetter";
import { UPDATE_INTERVAL_TIME } from "../../utils/UpdateIntervalTime";
import fetchAccountUpdate from "../../utils/apiCaller/accountManager/AccountUpdateFetcher";
import getAccountType from "../../utils/apiCaller/accountManager/AccountTypeGetter";

class HeaderComponent extends React.Component {
    constructor() {
        super();
        this.state = {
          languageFile: null,
            account: null,
            accountMenuOpen: false,
            updateInterval: null
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'Header', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });

        isSignedIn((status, isSignedIn) => {
            if (status !== "success") {
                this.props.onCriticalError(status);
                return;
            }

            if (!isSignedIn)
                return;
            
            getAccountType((status, accountType) => {
                if(status !== 'success'){
                    this.props.onCriticalError(status);
                    return;
                }

                getUsername((status, username) => {
                  if (status !== "success") {
                      this.props.onCriticalError(status);
                      return;
                  }

                  getProfilePicture((status, profilePicture) => {
                    if (status !== "success") {
                      this.props.onCriticalError(status);
                      return;
                    }

                    this.setState({ account: {
                      username: username,
                      profilePicture: profilePicture,
                      accountType: accountType
                    }});

                    if (this.state.updateInterval)
                      clearInterval(this.state.updateInterval);

                    this.setState({
                      updateInterval: setInterval(() => {
                          fetchAccountUpdate((status, updateAvailable, pendingUpdate) => {
                              if (status !== "success") {
                                  this.props.onCriticalError(status);
                                  return;
                              }
    
                              if (updateAvailable) {
                                  this.setState({
                                      account: {
                                          username: pendingUpdate.username,
                                          profilePicture: pendingUpdate.profilePicture,
                                          accountType: this.state.account.accountType
                                      }
                                  });
                              }
                          });
                      }, UPDATE_INTERVAL_TIME)
                  });
                });
              });
            });
        });
    }

    render() {
        return (
            <AppBar 
              className="app-bar" 
              position="static" 
              sx={{ 
                backgroundColor: 
                  this.props.theme.palette.mode === 'dark' 
                  ? null 
                  : (this.props.homepageMode
                      ? 'primary.main'
                      : (
                        this.state.account 
                          ? (this.state.account.accountType === 'teacher' || this.state.account.accountType === 'school_teacher') 
                            ? 'secondary.main' 
                            : this.state.account.accountType === 'school_admin'
                              ? 'warning.main'
                              : null
                          : null 
                      )
                    )
              }}
            >
                <Toolbar>
                    <Box 
                      className="left-box"
                    >
                      { 
                        process.env.REACT_APP_PLATFORM==='web' ? 
                          <Link 
                            className="homepage-link" 
                            to="/"
                          >
                            <img 
                              className="homepage-icon" 
                              src={
                                `${process.env.PUBLIC_URL}/favicon.png`
                              } 
                              alt=""
                            />
                            <Typography 
                              variant="h5"
                            >
                              NovaLang
                            </Typography>
                          </Link> : process.env.REACT_APP_PLATFORM==='app' ? 
                          <Box 
                            className="homepage-link"
                          >
                            <img 
                              className="homepage-icon" 
                              src={
                                `${process.env.PUBLIC_URL}/favicon.png`
                              } 
                              alt=""
                            />
                            <Typography 
                              variant="h5"
                            >
                              NovaLang
                            </Typography>
                          </Box> : 
                          console.error(`Invalid REACT_APP_PLATFORM: ${process.env.REACT_APP_PLATFORM}`)
                      }
                    </Box>
                    <Box 
                      className="account-box"
                    >
                      <Box className="desk-username">
                        {this.state.account && 
                          <Link 
                            to='/settings'
                            className="account-name"
                          >
                              <Typography 
                                variant="body1"
                              >
                                {this.state.account.username}
                              </Typography>
                          </Link>
                        }
                      </Box>
                      <IconButton 
                        id="account-icon" 
                        onClick={
                          () => this.openAccountMenu()
                        }
                      >
                        <Avatar 
                          src={
                            this.state.account 
                              ? (this.state.account.profilePicture 
                                ? `/profilePictures/${encodeURIComponent(encodeURIComponent(this.state.account.profilePicture))}` 
                                : null) 
                              : null
                          }
                        />
                      </IconButton>
                    </Box>
                    <Menu 
                      anchorEl={
                        document.getElementById("account-icon")
                      } 
                      anchorOrigin={{ 
                        vertical: "top", 
                        horizontal: "right" 
                      }} 
                      open={
                        this.state.accountMenuOpen
                      } 
                      onClose={
                        () => this.closeAccountMenu()
                      }
                    >
                      <Box
                        className={`menu-box theme-${this.props.theme.palette.mode}`}
                      >
                        {this.state.account ? (
                            <>
                              <Box 
                                className="app-bar-mob-username"
                              >
                                <MenuItem
                                  disabled
                                >
                                  {this.state.account.username}
                                </MenuItem>
                                <Divider />
                              </Box>
                                <a 
                                  className="menu-button-link" 
                                  href={process.env.REACT_APP_PLATFORM === 'app' 
                                    ? '#/cockpit' 
                                    : '/cockpit'
                                  }
                                >
                                    <MenuItem 
                                      onClick={
                                        () => this.closeAccountMenu()
                                      }
                                    >
                                      {this.state.languageFile && this.state.languageFile.cockpit}
                                    </MenuItem>
                                </a>
                                <Link 
                                  className="menu-button-link" 
                                  to="/settings"
                                >
                                    <MenuItem 
                                      onClick={
                                        () => this.closeAccountMenu()
                                      }
                                    >
                                      {this.state.languageFile && this.state.languageFile.settings}
                                    </MenuItem>
                                </Link>
                                <Link 
                                  className="menu-button-link" 
                                  to="/signout"
                                >
                                    <MenuItem 
                                      onClick={
                                        () => this.closeAccountMenu()
                                      }
                                    >
                                      {this.state.languageFile && this.state.languageFile.signOut}
                                    </MenuItem>
                                </Link>
                            </>
                        ) : (
                            <>
                                <Link 
                                  className="menu-button-link" 
                                  to="/signin"
                                >
                                    <MenuItem 
                                      onClick={
                                        () => this.closeAccountMenu()
                                      }
                                    >
                                      {this.state.languageFile && this.state.languageFile.signIn}
                                    </MenuItem>
                                </Link>
                                <Link 
                                  className="menu-button-link" 
                                  to="/signup"
                                >
                                    <MenuItem 
                                      onClick={
                                        () => this.closeAccountMenu()
                                      }
                                    >
                                      {this.state.languageFile && this.state.languageFile.signUp}
                                    </MenuItem>
                                </Link>
                                <Link 
                                  className="menu-button-link" 
                                  to="/settings"
                                >
                                  <MenuItem 
                                    onClick={
                                      () => this.closeAccountMenu()
                                    }
                                  >
                                    {this.state.languageFile && this.state.languageFile.settings}
                                  </MenuItem>
                                </Link>
                            </>
                        )}
                      </Box>
                    </Menu>
                </Toolbar>
            </AppBar>
        );
    }

    openAccountMenu() {
        this.setState(
          { accountMenuOpen: true }
        );
    }

    closeAccountMenu() {
        this.setState(
          { accountMenuOpen: false }
        );
    }
}

export default function Header(props){
  return <HeaderComponent {...props} theme={useTheme()}/>;
}
