import React from "react";

import "./MemberListView.css";
import ClassroomLinkForm from "../forms/classroomLinkForm/ClassroomLinkForm";
import JoinRequestList from "../joinRequestList/JoinRequestList";
import TeacherView from "../teacherView/TeacherView";
import StudentList from "../studentList/StudentList";

class MemberListView extends React.Component {
    constructor() {
        super();
        this.state = {
            studentListUpdatePending: false
        };
    }

    render() {
        return (
            <div 
              className="member-list-view"
            >
                {(this.props.accountType === "teacher" || this.props.accountType === 'school_teacher') && (
                    <>
                        <ClassroomLinkForm
                          language={
                            this.props.language
                          }
                          accountType={
                            this.props.accountType
                          }
                          classroom={
                            this.props.classroom
                          } 
                          onCriticalError={
                            (error) => this.props.onCriticalError(error)
                          }
                          onJoinCodeChanged={
                            (joinCode) => this.props.onJoinCodeChanged(joinCode)
                          }
                        />
                        {this.props.accountType === 'teacher' && (
                          <JoinRequestList
                            language={
                              this.props.language
                            }
                            classroom={
                              this.props.classroom
                            } 
                            onJoinRequestAccepted={
                              () => this.handleJoinRequestAccepted()
                            } 
                            onJoinRequestDenied={
                              () => this.handleJoinRequestDenied()
                            } 
                            onCriticalError={
                              (error) => this.props.onCriticalError(error)
                            }
                          />
                        )}    
                    </>
                )}
                {this.props.accountType !== 'school_admin' && (
                  <TeacherView
                    language={
                      this.props.language
                    }
                    accountType={
                      this.props.accountType
                    } 
                    classroom={
                      this.props.classroom
                    } 
                    onCriticalError={
                      (error) => this.props.onCriticalError(error)
                    }
                  />
                )}
                <StudentList
                  language={
                    this.props.language
                  }
                  accountType={
                    this.props.accountType
                  }
                  grade={
                    this.props.grade
                  }
                  classroom={
                    this.props.classroom
                  } 
                  updatePending={
                    this.state.studentListUpdatePending
                  } 
                  onUpdate={
                    () => this.handleStudentListUpdated()
                  } 
                  onCriticalError={
                    (error) => this.props.onCriticalError(error)
                  }
                />
            </div>
        );
    }

    handleJoinRequestAccepted() {
        this.setState({ studentListUpdatePending: true });
        this.props.onJoinRequestProcessed();
    }

    handleJoinRequestDenied() {
        this.props.onJoinRequestProcessed();
    }

    handleStudentListUpdated() {
        this.setState({ studentListUpdatePending: false });
    }
}

export default MemberListView;