import { callApi } from '../ApiCaller';

export default function enumerateGrades(onFinish) {
  callApi('enumerateGrades', {}, (callStatus, httpStatus, grades) => {
    if (callStatus !== 'success') {
      onFinish(callStatus);
      return;
    }

    switch (httpStatus) {
      case 200:
        onFinish('success', JSON.parse(grades));
        break;

      case 401:
        onFinish('not-signed-in');
        break;

      case 403:
        onFinish('account-deleted');
        break;

      default:
        onFinish('unknown-error');
    }
  });
}