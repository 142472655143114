import React from "react";

import { Typography, Card, Box, Avatar, IconButton, CircularProgress, Alert } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import "./JoinRequestList.css";

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import getJoinRequests from "../../utils/apiCaller/classroomManager/JoinRequestGetter";
import acceptJoinRequest from "../../utils/apiCaller/classroomManager/JoinRequestAcceptor";
import denyJoinRequest from "../../utils/apiCaller/classroomManager/JoinRequestDenier";
import { UPDATE_INTERVAL_TIME } from "../../utils/UpdateIntervalTime";
import fetchJoinRequestListUpdate from "../../utils/apiCaller/classroomManager/JoinRequestListUpdateFetcher";

class JoinRequestList extends React.Component {
    constructor() {
        super();
        this.state = {
            languageFile: null,
            waiting: false,
            error: null,
            joinRequests: null,
            updateInterval: null
        };
    }

    componentDidMount() {
        getLanguageFile(this.props.language, 'JoinRequestList', (status, file) => {
            if (status !== 'success') {
                this.props.onCriticalError(status);
                return;
            }

            this.setState({
                languageFile: file
            });
        });

        this.setState({ waiting: true });
        getJoinRequests(this.props.classroom, (status, joinRequests) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            this.setState({ joinRequests: joinRequests });

            if (this.state.updateInterval)
                clearInterval(this.state.updateInterval);
            
            this.setState({
                updateInterval: setInterval(() => {
                    fetchJoinRequestListUpdate(this.props.classroom, (status, updateAvailable, pendingUpdate) => {
                        if (status !== "success") {
                            switch (status) {
                                case "invalid-fetch-classroom-code":
                                    break;

                                default:
                                    this.props.onCriticalError(status);
                            }
                            return;
                        }

                        if (updateAvailable) {
                            let addedJoinRequests = [];
                            let removedJoinRequests = [];
                            for (let update of pendingUpdate) {
                                switch (update["operation"]) {
                                    case "add":
                                        addedJoinRequests.push({
                                            requestCode: update["requestCode"],
                                            email: update["email"],
                                            username: update["username"],
                                            profilePicture: update["profilePicture"]
                                        });
                                        break;

                                    case "remove":
                                        removedJoinRequests.push(update["requestCode"]);
                                        break;

                                    default:
                                }
                            }

                            this.setState({ joinRequests: [
                                ...this.state.joinRequests.filter((joinRequest) => {
                                    return removedJoinRequests.indexOf(joinRequest.requestCode) === -1;
                                }),
                                ...addedJoinRequests
                            ]});
                        }
                    });
                }, UPDATE_INTERVAL_TIME)
            });
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.updateInterval);
    }

    render() {
        return (
            ((this.state.joinRequests && this.state.joinRequests.length !== 0) || this.state.waiting || this.state.error) && (
                <div 
                  className="join-request-list"
                >
                    <Typography 
                      className="subtitle" 
                      variant="h4"
                    >
                      {this.state.languageFile && this.state.languageFile.prompt}
                    </Typography>
                    {(this.state.joinRequests && this.state.joinRequests.length !== 0) && (
                        this.state.joinRequests.map((joinRequest) => (
                            <Card 
                              className="join-request"
                            >
                                <Box 
                                  className="student"
                                >
                                    <Avatar 
                                      className="avatar" 
                                      src={
                                        joinRequest.profilePicture 
                                          ? `/profilePictures/${encodeURIComponent(encodeURIComponent(joinRequest.profilePicture))}` 
                                          : null
                                      }
                                    />
                                    <Box>
                                        <Typography 
                                          variant="body1"
                                        >
                                          {joinRequest.username}
                                        </Typography>
                                        <Typography 
                                          variant="body1"
                                        >
                                          {joinRequest.email}
                                        </Typography>
                                    </Box>
                                </Box>
                                <IconButton 
                                  title={
                                    this.state.languageFile && this.state.languageFile.accept
                                  }
                                  onClick={
                                    () => this.acceptJoinRequest(joinRequest)
                                  }
                                >
                                    <CheckIcon/>
                                </IconButton>
                                <IconButton 
                                  title={
                                    this.state.languageFile && this.state.languageFile.decline
                                  }
                                  onClick={
                                    () => this.denyJoinRequest(joinRequest)
                                  }
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Card>
                        ))
                    )}
                    {(this.state.waiting || this.state.error) && (
                        <div 
                          className="status-container"
                        >
                            {this.state.waiting && (
                                <CircularProgress 
                                  color="secondary"
                                />
                            )}
                            {this.state.error === "no-connection" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.noConnectionError}
                                </Alert>
                            )}
                            {this.state.error === "unknown-error" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.unknownError}
                                </Alert>
                            )}
                            {this.state.error === "invalid-classroom-code" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.classroomCodeError}
                                </Alert>
                            )}
                            {this.state.error === "invalid-join-request-code" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.requestCodeError}
                                </Alert>
                            )}
                        </div>
                    )}
                </div>
            )
        );
    }

    acceptJoinRequest(joinRequest) {
        this.setState({
            waiting: true,
            error: null
        });

        acceptJoinRequest(joinRequest.requestCode, (status) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            this.setState({ joinRequests: this.state.joinRequests.filter((currentJoinRequest) => currentJoinRequest.requestCode !== joinRequest.requestCode)});
            this.props.onJoinRequestAccepted();
        });
    }

    denyJoinRequest(joinRequest) {
        this.setState({
            waiting: true,
            error: null
        });

        denyJoinRequest(joinRequest.requestCode, (status) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            this.setState({ joinRequests: this.state.joinRequests.filter((currentJoinRequest) => currentJoinRequest.requestCode !== joinRequest.requestCode)});
            this.props.onJoinRequestDenied();
        });
    }
}

export default JoinRequestList;