import React from 'react';

import { Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';

export default class LearnWord extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'LearnWord', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
         nextProps.word !== this.props.word
      || nextProps.translation !== this.props.translation
      || nextState !== this.state
    );
  }
  
  render() {
    return (
      <div
        className='learn-word'
      >
        <Typography
          className='word'
          variant='h4'
        >
          {this.props.word}
        </Typography>
        <Typography
          className='translation'
          variant='h5'
        >
          {this.props.translation}
        </Typography>
        <div
          className='next-link-container'
        >
          <Typography
            className='next-link'
            onClick={
              () => this.handleNextClicked()
            }
          >
            <ArrowForwardIcon
              className='next-link-icon'
            />
            {this.state.languageFile && this.state.languageFile.next}
          </Typography>
        </div>
      </div>
    );
  }

  handleNextClicked() {
    this.props.onNextWordRequested();
  }
}