import React from 'react';
import { Link } from 'react-router-dom';

import { Card, Typography } from '@mui/material';

import './DictionaryLessonCard.css';

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';

export default class DictionaryLessonCard extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'DictionaryLessonCard', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file        
      });
    });
  }

  render() {
    return (
      <Card
        className='dictionary-lesson-card'
      >
        <div
          className='lesson-information'
        >
          <Typography
            variant='h5'
          >
            {this.props.title}
          </Typography>
          <Typography
            variant='body1'
          >
            {this.props.description}
            {this.props.dictionaryURL && (
              <Typography
                variant='body1'
              >
                <Link
                  className='lesson-link'
                  to={
                    this.props.dictionaryURL
                  }
                >
                  {this.state.languageFile && this.state.languageFile.vocabulary}
                </Link>
              </Typography>
            )}
          </Typography>
        </div>
      </Card>
    );
  }
}