import React from 'react';

import { Dialog, DialogTitle, DialogContent, TextField, Typography, CircularProgress, Alert, DialogActions, Button } from '@mui/material';

import './StudentAccountEditDialog.css';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import getDomainName from '../../../utils/apiCaller/domainManager/DomainNameGetter';
import editGradeStudentAccount from '../../../utils/apiCaller/gradeManager/GradeStudentAccountEditor';

export default class StudentAccountEditDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      waiting: false,
      error: null,
      canBeSent: false,
      domainName: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'StudentAccountEditDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
         nextProps.open !== this.props.open
      || nextState !== this.state
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open && this.props.open) {
      this.setState({
        waiting: true,
        error: null
      });

      getDomainName((status, domainName) => {
        this.setState({
          waiting: false
        });

        if (status !== 'success') {
          switch (status) {
            case 'not-signed-in':
            case 'account-deleted':
              this.props.onCriticalError(status);
              break;

            default:
              this.setState({
                error: status
              });
          }
          
          return;
        }

        this.setState({
          domainName: domainName
        });
      });
    }
  }

  render() {
    return (
      <>
        <Dialog
          open={
            this.props.open
          }
          onClose={
            () => this.handleClose()
          }
          fullWidth
        >
          <DialogTitle
            className='dialog-title'
          >
            {this.state.languageFile && this.state.languageFile.prompt}
          </DialogTitle>
          <DialogContent>
            {this.state.domainName && (
              <>
                <div
                  className='student-account-edit-dialog-student-email'
                >
                  <TextField
                    id='newStudentEmail'
                    type='text'
                    label={
                      this.state.languageFile && this.state.languageFile.email
                    }
                    autoComplete='none'
                    required
                    disabled={
                      this.state.waiting
                    }
                    color='warning'
                    margin='normal'
                    fullWidth
                    variant='outlined'
                    inputProps={{
                      maxLength: 254 - this.state.domainName.length
                    }}
                    onChange={
                      () => this.handleChange()
                    }
                    autoFocus
                    defaultValue={
                      this.props.email && this.props.email.substring(0, this.props.email.lastIndexOf(`@${this.state.domainName}`))
                    }
                  />
                  <Typography
                    className='domain-name'
                    variant='body1'
                  >
                    @ {this.state.domainName}
                  </Typography>
                </div>
                <TextField
                  id='newStudentUsername'
                  type='text'
                  label={
                    this.state.languageFile && this.state.languageFile.username
                  }
                  autoComplete='none'
                  required
                  disabled={
                    this.state.waiting
                  }
                  color='warning'
                  margin='normal'
                  fullWidth
                  variant='outlined'
                  inputProps={{
                    maxLength: 255
                  }}
                  onChange={
                    () => this.handleChange()
                  }
                  defaultValue={
                    this.props.username
                  }
                />
                <div
                  className='student-account-edit-dialog-status-container'
                >
                  {this.state.waiting && (
                    <CircularProgress
                      color='warning'
                    />
                  )}
                  {this.state.error === 'no-connection' && (
                    <Alert
                      severity='error'
                    >
                      {this.state.languageFile && this.state.languageFile.noConnectionError}
                    </Alert>
                  )}
                  {this.state.error === 'unknown-error' && (
                    <Alert
                      severity='error'
                    >
                      {this.state.languageFile && this.state.languageFile.unknownError}
                    </Alert>
                  )}
                  {this.state.error === 'invalid-student-code' && (
                    <Alert
                      severity='error'
                    >
                      {this.state.languageFile && this.state.languageFile.studentCodeError}
                    </Alert>
                  )}
                  {this.state.error === 'account-already-exists' && (
                    <Alert
                      severity='error'
                    >
                      {this.state.languageFile && this.state.languageFile.emailTakenError}
                    </Alert>
                  )}
                </div>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color='warning'
              disabled={
                this.state.waiting || !this.state.canBeSent
              }
              onClick={
                () => this.handleSubmit()
              }
            >
              {this.state.languageFile && this.state.languageFile.ok}
            </Button>
            <Button
              color='warning'
              disabled={
                this.state.waiting
              }
              onClick={
                () => this.handleClose()
              }
            >
              {this.state.languageFile && this.state.languageFile.cancel}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  getUserFromEmail(email) {
    if (!email)
      return null;

    return this.props.email.substring(0, this.props.email.lastIndexOf(`@${this.state.domainName}`));
  }

  handleChange() {
    const newStudentEmail = document.querySelector('#newStudentEmail');
    const newStudentUsername = document.querySelector('#newStudentUsername');
    this.setState({
      canBeSent:
        newStudentEmail.value.length !== 0 &&
        newStudentUsername.value.length !== 0 &&
        (
             newStudentEmail.value !== this.getUserFromEmail(this.props.email)
          || newStudentUsername.value !== this.props.username
        )
    });
  }

  handleClose() {
    this.setState({
      waiting: false,
      error: null,
      canBeSent: false,
      domainName: null
    });

    this.props.onClose();
  }

  handleSubmit() {
    this.setState({
      waiting: true,
      error: null
    });

    const newEmail = document.querySelector('#newStudentEmail').value;
    const newUsername = document.querySelector('#newStudentUsername').value;
    editGradeStudentAccount(this.props.grade, this.props.student, newEmail, newUsername, (status) => {
      this.setState({
        waiting: false
      });

      if (status !== 'success') {
        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.setState({
              error: status
            });
        }
        return;
      }

      this.props.onStudentAccountEdited(this.props.student, `${newEmail}@${this.state.domainName}`, newUsername);
      this.handleClose();
    });
  }
}