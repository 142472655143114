import React from 'react';

import { Dialog, DialogTitle, DialogContent, Card, Avatar, Typography, IconButton, Menu, MenuItem, TextField, CircularProgress, Alert, DialogActions, Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';

import './TeacherAccountManagementDialog.css';
import TeacherAccountEditDialog from '../teacherAccountEditDialog/TeacherAccountEditDialog';
import TeacherAccountDeleteDialog from '../teacherAccountDeleteDialog/TeacherAccountDeleteDialog';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import getDomainName from '../../../utils/apiCaller/domainManager/DomainNameGetter';
import enumerateTeacherAccounts from '../../../utils/apiCaller/domainManager/TeacherAccountEnumerator';
import { UPDATE_INTERVAL_TIME } from '../../../utils/UpdateIntervalTime'; 
import fetchTeacherAccountListUpdate from '../../../utils/apiCaller/domainManager/TeacherAccountListUpdateFetcher';
import createTeacherAccount from '../../../utils/apiCaller/domainManager/TeacherAccountCreator';

export default class TeacherAccountManagementDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      waiting: false,
      error: null,
      canBeSent: false,
      domainName: null,
      teacherAccountList: null,
      openTeacherMenu: null,
      openEditDialog: null,
      openDeleteDialog: null,
      updateInterval: null,
      currentTeacherEmail: null,
      currentTeacherUsername: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'TeacherAccountManagementDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
         (nextProps.open !== this.props.open)
      || nextState !== this.state
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open) {
      if (this.props.open) {
        this.setState({
          waiting: true
        });

        getDomainName((status, domainName) => {
          if (status !== 'success') {
            this.setState({
              waiting: false
            });
            
            switch (status) {
              case 'not-signed-in':
              case 'account-deleted':
                this.props.onCriticalError(status);
                break;

              default:
                this.setState({
                  error: status
                });
            }

            return;
          }

          this.setState({
            domainName: domainName
          });

          enumerateTeacherAccounts((status, teachers) => {
            this.setState({
              waiting: false
            });
    
            if (status !== 'success') {
              switch (status) {
                case 'not-signed-in':
                case 'account-deleted':
                  this.props.onCriticalError(status);
                  break;
    
                default:
                  this.setState({
                    error: status
                  });
              }
            }
    
            this.setState({
              teacherAccountList: teachers,
              updateInterval: setInterval(() => {
                fetchTeacherAccountListUpdate((status, updateAvailable, pendingUpdate) => {
                  if (status !== 'success') {
                    this.props.onCriticalError(status);
                    return;
                  }

                  if (updateAvailable) {
                    let addedTeachers = [];
                    let removedTeachers = [];
                    let renamedTeachers = [];
                    for (let update of pendingUpdate) {
                      switch (update['operation']) {
                        case 'add':
                          addedTeachers = [
                            {
                              id: update['id'],
                              profilePicture: update['profile_picture'],
                              email: update['email'],
                              username: update['username']
                            },
                            ...addedTeachers
                          ];
                          break;

                        case 'remove':
                          removedTeachers.push(update['id']);
                          break;

                        case 'rename':
                          renamedTeachers.push({
                            id: update['id'],
                            profilePicture: update['profile_picture'],
                            email: update['email'],
                            username: update['username']
                          });
                          break;

                        default:
                      }
                    }

                    let newTeacherList = [
                      ...addedTeachers,
                      ...this.state.teacherAccountList.filter((teacher) => {
                        return removedTeachers.indexOf(teacher.id) === -1
                      })
                    ].map((teacher) => {
                      let renamedTeacher = renamedTeachers.find((temporaryTeacher) => temporaryTeacher.id === teacher.id);
                      if (renamedTeacher)
                        return renamedTeacher;

                      return teacher;
                    });

                    this.setState({
                      teacherAccountList: newTeacherList
                    });
                  }
                });
              }, UPDATE_INTERVAL_TIME)
            });
          });
        });
      } else {
        clearInterval(this.state.updateInterval);
        this.setState({
          updateInterval: null
        });
      }
    }
  }

  render() {
    return (
      <>
        <Dialog
          open={
            this.props.open
          }
          onClose={
            () => this.handleClose()
          }
          fullWidth
        >
          <DialogTitle
            className='dialog-title'
          >
            {this.state.languageFile && this.state.languageFile.prompt}
          </DialogTitle>
          <DialogContent>
            {
              (this.state.domainName && this.state.teacherAccountList) && (
                <>
                  <div
                    className='teacher-account-management-dialog-teacher-list'
                  >
                    {
                      this.state.teacherAccountList.map((teacher) => {
                        return (
                          <Card
                            className='teacher-account-management-dialog-teacher'
                          >
                            <div
                              className='teacher-information'
                            >
                              <Avatar
                                className='avatar'
                                src={
                                  teacher.profilePicture
                                    ? `/profilePictures/${encodeURIComponent(encodeURIComponent(teacher.profilePicture))}`
                                    : null
                                }
                              />
                              <div>
                                <Typography
                                  variant='body1'
                                >
                                  {teacher.username}
                                </Typography>
                                <Typography
                                  variant='body1'
                                >
                                  {teacher.email}
                                </Typography>
                              </div>
                            </div>
                            <IconButton
                              title={
                                this.state.languageFile && this.state.languageFile.moreOptions
                              }
                              onClick={
                                () => this.openTeacherMenu(teacher)
                              }
                            >
                              <MoreVertIcon
                                className='icon'
                                id={
                                  `${teacher.id}-more-vert`
                                }
                              />
                            </IconButton>
                            <Menu
                              anchorEl={
                                document.getElementById(`${teacher.id}-more-vert`)
                              }
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                              open={
                                this.state.openTeacherMenu === teacher.id
                              }
                              onClose={
                                () => this.closeTeacherMenu()
                              }
                            >
                              <MenuItem
                                onClick={
                                  () => this.openEditDialog(teacher)
                                }
                              >
                                {this.state.languageFile && this.state.languageFile.edit}
                              </MenuItem>
                              <MenuItem
                                onClick={
                                  () => this.openDeleteDialog(teacher)
                                }
                              >
                                {this.state.languageFile && this.state.languageFile.delete}
                              </MenuItem>
                            </Menu>
                          </Card>
                        );
                      })
                    }
                  </div>
                  <div>
                    <div
                      className='teacher-account-management-dialog-teacher-account-creation-panel'
                    >
                      <TextField
                        id='teacher-email'
                        type='text'
                        label={
                          this.state.languageFile && this.state.languageFile.email
                        }
                        autoComplete='none'
                        disabled={
                          this.state.waiting
                        }
                        margin='normal'
                        fullWidth
                        variant='outlined'
                        color='warning'
                        inputProps={{
                          maxLength: 254 - this.state.domainName.length
                        }}
                        onChange={
                          () => this.handleChange()
                        }
                        autoFocus
                      />
                      <Typography
                        className='domain-name'
                        variant='body1'
                      >
                        @ {this.state.domainName}
                      </Typography>
                    </div>
                    <div
                      className='teacher-account-management-dialog-teacher-account-creation-panel'
                    >
                      <TextField
                        id='teacher-username'
                        type='text'
                        label={
                          this.state.languageFile && this.state.languageFile.username
                        }
                        autoComplete='none'
                        disabled={
                          this.state.waiting
                        }
                        margin='normal'
                        fullWidth
                        variant='outlined'
                        color='warning'
                        inputProps={{
                          maxLength: 255
                        }}
                        onChange={
                          () => this.handleChange()
                        }
                      />
                    </div>
                    <div
                      className='teacher-account-management-dialog-teacher-account-creation-panel'
                    >
                      <TextField
                        id='teacher-password'
                        type={
                          this.state.languageFile && this.state.languageFile.password
                        }
                        label='Hasło'
                        autoComplete='none'
                        disabled={
                          this.state.waiting
                        }
                        margin='normal'
                        fullWidth
                        variant='outlined'
                        color='warning'
                        inputProps={{
                          maxLength: 255
                        }}
                        onChange={
                          () => this.handleChange()
                        }
                      />
                      <IconButton
                        className='create-account-button'
                        title={
                          this.state.languageFile && this.state.languageFile.createAccount
                        }
                        disabled={
                          this.state.waiting || !this.state.canBeSent
                        }
                        onClick={
                          () => this.handleCreateTeacherAccount()
                        }
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                  </div>
                </>
              )
            }
            {
              (this.state.waiting || this.state.error) && (
                <div
                  className='teacher-account-management-dialog-status-container'
                >
                  {this.state.waiting && (
                    <CircularProgress
                      color='warning'
                    />
                  )}
                  {this.state.error === 'no-connection' && (
                    <Alert
                      severity='error'
                    >
                      {this.state.languageFile && this.state.languageFile.noConnectionError}
                    </Alert>
                  )}
                  {this.state.error === 'unknown-error' && (
                    <Alert
                      severity='error'
                    >
                      {this.state.languageFile && this.state.languageFile.unknownError}
                    </Alert>
                  )}
                  {this.state.error === 'account-already-exists' && (
                    <Alert
                      severity='error'
                    >
                      {this.state.languageFile && this.state.languageFile.accountAlreadyExistsError}
                    </Alert>
                  )}
                </div>
              )
            }
          </DialogContent>
          <DialogActions>
            <Button
              color='warning'
              disabled={
                this.state.waiting
              }
              onClick={
                () => this.handleClose()
              }
            >
              {this.state.languageFile && this.state.languageFile.ok}
            </Button>
          </DialogActions>
        </Dialog>
        <TeacherAccountEditDialog
          language={
            this.props.language
          }
          open={
            this.state.openEditDialog
          }
          onClose={
            () => this.closeEditDialog()
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
          email={
            this.state.currentTeacherEmail
          }
          username={
            this.state.currentTeacherUsername
          }
          domainName={
            this.state.domainName
          }
          teacherId={
            this.state.openEditDialog
          }
          onTeacherAccountEdited={
            (teacherId, newEmail, newUsername) => {
              this.setState({
                teacherAccountList: this.state.teacherAccountList.map((teacher) => {
                  if (teacher.id === teacherId) {
                    return {
                      ...teacher,
                      email: `${newEmail}@${this.state.domainName}`,
                      username: newUsername
                    };
                  }

                  return teacher;
                })
              });
            }
          }
        />
        <TeacherAccountDeleteDialog
          language={
            this.props.language
          }
          open={
            this.state.openDeleteDialog
          }
          onClose={
            () => this.closeDeleteDialog()
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
          teacherId={
            this.state.openDeleteDialog
          }
          onTeacherAccountDeleted={
            (teacherId) => {
              this.setState({
                teacherAccountList: this.state.teacherAccountList.filter((teacher) => {
                  return teacher.id !== teacherId;
                })
              });
            }
          }
        />
      </>
    );
  }
  
  handleClose() {
    this.setState({
      waiting: false,
      error: null,
      domainName: null,
      teacherAccountList: null
    });

    this.props.onClose();
  }

  handleChange() {
    this.setState({
      canBeSent:
           (document.querySelector('#teacher-email').value.length !== 0)
        && (document.querySelector('#teacher-username').value.length !== 0)
        && (document.querySelector('#teacher-password').value.length !== 0)
    });
  }

  handleCreateTeacherAccount() {
    this.setState({
      waiting: true,
      error: null
    });

    const email = document.querySelector('#teacher-email').value;
    const username = document.querySelector('#teacher-username').value;
    const password = document.querySelector('#teacher-password').value;
    createTeacherAccount(email, username, password, (status, teacherId) => {
      this.setState({
        waiting: false
      });

      if (status !== 'success') {
        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.setState({
              error: status
            });
        }
        return;
      }

      this.setState({
        teacherAccountList: [
          {
            id: teacherId,
            profilePicture: null,
            email: `${email}@${this.state.domainName}`,
            username: username
          },
          ...this.state.teacherAccountList
        ]
      });

      document.querySelector('#teacher-email').value = '';
      document.querySelector('#teacher-username').value = '';
      document.querySelector('#teacher-password').value = '';
    });
  }

  openTeacherMenu(teacher) {
    this.setState({
      openTeacherMenu: teacher.id
    });
  }

  closeTeacherMenu() {
    this.setState({
      openTeacherMenu: null
    });
  }

  openEditDialog(teacher) {
    this.setState({
      openTeacherMenu: null,
      openEditDialog: teacher.id,
      currentTeacherEmail: teacher.email,
      currentTeacherUsername: teacher.username
    });
  }

  closeEditDialog() {
    this.setState({
      openEditDialog: null,
      currentTeacherEmail: null,
      currentTeacherUsername: null
    });
  }

  openDeleteDialog(teacher) {
    this.setState({
      openTeacherMenu: null,
      openDeleteDialog: teacher.id
    });
  }

  closeDeleteDialog() {
    this.setState({
      openDeleteDialog: null
    });
  }
}