import { callApi } from '../ApiCaller';

export default function fetchWordListUpdate(classroomCode, wordSetCode, onFinish) {
  callApi('fetchWordListUpdate', {
    classroomCode: classroomCode,
    wordSetCode: wordSetCode
  }, (callStatus, httpStatus, pendingUpdate) => {
    if (callStatus !== 'success') {
      onFinish(callStatus);
      return;
    }

    switch (httpStatus) {
      case 200:
        onFinish('success', true, JSON.parse(pendingUpdate));
        break;

      case 401:
        onFinish('not-signed-in');
        break;

      case 403:
        onFinish('account-deleted');
        break;

      case 404:
        onFinish('success', false);
        break;

      case 410:
        onFinish('invalid-fetch-word-set-code');
        break;

      default:
        onFinish('unknown-error');
    }
  });
}