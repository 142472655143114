import React from "react";
import { useNavigate } from "react-router-dom";

import { TextField, Button, CircularProgress, Alert } from "@mui/material";

import "./PasswordRecoveryForm.css";

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import requestPasswordRecovery from "../../../utils/apiCaller/accountManager/PasswordRecoveryRequester";

class PasswordRecoveryFormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          languageFile: null,
            waiting: false,
            error: null
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'PasswordRecoveryForm', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });
    }

    render() {
        return (
            <div 
              className="password-recovery-form-container"
            >
                <form 
                  className="password-recovery-form" 
                  onSubmit={
                    (e) => this.handleSubmit(e)
                  }
                >
                    <div 
                      className="fields"
                    >
                        <TextField 
                          name="email" 
                          type="email" 
                          label={
                            this.state.languageFile && this.state.languageFile.email
                          }
                          required 
                          disabled={
                            this.state.waiting
                          } 
                          margin="normal" 
                          fullWidth 
                          variant="outlined" 
                          inputProps={{ 
                            maxLength: 255 
                          }}
                          inputRef={
                            (input) => input && input.focus()
                          }
                        />
                    </div>
                    <Button 
                      type="submit" 
                      disabled={
                        this.state.waiting
                      } 
                      fullWidth 
                      variant="contained"
                    >
                      {this.state.languageFile && this.state.languageFile.send}
                    </Button>
                </form>
                {this.state.waiting && (
                    <CircularProgress/>
                )}
                {this.state.error === "no-connection" && (
                    <Alert 
                      severity="error"
                    >
                      {this.state.languageFile && this.state.languageFile.noConnectionError}
                    </Alert>
                )}
                {this.state.error === "unknown-error" && (
                    <Alert 
                      severity="error"
                    >
                      {this.state.languageFile && this.state.languageFile.unknownError}
                    </Alert>
                )}
                {this.state.error === "invalid-email" && (
                    <Alert 
                      severity="error"
                    >
                      {this.state.languageFile && this.state.languageFile.invalidEmailError}
                    </Alert>
                )}
                {this.state.error === "email-not-verified" && (
                    <Alert 
                      severity="error"
                    >
                      {this.state.languageFile && this.state.languageFile.emailNotVerifiedError}
                    </Alert>
                )}
            </div>
        );
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({
            waiting: true,
            error: null
        });

        let fields = e.target.elements;
        requestPasswordRecovery(fields["email"].value, (status) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                this.setState({ error: status });
                return;
            }

            let navigate = this.props.navigate;
            navigate("/passwordrecovery/sent");
        });
    }
}

export default function PasswordRecoveryForm(props) {
    return <PasswordRecoveryFormComponent {...props} navigate={useNavigate()}/>
};