import { callApi } from '../ApiCaller';

export default function getDailyFunFact(language, onFinish) {
    callApi('getDailyFunFact', {
        funFactLanguage: language
    }, (callStatus, httpStatus, funFact) => {
        if (callStatus !== 'success') {
            onFinish(callStatus);
            return;
        }

        switch (httpStatus) {
            case 200:
                onFinish('success', funFact);
                break;

            default:
                onFinish('unknown-error');
        }
    });
}