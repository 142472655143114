import React from "react";
import { useNavigate } from "react-router-dom";

import { Typography, CircularProgress, Alert } from "@mui/material";

import "./EmailVerification.css";

import increaseTrafficCounter from '../../utils/apiCaller/TrafficCounterIncreaser';
import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import getAccountType from "../../utils/apiCaller/accountManager/AccountTypeGetter";
import verifyEmail from "../../utils/apiCaller/accountManager/EmailVerifier";

class EmailVerificationComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            languageFile: null,
            accountType: null,
            waiting: false,
            error: null,
            color: null
        };
    }

    componentDidMount() {
        increaseTrafficCounter((status) => {
            if (status !== 'success') {
                this.props.onCriticalError(status);
                return;
            }
        });

        getLanguageFile(this.props.language, 'EmailVerification', (status, file) => {
            if (status !== 'success') {
                this.props.onCriticalError(status);
                return;
            }

            this.setState({
                languageFile: file
            });
        });

        getAccountType((status, accountType) => {
            if (status !== "success") {
                switch (status) {
                    case "no-connection":
                    case "unknown-error":
                        this.setState({ error: status });
                        break;

                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                }

                this.attemptVerification();
                return;
            }

            if (accountType === 'school_student' || accountType === 'school_teacher') {
                const navigate = this.props.navigate;
                navigate('/cockpit');
                return;
            }

            this.setState({ accountType: accountType });
            switch (accountType) {
                case "student":
                    this.setState({ color: "primary" });
                    break;

                case "teacher":
                    this.setState({ color: "secondary" });
                    break;

                case 'school_admin':
                    this.setState({ color: 'warning' });
                    break;

                default:
            }

            this.attemptVerification();
        });
    }

    render() {
        return (
            <main 
              className="email-verification"
            >
                <Typography 
                  className="title" 
                  variant="h4"
                >
                  {this.state.languageFile && this.state.languageFile.prompt}
                </Typography>
                <div 
                  className="center-container"
                >
                    {this.state.waiting && (
                        <CircularProgress 
                          color={
                            this.state.color 
                              ? this.state.color 
                              : "primary"
                          }
                        />
                    )}
                    {this.state.error === "no-connection" && (
                        <Alert 
                          severity="error"
                        >
                          {this.state.languageFile && this.state.languageFile.noConnectionError}
                        </Alert>
                    )}
                    {this.state.error === "unknown-error" && (
                        <Alert 
                          severity="error"
                        >
                            {this.state.languageFile && this.state.languageFile.unknownError}
                        </Alert>
                    )}
                    {this.state.error === "invalid-verification-code" && (
                        <Alert 
                          severity="error"
                        >
                            {this.state.languageFile && this.state.languageFile.verificationCodeError}
                        </Alert>
                    )}
                </div>
            </main>
        );
    }

    attemptVerification() {
        this.setState({
            waiting: true,
            error: null
        });

        let verificationCode = new URLSearchParams(window.location.search).get("verification_code");
        verifyEmail(verificationCode, (status) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                this.setState({ error: status });
                return;
            }

            let navigate = this.props.navigate;
            navigate("/verifyemail/success");
        });
    }
}

export default function EmailVerification(props) {
    return <EmailVerificationComponent {...props} navigate={useNavigate()}/>
};