import React from 'react';

import { Typography } from '@mui/material';

import Flashcard from '../../../components/flashcard/Flashcard';
import FlashcardDialog from '../../../components/dialogs/flashcardDialog/FlashcardDialog';

import '../FlashcardsLesson.css';

import increaseTrafficCounter from '../../../utils/apiCaller/TrafficCounterIncreaser';
import { mapFlashcardsSection } from '../../../utils/LessonUtils';
import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import getLessonFile from '../../../utils/apiCaller/languageManager/LessonFileGetter';
import getAccountType from '../../../utils/apiCaller/accountManager/AccountTypeGetter';

export default class FlashcardsLesson5 extends React.Component {
  constructor() {
    super();

    const lessonLanguage = new URLSearchParams(window.location.search).get('language');
    this.state = {
      languageFile: null,
      lessonLanguage: lessonLanguage,
      sections: [
        'Places',
        'MedicalStaff',
        'Medicines',
        'DiseasesAndWounds',
        'Symptoms',
        'ExaminationsAndTreatment'
      ],
      flashcards: [],
      openFlashcard: null,
      color: null
    };
  }

  componentDidMount() {
    increaseTrafficCounter((status) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }
    });

    getLanguageFile(this.props.language, 'FlashcardsLesson5', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });

    //Attempt to download the first section
    this.downloadVocabulary(0);

    getAccountType((status, accountType) => {
      if (status !== 'success')
        return;

      switch (accountType) {
        case 'student':
        case 'school_student':
          this.setState({
            color: 'primary'
          });
          break;

        case 'teacher':
        case 'school_teacher':
          this.setState({
            color: 'secondary'
          });
          break;

        case 'school_admin':
          this.setState({
            color: 'warning'
          });
          break;

        default:
      }
    });
  }

  //Download a language file for section at index 'i' from this.state.sections
  downloadVocabulary(i) {
    getLessonFile(this.state.lessonLanguage, 'lesson5', this.state.sections[i], (status, sourceFile) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      getLessonFile(this.props.language, 'lesson5', this.state.sections[i], (status, translationFile) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          flashcards: [
            ...this.state.flashcards,
            mapFlashcardsSection(sourceFile, translationFile)
          ]
        }, () => {
          //The next section needs to be downloaded after setState is done
          //to avoid races and crashes
          if (i + 1 < this.state.sections.length)
            this.downloadVocabulary(i + 1);
        });
      });
    });
  }

  render() {
    return (
      <main
        className='flashcards-lesson'
      >
        <div
          className='title-bar'
        >
          <Typography
            variant='h4'
          >
            {this.state.languageFile && this.state.languageFile[this.state.lessonLanguage]}
          </Typography>
          <Typography
            variant='h5'
          >
            {this.state.languageFile && this.state.languageFile.description}
          </Typography>
        </div>
        <div
          className='flashcard-container'
        >
          <div
            className='flashcard-subcontainer'
          >
            {this.state.flashcards && this.state.flashcards.map((section) => (
              <div
                className='flashcard-section'
              >
                <Typography
                  className='section-title'
                  variant='h5'
                >
                  {section.title}
                </Typography>
                <div
                  className='section-container'
                >
                  {section.content.map((flashcard) => (
                    <Flashcard
                      word={
                        flashcard.word
                      }
                      visible
                      onClick={
                        () => this.openFlashcardDialog(flashcard)
                      }
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <FlashcardDialog
          language={
            this.props.language
          }
          open={
            this.state.openFlashcard
          }
          onClose={
            () => this.closeFlashcardDialog()
          }
          word={
            this.state.openFlashcard && this.state.openFlashcard.word
          }
          translation={
            this.state.openFlashcard && this.state.openFlashcard.translation
          }
          example={
            this.state.openFlashcard && this.state.openFlashcard.example
          }
          exampleTranslation={
            this.state.openFlashcard && this.state.openFlashcard.exampleTranslation
          }
          wordAudio={
            this.state.openFlashcard && this.state.openFlashcard.wordAudio
          }
          translationAudio={
            this.state.openFlashcard && this.state.openFlashcard.translationAudio
          }
          exampleAudio={
            this.state.openFlashcard && this.state.openFlashcard.exampleAudio
          }
          exampleTranslationAudio={
            this.state.openFlashcard && this.state.openFlashcard.exampleTranslationAudio
          }
          color={
            this.state.color
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
      </main>
    );
  }

  openFlashcardDialog(flashcard) {
    this.setState({
      openFlashcard: flashcard
    });
  }

  closeFlashcardDialog() {
    this.setState({
      openFlashcard: null
    });
  }
}