import React from 'react';

import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, IconButton, Alert, Button } from '@mui/material';
import RuleIcon from '@mui/icons-material/Rule';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import createClassroom from '../../../utils/apiCaller/classroomManager/ClassroomCreator';
import { useNavigate } from 'react-router-dom';

import './ClassroomCreationDialog.css';
import RuleDialog from '../ruleDialog/RuleDialog';

class ClassroomCreationDialogComponent extends React.Component{
  constructor() {
    super();
    this.state = {
      languageFile: null,
      waiting: false,
      error: null,
      canBeSent: false,
      rules: '',
      ruleDialogOpen: false
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'ClassroomCreationDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  render(){
    return(
      <>
        <Dialog 
          open={
            this.props.open
          }
          onClose={
            () => {
              this.handleClose()
            }
          }
          fullWidth
        >
          <DialogTitle 
            className='dialog-title'
          >
            {this.state.languageFile && this.state.languageFile.prompt}
          </DialogTitle>
          <DialogContent>
            <TextField 
              id='classroom-name'
              type="text"
              label={
                this.state.languageFile && this.state.languageFile.classroomName
              }
              autoComplete='none'
              disabled={
                this.state.waiting
              }
              margin='normal'
              fullWidth
              variant='outlined'
              color='secondary'
              inputProps={{ 
                maxLength: 255 
              }}
              onChange={
                () => {
                  this.handleChange()
                }
              }
              autoFocus
            />
            <TextField
              id='classroom-name-short'
              type='text'
              label={
                this.state.languageFile && this.state.languageFile.classroomNameShort
              }
              autoComplete='none'
              disabled={
                this.state.waiting
              }
              margin='normal'
              fullWidth
              variant='outlined'
              color='secondary'
              inputProps={{
                maxLength: 4
              }}
              onChange={
                () => this.handleChange()
              }
            />
            <FormControl
              disabled={
                this.state.waiting
              }
              margin='normal'
              fullWidth
              color='secondary'
            >
              <InputLabel>
                {this.state.languageFile && this.state.languageFile.language}
              </InputLabel>
              <Select
                inputProps={{
                  id: 'language'
                }}
                label={
                  this.state.languageFile && this.state.languageFile.language
                }
                fullWidth
                onChange={
                  (e) => {
                    this.handleChange(e.target.value)
                  }
                }
              >
                <MenuItem
                  value='english'
                >
                  {this.state.languageFile && this.state.languageFile.english}
                </MenuItem>
                <MenuItem
                  value='german'
                >
                  {this.state.languageFile && this.state.languageFile.german}
                </MenuItem>
                <MenuItem
                  value='spanish'
                >
                  {this.state.languageFile && this.state.languageFile.spanish}
                </MenuItem>
                <MenuItem
                  value='french'
                >
                  {this.state.languageFile && this.state.languageFile.french}
                </MenuItem>
                <MenuItem
                  value='russian'
                >
                  {this.state.languageFile && this.state.languageFile.russian}
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              id='classroom-place'
              type='text'
              label={
                this.state.languageFile && this.state.languageFile.place
              }
              autoComplete='none'
              disabled={
                this.state.waiting
              }
              margin='normal'
              fullWidth
              variant='outlined'
              color='secondary'
              inputProps={{
                maxLength: 255
              }}
            />
            <TextField
              id='classroom-description'
              label={
                this.state.languageFile && this.state.languageFile.description
              }
              disabled={
                this.state.waiting
              }
              color='secondary'
              margin='normal'
              fullWidth
              variant='outlined'
              multiline
              rows='5'
              inputProps={{
                maxLength: 255
              }}
            />
            <div
              className='classroom-creation-dialog-options'
            >
              <IconButton
                title={
                  this.state.languageFile && this.state.languageFile.manageRules
                }
                disabled={
                  this.state.waiting
                }
                onClick={
                  () => this.openRuleDialog()
                }
              >
                <RuleIcon/>
              </IconButton>
            </div>
            { 
              (this.state.waiting || this.state.error) && (
                <div className='classroom-creation-dialog-status-container'>
                  {
                    this.state.waiting && 
                      <CircularProgress 
                        color='secondary'
                      /> 
                  }
                  {this.state.error === "no-connection" && (
                      <Alert 
                        severity="error"
                      >
                        {this.state.languageFile && this.state.languageFile.noConnectionError}
                      </Alert>
                  )}
                  {this.state.error === "unknown-error" && (
                      <Alert 
                        severity="error"
                      >
                        {this.state.languageFile && this.state.languageFile.unknownError}
                      </Alert>
                  )}
                </div>
              )
            }
          </DialogContent>
          <DialogActions>
            <Button
            color='secondary'
            disabled={
              this.state.waiting || !this.state.canBeSent
            }
            onClick={
              () => {
                this.handleSubmit()
              }
            }
            >
              {this.state.languageFile && this.state.languageFile.create}
            </Button>
            <Button
            color='secondary'
            disabled={
              this.state.waiting
            }
            onClick={
              () => {
                this.handleClose()
              }
            }
            >
              {this.state.languageFile && this.state.languageFile.cancel}
            </Button>
          </DialogActions>
        </Dialog>
        <RuleDialog
          language={
            this.props.language
          }
          accountType='teacher'
          open={
            this.state.ruleDialogOpen
          }
          onClose={
            () => this.closeRuleDialog()
          }
          rules={
            this.state.rules
          }
          onRulesChanged={
            (rules) => this.setState({
              rules: rules
            })
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
      </>
    );
  }

  handleSubmit(){
    this.setState({
      waiting: true,
      error: null
    });  
    
    const classroomName = document.querySelector('#classroom-name').value;
    const classroomNameShort = document.querySelector('#classroom-name-short').value;
    const classroomLanguage = document.querySelector('#language').value;
    const classroomPlace = document.querySelector('#classroom-place').value;
    const classroomDescription = document.querySelector('#classroom-description').value;

    createClassroom(
      classroomName, classroomNameShort, classroomLanguage, classroomPlace, classroomDescription, this.state.rules,
        (status, classroomCode) => {
          this.setState({
            waiting: false
          });
          
          if(status !== 'success'){
            switch(status){
              case 'not-signed-in':
              case 'account-deleted':
                this.props.onCriticalError(status);
                break;
              default:
                this.setState({
                  error: status
                });
            }
          } else {
            this.props.onClassroomCreated(classroomCode, classroomName, classroomNameShort);
            this.handleClose();
            this.props.navigate(
              `/cockpit?classroom=${encodeURIComponent(classroomCode)}&tab=materials`
            );
          }
        }
    )
  }

  handleClose(){
    this.setState({
      waiting: false,
      error: null,
      canBeSent: false,
      rules: '',
      ruleDialogOpen: false
    });
    this.props.onClose();
  }

  handleChange(language){
    this.setState({
      canBeSent: 
        (document.querySelector('#classroom-name').value.length !== 0) &&
        (document.querySelector('#classroom-name-short').value.length !== 0) &&
        ( 

          (document.querySelector('#language').value.length !== 0) ||
            (language && language.length !== 0)
        
        )
    });
  }

  openRuleDialog() {
    this.setState({
      ruleDialogOpen: true
    });
  }

  closeRuleDialog() {
    this.setState({
      ruleDialogOpen: false
    });
  }
}

export default function ClassroomCreationDialog(props){
  return <ClassroomCreationDialogComponent 
    {...props} 
    navigate={
      useNavigate()
    } 
  />
}