import React from 'react';

import { Typography } from '@mui/material';

import '../DictionaryLesson.css';

import increaseTrafficCounter from '../../../utils/apiCaller/TrafficCounterIncreaser';
import { mapDictionarySection } from '../../../utils/LessonUtils';
import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import getLessonFile from '../../../utils/apiCaller/languageManager/LessonFileGetter';

export default class DictionaryLesson3 extends React.Component {
  constructor() {
    super();

    const lessonLanguage = new URLSearchParams(window.location.search).get('language');
    this.state = {
      languageFile: null,
      lessonLanguage: lessonLanguage,
      sections: [
        'FamilyAndFamilyMembers',
        'PeopleAndInterpersonalRelations',
        'MaritalStatus',
        'HumanLifeStages'
      ],
      vocabulary: []
    };
  }

  componentDidMount() {
    increaseTrafficCounter((status) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }
    });

    getLanguageFile(this.props.language, 'DictionaryLesson3', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });

    //Attempt to download the first section
    this.downloadVocabulary(0);
  }

  //Download a language file for section at index 'i' from this.state.sections
  downloadVocabulary(i) {
    getLessonFile(this.state.lessonLanguage, 'lesson3', this.state.sections[i], (status, sourceFile) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      getLessonFile(this.props.language, 'lesson3', this.state.sections[i], (status, translationFile) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          vocabulary: [
            ...this.state.vocabulary,
            mapDictionarySection(sourceFile, translationFile)
          ]
        }, () => {
          //The next section needs to be downloaded after setState is done
          //to avoid races and crashes
          if (i + 1 < this.state.sections.length)
            this.downloadVocabulary(i + 1);
        });
      });
    });
  }

  render() {
    return (
      <main
        className='dictionary-lesson'
      >
        <div
          className='title-bar'
        >
          <Typography
            variant='h4'
          >
            {this.state.languageFile && this.state.languageFile[this.state.lessonLanguage]}
          </Typography>
          <Typography
            variant='h5'
          >
            {this.state.languageFile && this.state.languageFile.description}
          </Typography>
        </div>
        <div
          className='dictionary-lesson-container'
        >
          <div
            className='dictionary-lesson-subcontainer'
          >
            {this.state.vocabulary}
          </div>
        </div>
      </main>
    );
  }
}