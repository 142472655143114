import { callApi } from "../ApiCaller";

function enumerateClassrooms(onFinish) {
    callApi("enumerateClassrooms", {}, (callStatus, httpStatus, classrooms) => {
        if (callStatus !== "success") {
            onFinish(callStatus, null);
            return;
        }

        switch (httpStatus) {
            case 200:
                onFinish("success", JSON.parse(classrooms));
                break;

            case 401:
                onFinish("not-signed-in", null);
                break;

            case 403:
                onFinish("account-deleted", null);
                break;

            default:
                onFinish("unknown-error", null);
        }
    });
}

export default enumerateClassrooms;