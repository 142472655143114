import React from 'react';

import { Typography, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';

export default class QuizQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageFile: null,
      answers: this.randomizeAnswers(props.answers, props.correctAnswer),
      answer: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'QuizQuestion', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
         nextProps.question !== this.props.question
      || nextProps.correctAnswer !== this.props.correctAnswer
      || nextState !== this.state
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.question !== prevProps.question || this.props.correctAnswer !== prevProps.correctAnswer) {
      this.setState({
        answers: this.randomizeAnswers(this.props.answers, this.props.correctAnswer),
        answer: null
      });
    }
  }

  randomizeAnswers(answers, correctAnswer) {
    let randomizedAnswers = [];
    let insertedAnswers = [];
    
    let answerIndex = Math.floor(Math.random() * Math.min(4, answers.length));
    randomizedAnswers[answerIndex] = {
      answer: correctAnswer,
      correct: true
    };

    for (let i = 0; i < Math.min(3, answers.length - 1); i++) {
      let answerIndex = Math.floor(Math.random() * Math.min(4, answers.length));
      while (randomizedAnswers[answerIndex])
        answerIndex = (answerIndex + 1) % Math.min(4, answers.length);

      let sourceAnswerIndex = Math.floor(Math.random() * answers.length);
      while (answers[sourceAnswerIndex] === correctAnswer || insertedAnswers.indexOf(sourceAnswerIndex) !== -1)
        sourceAnswerIndex = (sourceAnswerIndex + 1) % answers.length;

      insertedAnswers.push(sourceAnswerIndex);
      randomizedAnswers[answerIndex] = {
        answer: answers[sourceAnswerIndex],
        correct: false
      };
    }

    return randomizedAnswers;
  }

  render() {
    return (
      <div
        className='quiz-question'
      >
        <Typography
          className='question'
          variant='h4'
        >
          {this.props.question}
        </Typography>
        {this.state.answers && (
          <div
            className='answer-container'
          >
            {this.state.answers.map((answer) => (
              <Button
                className='answer'
                disabled={
                  this.state.answer
                }
                onClick={
                  () => this.handleAnswerClicked(answer)
                }
              >
                {answer.answer}
              </Button>
            ))}
          </div>
        )}
        {this.state.answer && (
          <>
            {
              this.state.answer.correct
                ? (
                  <>
                    <Typography
                      className='correct-answer'
                    >
                      {this.state.languageFile && this.state.languageFile.correctAnswer}
                    </Typography>
                    <Typography
                      className='correct-answer'
                    >
                      {this.props.question} - {this.props.correctAnswer}
                    </Typography>
                  </>
                )
                : (
                  <>
                    <Typography
                      className='wrong-answer'
                    >
                      {this.state.languageFile && this.state.languageFile.incorrectAnswer}
                    </Typography>
                    <Typography
                      className='wrong-answer'
                    >
                      {this.props.question} - {this.props.correctAnswer}
                    </Typography>
                  </>
                )
            }
            <div
              className='next-link-container'
            >
              <Typography
                className='next-link'
                onClick={
                  () => this.handleNextClicked()
                }
              >
                <ArrowForwardIcon
                  className='next-link-icon'
                />
                {this.state.languageFile && this.state.languageFile.next}
              </Typography>
            </div>
          </>
        )}
      </div>
    );
  }

  handleAnswerClicked(answer) {
    this.setState({
      answer: answer
    });
  }

  handleNextClicked() {
    this.props.onNextQuestionRequested();
  }
}