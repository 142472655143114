import React from 'react';

import { Typography, Avatar, Button } from "@mui/material";

import ProfilePictureDialog from "../../../components/dialogs/profilePictureDialog/ProfilePictureDialog";
import UsernameDialog from "../../../components/dialogs/usernameDialog/UsernameDialog";
import PasswordDialog from "../../../components/dialogs/passwordDialog/PasswordDialog";
import EmailDialog from "../../../components/dialogs/emailDialog/EmailDialog";
import AccountDeleteDialog from "../../../components/dialogs/accountDeleteDialog/AccountDeleteDialog";

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import getEmail from "../../../utils/apiCaller/accountManager/EmailGetter";
import getUsername from "../../../utils/apiCaller/accountManager/UsernameGetter";
import getProfilePicture from "../../../utils/apiCaller/accountManager/ProfilePictureGetter";
import { UPDATE_INTERVAL_TIME } from "../../../utils/UpdateIntervalTime";
import fetchProfileUpdate from "../../../utils/apiCaller/accountManager/ProfileUpdateFetcher";

import './AccountSettings.css';

export default class AccountSettings extends React.Component{
  constructor() {
    super();
    this.state = {
      languageFile: null,
      profilePicture: null,
      username: null,
      email: null,
      profilePictureDialogOpen: false,
      usernameDialogOpen: false,
      passwordDialogOpen: false,
      emailDialogOpen: false,
      deleteDialogOpen: false,
      updateInterval: null,
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'AccountSettings', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });

    getEmail((status, email) => {
        if (status !== "success") {
            this.props.onCriticalError(status);
            return;
        }
        this.setState({ email: email });
    });

    getUsername((status, username) => {
        if (status !== "success") {
            this.props.onCriticalError(status);
            return;
        }
        this.setState({ username: username });
    });

    getProfilePicture((status, profilePicture) => {
        if (status !== "success") {
            this.props.onCriticalError(status);
            return;
        }
        this.setState({ profilePicture: profilePicture });
    });

    if (this.state.updateInterval)
        clearInterval(this.state.updateInterval);

    this.setState({
        updateInterval: setInterval(() => {
            fetchProfileUpdate((status, updateAvailable, pendingUpdate) => {
                if (status !== "success") {
                    this.props.onCriticalError(status);
                    return;
                }

                if (updateAvailable) {
                    this.setState({
                        email: pendingUpdate.email,
                        username: pendingUpdate.username,
                        profilePicture: pendingUpdate.profilePicture
                    });
                }
            });
        }, UPDATE_INTERVAL_TIME)
    });
  }

  componentWillUnmount(){
    if(this.state.updateInterval){
      clearInterval(this.state.updateInterval);
    }
  }

  render(){
    return(
      <div
        className='account-setts'
      >
        <Typography 
          className="title" 
          variant="h4"
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </Typography>
        <div 
          className="profile-picture"
        >
            <Typography 
              className="subtitle" 
              variant="h5"
            >
              {this.state.languageFile && this.state.languageFile.profilePicture}
            </Typography>
            <div 
              className="avatar-container"
            >
                <Avatar 
                  className="avatar" 
                  src={
                    this.state.profilePicture 
                      ? `/profilePictures/${encodeURIComponent(encodeURIComponent(this.state.profilePicture))}` 
                      : null
                  }
                />
                <Button 
                  color={
                    this.props.color
                  } 
                  variant="contained" 
                  onClick={
                    () => this.openProfilePictureDialog()
                  }
                >
                  {this.state.languageFile && this.state.languageFile.change}
                </Button>
            </div>
        </div>
        <div 
          className="username-container"
        >
            <Typography 
              className="subtitle" 
              variant="h5"
            >
              {this.state.languageFile && this.state.languageFile.username}
            </Typography>
            <Typography 
              className="username" 
              variant="body"
            >
              {this.state.username}
            </Typography>
            <Button 
              color={
                this.props.color
              } 
              variant="contained" 
              onClick={
                () => this.openUsernameDialog()
              }
              disabled={
                this.props.accountType === 'school_student' || this.props.accountType === 'school_teacher'
              }
            >
              {this.state.languageFile && this.state.languageFile.change}
            </Button>
        </div>
        <div 
          className="password"
        >
            <Typography 
              className="subtitle" 
              variant="h5"
            >
              {this.state.languageFile && this.state.languageFile.password}
            </Typography>
            <Button 
              color={
                this.props.color
              } 
              variant="contained" 
              onClick={
                () => this.openPasswordDialog()
              }
            >
              {this.state.languageFile && this.state.languageFile.change}
            </Button>
        </div>
        <div 
          className="email-container"
        >
            <Typography 
              className="subtitle" 
              variant="h5"
            >
              {this.state.languageFile && this.state.languageFile.email}
            </Typography>
            <Typography 
              className="email" 
              variant="body"
            >
              {this.state.email}
            </Typography>
            <Button 
              color={
                this.props.color
              } 
              variant="contained" 
              onClick={
                () => this.openEmailDialog()
              }
              disabled={
                this.props.accountType === 'school_student' || this.props.accountType === 'school_teacher'
              }
            >
              {this.state.languageFile && this.state.languageFile.change}
            </Button>
        </div>
        <div 
          className="account-delete-container"
        >
            <Typography 
              className="subtitle" 
              variant="h5"
            >
              {this.state.languageFile && this.state.languageFile.deleteAccount}
            </Typography>
            <Button 
              color="error" 
              variant="contained" accountType
              onClick={
                () => this.openDeleteDialog()
              }
              disabled={
                this.props.accountType === 'school_student' || this.props.accountType === 'school_teacher' || this.props.accountType === 'school_admin'
              }
            >
              {this.state.languageFile && this.state.languageFile.deleteAccountButton}
            </Button>
        </div>
        <ProfilePictureDialog
          language={
            this.props.language
          }
          color={
            this.props.color
          } 
          open={
            this.state.profilePictureDialogOpen
          } 
          onClose={
            () => this.closeProfilePictureDialog()
          } 
          profilePicture={
            this.state.profilePicture
          } 
          onProfilePictureSet={
            () => this.handleProfilePictureSet()
          } 
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
        <UsernameDialog
          language={
            this.props.language
          }
          color={
            this.props.color
          } 
          open={
            this.state.usernameDialogOpen
          } 
          onClose={
            () => this.closeUsernameDialog()
          } 
          username={
            this.state.username
          } 
          onUsernameSet={
            () => this.handleUsernameSet()
          } 
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
        <PasswordDialog
          language={
            this.props.language
          }
          color={
            this.props.color
          } 
          open={
            this.state.passwordDialogOpen
          } 
          onClose={
            () => this.closePasswordDialog()
          } 
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
        <EmailDialog
          language={
            this.props.language
          }
          color={
            this.props.color
          } 
          open={
            this.state.emailDialogOpen
          } 
          onClose={
            () => this.closeEmailDialog()
          } 
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
        <AccountDeleteDialog
          language={
            this.props.language
          }
          color={
            this.props.color
          } 
          open={
            this.state.deleteDialogOpen
          } 
          onClose={
            () => this.closeDeleteDialog()
          } 
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
        />
      </div>
    );
  }

  openProfilePictureDialog() {
      this.setState({ profilePictureDialogOpen: true });
  }

  closeProfilePictureDialog() {
      this.setState({ profilePictureDialogOpen: false });
  }

  openUsernameDialog() {
      this.setState({ usernameDialogOpen: true });
  }

  closeUsernameDialog() {
      this.setState({ usernameDialogOpen: false });
  }

  openPasswordDialog() {
      this.setState({ passwordDialogOpen: true });
  }

  closePasswordDialog() {
      this.setState({ passwordDialogOpen: false });
  }

  openEmailDialog() {
      this.setState({ emailDialogOpen: true });
  }

  closeEmailDialog() {
      this.setState({ emailDialogOpen: false });
  }

  openDeleteDialog() {
      this.setState({ deleteDialogOpen: true });
  }

  closeDeleteDialog() {
      this.setState({ deleteDialogOpen: false });
  }

  handleProfilePictureSet() {
      document.location.reload();
  }

  handleUsernameSet() {
      document.location.reload();
  }
}