import React from "react";
import adaptiveNavigate from "../../utils/AdaptiveNavigator";
import { useNavigate } from "react-router-dom";

import { Typography, CircularProgress } from "@mui/material";

import "./SignOut.css";

import getLanguageFile from '../../utils/apiCaller/languageManager/LanguageFileGetter';
import getAccountType from "../../utils/apiCaller/accountManager/AccountTypeGetter";
import signOut from "../../utils/apiCaller/accountManager/SignOut";

class SignOutComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            languageFile: null,
            accountType: null,
            waiting: false,
            color: null
        };
    }

    componentDidMount() {
        getLanguageFile(this.props.language, 'SignOut', (status, file) => {
            if (status !== 'success') {
                this.props.onCriticalError(status);
                return;
            }

            this.setState({
                languageFile: file
            });
        });

        getAccountType((status, accountType) => {
            if (status !== "success") {
                this.attemptSignOut();
                return;
            }

            this.setState({ accountType: accountType });
            switch (accountType) {
                case "student":
                case 'school_student':
                    this.setState({ color: "primary" });
                    break;

                case "teacher":
                case 'school_teacher':
                    this.setState({ color: "secondary" });
                    break;

                case 'school_admin':
                    this.setState({ color: 'warning' });
                    break;

                default:
            }

            this.attemptSignOut();
        });
    }

    render() {
        return (
            <main 
              className="sign-out-action"
            >
                <Typography 
                  className="title" 
                  variant="h4"
                >
                  {this.state.languageFile && this.state.languageFile.prompt}
                </Typography>
                {(this.state.accountType && this.state.color) && (
                    <div 
                      className="center-container"
                    >
                        {this.state.waiting && (
                            <CircularProgress 
                              color={this.state.color}
                            />
                        )}
                    </div>
                )}
            </main>
        );
    }

    attemptSignOut() {
        this.setState({
            waiting: true,
            error: null
        });

        signOut(() => {
            this.setState({ waiting: false });
            document.cookie = "PHPSESSID=; expires=Thu, 01 Jan 1970 00:00:00 GMT";

            adaptiveNavigate(this.props.navigate, "/");
        });
    }
}

export default function SignOut(props){
    return <SignOutComponent {...props} navigate = {useNavigate()}/>
};