import { callApi } from "../ApiCaller";

function verifyEmail(verificationCode, onFinish) {
    callApi("verifyEmail", {
        verificationCode: verificationCode
    }, (callStatus, httpStatus) => {
        if (callStatus !== "success") {
            onFinish(callStatus);
            return;
        }

        switch (httpStatus) {
            case 200:
                onFinish("success");
                break;

            case 404:
                onFinish("invalid-verification-code");
                break;

            default:
                onFinish("unknown-error");
        }
    });
}

export default verifyEmail;