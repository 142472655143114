import HttpError from "./HttpError";
import encodeBody from "./BodyEncoder";

function callApi(functionName, params, onFinish) {
    fetch(`/api/${functionName}.php`, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
        body: encodeBody(params)
    }).then((response) => {
        if (response.status !== 200)
            throw new HttpError(response.status);

        return response.text();
    }).then((text) => {
        onFinish("success", 200, text);
    }).catch((error) => {
        if (error instanceof TypeError) {
            onFinish("no-connection");
            return;
        }

        if (error instanceof HttpError) {
            onFinish("success", error.status);
            return;
        }

        onFinish("unknown-error");
    });
}

function callFormDataApi(functionName, formData, onFinish) {
    fetch(`/api/${functionName}.php`, {
        method: "POST",
        body: formData
    })
        .then((response) => {
            if (response.status !== 200)
                throw new HttpError(response.status);

            return response.text();
        }).then((text) => {
            onFinish("success", 200, text);
        }).catch((error) => {
            if (error instanceof TypeError) {
                onFinish("no-connection");
                return;
            }

            if (error instanceof HttpError) {
                onFinish("success", error.status);
                return;
            }

            onFinish("unknown-error");
        });
}

export { callApi, callFormDataApi };