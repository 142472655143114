import React from "react";

import { Dialog, DialogTitle, DialogContent, DialogContentText, CircularProgress, Alert, DialogActions, Button } from "@mui/material";

import "./StudentRemoveDialog.css";

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import deleteGradeStudentAccount from "../../../utils/apiCaller/gradeManager/GradeStudentAccountDeleter";
import removeStudent from "../../../utils/apiCaller/classroomManager/StudentRemover";

class StudentRemoveDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          languageFile: null,
            waiting: false,
            error: null,
            color: props.accountType === 'school_admin'
              ? 'warning'
              : 'secondary'
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'StudentRemoveDialog', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });
    }
    
    render() {
        return (
            <Dialog 
              className="student-remove-dialog" 
              open={
                this.props.open
              } 
              onClose={
                () => this.handleClose()
              } 
              fullWidth
            >
                <DialogTitle 
                  className="dialog-title"
                >
                  {this.state.languageFile && this.state.languageFile.prompt}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                      {this.state.languageFile && this.state.languageFile.description}
                    </DialogContentText>
                    {
                      (this.state.waiting || this.state.error) && (
                          <div 
                            className="status-container"
                          >
                              {this.state.waiting && (
                                  <CircularProgress 
                                    color={
                                      this.state.color
                                    }
                                  />
                              )}
                              {this.state.error === "no-connection" && (
                                  <Alert 
                                    severity="error"
                                  >
                                    {this.state.languageFile && this.state.languageFile.noConnectionError}
                                  </Alert>
                              )}
                              {this.state.error === "unknown-error" && (
                                  <Alert 
                                    severity="error"
                                  >
                                    {this.state.languageFile && this.state.languageFile.unknownError}
                                  </Alert>
                              )}
                              {this.state.error === "invalid-code" && (
                                  <Alert 
                                    severity="error"
                                  >
                                    {this.state.languageFile && this.state.languageFile.invalidCodeError}
                                  </Alert>
                              )}
                          </div>
                      )
                    }
                </DialogContent>
                <DialogActions>
                    <Button 
                      color={
                        this.state.color
                      }
                      disabled={
                        this.state.waiting
                      } 
                      onClick={
                        () => this.handleSubmit()
                      }
                    >
                      {this.state.languageFile && this.state.languageFile.yes}
                    </Button>
                    <Button 
                      color={
                        this.state.color
                      }
                      disabled={
                        this.state.waiting
                      } 
                      onClick={
                        () => this.handleClose()
                      }
                    >
                      {this.state.languageFile && this.state.languageFile.no}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleClose() {
        this.setState({
            waiting: false,
            error: null
        });

        this.props.onClose();
    }

    handleSubmit() {
        this.setState({
            waiting: true,
            error: null
        });

        if (this.props.accountType === 'school_admin') {
          deleteGradeStudentAccount(this.props.grade, this.props.student, (status) => {
            this.setState({
              waiting: false
            });

            if (status !== 'success') {
              switch (status) {
                case 'not-signed-in':
                case 'account-deleted':
                  this.props.onCriticalError(status);
                  break;

                default:
                  this.setState({
                    error: status
                  });
              }
              return;
            }

            this.handleClose();
            this.props.onStudentRemoved();
          });
        } else {
          removeStudent(this.props.classroom, this.props.student, (status) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            this.handleClose();
            this.props.onStudentRemoved();
          }); 
        }
    }
}

export default StudentRemoveDialog;