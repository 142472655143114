import React from 'react';

import { Typography } from '@mui/material';

import LearnWord from '../LearnWord';

import { mapLearnSection } from '../../../utils/LessonUtils';
import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import getLessonFile from '../../../utils/apiCaller/languageManager/LessonFileGetter';

export default class LearnLesson4UsefulPhrases extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      word: 0,
      words: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'LearnLesson4UsefulPhrases', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }
      
      this.setState({
        languageFile: file
      });
    });

    getLessonFile(this.props.lessonLanguage, 'lesson4', 'UsefulPhrases', (status, sourceFile) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      getLessonFile(this.props.language, 'lesson4', 'UsefulPhrases', (status, translationFile) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          words: mapLearnSection(sourceFile, translationFile)
        });
      });
    });
  }

  render() {
    return (
      <div
        className='learn-section'
      >
        <Typography
          className='section-title'
          variant='h5'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </Typography>
        <div
          className='section-container'
        >
          {this.state.words && (
            <LearnWord
              language={
                this.props.language
              }
              word={
                this.state.words[this.state.word].word
              }
              translation={
                this.state.words[this.state.word].translation
              }
              onNextWordRequested={
                () => this.handleNextWordRequested()
              }
              onCriticalError={
                (error) => this.props.onCriticalError(error)
              }
            />
          )}
        </div>
      </div>
    );
  }

  handleNextWordRequested() {
    if (this.state.word + 1 === this.state.words.length) {
      this.props.onStageFinished();
      return;
    }

    this.setState({
      word: this.state.word + 1
    });
  }
}