import React from 'react';

import { Dialog, DialogTitle, DialogContent, TextField, FormControl, InputLabel, Select, MenuItem, Avatar, Typography, IconButton, CircularProgress, Alert, DialogActions, Button } from '@mui/material';
import RuleIcon from '@mui/icons-material/Rule';
import GroupIcon from '@mui/icons-material/Group';

import './GradeClassroomCreationDialog.css';
import StudentAssignmentDialog from '../studentAssignmentDialog/StudentAssignmentDialog';
import RuleDialog from '../ruleDialog/RuleDialog';

import enumerateTeacherAccounts from '../../../utils/apiCaller/domainManager/TeacherAccountEnumerator';
import getGradeStudentList from '../../../utils/apiCaller/gradeManager/GradeStudentListGetter';
import createGradeClassroom from '../../../utils/apiCaller/gradeManager/GradeClassroomCreator';

export default class GradeClassroomCreationDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      waiting: false,
      error: null,
      canBeSent: false,
      rules: '',
      teacherAccountList: null,
      studentAccountList: null,
      studentAssignmentDialogOpen: false,
      ruleDialogOpen: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
         nextProps.open !== this.props.open
      || nextState !== this.state
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open && this.props.open) {
      this.setState({
        waiting: true,
        error: null
      });

      enumerateTeacherAccounts((status, teachers) => {
        if (status !== 'success') {
          switch (status) {
            case 'not-signed-in':
            case 'account-deleted':
              this.props.onCriticalError(status);
              break;

            default:
              this.setState({
                waiting: false,
                error: status
              });
          }
          return;
        }

        this.setState({
          teacherAccountList: teachers
        });

        getGradeStudentList(this.props.grade, (status, students) => {
          this.setState({
            waiting: false
          });

          if (status !== 'success') {
            switch (status) {
              case 'not-signed-in':
              case 'account-deleted':
                this.props.onCriticalError(status);
                break;

              default:
                this.setState({
                  error: status
                });
            }
            return;
          }

          this.setState({
            studentAccountList: students.map((student) => {
              return {
                ...student,
                assigned: true
              };
            })
          });
        });
      });
    }
  }

  render() {
    return (
      <>
        <Dialog
          open={
            this.props.open
          }
          onClose={
            () => this.handleClose()
          }
          fullWidth
        >
          <DialogTitle
            className='dialog-title'
          >
            Dodawanie zajęć
          </DialogTitle>
          <DialogContent>
            {this.state.teacherAccountList && (
              <>
                <TextField
                  id='classroom-name'
                  type='text'
                  label='Nazwa zajęć'
                  autoComplete='none'
                  disabled={
                    this.state.waiting
                  }
                  margin='normal'
                  fullWidth
                  variant='outlined'
                  color='warning'
                  inputProps={{
                    maxLength: 255
                  }}
                  onChange={
                    () => this.handleChange(null, null)
                  }
                  autoFocus
                />
                <TextField
                  id='classroom-name-short'
                  type='text'
                  label='Skrót od nazwy zajęć'
                  autoComplete='none'
                  disabled={
                    this.state.waiting
                  }
                  margin='normal'
                  fullWidth
                  variant='outlined'
                  color='warning'
                  inputProps={{
                    maxLength: 4
                  }}
                  onChange={
                    () => this.handleChange()
                  }
                />
                <FormControl
                  disabled={
                    this.state.waiting
                  }
                  margin='normal'
                  fullWidth
                  color='warning'
                >
                  <InputLabel>
                    Język
                  </InputLabel>
                  <Select
                    inputProps={{
                      id: 'language'
                    }}
                    label='Język'
                    fullWidth
                    onChange={
                      (e) => this.handleChange(e.target.value, null)
                    }
                  >
                    <MenuItem
                      value='english'
                    >
                      Angielski
                    </MenuItem>
                    <MenuItem
                      value='german'
                    >
                      Niemiecki
                    </MenuItem>
                    <MenuItem
                      value='spanish'
                    >
                      Hiszpański
                    </MenuItem>
                    <MenuItem
                      value='french'
                    >
                      Francuski
                    </MenuItem>
                    <MenuItem
                      value='russian'
                    >
                      Rosyjski
                    </MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  id='classroom-description'
                  label='Opis zajęć (opcjonalny)'
                  disabled={
                    this.state.waiting
                  }
                  color='warning'
                  margin='normal'
                  fullWidth
                  variant='outlined'
                  multiline
                  rows='5'
                  inputProps={{
                    maxLength: 255
                  }}
                />
                <FormControl
                  disabled={
                    this.state.waiting
                  }
                  margin='normal'
                  fullWidth
                  color='warning'
                >
                  <InputLabel>
                    Nauczyciel
                  </InputLabel>
                  <Select
                    inputProps={{
                      id: 'teacher'
                    }}
                    label='Nauczyciel'
                    fullWidth
                    onChange={
                      (e) => this.handleChange(null, e.target.value)
                    }
                  >
                    {
                      this.state.teacherAccountList.map((teacher) => (
                        <MenuItem
                          value={teacher.id}
                        >
                          <div
                            className='grade-classroom-creation-dialog-teacher'
                          >
                            <Avatar
                              className='grade-classroom-creation-dialog-teacher-avatar'
                              src={
                                teacher.profilePicture
                                  ? `/profilePictures/${encodeURIComponent(encodeURIComponent(teacher.profilePicture))}`
                                  : null
                              }
                            />
                            <div>
                              <Typography
                                variant='body1'
                              >
                                {teacher.username}
                              </Typography>
                              <Typography
                                variant='body1'
                              >
                                {teacher.email}
                              </Typography>
                            </div>
                          </div>
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                <div className='grade-classroom-creation-dialog-button-container'>
                  <IconButton
                    className='option'
                    title='Zarządzaj regulaminem'
                    onClick={
                      () => this.openRuleDialog()
                    }
                  >
                    <RuleIcon/>
                  </IconButton>
                  <IconButton
                    className='option'
                    title='Zarządzaj uczniami'
                    onClick={
                      () => this.openStudentAssignmentDialog()
                    }
                  >
                    <GroupIcon/>
                  </IconButton>
                </div>
              </>
            )}
            {
              (this.state.waiting || this.state.error) && (
                <div
                  className='grade-classroom-creation-dialog-status-container'
                >
                  {
                    this.state.waiting && (
                      <CircularProgress
                        color='warning'
                      />
                    )
                  }
                  {
                    this.state.error === 'no-connection' && (
                      <Alert
                        severity='error'
                      >
                        Wystąpił błąd podczas łączenia z serwerem. Sprawdź swoje połączenie z internetem.
                      </Alert>
                    )
                  }
                  {
                    this.state.error === 'unknown-error' && (
                      <Alert
                        severity='error'
                      >
                        Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.
                      </Alert>
                    )
                  }
                </div>
              )
            }
            <StudentAssignmentDialog
              open={
                this.state.studentAssignmentDialogOpen
              }
              onClose={
                () => this.closeStudentAssignmentDialog()
              }
              studentAccountList={
                this.state.studentAccountList
              }
              onStudentAssignmentChanged={
                (studentCode, assigned) => {
                  const newStudentList = [...this.state.studentAccountList];
                  const student = newStudentList.find((currentStudent) => currentStudent.studentCode === studentCode);
                  if (!student)
                    return;

                  student.assigned = assigned;
                  this.setState({
                    studentAccountList: newStudentList
                  });
                }
              }
            />
          </DialogContent>
          <DialogActions>
            <Button
              color='warning'
              disabled={
                this.state.waiting || !this.state.canBeSent || !this.state.teacherAccountList
              }
              onClick={
                () => this.handleSubmit()
              }
            >
              Utwórz
            </Button>
            <Button
              color='warning'
              disabled={
                this.state.waiting
              }
              onClick={
                () => this.handleClose()
              }
            >
              Anuluj
            </Button>
          </DialogActions>
        </Dialog>
        <RuleDialog
          accountType='admin'
          open={
            this.state.ruleDialogOpen
          }
          onClose={
            () => this.closeRuleDialog()
          }
          rules={
            this.state.rules
          }
          onRulesChanged={
            (rules) => this.setState({
              rules: rules
            })
          }
        />
      </>
    );
  }

  handleSubmit() {
    this.setState({
      waiting: true,
      error: null
    });

    const classroomName = document.querySelector('#classroom-name').value;
    const classroomNameShort = document.querySelector('#classroom-name-short').value;
    const classroomLanguage = document.querySelector('#language').value;
    const classroomDescription = document.querySelector('#classroom-description').value;
    const classroomTeacher = document.querySelector('#teacher').value;
    const classroomStudents = this.state.studentAccountList.filter((student) => student.assigned).map((student) => student.studentCode);
    const teacher = this.state.teacherAccountList.find((currentTeacher) => currentTeacher.id === classroomTeacher);
    const teacherProfilePicture = teacher.profilePicture;
    const teacherUsername = teacher.username;
    const teacherEmail = teacher.email;

    createGradeClassroom(
      this.props.grade, classroomName, classroomNameShort, classroomLanguage, classroomDescription, this.state.rules, classroomTeacher, classroomStudents,
      (status, classroomCode) => {
        this.setState({
          waiting: false
        });

        if (status !== 'success') {
          switch (status) {
            case 'not-signed-in':
            case 'account-deleted':
              this.props.onCriticalError(status);
              break;

            default:
              this.setState({
                error: status
              });
          }
        } else {
          this.props.onClassroomCreated(classroomCode, classroomName, classroomNameShort, classroomLanguage, classroomTeacher, teacherProfilePicture, teacherUsername, teacherEmail);
          this.handleClose();
        }
      }
    );
  }

  handleClose() {
    this.setState({
      waiting: false,
      error: null,
      canBeSent: false,
      rules: '',
      teacherAccountList: null
    });
    this.props.onClose();
  }

  handleChange(language, teacher) {
    this.setState({
      canBeSent:
        (document.querySelector('#classroom-name').value.length !== 0) &&
        (document.querySelector('#classroom-name-short').value.length !== 0) &&
        (
          (document.querySelector('#language').value.length !== 0) ||
          (language && language.length !== 0)
        ) && (
          (document.querySelector('#teacher').value.length !== 0) ||
          (teacher && teacher.length !== 0)
        )
    });
  }

  openStudentAssignmentDialog() {
    this.setState({
      studentAssignmentDialogOpen: true
    });
  }

  closeStudentAssignmentDialog() {
    this.setState({
      studentAssignmentDialogOpen: false
    });
  }

  openRuleDialog() {
    this.setState({
      ruleDialogOpen: true
    });
  }

  closeRuleDialog() {
    this.setState({
      ruleDialogOpen: false
    });
  }
}