import { callApi } from "../ApiCaller";

function requestPasswordRecovery(email, onFinish) {
    callApi("requestPasswordRecovery", {
        email: email
    }, (callStatus, httpStatus) => {
        if (callStatus !== "success") {
            onFinish(callStatus);
            return;
        }

        switch (httpStatus) {
            case 200:
                onFinish("success");
                break;

            case 403:
                onFinish("email-not-verified");
                break;

            case 404:
                onFinish("invalid-email");
                break;

            default:
                onFinish("unknown-error");
        }
    });
}

export default requestPasswordRecovery;