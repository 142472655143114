import React from 'react';

import { Dialog, DialogTitle, DialogContent, TextField, Typography, CircularProgress, Alert, DialogActions, Button } from '@mui/material';

import './StudentAccountCreationDialog.css';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import getDomainName from '../../../utils/apiCaller/domainManager/DomainNameGetter';
import createGradeStudentAccount from '../../../utils/apiCaller/gradeManager/GradeStudentAccountCreator';

export default class StudentAccountCreationDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      waiting: false,
      error: null,
      canBeSent: false,
      domainName: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'StudentAccountCreationDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
         (nextProps.open !== this.props.open)
      || nextState !== this.state
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open && this.props.open) {
      this.setState({
        waiting: true
      });

      getDomainName((status, domainName) => {
        this.setState({
          waiting: false
        });

        if (status !== 'success') {
          switch (status) {
            case 'not-signed-in':
            case 'account-deleted':
              this.props.onCriticalError(status);
              break;

            default:
              this.setState({
                error: status
              });
          }

          return;
        }

        this.setState({
          domainName: domainName
        });
      });
    }
  }

  render() {
    return (
      <Dialog
        open={
          this.props.open
        }
        onClose={
          () => this.handleClose()
        }
        fullWidth
      >
        <DialogTitle
          className='dialog-title'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </DialogTitle>
        <DialogContent>
          {this.state.domainName && (
            <>
              <div
                className='student-account-creation-dialog-panel'
              >
                <TextField
                  id='student-email'
                  type='text'
                  label={
                    this.state.languageFile && this.state.languageFile.email
                  }
                  autoComplete='none'
                  disabled={
                    this.state.waiting
                  }
                  margin='normal'
                  fullWidth
                  variant='outlined'
                  color='warning'
                  inputProps={{
                    maxLength: 254 - this.state.domainName.length
                  }}
                  onChange={
                    () => this.handleChange()
                  }
                  autoFocus
                />
                <Typography
                  className='domain-name'
                  variant='body1'
                >
                  @ {this.state.domainName}
                </Typography>
              </div>
              <div
                className='student-account-creation-dialog-panel'
              >
                <TextField
                  id='student-username'
                  type='text'
                  label={
                    this.state.languageFile && this.state.languageFile.username
                  }
                  autoComplete='none'
                  disabled={
                    this.state.waiting
                  }
                  margin='normal'
                  fullWidth
                  variant='outlined'
                  color='warning'
                  inputProps={{
                    maxLength: 255
                  }}
                  onChange={
                    () => this.handleChange()
                  }
                />
              </div>
              <div
                className='student-account-creation-dialog-panel'
              >
                <TextField
                  id='student-password'
                  type='password'
                  label={
                    this.state.languageFile && this.state.languageFile.password
                  }
                  autoComplete='none'
                  disabled={
                    this.state.waiting
                  }
                  margin='normal'
                  fullWidth
                  variant='outlined'
                  color='warning'
                  inputProps={{
                    maxLength: 255
                  }}
                  onChange={
                    () => this.handleChange()
                  }
                />
              </div>
            </>
          )}
          {(this.state.waiting || this.state.error) && (
            <div
              className='student-account-creation-dialog-status-container'
            >
              {this.state.waiting && (
                <CircularProgress
                  color='warning'
                />
              )}
              {this.state.error === 'no-connection' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.noConnectionError}
                </Alert>
              )}
              {this.state.error === 'unknown-error' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.unknownError}
                </Alert>
              )}
              {this.state.error === 'invalid-grade-code' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.gradeCodeError}
                </Alert>
              )}
              {this.state.error === 'account-already-exists' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.emailTakenError}
                </Alert>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color='warning'
            disabled={
              this.state.waiting || !this.state.canBeSent
            }
            onClick={
              () => this.handleSubmit()
            }
          >
            {this.state.languageFile && this.state.languageFile.create}
          </Button>
          <Button
            color='warning'
            disabled={
              this.state.waiting
            }
            onClick={
              () => this.handleClose()
            }
          >
            {this.state.languageFile && this.state.languageFile.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleClose() {
    this.setState({
      waiting: false,
      error: null,
      canBeSent: false,
      domainName: null
    });

    this.props.onClose();
  }

  handleSubmit() {
    this.setState({
      waiting: true,
      error: null
    });

    const email = document.querySelector('#student-email').value;
    const username = document.querySelector('#student-username').value;
    const password = document.querySelector('#student-password').value;
    createGradeStudentAccount(this.props.grade, email, username, password, (status, studentCode) => {
      this.setState({
        waiting: false
      });

      if (status !== 'success') {
        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.setState({
              error: status
            });
        }
        return;
      }

      this.props.onStudentAccountCreated(studentCode, `${email}@${this.state.domainName}`, username);
      this.handleClose();
    });
  }

  handleChange() {
    this.setState({
      canBeSent:
           (document.querySelector('#student-email').value.length !== 0)
        && (document.querySelector('#student-username').value.length !== 0)
        && (document.querySelector('#student-password').value.length !== 0)
    });
  }
}