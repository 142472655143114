import React from "react";
import { FilePicker } from "react-file-picker";

import { Dialog, DialogTitle, DialogContent, TextField, Chip, CircularProgress, Alert, DialogActions, Button, IconButton, Box } from "@mui/material";

import AttachFileIcon from "@mui/icons-material/AttachFile";

import "./MaterialCreationDialog.css";

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import createMaterial from "../../../utils/apiCaller/materialManager/MaterialCreator";

class MaterialCreationDialog extends React.Component {
    constructor() {
        super();
        this.state = {
          languageFile: null,
            waiting: false,
            error: null,
            attachments: [],
            canBeSent: false
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'MaterialCreationDialog', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });
    }

    render() {
        return (
            <Dialog 
              className="material-creation-dialog" 
              open={
                this.props.open
              }
              onClose={
                () => this.handleClose()
              } 
              fullWidth
            >
                <DialogTitle 
                  className="dialog-title"
                >
                  {this.state.languageFile && this.state.languageFile.prompt}
                </DialogTitle>
                <DialogContent>
                    <TextField 
                      id="materialContent" 
                      label={
                        this.state.languageFile && this.state.languageFile.content
                      }
                      required 
                      disabled={
                        this.state.waiting
                      } 
                      color="secondary" 
                      margin="normal" 
                      fullWidth 
                      variant="outlined" 
                      multiline 
                      rows="5" 
                      inputProps={{ 
                        maxLength: 255 
                      }} 
                      onChange={
                        (e) => this.setState(
                          {canBeSent: e.target.value.length !== 0 }
                        )
                      }
                      autoFocus
                    />
                    <Box 
                      className="material-creation-dialog-message-options"
                    >
                      <FilePicker 
                        onChange={
                          (file) => this.addAttachment(file)
                        } 
                        onError={
                          () => this.setState(
                            { 
                              error: "invalid-file-size" 
                            }
                          )
                        }
                        maxSize={
                          (2 * 1024 * 1024) / 1000000
                        }
                      >
                          <IconButton 
                            title={
                              this.state.languageFile && this.state.languageFile.addAttachment
                            } 
                            disabled={
                              this.state.waiting
                            }
                          >
                            <AttachFileIcon />
                          </IconButton>
                      </FilePicker>
                    </Box>
                    <div 
                      className="material-creation-dialog-attachment-list"
                    >
                      {
                        this.state.attachments.map(
                          (attachment) => (
                            <Chip 
                              className="attachment" 
                              label={
                                attachment.name
                              } 
                              disabled={
                                this.state.waiting
                              } 
                              onDelete={
                                () => this.removeAttachment(attachment)
                              }
                            />
                          )
                        )
                      }
                    </div>
                    {(this.state.waiting || this.state.error) && (
                        <div 
                          className="material-creation-dialog-status-container"
                        >
                            {this.state.waiting && (
                                <CircularProgress 
                                  color="secondary"
                                />
                            )}
                            {this.state.error === "invalid-file-size" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.fileSizeError}
                                </Alert>
                            )}
                            {this.state.error === "no-connection" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.noConnectionError}
                                </Alert>
                            )}
                            {this.state.error === "unknown-error" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.unknownError}
                                </Alert>
                            )}
                            {this.state.error === "invalid-classroom-code" && (
                                <Alert 
                                  severity="error"
                                >
                                  {this.state.languageFile && this.state.languageFile.classroomCodeError}
                                </Alert>
                            )}
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button 
                      color="secondary" 
                      disabled={
                        this.state.waiting || !this.state.canBeSent
                      } 
                      onClick={
                        () => this.handleSubmit()
                      }
                    >
                      {this.state.languageFile && this.state.languageFile.add}
                    </Button>
                    <Button 
                      color="secondary" 
                      disabled={
                        this.state.waiting
                      } 
                      onClick={
                        () => this.handleClose()
                      }
                    >
                      {this.state.languageFile && this.state.languageFile.cancel}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleClose() {
        this.setState({
            waiting: false,
            error: null,
            attachments: [],
            canBeSent: false
        });

        this.props.onClose();
    }

    handleSubmit() {
        this.setState({
            waiting: true,
            error: null
        });

        let content = document.getElementById("materialContent").value;
        createMaterial(this.props.classroom, content, this.state.attachments, (status, material) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            let attachmentIndex = 0;
            this.props.onMaterialCreated({
                materialCode: material.materialCode,
                content: content,
                attachments: material.attachmentCodes.map((attachmentCode) => ({
                    attachmentCode: attachmentCode,
                    filename: this.state.attachments[attachmentIndex++].name
                }))
            });
            this.handleClose();
        });
    }

    addAttachment(file) {
        this.setState({
            error: null,
            attachments: [
                ...this.state.attachments,
                file
            ]
        });
    }

    removeAttachment(attachmentToRemove) {
        this.setState({ attachments: this.state.attachments.filter((attachment) => {
            return attachment !== attachmentToRemove;
        })});
    }
}

export default MaterialCreationDialog;