import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogContentText, CircularProgress, Alert, DialogActions, Button } from '@mui/material';

import './GradeClassroomDeleteDialog.css';

import deleteGradeClassroom from '../../../utils/apiCaller/gradeManager/GradeClassroomDeleter';

export default class GradeClassroomDeleteDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      waiting: false,
      error: null
    };
  }

  render() {
    return (
      <Dialog
        open={
          this.props.open
        }
        onClose={
          () => this.handleClose()
        }
        fullWidth
      >
        <DialogTitle
          className='dialog-title'
        >
          Czy na pewno chcesz usunąć te zajęcia?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tej operacji nie można cofnąć!
          </DialogContentText>
          {
            (this.state.waiting || this.state.error) && (
              <div
                className='grade-classroom-delete-dialog-status-container'
              >
                {this.state.waiting && (
                  <CircularProgress
                    color='warning'
                  />
                )}
                {this.state.error === 'no-connection' && (
                  <Alert
                    severity='error'
                  >
                    Wystąpił błąd podczas łączenia z serwerem. Sprawdź swoje połączenie z internetem.
                  </Alert>
                )}
                {this.state.error === 'unknown-error' && (
                  <Alert
                    severity='error'
                  >
                    Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.
                  </Alert>
                )}
                {this.state.error === 'invalid-classroom-code' && (
                  <Alert
                    severity='error'
                  >
                    Te zajęcia zostały usunięte z poziomu innej karty lub innego urządzenia.
                  </Alert>
                )}
              </div>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button
            color='warning'
            disabled={
              this.state.waiting
            }
            onClick={
              () => this.handleSubmit()
            }
          >
            Tak
          </Button>
          <Button
            color='warning'
            disabled={
              this.state.waiting
            }
            onClick={
              () => this.handleClose()
            }
          >
            Nie
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleClose() {
    this.setState({
      waiting: false,
      error: null
    });

    this.props.onClose();
  }

  handleSubmit() {
    this.setState({
      waiting: true,
      error: null
    });

    deleteGradeClassroom(this.props.grade, this.props.classroom, (status) => {
      this.setState({
        waiting: false
      });

      if (status !== 'success') {
        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.setState({
              error: status
            });
        }
        return;
      }

      this.handleClose();
      this.props.onClassroomDeleted();
    });
  }
}