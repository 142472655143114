import { callApi } from '../ApiCaller';

export default function enumerateWordSets(classroomCode, onFinish) {
  callApi('enumerateWordSets', {
    classroomCode: classroomCode
  }, (callStatus, httpStatus, wordSets) => {
    if (callStatus !== 'success') {
      onFinish(callStatus);
      return;
    }

    switch (httpStatus) {
      case 200:
        onFinish('success', JSON.parse(wordSets));
        break;

      case 401:
        onFinish('not-signed-in');
        break;

      case 403:
        onFinish('account-deleted');
        break;

      case 404:
        onFinish('invalid-classroom-code');
        break;

      default:
        onFinish('unknown-error');
    }
  });
}