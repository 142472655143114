import React from 'react';

import { Dialog, DialogTitle, DialogContent, TextField, Typography, IconButton, CircularProgress, Alert, DialogActions, Button } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';

import './TeacherAccountEditDialog.css';
import TeacherAccountPasswordDialog from '../teacherAccountPasswordDialog/TeacherAccountPasswordDialog';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import editTeacherAccount from '../../../utils/apiCaller/domainManager/TeacherAccountEditor';

export default class TeacherAccountEditDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      waiting: false,
      error: null,
      canBeSent: false,
      passwordDialogOpen: false
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'TeacherAccountEditDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  render() {
    return (
      <>
        <Dialog
          open={
            this.props.open
          }
          onClose={
            () => this.handleClose()
          }
          fullWidth
        >
          <DialogTitle
            className='dialog-title'
          >
            {this.state.languageFile && this.state.languageFile.prompt}
          </DialogTitle>
          <DialogContent>
            {this.props.domainName && (
              <>
                <div
                  className='teacher-account-edit-dialog-teacher-email'
                >
                  <TextField
                    id='newTeacherEmail'
                    type='text'
                    label={
                      this.state.languageFile && this.state.languageFile.email
                    }
                    autoComplete='none'
                    required
                    disabled={
                      this.state.waiting
                    }
                    color='warning'
                    margin='normal'
                    fullWidth
                    variant='outlined'
                    inputProps={{
                      maxLength: 254 - this.props.domainName.length
                    }}
                    onChange={
                      () => this.handleChange()
                    }
                    autoFocus
                    defaultValue={
                      this.props.email && this.props.email.substring(0, this.props.email.lastIndexOf(`@${this.props.domainName}`))
                    }
                  />
                  <Typography
                    className='domain-name'
                    variant='body1'
                  >
                    @ {this.props.domainName}
                  </Typography>
                </div>
                <TextField
                  id='newTeacherUsername'
                  type='text'
                  label={
                    this.state.languageFile && this.state.languageFile.username
                  }
                  autoComplete='none'
                  required
                  disabled={
                    this.state.waiting
                  }
                  color='warning'
                  margin='normal'
                  fullWidth
                  variant='outlined'
                  inputProps={{
                    maxLength: 255
                  }}
                  onChange={
                    () => this.handleChange()
                  }
                  defaultValue={
                    this.props.username
                  }
                />
                <div
                  className='teacher-account-edit-dialog-password-button-container'
                >
                  <IconButton
                    className='teacher-account-edit-dialog-password-button'
                    title={
                      this.state.languageFile && this.state.languageFile.changePassword
                    }
                    disabled={
                      this.state.waiting
                    }
                    onClick={
                      () => this.openPasswordDialog()
                    }
                  >
                    <KeyIcon />
                  </IconButton>
                </div>
                <div
                  className='teacher-account-edit-dialog-status-container'
                >
                  {this.state.waiting && (
                    <CircularProgress
                      color='warning'
                    />
                  )}
                  {this.state.error === 'no-connection' && (
                    <Alert
                      severity='error'
                    >
                      {this.state.languageFile && this.state.languageFile.noConnectionError}
                    </Alert>
                  )}
                  {this.state.error === 'unknown-error' && (
                    <Alert
                      severity='error'
                    >
                      {this.state.languageFile && this.state.languageFile.unknownError}
                    </Alert>
                  )}
                  {this.state.error === 'invalid-teacher-id' && (
                    <Alert
                      severity='error'
                    >
                      {this.state.languageFile && this.state.languageFile.accountDeletedError}
                    </Alert>
                  )}
                  {this.state.error === 'account-already-exists' && (
                    <Alert
                      severity='error'
                    >
                      {this.state.languageFile && this.state.languageFile.accountAlreadyExists}
                    </Alert>
                  )}
                </div>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color='warning'
              disabled={
                this.state.waiting || !this.state.canBeSent
              }
              onClick={
                () => this.handleSubmit()
              }
            >
              {this.state.languageFile && this.state.languageFile.ok}
            </Button>
            <Button
              color='warning'
              disabled={
                this.state.waiting
              }
              onClick={
                () => this.handleClose()
              }
            >
              {this.state.languageFile && this.state.languageFile.cancel}
            </Button>
          </DialogActions>
        </Dialog>
        <TeacherAccountPasswordDialog
          language={
            this.props.language
          }
          open={
            this.state.passwordDialogOpen
          }
          onClose={
            () => this.closePasswordDialog()
          }
          onCriticalError={
            (error) => this.props.onCriticalError(error)
          }
          teacherId={
            this.props.teacherId
          }
        />
      </>
    );
  }

  getUserFromEmail(email) {
    if (!email)
      return null;

    return this.props.email.substring(0, this.props.email.lastIndexOf(`@${this.props.domainName}`));
  }

  handleChange() {
    const newTeacherEmail = document.querySelector('#newTeacherEmail');
    const newTeacherUsername = document.querySelector('#newTeacherUsername');
    this.setState({
      canBeSent:
        newTeacherEmail.value.length !== 0 &&
        newTeacherUsername.value.length !== 0 &&
        (
             newTeacherEmail.value !== this.getUserFromEmail(this.props.email)
          || newTeacherUsername.value !== this.props.username
        )
    });
  }

  handleClose() {
    this.setState({
      waiting: false,
      error: null,
      canBeSent: false
    });

    this.props.onClose();
  }

  handleSubmit() {
    this.setState({
      waiting: true,
      error: null
    });

    const newEmail = document.querySelector('#newTeacherEmail').value;
    const newUsername = document.querySelector('#newTeacherUsername').value;
    editTeacherAccount(this.props.teacherId, newEmail, newUsername, (status) => {
      this.setState({
        waiting: false
      });
      
      if (status !== 'success') {
        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.setState({
              error: status
            });
        }
        return;
      }

      this.props.onTeacherAccountEdited(this.props.teacherId, newEmail, newUsername);
      this.handleClose();
    });
  }

  openPasswordDialog() {
    this.setState({
      passwordDialogOpen: true
    });
  }

  closePasswordDialog() {
    this.setState({
      passwordDialogOpen: false
    });
  }
}