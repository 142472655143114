import HttpError from '../HttpError';

//Get a language file
export default function getLanguageFile(language, component, onFinish) {
  fetch(`/languages/${encodeURIComponent(language)}/${encodeURIComponent(component)}.json`, {
    headers: { "Content-Type": "charset=UTF-8" }
  }).then((response) => {
    if (response.status !== 200)
      throw new HttpError(response.status);

    return response.json();
  }).then((file) => {
    onFinish('success', file);
  }).catch((error) => {
    if (error instanceof TypeError || error instanceof HttpError) {
      onFinish('no-connection');
      return;
    }

    onFinish('unknown-error');
  });
}