import { Card, Typography } from '@mui/material';

export function mapDictionarySection(sourceFile, translationFile) {
  return (
    <Card
      className='dictionary-lesson-section'
    >
      <Typography
        className='section-title'
        variant='h6'
      >
        {translationFile.title}
      </Typography>
      {sourceFile.content.map((word, i) => (
        <Typography
          variant='body1'
        >
          {stringifyWord(word)} - {stringifyWord(translationFile.content[i])}
          {(typeof word === 'object' && word.example) && (
            <>
              {` (przykład: `}
              {stringifyWord(word.example)}
              {` - `}
              {stringifyWord(translationFile.content[i].example)}
              {`)`}
            </>
          )}
        </Typography>
      ))}
    </Card>
  );
}

export function mapLearnSection(sourceFile, translationFile) {
  return sourceFile.content.map((word, i) => ({
    word: stringifyWord(word),
    translation: stringifyWord(translationFile.content[i])
  }));
}

export function mapFlashcardsSection(sourceFile, translationFile) {
  return {
    title: translationFile.title,
    content: sourceFile.content.map((word, i) => {
      const translation = translationFile.content[i];
      return {
        word: stringifyWord(word),
        translation: stringifyWord(translation),
        example: (typeof word === 'object' && word.example && stringifyWord(word.example)),
        exampleTranslation: (typeof translation === 'object' && translation.example && stringifyWord(translation.example)),

        wordAudio: (typeof word === 'object' && word.audio),
        translationAudio: (typeof translation === 'object' && translation.audio),
        exampleAudio: (typeof word === 'object' && word.exampleAudio),
        exampleTranslationAudio: (typeof translation === 'object' && translation.exampleAudio)
      };
    })
  };
}

export function mapQuizSectionWords(sourceFile, translationFile) {
  return sourceFile.content.map((word, i) => ({
    question: stringifyWord(word),
    correctAnswer: stringifyWord(translationFile.content[i])
  }));
}

export function mapQuizSectionAnswers(translationFile) {
  return translationFile.content.map((word) => stringifyWord(word));
}

export function mapWritingSection(sourceFile, translationFile) {
  return translationFile.content.map((word, i) => {
    if (typeof sourceFile.content[i] === 'object') {
      return {
        question: stringifyWord(word),
        correctAnswer: getTextFromWord(sourceFile.content[i]),
        displayCorrectAnswer: stringifyWord(sourceFile.content[i])
      };
    }

    return {
      question: stringifyWord(word),
      correctAnswer: getTextFromWord(sourceFile.content[i])
    };
  });
};

function stringifyWord(word) {
  //If 'word' is an object, it needs some additional processing
  if (typeof word === 'object') {
    //If it's an array, simply return a string of its elements joined by the ' / ' separator
    if (Array.isArray(word)) {
      let x = [];
      for (let i = 0; i < word.length; i++) {
        if (i > 0)
          x.push(' / ');

        x.push(<span className='word-part'>{word[i]}</span>);
      }

      return x;
    }

    //Otherwise, if it has 'displayText', just return the 'displayText'
    if (word.displayText)
      return word.displayText;

    //If it doesn't and 'text' is an object (assumed to be an array),
    //return a string of its elements joined by ' / '
    if (typeof word.text === 'object') {
      let x = [];
      for (let i = 0; i < word.text.length; i++) {
        if (i > 0)
          x.push(' / ');

        x.push(<span className='word-part'>{word.text[i]}</span>);
      }

      return x;
    }

    //Otherwise, just assume 'text' is a string
    return word.text;
  }

  //Otherwise, we assume 'word' is just a regular string
  return word;
}

function getTextFromWord(word) {
  //If 'word' is an object, it needs some additional processing
  if (typeof word === 'object') {
    //If it's an array, simply return itself
    if (Array.isArray(word))
      return word;

    //If it's not, simply return 'text'
    return word.text;
  }

  //If it's not, simply return itself
  return word;
}