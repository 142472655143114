import React from 'react';

import { Dialog, DialogTitle, DialogContent, Card, Avatar, Typography, Checkbox, CircularProgress, Alert, DialogActions, Button } from '@mui/material';

import './StudentAssignmentDialog.css';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import setGradeClassroomStudentAssignment from '../../../utils/apiCaller/gradeManager/GradeClassroomStudentAssignmentSetter';

export default class StudentAssignmentDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      waiting: false,
      error: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'StudentAssignmentDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  render() {
    return (
      <Dialog
        open={
          this.props.open
        }
        onClose={
          () => this.handleClose()
        }
        fullWidth
      >
        <DialogTitle
          className='dialog-title'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </DialogTitle>
        <DialogContent>
          {this.props.studentAccountList && (
            this.props.studentAccountList.map((student) => (
              <Card
                className='student-assignment-dialog-student-container'
              >
                <div
                  className='student-assignment-dialog-student'
                >
                  <Avatar
                    className='student-assignment-dialog-student-avatar'
                    src={
                      student.profilePicture
                        ? `/profilePictures/${encodeURIComponent(encodeURIComponent(student.profilePicture))}`
                        : null
                    }
                  />
                  <div>
                    <Typography
                      variant='body1'
                    >
                      {student.username}
                    </Typography>
                    <Typography
                      variant='body1'
                    >
                      {student.email}
                    </Typography>
                  </div>
                </div>
                <Checkbox
                  color='warning'
                  onChange={
                    (e) => {
                      if (this.props.editMode) {
                        this.setState({
                          waiting: true,
                          error: null
                        });
                        
                        const assigned = e.target.checked;
                        setGradeClassroomStudentAssignment(this.props.grade, this.props.classroom, student.studentCode, e.target.checked, (status) => {
                          this.setState({
                            waiting: false
                          });
                          
                          if (status !== 'success') {
                            switch (status) {
                              case 'not-signed-in':
                              case 'account-deleted':
                                this.props.onCriticalError(status);
                                break;

                              default:
                                this.setState({
                                  error: status
                                });
                            }
                            return;
                          }

                          this.props.onStudentAssignmentChanged(student.studentCode, assigned);
                        });
                      } else
                        this.props.onStudentAssignmentChanged(student.studentCode, e.target.checked);
                    }
                  }
                  checked={
                    student.assigned
                  }
                  disabled={
                    this.state.waiting
                  }
                />
              </Card>
            ))
          )}
          <div
            className='student-assignment-dialog-status-container'
          >
            {this.state.waiting && (
              <CircularProgress
                color='warning'
              />
            )}
            {this.state.error === 'no-connection' && (
              <Alert
                severity='error'
              >
                {this.state.languageFile && this.state.languageFile.noConnectionError}
              </Alert>
            )}
            {this.state.error === 'unknown-error' && (
              <Alert
                severity='error'
              >
                {this.state.languageFile && this.state.languageFile.unknownError}
              </Alert>
            )}
            {this.state.error === 'invalid-student-code' && (
              <Alert
                severity='error'
              >
                {this.state.languageFile && this.state.languageFile.studentCodeError}
              </Alert>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color='warning'
            onClick={
              () => this.handleClose()
            }
          >
            {this.state.languageFile && this.state.languageFile.ok}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleClose() {
    this.setState({
      waiting: false,
      error: null
    });
    this.props.onClose();
  }
}