import { callApi } from '../ApiCaller';

export default function createTeacherAccount(email, username, password, onFinish) {
  callApi('createTeacherAccount', {
    teacherEmail: email,
    username: username,
    password: password
  }, (callStatus, httpStatus, teacherId) => {
    if (callStatus !== 'success') {
      onFinish(callStatus);
      return;
    }

    switch (httpStatus) {
      case 200:
        onFinish('success', teacherId);
        break;

      case 401:
        onFinish('not-signed-in');
        break;

      case 403:
        onFinish('account-deleted');
        break;

      case 409:
        onFinish('account-already-exists');
        break;

      default:
        onFinish('unknown-error');
    }
  });
}