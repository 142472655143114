import React from 'react';
import isSignedIn from '../../../utils/apiCaller/accountManager/SignInStatusChecker';
import { useNavigate, Link } from 'react-router-dom';
import { Button, IconButton, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box } from '@mui/system';
import './HomepageApp.css';

class HomepageAppComponent extends React.Component{
  componentDidMount(){
    isSignedIn((status, signedIn) => {
      if(status !== 'success'){
        this.props.onCriticalError(status);
      } else {
        if(signedIn){
          this.props.navigate('/cockpit');
        }
      }
    });
  }
  render(){
    return(
      <main 
        className="homepage-app"
      >
        <Box className='horizontal-center'>
          <Box>
            <Box 
              className='title-container'
            >
              <img 
                src='/favicon.png' 
                alt='' 
              />
              <Typography 
                variant='h3'
              >
                NovaLang
              </Typography>
            </Box>
            <Box 
              className='content'
            >
              <Typography 
                variant='h4' 
                className='subtitle'
              >
                Szybka i skuteczna nauka języków obcych
              </Typography>
              <Typography 
                variant='h6' 
                className='text'
              >
                Zaloguj się, aby  rozpocząć pracę
              </Typography>
              <Link 
                to='/signin' 
                className='link-btn'
              >
                <Button 
                  variant='contained' 
                  className='link-btn'
                >
                  Zaloguj się
                </Button>
              </Link>
              <Typography 
                variant='h6' 
                className='text'
              >
                Lub załóż konto, jeśli jeszcze go nie posiadasz
              </Typography>
              <Link 
                to='/signup' 
                className='link-btn'
              >
                <Button 
                  variant='contained' 
                  className='link-btn'
                >
                  Załóż konto
                </Button>
              </Link>
            </Box>
            <Box
              className='setts-box'
            >
              <Link
                className='link'
                to='/settings'
              >
                <IconButton>
                  <SettingsIcon />
                </IconButton>
              </Link>
            </Box>
          </Box>
        </Box>
      </main>
    );
  }
}

export default function HomepageApp(props) {
    return <HomepageAppComponent {...props} navigate={useNavigate()} />
}