import React from 'react';
import { FilePicker } from 'react-file-picker';

import { Dialog, DialogTitle, DialogContent, TextField, IconButton, Chip, CircularProgress, Alert, DialogActions, Button } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'dayjs/locale/pl';
import 'dayjs/locale/en';
import 'dayjs/locale/en-au';
import 'dayjs/locale/en-ca';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/en-ie';
import 'dayjs/locale/en-il';
import 'dayjs/locale/en-in';
import 'dayjs/locale/en-nz';
import 'dayjs/locale/en-sg';
import 'dayjs/locale/en-tt';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import './TaskCreationDialog.css';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import createTask from '../../../utils/apiCaller/taskManager/TaskCreator';

export default class TaskCreationDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      waiting: false,
      error: null,
      attachments: [],
      due: null,
      canBeSent: false
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'TaskCreationDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  render() {
    return (
      <Dialog
        open={
          this.props.open
        }
        onClose={
          () => this.handleClose()
        }
        fullWidth
      >
        <DialogTitle
          className='dialog-title'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </DialogTitle>
        <DialogContent>
          <TextField
            id='task-content'
            label={
              this.state.languageFile && this.state.languageFile.message
            }
            required
            disabled={
              this.state.waiting
            }
            color='secondary'
            margin='normal'
            fullWidth
            variant='outlined'
            multiline
            rows='5'
            inputProps={{
              maxLength: 255
            }}
            onChange={
              () => this.handleChange(this.state.due)
            }
            autoFocus
          />
          <div
            className='task-creation-dialog-options'
          >
            <FilePicker
              onChange={
                (file) => this.addAttachment(file)
              }
              onError={
                () => this.setState({
                  error: 'invalid-file-size'
                })
              }
              maxSize={
                (2 * 1024 * 1024) / 1000000
              }
            >
              <IconButton
                title={
                  this.state.languageFile && this.state.languageFile.addAttachment
                }
                disabled={
                  this.state.waiting
                }
              >
                <AttachFileIcon/>
              </IconButton>
            </FilePicker>
          </div>
          <div
            className='task-creation-dialog-attachment-list'
          >
            {this.state.attachments.map((attachment) => (
              <Chip
                className='attachment'
                label={
                  attachment.name
                }
                disabled={
                  this.state.waiting
                }
                onDelete={
                  () => this.removeAttachment(attachment)
                }
              />
            ))}
          </div>
          <div
            className='task-creation-dialog-due-container'
          >
            <LocalizationProvider
              dateAdapter={
                AdapterDayjs
              }
              adapterLocale={
                navigator.language.toLowerCase()
              }
            >
              <DateTimePicker
                label={
                  this.state.languageFile && this.state.languageFile.deadline
                }
                required
                disabled={
                  this.state.waiting
                }
                color='secondary'
                margin='normal'
                fullWidth
                variant='outlined'
                onChange={
                  (due) => this.handleChange(due)
                }
              />
            </LocalizationProvider>
          </div>
          {(this.state.waiting || this.state.error) && (
            <div
              className='task-creation-dialog-status-container'
            >
              {this.state.waiting && (
                <CircularProgress
                  color='secondary'
                />
              )}
              {this.state.error === 'invalid-file-size' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.fileSizeError}
                </Alert>
              )}
              {this.state.error === 'no-connection' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.noConnectionError}
                </Alert>
              )}
              {this.state.error === 'unknown-error' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.unknownError}
                </Alert>
              )}
              {this.state.error === 'invalid-classroom-code' && (
                <Alert
                  severity='error'
                >
                  {this.state.languageFile && this.state.languageFile.classroomCodeError}
                </Alert>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color='secondary'
            disabled={
              this.state.waiting || !this.state.canBeSent
            }
            onClick={
              () => this.handleSubmit()
            }
          >
            {this.state.languageFile && this.state.languageFile.add}
          </Button>
          <Button
            color='secondary'
            disabled={
              this.state.waiting
            }
            onClick={
              () => this.handleClose()
            }
          >
            {this.state.languageFile && this.state.languageFile.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleChange(due) {
    this.setState({
      due: due,
      canBeSent: (
           document.querySelector('#task-content').value.length !== 0
        && due
      )
    });
  }

  handleClose() {
    this.setState({
      waiting: false,
      error: null,
      attachments: [],
      due: null,
      canBeSent: false
    });

    this.props.onClose();
  }

  handleSubmit() {
    this.setState({
      waiting: true,
      error: null
    });

    const content = document.querySelector('#task-content').value;
    const due = {
      D: this.state.due['$D'],
      M: this.state.due['$M'] + 1,
      Y: this.state.due['$y'],
      h: this.state.due['$H'],
      m: this.state.due['$m']
    };
    createTask(this.props.classroom, content, this.state.attachments, due, (status, task) => {
      this.setState({
        waiting: false
      });

      if (status !== 'success') {
        switch (status) {
          case 'not-signed-in':
          case 'account-deleted':
            this.props.onCriticalError(status);
            break;

          default:
            this.setState({
              error: status
            });
        }
        return;
      }

      let attachmentIndex = 0;
      this.props.onTaskCreated({
        taskCode: task.taskCode,
        content: content,
        attachments: task.attachmentCodes.map((attachmentCode) => ({
          attachmentCode: attachmentCode,
          filename: this.state.attachments[attachmentIndex++].name
        })),
        due: {
          D: this.state.due['$D'],
          M: this.state.due['$M'] + 1,
          Y: this.state.due['$y'],
          h: this.state.due['$H'],
          m: this.state.due['$m']
        },
        studentCount: task.studentCount
      });

      this.handleClose();
    });
  }

  addAttachment(file) {
    this.setState({
      error: null,
      attachments: [
        ...this.state.attachments,
        file
      ]
    });
  }

  removeAttachment(attachmentToRemove) {
    this.setState({
      attachments: this.state.attachments.filter((attachment) => {
        return attachment !== attachmentToRemove;
      })
    });
  }
}