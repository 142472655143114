import React from 'react';

import { Typography } from '@mui/material';

import QuizQuestion from '../QuizQuestion';

import { mapQuizSectionWords, mapQuizSectionAnswers } from '../../../utils/LessonUtils';
import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import getLessonFile from '../../../utils/apiCaller/languageManager/LessonFileGetter';

export default class QuizPersonalLife extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null,
      question: 0,
      questions: null,
      answers: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'QuizPersonalLife', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });

    getLessonFile(this.props.lessonLanguage, 'lesson1', 'PersonalLife', (status, sourceFile) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      getLessonFile(this.props.language, 'lesson1', 'PersonalLife', (status, translationFile) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          questions: mapQuizSectionWords(sourceFile, translationFile),
          answers: mapQuizSectionAnswers(translationFile)
        });
      });
    });
  }

  render() {
    return (
      <div
        className='quiz-section'
      >
        <Typography
          className='section-title'
          variant='h5'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </Typography>
        <div
          className='section-container'
        >
          {(this.state.questions && this.state.answers) && (
            <QuizQuestion
              language={
                this.props.language
              }
              question={
                this.state.questions[this.state.question].question
              }
              answers={
                this.state.answers
              }
              correctAnswer={
                this.state.questions[this.state.question].correctAnswer
              }
              onNextQuestionRequested={
                () => this.handleNextQuestionRequested()
              }
              onCriticalError={
                (error) => this.props.onCriticalError(error)
              }
            />
          )}
        </div>
      </div>
    );
  }
  
  handleNextQuestionRequested() {
    if (this.state.question + 1 === this.state.questions.length) {
      this.props.onStageFinished();
      return;
    }

    this.setState({
      question: this.state.question + 1
    });
  }
}