import React from "react";
import { useNavigate } from "react-router-dom";

import isSignedIn from "../../utils/apiCaller/accountManager/SignInStatusChecker";

class RedirectComponent extends React.Component {
    componentDidMount() {
        isSignedIn((status, signedIn) => {
            if (status !== "success") {
                this.props.onCriticalError(status);
                return;
            }

            let navigate = this.props.navigate;
            if (signedIn)
                navigate("/cockpit");
            else
                navigate("/signin");
        });
    }

    render() {
        return (
            <></>
        );
    }
}

export default function Redirect(props) {
    return <RedirectComponent {...props} navigate={useNavigate()}/>
}