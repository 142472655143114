import React from 'react';

import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';

export default class RuleDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      languageFile: null
    };
  }

  componentDidMount() {
    getLanguageFile(this.props.language, 'RuleDialog', (status, file) => {
      if (status !== 'success') {
        this.props.onCriticalError(status);
        return;
      }

      this.setState({
        languageFile: file
      });
    });
  }

  render() {
    return (
      <Dialog
        open={
          this.props.open
        }
        onClose={
          () => this.handleClose()
        }
        fullWidth
      >
        <DialogTitle
          className='dialog-title'
        >
          {this.state.languageFile && this.state.languageFile.prompt}
        </DialogTitle>
        <DialogContent>
          <TextField
            id='classroom-rules'
            label={
              this.state.languageFile && this.state.languageFile.rules
            }
            color={
              this.props.accountType === 'teacher'
                ? 'secondary'
                : 'warning'
            }
            margin='normal'
            fullWidth
            variant='outlined'
            multiline
            rows='5'
            inputProps={{
              maxLength: 255
            }}
            defaultValue={
              this.props.rules
            }
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button
            color={
              this.props.accountType === 'teacher'
                ? 'secondary'
                : 'warning'
            }
            onClick={
              () => this.handleSubmit()
            }
          >
            {this.state.languageFile && this.state.languageFile.ok}
          </Button>
          <Button
            color={
              this.props.accountType === 'teacher'
                ? 'secondary'
                : 'warning'
            }
            onClick={
              () => this.handleClose()
            }
          >
            {this.state.languageFile && this.state.languageFile.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleClose() {
    this.props.onClose();
  }

  handleSubmit() {
    const rules = document.querySelector('#classroom-rules').value;
    this.props.onRulesChanged(rules);

    this.handleClose();
  }
}