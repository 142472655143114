import React from "react";
import { useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";

import "./Cockpit.css";
import ClassroomList from "../../components/classroomList/ClassroomList";
import GradeList from '../../components/gradeList/GradeList';
import ClassroomView from "../../components/classroomView/ClassroomView";
import GradeView from '../../components/gradeView/GradeView';

import increaseTrafficCounter from '../../utils/apiCaller/TrafficCounterIncreaser';
import getAccountType from "../../utils/apiCaller/accountManager/AccountTypeGetter";
import getLanguageFile from "../../utils/apiCaller/languageManager/LanguageFileGetter";

class CockpitComponent extends React.Component {
    constructor() {
        super();
        this.state = {
          languageFile: null,
            accountType: null,
            gradeListLength: null,
            classroomListLength: null,
            openGrade: new URLSearchParams(window.location.search).get('grade'),
            openClassroom: new URLSearchParams(window.location.search).get("classroom"),
            tabResetPending: false,
            newGradeName: null,
            newClassroomInformation: null,
            gradeDeleteScheduled: false,
            classroomDeleteScheduled: false,
            error: null
        };
    }

    componentDidMount() {
      increaseTrafficCounter((status) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }
      });

      getLanguageFile(this.props.language, 'Cockpit', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });

        getAccountType((status, accountType) => {
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                        let navigate = this.props.navigate;
                        navigate("/signin");
                        break;

                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;

                    default:
                        this.setState({ error: status });
                }
                return;
            }

            this.setState({ accountType: accountType });
        });
    }

    render() {
        return (
            <main 
              className="cockpit"
            >
                {this.state.accountType && (
                    <>
                        {/* TODO: Error messages */}
                        {
                          this.state.accountType === 'student' || this.state.accountType === 'teacher' || this.state.accountType === 'school_student' || this.state.accountType === 'school_teacher'
                            ? (
                              <ClassroomList
                                language={
                                  this.props.language
                                }
                                accountType={this.state.accountType} 
                                onClassroomListLengthChanged={
                                  (length) => this.setState(
                                    { 
                                      classroomListLength: length 
                                    }
                                  )
                                } 
                                openClassroom={
                                  this.state.openClassroom
                                } 
                                onOpenClassroom={
                                  (classroomCode) => {
                                    this.setState(
                                      {
                                        openClassroom: classroomCode,
                                        tabResetPending: this.state.openClassroom
                                      }
                                    )
                                  }
                                }
                                newClassroomInformation={
                                  this.state.newClassroomInformation
                                }
                                classroomDeleteScheduled={
                                  this.state.classroomDeleteScheduled
                                } 
                                onClassroomRenameFinished={
                                  () => this.handleClassroomRenameFinished()
                                } 
                                onClassroomDeleteFinished={
                                  () => this.handleClassroomDeleteFinished()
                                } 
                                onCriticalError={
                                  (error) => this.props.onCriticalError(error)
                                }
                              />
                            )
                            : this.state.accountType === 'school_admin'
                              ? (
                                <GradeList
                                  language={
                                    this.props.language
                                  }
                                  onGradeListLengthChanged={
                                    (length) => this.setState({
                                      gradeListLength: length
                                    })
                                  }
                                  openGrade={
                                    this.state.openGrade
                                  }
                                  onOpenGrade={
                                    (gradeCode) => this.setState({
                                      openGrade: gradeCode,
                                      tabResetPending: this.state.openGrade
                                    })
                                  }
                                  newGradeName={
                                    this.state.newGradeName
                                  }
                                  gradeDeleteScheduled={
                                    this.state.gradeDeleteScheduled
                                  }
                                  onGradeRenameFinished={
                                    () => this.handleGradeRenameFinished()
                                  }
                                  onGradeDeleteFinished={
                                    () => this.handleGradeDeleteFinished()
                                  }
                                  onCriticalError={
                                    (error) => this.props.onCriticalError(error)
                                  }
                                />
                              )
                              : null
                        }
                        {(this.state.accountType === "teacher" || this.state.accountType === 'school_teacher' || this.state.accountType === 'school_admin') ? (
                            <>
                                {(this.state.gradeListLength === 0 || this.state.classroomListLength === 0) 
                                  ? (
                                    <div 
                                      className="adaptive-cockpit-message cockpit-message-horizontal-center"
                                    >
                                        <div 
                                          className="cockpit-message-vertical-center"
                                        >
                                            <Typography 
                                              className="cockpit-message cockpit-message-title" 
                                              variant="h4"
                                            >
                                              {this.state.languageFile && this.state.languageFile.teacherNoClassrooms}
                                            </Typography>
                                            {(this.state.accountType === 'teacher' || this.state.accountType === 'school_admin') && (
                                              <Typography 
                                                className="cockpit-message" 
                                                variant="h5"
                                              >
                                                {this.state.languageFile && this.state.languageFile.teacherNoClassroomsDescription}
                                              </Typography>
                                            )}
                                        </div>
                                    </div>
                                  ) 
                                  : (!this.state.openGrade && !this.state.openClassroom) ? (
                                    <div 
                                      className="adaptive-cockpit-message cockpit-message-horizontal-center"
                                    >
                                        <div 
                                          className="cockpit-message-vertical-center"
                                        >
                                            <Typography 
                                              className="cockpit-message cockpit-message-title" 
                                              variant="h4"
                                            >
                                              {this.state.languageFile && this.state.languageFile.chooseClassroom}
                                            </Typography>
                                            <Typography 
                                              className="cockpit-message" 
                                              variant="h5"
                                            >
                                              {this.state.languageFile && this.state.languageFile.chooseClassroomDescription}
                                            </Typography>
                                        </div>
                                    </div>
                                  ) 
                                  : null
                                }
                            </>
                        ) : (this.state.accountType === "student" || this.state.accountType === 'school_student') ? (
                            <>
                                {this.state.classroomListLength === 0 ? (
                                    <div 
                                      className="cockpit-message-horizontal-center"
                                    >
                                        <div 
                                          className="cockpit-message-vertical-center"
                                        >
                                            <Typography 
                                              className="cockpit-message cockpit-message-title" 
                                              variant="h4"
                                            >
                                              {this.state.languageFile && this.state.languageFile.studentNoClassrooms}
                                            </Typography>
                                            {this.state.accountType === 'student' && (
                                              <>
                                                <Typography 
                                                  className="cockpit-message" 
                                                  variant="h5"
                                                >
                                                  {this.state.languageFile && this.state.languageFile.studentNoClassroomsDescription}
                                                </Typography>
                                              </>
                                            )}
                                        </div>
                                    </div>
                                ) : !this.state.openClassroom ? (
                                    <div 
                                      className="adaptive-cockpit-message cockpit-message-horizontal-center"
                                    >
                                        <div 
                                          className="cockpit-message-vertical-center"
                                        >
                                            <Typography 
                                              className="cockpit-message cockpit-message-title" 
                                              variant="h4"
                                            >
                                              {this.state.languageFile && this.state.languageFile.chooseClassroom}
                                            </Typography>
                                            <Typography 
                                              className="cockpit-message" 
                                              variant="h5"
                                            >
                                              {this.state.languageFile && this.state.languageFile.chooseClassroomDescription}
                                            </Typography>
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                        {this.state.openClassroom && (
                            <ClassroomView
                              theme={
                                this.props.theme
                              }
                              language={
                                this.props.language
                              }
                              accountType={
                                this.state.accountType
                              } 
                              classroom={
                                this.state.openClassroom
                              } 
                              tabResetPending={
                                this.state.tabResetPending
                              } 
                              onTabReset={
                                () => this.setState(
                                  { 
                                    tabResetPending: false 
                                  }
                                )
                              } 
                              onClose={
                                () => this.setState(
                                  { 
                                    openClassroom: null 
                                  }
                                )
                              } 
                              onClassroomEdited={
                                (newClassroomName, newClassroomNameShort) => this.handleClassroomEdited(newClassroomName, newClassroomNameShort)
                              } 
                              onClassroomDeleted={
                                () => this.handleClassroomDeleted()
                              } 
                              onCriticalError={
                                (error) => this.props.onCriticalError(error)
                              }
                            />
                        )}
                        {this.state.openGrade && (
                          <GradeView
                            language={
                              this.props.language
                            }
                            grade={
                              this.state.openGrade
                            }
                            tabResetPending={
                              this.state.tabResetPending
                            }
                            onTabReset={
                              () => this.setState({
                                tabResetPending: false
                              })
                            }
                            onClose={
                              () => this.setState({
                                openGrade: null
                              })
                            }
                            onGradeRenamed={
                              (newGradeName) => this.handleGradeRenamed(newGradeName)
                            }
                            onGradeDeleted={
                              () => this.handleGradeDeleted()
                            }
                            onCriticalError={
                              (error) => this.props.onCriticalError(error)
                            }
                          />
                        )}
                    </>
                )}
            </main>
        );
    }

    handleGradeRenamed(newGradeName) {
      this.setState({
        newGradeName: newGradeName
      });
    }

    handleGradeRenameFinished() {
      this.setState({
        newGradeName: null
      });
    }

    handleGradeDeleted() {
      this.setState({
        gradeDeleteScheduled: true
      });

      let navigate = this.props.navigate;
      navigate('/cockpit');
    }

    handleGradeDeleteFinished() {
      this.setState({
        openGrade: null,
        gradeDeleteScheduled: false,
        gradeListLength: this.state.gradeListLength - 1
      });

      let navigate = this.props.navigate;
      navigate('/cockpit');
    }

    handleClassroomEdited(newClassroomName, newClassroomNameShort) {
        this.setState({
          newClassroomInformation: {
            classroomName: newClassroomName,
            classroomNameShort: newClassroomNameShort
          }
        });
    }

    handleClassroomRenameFinished() {
        this.setState({
          newClassroomInformation: null
        });
    }

    handleClassroomDeleted() {
        this.setState({ classroomDeleteScheduled: true });

        let navigate = this.props.navigate;
        navigate("/cockpit");
    }

    handleClassroomDeleteFinished() {
        this.setState({
            openClassroom: null,
            classroomDeleteScheduled: false,
            classroomListLength: this.state.classroomListLength - 1
        });

        let navigate = this.props.navigate;
        navigate("/cockpit");
    }
}

export default function Cockpit(props) {
    return <CockpitComponent {...props} navigate={useNavigate()}/>
}