import React from "react";
import { useNavigate } from "react-router-dom";

import { TextField, FormControl, InputLabel, Select, MenuItem, Button, CircularProgress, Alert } from "@mui/material";

import "./ClassroomCreationForm.css";
import createClassroom from "../../../utils/apiCaller/classroomManager/ClassroomCreator";

class ClassroomCreationFormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            waiting: false,
            error: null
        };
    }

    render() {
        return (
            <div 
              className="classroom-creation-form-container"
            >
                <form 
                  className="classroom-creation-form" 
                  onSubmit={
                    (e) => this.handleSubmit(e)
                  }
                >
                    <div 
                      className="fields"
                    >
                        <TextField 
                          name="classroomName" 
                          type="text" 
                          label="Nazwa klasy" 
                          autoComplete="none" 
                          required 
                          disabled={
                            this.state.waiting
                          } 
                          margin="normal" 
                          fullWidth 
                          variant="outlined" 
                          color="secondary" 
                          inputProps={{ 
                            maxLength: 255 
                          }}
                        />
                        <FormControl 
                          disabled={
                            this.state.waiting
                          } 
                          margin="normal" 
                          fullWidth 
                          color="secondary"
                        >
                            <InputLabel>
                              Język
                            </InputLabel>
                            <Select 
                              name="language" 
                              label="Język" 
                              required 
                              fullWidth
                            >
                                <MenuItem 
                                  value="english"
                                >
                                  Angielski
                                </MenuItem>
                                <MenuItem 
                                  value="german"
                                >
                                  Niemiecki
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <Button 
                      type="submit" 
                      disabled={
                        this.state.waiting
                      } 
                      fullWidth 
                      variant="contained" 
                      color="secondary"
                    >
                      Utwórz
                    </Button>
                </form>
                {this.state.waiting && (
                    <CircularProgress 
                      color="secondary"
                    />
                )}
                {this.state.error === "no-connection" && (
                    <Alert 
                      severity="error"
                    >
                      Wystąpił błąd podczas łączenia z serwerem. Sprawdź swoje połączenie z internetem.
                    </Alert>
                )}
                {this.state.error === "unknown-error" && (
                    <Alert 
                      severity="error"
                    >
                      Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.
                    </Alert>
                )}
            </div>
        );
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({
            waiting: true,
            error: null
        });
        
        let fields = e.target.elements;
        createClassroom(fields["classroomName"].value, fields["language"].value, (status, classroomCode) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                switch (status) {
                    case "not-signed-in":
                    case "account-deleted":
                        this.props.onCriticalError(status);
                        break;
                    
                    default:
                        this.setState({ error: status });
                }
                return;
            }

            let navigate = this.props.navigate;
            navigate(`/cockpit?classroom=${encodeURIComponent(classroomCode)}`);
        });
    }
}

export default function ClassroomCreationForm(props) {
    return <ClassroomCreationFormComponent {...props} navigate={useNavigate()}/>
}