import { callApi } from '../ApiCaller';

export default function createGradeClassroom(gradeCode, classroomName, classroomNameShort, language, description, rules, teacherId, students, onFinish) {
  callApi('createGradeClassroom', {
    gradeCode: gradeCode,
    classroomName: classroomName,
    classroomNameShort: classroomNameShort,
    language: language,
    description: description,
    rules: rules,
    teacherId: teacherId,
    students: students
  }, (callStatus, httpStatus, classroomCode) => {
    if (callStatus !== 'success') {
      onFinish(callStatus);
      return;
    }

    switch (httpStatus) {
      case 200:
        onFinish('success', classroomCode);
        break;

      case 401:
        onFinish('not-signed-in');
        break;

      case 403:
        onFinish('account-deleted');
        break;

      default:
        onFinish('unknown-error');
    }
  });
}