import React from "react";
import { useNavigate } from "react-router-dom";

import { TextField, Typography, Button, CircularProgress, Alert } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import "./AccountCreationForm.css";

import getLanguageFile from '../../../utils/apiCaller/languageManager/LanguageFileGetter';
import checkPassword from '../../../utils/PasswordChecker';
import createAccount from "../../../utils/apiCaller/accountManager/AccountCreator";

class AccountCreationFormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          languageFile: null,
            waiting: false,
            error: null,
            color: props.accountType === "student" 
            ? "primary"
            : props.accountType === "teacher" 
              ? "secondary"
              : null,
            autoFocused: false,
            passwordStatusVisible: false,
            passwordStatus: {
              hasEightCharacters: false,
              hasLowercaseLetter: false,
              hasUppercaseLetter: false,
              hasDigit: false,
              hasSymbol: false
            }
        };
    }

    componentDidMount() {
      getLanguageFile(this.props.language, 'AccountCreationForm', (status, file) => {
        if (status !== 'success') {
          this.props.onCriticalError(status);
          return;
        }

        this.setState({
          languageFile: file
        });
      });
    }

    render() {
        return (
            <div 
              className="account-creation-form-container"
            >
                <form 
                  className="account-creation-form" 
                  onSubmit={(e) => this.handleSubmit(e)}
                >
                    <div 
                      className="fields"
                    >
                        <TextField 
                          name="email" 
                          type="email" 
                          label={
                            this.state.languageFile && this.state.languageFile.email
                          }
                          autoComplete="none"
                          required 
                          disabled={
                            this.state.waiting
                          } 
                          margin="normal" 
                          fullWidth 
                          variant="outlined" 
                          color={
                            this.state.color
                          } 
                          inputProps={{ 
                            maxLength: 255 
                          }}
                          inputRef={
                            (input) => {
                              if (input && !this.state.autoFocused) {
                                input.focus();
                                this.setState({
                                  autoFocused: true
                                });
                              }
                            }
                          }
                        />
                        <TextField 
                          name="username" 
                          type="text" 
                          label={
                            this.state.languageFile && this.state.languageFile.username
                          }
                          autoComplete="none" 
                          required 
                          disabled={
                            this.state.waiting
                          } 
                          margin="normal" 
                          fullWidth 
                          variant="outlined" 
                          color={
                            this.state.color
                          } 
                          inputProps={{ 
                            maxLength: 255 
                          }}
                        />
                        <TextField 
                          id='password'
                          name="password" 
                          type="password" 
                          label={
                            this.state.languageFile && this.state.languageFile.password
                          }
                          autoComplete="none" 
                          required 
                          disabled={
                            this.state.waiting
                          } 
                          margin="normal" 
                          fullWidth 
                          variant="outlined" 
                          color={
                            this.state.color
                          }
                          onChange={
                            () => this.handlePasswordChange()
                          }
                          inputProps={{
                            maxLength: 255
                          }}
                        />
                        {this.state.passwordStatusVisible && (
                          <div
                            className='password-status-container'
                          >
                            <Typography
                              variant='body1'
                            >
                              {this.state.languageFile && this.state.languageFile.passwordStatusPrompt}
                            </Typography>
                            <Typography
                              className={
                                `password-status ${
                                  this.state.passwordStatus.hasEightCharacters
                                    ? 'password-status-correct'
                                    : 'password-status-incorrect'
                                }`
                              }
                              variant='body1'
                            >
                              {this.state.passwordStatus.hasEightCharacters
                                ? (
                                  <CheckIcon
                                    className='password-status-icon'
                                  />
                                )
                                : (
                                  <CloseIcon
                                    className='password-status-icon'
                                  />
                                )
                              }
                              {this.state.languageFile && this.state.languageFile.passwordStatusLength}
                            </Typography>
                            <Typography
                              className={
                                `password-status ${
                                  this.state.passwordStatus.hasLowercaseLetter
                                    ? 'password-status-correct'
                                    : 'password-status-incorrect'
                                }`
                              }
                              variant='body1'
                            >
                              {this.state.passwordStatus.hasLowercaseLetter
                                ? (
                                  <CheckIcon
                                    className='password-status-icon'
                                  />
                                )
                                : (
                                  <CloseIcon
                                    className='password-status-icon'
                                  />
                                )
                              }
                              {this.state.languageFile && this.state.languageFile.passwordStatusLowerCaseLetter}
                            </Typography>
                            <Typography
                              className={
                                `password-status ${
                                  this.state.passwordStatus.hasUppercaseLetter
                                  ? 'password-status-correct'
                                  : 'password-status-incorrect'
                                }`
                              }
                              variant='body1'
                            >
                              {this.state.passwordStatus.hasUppercaseLetter
                                ? (
                                  <CheckIcon
                                    className='password-status-icon'
                                  />
                                )
                                : (
                                  <CloseIcon
                                    className='password-status-icon'
                                  />
                                )
                              }
                              {this.state.languageFile && this.state.languageFile.passwordStatusUpperCaseLetter}
                            </Typography>
                            <Typography
                              className={
                                `password-status ${
                                  this.state.passwordStatus.hasDigit
                                    ? 'password-status-correct'
                                    : 'password-status-incorrect'
                                }`
                              }
                              variant='body1'
                            >
                              {this.state.passwordStatus.hasDigit
                                ? (
                                  <CheckIcon
                                    className='password-status-icon'
                                  />
                                )
                                : (
                                  <CloseIcon
                                    className='password-status-icon'
                                  />
                                )
                              }
                              {this.state.languageFile && this.state.languageFile.passwordStatusDigit}
                            </Typography>
                            <Typography
                              className={
                                `password-status ${
                                  this.state.passwordStatus.hasSymbol
                                    ? 'password-status-correct'
                                    : 'password-status-incorrect'
                                }`
                              }
                              variant='body1'
                            >
                              {this.state.passwordStatus.hasSymbol
                                ? (
                                  <CheckIcon
                                    className='password-status-icon'
                                  />
                                )
                                : (
                                  <CloseIcon
                                    className='password-status-icon'
                                  />
                                )
                              }
                              {this.state.languageFile && this.state.languageFile.passwordStatusSymbol}
                            </Typography>
                          </div>
                        )}
                        <TextField 
                          name="passwordRepeat" 
                          type="password" 
                          label={
                            this.state.languageFile && this.state.languageFile.passwordRepeat
                          }
                          required 
                          disabled={
                            this.state.waiting
                          } 
                          margin="normal" 
                          fullWidth 
                          variant="outlined" 
                          color={
                            this.state.color
                          }
                          inputProps={{
                            maxLength: 255
                          }}
                        />
                    </div>
                    <Button 
                      type="submit" 
                      disabled={
                        this.state.waiting
                      } 
                      fullWidth 
                      variant="contained" 
                      color={
                        this.state.color
                      }
                    >
                      {this.state.languageFile && this.state.languageFile.create}
                    </Button>
                </form>
                {this.state.waiting && (
                    <CircularProgress 
                      color={
                        this.state.color
                      }
                    />
                )}
                {this.state.error === "invalid-password-repeat" && (
                    <Alert 
                      severity="error"
                    >
                      {this.state.languageFile && this.state.languageFile.passwordError}
                    </Alert>
                )}
                {this.state.error === "no-connection" && (
                    <Alert 
                      severity="error"
                    >
                      {this.state.languageFile && this.state.languageFile.noConnectionError}
                    </Alert>
                )}
                {this.state.error === "unknown-error" && (
                    <Alert 
                      severity="error"
                    >
                      {this.state.languageFile && this.state.languageFile.unknownError}
                    </Alert>
                )}
                {this.state.error === 'domain-taken' && (
                  <Alert
                    severity='error'
                  >
                    {this.state.languageFile && this.state.languageFile.domainTakenError}
                  </Alert>
                )}
                {this.state.error === "account-already-exists" && (
                    <Alert 
                      severity="error"
                    >
                      {this.state.languageFile && this.state.languageFile.emailTakenError}
                    </Alert>
                )}
            </div>
        );
    }

    handleSubmit(e) {
        e.preventDefault();
        for (let status of Object.values(this.state.passwordStatus)) {
          if (!status) {
            if (!this.state.passwordStatusVisible)
              this.setState({
                passwordStatusVisible: true
              });

            return;
          }
        }

        this.setState({
            passwordStatusVisible: false,
            waiting: true,
            error: null
        });
        
        let fields = e.target.elements;
        if (fields["password"].value !== fields["passwordRepeat"].value) {
            this.setState({
                waiting: false,
                error: "invalid-password-repeat"
            });
            return;
        }

        createAccount(this.props.accountType, fields["email"].value, fields["username"].value, fields["password"].value, (status) => {
            this.setState({ waiting: false });
            if (status !== "success") {
                this.setState({ error: status });
                return;
            }

            let navigate = this.props.navigate;
            navigate("/signup/success");
        });
    }
    
    handlePasswordChange() {
      const password = document.querySelector('#password').value;
      this.setState({
        passwordStatusVisible: true,
        passwordStatus: checkPassword(password)
      });
    }
}

export default function AccountCreationForm(props) {
    return <AccountCreationFormComponent {...props} navigate={useNavigate()}/>
}